import { List, ListItem, ListItemText, ListProps } from '@material-ui/core';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import { OmniboxItemSelectionTrigger } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { OmniboxItem, OmniboxOnClickProps } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { Row } from '../common';

type OmniboxProps<T> = {
    onClick: (opts: OmniboxOnClickProps<T>) => void;
    onClose?: () => void;
    items: OmniboxItem<T>[] | null;
    listStyle?: CSSProperties;
    listProps?: Partial<ListProps>;
    disableKeyboard?: boolean;
    listItemStyle?: CSSProperties;
};

export function Omnibox<T>({
    onClick,
    onClose,
    items,
    listStyle,
    listItemStyle,
    disableKeyboard,
    listProps,
}: OmniboxProps<T>): JSX.Element {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const listRef = useRef<HTMLUListElement | null>(null);

    const mouseDownEventListener = (e: MouseEvent) => {
        const clickTarget = e.target;

        const isClickTargetWithinList =
            listRef.current && clickTarget && clickTarget instanceof Node && !listRef.current.contains(clickTarget);

        if (onClose && isClickTargetWithinList) {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('mouseup', mouseDownEventListener);
        return () => window.removeEventListener('mouseup', mouseDownEventListener);
    }, []);

    useEffect(() => {
        const eventListener = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && items?.length) {
                onClick({
                    item: items[tabIndex].item,
                    title: items[tabIndex].title,
                    trigger: OmniboxItemSelectionTrigger.Enter,
                    index: tabIndex,
                });
            }

            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();

                let newTabIndex = tabIndex;
                if (e.key === 'ArrowDown' && items?.length) {
                    if (tabIndex + 1 <= items?.length - 1) {
                        newTabIndex = tabIndex + 1;
                    }
                }
                if (e.key === 'ArrowUp') {
                    if (tabIndex !== null && tabIndex - 1 >= 0) {
                        newTabIndex = tabIndex - 1;
                    }
                }

                setTabIndex(newTabIndex);
                return false;
            }
        };

        if (!disableKeyboard) {
            window?.addEventListener('keydown', eventListener);
        }

        return () => {
            if (!disableKeyboard) {
                return window.removeEventListener('keydown', eventListener);
            }
        };
    }, [tabIndex, items]);

    if (!items?.length) {
        return <></>;
    }

    return (
        <List
            ref={listRef}
            style={{
                maxHeight: '200px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...listStyle,
            }}
            onMouseEnter={() => undefined}
            disablePadding
            {...listProps}
        >
            {items.map((item, i) => {
                return (
                    <Row key={`${item.title}-${i}`}>
                        <ListItem
                            id={`${ElementId.OmniboxListItem}-${i}`}
                            style={{ height: '26px', ...listItemStyle }}
                            onClick={() => {
                                onClick({
                                    item: item.item,
                                    title: item.title,
                                    trigger: OmniboxItemSelectionTrigger.Click,
                                    index: i,
                                });
                            }}
                            disableGutters
                            button={true}
                        >
                            <ListItemText
                                className={withFullStoryMasking()}
                                tabIndex={i}
                                style={{
                                    height: '26px',
                                    backgroundColor: !disableKeyboard && i === tabIndex ? 'rgba(0,0,0,0.08)' : 'unset',
                                }}
                                primaryTypographyProps={{
                                    style: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                                }}
                                primary={item.title}
                            />
                        </ListItem>
                        {item.endIcon}
                    </Row>
                );
            })}
        </List>
    );
}
