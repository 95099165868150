import { TextField } from '@material-ui/core';

import { ClientEventType, HowDidYouHearCode, HowDidYouHearMap } from '@spinach-shared/types';

import { useExperienceTracking, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { ListItemValue } from '../../../types';
import { withFullStoryMasking } from '../../../utils';
import { DropDown, Row, SlidingColumn, Spacing } from '../../common';
import { useSpinachInputStyles } from '../../input';
import { ScrollView } from '../../series';
import { Stationary } from '../../series/first-series/common';
import { PrimaryButton } from '../../stand-up/PrimaryButton';
import { StepProps } from './common';

export type AboutProps = StepProps & {
    teamKind: string;
    setTeamKind: (teamKind: string) => void;
    role: string;
    setRole: (role: string) => void;
    otherRole: string;
    setOtherRole: (otherRole: string) => void;
    howDidYouHear: string;
    setHowDidYouHear: (howDidYouHear: string) => void;
    teamKindOther: string;
    setTeamKindOther: (str: string) => void;
    howDidYouHearOther: string;
    setHowDidYouHearOther: (str: string) => void;
};

const TeamKindMap = [
    {
        label: 'Product/Engineering',
        code: 'product-engineering',
    },
    {
        label: 'Customer Support',
        code: 'customer-support',
    },
    {
        label: 'Marketing',
        code: 'marketing',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

const TeamRoleMap = [
    {
        label: 'Senior Leader',
        code: 'senior-leader',
    },
    {
        label: 'Individual Contributor',
        code: 'ic',
    },
    {
        label: 'Team Lead',
        code: 'team-lead',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

export function AboutStep({
    direction,
    loadingMessage,
    onSubmit,
    teamKind,
    setTeamKind,
    role,
    setRole,
    otherRole,
    setOtherRole,
    howDidYouHear,
    setHowDidYouHear,
    teamKindOther,
    setTeamKindOther,
    howDidYouHearOther,
    setHowDidYouHearOther,
}: AboutProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAboutStep);
    const track = useExperienceTracking();
    const isDisabled =
        !teamKind ||
        !role ||
        (role === 'other' && !otherRole.trim()) ||
        (teamKind === 'other' && !teamKindOther.trim()) ||
        (howDidYouHear === 'other' && !howDidYouHearOther.trim());
    const classes = useSpinachInputStyles({ value: '' });
    return (
        <Stationary style={{ minHeight: '490px' }}>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <HeaderThreeOnboard>Tell us about yourself</HeaderThreeOnboard>

                    <Spacing />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>What team are you on?</BodyBigOnboard>
                    </Row>
                    <Row>
                        <DropDown
                            title={'Select an option'}
                            values={Object.values(TeamKindMap) as ListItemValue<HowDidYouHearCode>[]}
                            handleSelection={(value) => {
                                setTeamKind(value);
                                track(ClientEventType.UserSelectedTeamKind, {
                                    TeamKind: value,
                                });
                            }}
                            selected={TeamKindMap.find((t) => t.code === teamKind)?.label || undefined}
                        />
                    </Row>

                    {teamKind === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="What kind of team is it?"
                                    value={teamKindOther}
                                    onChange={(e) => {
                                        setTeamKindOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>What is your role?</BodyBigOnboard>
                    </Row>
                    <Row>
                        <DropDown
                            title={'What is your role?'}
                            values={Object.values(TeamRoleMap) as ListItemValue<HowDidYouHearCode>[]}
                            handleSelection={(value) => {
                                setRole(value);
                                track(ClientEventType.UserSelectedTeamRole, {
                                    Role: value,
                                });
                            }}
                            selected={TeamRoleMap.find((t) => t.code === role)?.label || undefined}
                        />
                    </Row>

                    {role === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="Your role"
                                    value={otherRole}
                                    onChange={(e) => {
                                        setOtherRole(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>How did you hear about us?</BodyBigOnboard>
                    </Row>

                    <Row>
                        <DropDown
                            title={'Select an option'}
                            values={Object.values(HowDidYouHearMap) as ListItemValue<HowDidYouHearCode>[]}
                            handleSelection={(value) => {
                                track(ClientEventType.UserSelectedHowDidYouHear);
                                setHowDidYouHear(value);
                            }}
                            selected={
                                Object.values(HowDidYouHearMap).find((t) => t.code === howDidYouHear)?.label ||
                                undefined
                            }
                        />
                    </Row>

                    {howDidYouHear === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="How did you hear?"
                                    value={howDidYouHearOther}
                                    onChange={(e) => {
                                        setHowDidYouHearOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                </SlidingColumn>
            </ScrollView>

            <Spacing />
            <PrimaryButton
                onClick={onSubmit}
                disabled={!!loadingMessage || isDisabled}
                isLoading={!!loadingMessage}
                title={'Next'}
            />
        </Stationary>
    );
}
