import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import { PrimaryButtonLabel, lightTheme } from '../../styles';
import { ButtonProps } from '../../types/StandUp';
import { PillButton } from './Shared';

export function PrimaryButton({
    title,
    onClick,
    disabled = false,
    isLoading = false,
    loadingText = 'Loading...',
    fadeInDuration,
    endIcon,
    preIcon,
    size,
    containerStyles,
    ...rest
}: ButtonProps): JSX.Element {
    return (
        <PillButton
            size={size}
            onClick={() => onClick()}
            disabled={disabled || isLoading}
            fadeInDuration={fadeInDuration}
            style={containerStyles}
            {...rest}
        >
            <PrimaryButtonLabel size={size} style={{ ...rest.labelStyles }}>
                {preIcon}
                <>
                    {isLoading ? (
                        <CircularProgress
                            size={'20px'}
                            style={{ marginRight: '5px', marginBottom: '-5px', color: lightTheme.primary.orangeDark }}
                        />
                    ) : null}
                    {isLoading ? loadingText : title}
                    {endIcon}
                </>
            </PrimaryButtonLabel>
        </PillButton>
    );
}

export const CenteredPrimaryButton = styled(PrimaryButton)`
    align-self: center;
    white-space: nowrap;
`;
