import moment from 'moment';
import { useState } from 'react';

import { CalendarEvent, ClientEventType } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import { postSlackDefaultUserChannel } from '../../../apis/postSlackDefaultUserChannel';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../hooks';
import { useBulkUpdateScribeOnEvents, useGoogleCalendarEvents } from '../../../hooks/useGoogleCalendarEvents';
import { Direction, ViewContainer } from '../../common';
import { isScribeOnEvent } from '../ScribeCalendarPage';
import { AboutStep } from './AboutStep';
import { AddToMeetingsStep } from './AddToMeetingsStep';
import { CalendarPermissionsStep } from './CalendarPermissionsStep';
import { FinishedFlow } from './FinishedFlowStep';
import { ManualInvite } from './ManualInviteStep';
import { SlackDefaults } from './SlackDefaultsStep';
import { SlackSetup } from './SlackSetupStep';
import { TicketSetup } from './TicketSetupStep';
import { OnboardingStep } from './common';

export function OnboardingFlowContainer(): JSX.Element {
    return (
        <ViewContainer>
            <OnboardingFlow />
        </ViewContainer>
    );
}

export function OnboardingFlow(): JSX.Element {
    const { routeToScribeUserSettings } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(OnboardingStep.About);
    const [direction, setSlidingDirection] = useState<Direction>(Direction.Forward);
    const [loadingMessage, setLoadingMessage] = useState('');
    const track = useExperienceTracking();

    const [teamKind, setTeamKind] = useState('');
    const [teamKindOther, setTeamKindOther] = useState('');
    const [role, setRole] = useState('');
    const [otherRole, setOtherRole] = useState('');
    const [howDidYouHear, setHowDidYouHear] = useState('');
    const [howDidYouHearOther, setHowDidYouHearOther] = useState('');

    const [onboardingEventsToAddScribeTo, setOnboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const { data, error, queryKey } = useGoogleCalendarEvents({
        startISOString: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
        endISOString: moment.tz(TimeUtils.getTimezoneRegion()).add(10, 'days').endOf('day').toISOString(),
    });

    const { mutateAsync: bulkUpdateAddScribeToEvents, error: bulkError } = useBulkUpdateScribeOnEvents(queryKey);

    const preSelectedChannel =
        user.slackSettings?.defaultChannelId && user.slackSettings?.defaultChannelName
            ? { code: user.slackSettings?.defaultChannelId, label: user.slackSettings?.defaultChannelName }
            : null;

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(preSelectedChannel);

    function progressTo(step: OnboardingStep) {
        setSlidingDirection(Direction.Forward);
        setOnboardingStep(step);
    }

    function goBackTo(step: OnboardingStep) {
        setSlidingDirection(Direction.Backward);
        setOnboardingStep(step);
    }

    switch (onboardingStep) {
        case OnboardingStep.About:
            return (
                <AboutStep
                    teamKind={teamKind}
                    setTeamKind={setTeamKind}
                    role={role}
                    setRole={setRole}
                    otherRole={otherRole}
                    setOtherRole={setOtherRole}
                    howDidYouHear={howDidYouHear}
                    setHowDidYouHear={setHowDidYouHear}
                    direction={direction}
                    teamKindOther={teamKindOther}
                    setTeamKindOther={setTeamKindOther}
                    howDidYouHearOther={howDidYouHearOther}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    onSubmit={async () => {
                        setLoadingMessage('Loading...');

                        track(ClientEventType.UserSubmittedAboutStep, {
                            TeamKind: teamKind,
                            TeamKindOther: teamKindOther,
                            RoleOnTeam: role,
                            RoleOnTeamOther: otherRole,
                            HowDidYouHear: howDidYouHear,
                            HowDidYouHearOther: howDidYouHearOther,
                        });
                        const updatedUser = await patchUser({
                            metadata: {
                                teamKind,
                                teamKindOther,
                                roleOnTeam: role,
                                roleOnTeamOther: otherRole,
                                howDidYouHear,
                                howDidYouHearOther,
                            },
                        });
                        setLoadingMessage('');
                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }
                        if (user.isAuthedForGoogleCalendar) {
                            progressTo(OnboardingStep.SelectEventForMeeting);
                        } else if (user.googleId) {
                            progressTo(OnboardingStep.CalendarPermissions);
                        } else {
                            progressTo(OnboardingStep.ManualInvite);
                        }
                    }}
                    loadingMessage={loadingMessage}
                />
            );
        case OnboardingStep.SelectEventForMeeting:
            return (
                <AddToMeetingsStep
                    data={data}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedAddSpinachToMeetingsStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    onEventClick={(event: CalendarEvent) => {
                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);
                        if (isScribeOn) {
                            return;
                        }
                        track(ClientEventType.GoogleCalendarMeetingItemClick, {
                            ICalUid: event.iCalUID,
                            Action: !!onboardingEventsToAddScribeTo.find((e) => e.iCalUID === event.iCalUID)
                                ? 'remove'
                                : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: true,
                            AttendeeCount: event.attendees?.length,
                        });
                        if (!event.iCalUID) {
                            return;
                        }
                        if (onboardingEventsToAddScribeTo.includes(event)) {
                            setOnboardingEventsToAddScribeTo(
                                onboardingEventsToAddScribeTo.filter(
                                    (eventToAdd) => eventToAdd.iCalUID !== event.iCalUID
                                )
                            );
                        } else {
                            setOnboardingEventsToAddScribeTo([...onboardingEventsToAddScribeTo, event]);
                        }
                    }}
                    hasError={!!error || !!bulkError}
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedAddSpinachToMeetingStep, {
                            NumberOfEventsScribeWasAddedTo: onboardingEventsToAddScribeTo.length,
                        });

                        setLoadingMessage('Saving...');
                        await bulkUpdateAddScribeToEvents(
                            onboardingEventsToAddScribeTo.map((event) => ({
                                iCalUid: event.iCalUID!,
                                addToEvent: true,
                            }))
                        );

                        setLoadingMessage('');
                        setOnboardingEventsToAddScribeTo([]);

                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromAddSpinachToMeetingsStep);
                        goBackTo(OnboardingStep.About);
                    }}
                />
            );
        case OnboardingStep.CalendarPermissions:
            return (
                <CalendarPermissionsStep
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedCalendarPermissionsStep);
                        if (user.isAuthedForGoogleCalendar) {
                            progressTo(OnboardingStep.SelectEventForMeeting);
                        } else if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromCalendarPermissionsStep);
                        goBackTo(OnboardingStep.About);
                    }}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedCalendarPermissionsStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    onProgressToAddEventToMeeting={(updatedUser) => {
                        if (updatedUser.isAuthedForGoogleCalendar) {
                            track(ClientEventType.UserAutoProgressedCalendarPermissionsStep);
                            progressTo(OnboardingStep.SelectEventForMeeting);
                        }
                    }}
                />
            );
        case OnboardingStep.ManualInvite:
            return (
                <ManualInvite
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedManualSpinachSetupStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromManualSpinachSetupStep);
                        if (user.isAuthedForSlack) {
                            goBackTo(OnboardingStep.About);
                        } else {
                            goBackTo(OnboardingStep.Slack);
                        }
                    }}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedManualSpinachSetupStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                />
            );
        case OnboardingStep.Slack:
            return (
                <SlackSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectSlackStep);
                        if (user.isAuthedForGoogleCalendar) {
                            goBackTo(OnboardingStep.SelectEventForMeeting);
                        } else if (user.googleId) {
                            goBackTo(OnboardingStep.CalendarPermissions);
                        } else {
                            goBackTo(OnboardingStep.ManualInvite);
                        }
                    }}
                    direction={direction}
                    onSkip={(reason: string) => {
                        track(ClientEventType.UserSkippedConnectSlackStep, {
                            Reason: reason,
                        });
                        progressTo(OnboardingStep.Tickets);
                    }}
                    progressToSlackDefaults={() => {
                        track(ClientEventType.UserAutoProgressedConnectSlackStep);
                        progressTo(OnboardingStep.SlackDefaults);
                    }}
                />
            );
        case OnboardingStep.SlackDefaults:
            return (
                <SlackDefaults
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromSlackDefaultsStep);
                        if (user.isAuthedForSlack) {
                            if (user.isAuthedForGoogleCalendar) {
                                goBackTo(OnboardingStep.SelectEventForMeeting);
                            } else if (user.googleId) {
                                goBackTo(OnboardingStep.CalendarPermissions);
                            } else {
                                goBackTo(OnboardingStep.ManualInvite);
                            }
                        } else {
                            goBackTo(OnboardingStep.Slack);
                        }
                    }}
                    selectedChannel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                    direction={direction}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedSlackDefaultsStep);
                        progressTo(OnboardingStep.Tickets);
                    }}
                    onSubmit={async () => {
                        if (!selectedChannel) {
                            return;
                        }

                        track(ClientEventType.UserSubmittedSlackDefaultsStep);
                        setLoadingMessage('Saving...');
                        const teamId = user.slackSettings?.teamId;
                        const teamType = user.slackSettings?.teamType;

                        if (!teamId || !teamType) {
                            return;
                        }

                        const result = await postSlackDefaultUserChannel(
                            teamId,
                            teamType,
                            selectedChannel.code,
                            selectedChannel.label.replaceAll('#', '')
                        );

                        if (result?.user) {
                            setUser(result.user);
                        }
                        setLoadingMessage('');
                        progressTo(OnboardingStep.Tickets);
                    }}
                />
            );
        case OnboardingStep.Tickets:
            return (
                <TicketSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectTicketsStep);
                        goBackTo(OnboardingStep.SlackDefaults);
                    }}
                    direction={direction}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedConnectTicketsStep);
                        setLoadingMessage('Here we go...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(OnboardingStep.Finished);
                    }}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedConnectTicketsStep);
                        setLoadingMessage('Preparing...');

                        await patchUser({ metadata: { isOnboarded: true } });
                        progressTo(OnboardingStep.Finished);
                        setLoadingMessage('');
                    }}
                />
            );
        case OnboardingStep.Finished:
            return (
                <FinishedFlow
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromFinishOnboardingStep);
                        goBackTo(OnboardingStep.Tickets);
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        setLoadingMessage('Here we go...');
                        track(ClientEventType.UserClickedGoToDashboardFromFinished);
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        routeToScribeUserSettings();
                    }}
                />
            );
    }
}
