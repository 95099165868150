import { useEffect } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { postExperienceEvent } from '../apis';
import { useGlobalUser } from './useGlobalUser';
import { useGlobalNullableLiveSeries } from './useLiveSeries';
import { useGlobalNullableStoredSeries } from './useSeriesData';

/**
 * @returns a `track(eventType, extraData)` function that auto-populates userIdentity metadata
 * Reduces boilerplate of common analytics needs on the front-end
 * Usage: const track = useExperienceTracking();
 * track(ClientEventType.SupportTabClicked)
 * track(ClientEventType.SupportTabClicked, { Count: count });
 *
 * can be awaited if desired.
 */
export function useExperienceTracking(): (eventType: ClientEventType, extraData?: any) => Promise<void> {
    const [user] = useGlobalUser();
    const [storedSeries] = useGlobalNullableStoredSeries();
    const [liveSeries] = useGlobalNullableLiveSeries();

    return async (eventType: ClientEventType, extraData: any = {}) => {
        await postExperienceEvent({
            eventType,
            payload: {
                ...user?.toUserIdentityPayload(),
                SeriesId: storedSeries?.slug,
                IsAISeries: storedSeries?.isStandupScribeSeries,
                ...extraData,
                CurrentDemoGuide: liveSeries?.currentDemoGuide,
                CurrentURL: location.href,
                BrowserWidth: window.innerWidth,
                BrowserHeight: window.innerHeight,
                DemoVersion: user?.metadata.demoVersion,
            },
        });
    };
}

export function useLandingAnalytic(eventType: ClientEventType, extraData?: any) {
    const track = useExperienceTracking();

    useEffect(() => {
        track(eventType, extraData);
    }, []);
}
