import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    IconButton,
    Slide,
    Tooltip,
} from '@material-ui/core';
import { styled as muiStyled } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { ArrowRight } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { CURRENT_ITEMS_CUSTOM_LIST_ID, DemoGuide } from '@spinach-shared/constants';
import {
    ClientEventType,
    ContextItemClickPayload,
    GoalStatus,
    JiraIssueSelectionType,
    Sentiment,
    SpinachUpdateType,
    TypedUpdate,
} from '@spinach-shared/types';
import { getNewTypedUpdate, getTicketSource } from '@spinach-shared/utils';

import { JiraInput } from '.';
import { postExperienceEvent } from '../../../apis';
import { ReactComponent as CheckIcon } from '../../../assets/check-icon.svg';
import { Titles } from '../../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalStoredSeries,
    useIssueBasedEnablement,
    useJiraEnablement,
    useSeriesReality,
    useTypedUpdateWrapper,
} from '../../../hooks';
import { BaseButton, BodyRegular, lightTheme } from '../../../styles';
import { withFullStoryMasking } from '../../../utils';
import { Bullet, BulletMark, Column, PulsingHint, Row } from '../../common';
import { Notification } from '../../stand-up';
import { IssueBasedAttachments } from '../Attachments';

const TextContainer = styled.div`
    width: 100%;
`;

const HoveredRow = styled(Row)<{ isHovered?: boolean; isSubItem?: boolean }>`
    position: relative;
    background-color: ${(props) => (props.isHovered ? props.theme.neutrals.grayLight : 'none')};
`;

const BulletMarkNoMargin = styled(BulletMark)`
    margin-left: 0px;
`;

const BulletMarkNoPadding = styled(BulletMarkNoMargin)`
    padding-right: unset;
`;

const Stationary = styled.div`
    position: absolute;
    right: 0px;
    width: 100px;
    height: 20px;
    padding: 2px 4px;
    border-radius: 3px;
    border: solid;
    border-width: thin;
    border-color: ${(props) => props.theme.neutrals.midnight};
    background-color: ${(props) => props.theme.neutrals.white};
    box-shadow: 0px 2px 8px rgba(0, 69, 62, 0.32);
    z-index: 1500;
`;

const IssueBasedBulletContainer = styled.div`
    height: 100%;
    display: flex;
    margin-left: -12px;
`;

const IssueBasedStationary = styled(Stationary)`
    width: fit-content;
    height: unset;
    left: -12px;
    right: unset;
    top: 0px;
`;

const YTBCopyButton = styled(BaseButton)<{ width: number }>`
    border-radius: 3px;
    margin: 1px 4px 1px 4px;
    text-align: center;
    display: flex;
    align-content: center;
    min-width: 2px;
    width: ${(props) => props.width}%;
    background-color: ${(props) => props.theme.secondary.orangeDark};
    color: white;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : '0px 2px 8px rgba(0, 69, 62, 0.32)')};
        background-color: ${(props) => props.theme.tertiary.orangeDark};
    }
`;

const WorkingButton = styled(YTBCopyButton)`
    background-color: white;
    height: 20px;

    &:hover {
        background-color: white;
        filter: brightness(1.1);
    }
`;

const DoneButton = styled(YTBCopyButton)`
    height: 20px;
    background-color: white;

    &:hover {
        background-color: white;
        filter: brightness(1.1);
    }
`;

const DoneDemoButton = styled(YTBCopyButton)`
    background-color: ${(props) => props.theme.secondary.green};

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : '0px 2px 8px rgba(0, 69, 62, 0.32)')};
        background-color: ${(props) => props.theme.secondary.greenDark};
    }
`;

const CustomCopyButton = styled(BaseButton)`
    border-radius: 3px;
    margin: 1px 4px 1px 4px;
    text-align: center;
    display: flex;
    align-content: center;
    min-width: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.secondary.orangeDark};
    color: white;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : '0px 2px 8px rgba(0, 69, 62, 0.32)')};
        background-color: ${(props) => props.theme.tertiary.orangeDark};
    }
`;

type PreviousUpdateSectionInputCommonProps = {
    typedUpdate: TypedUpdate;
    onConfirmation?: (typedUpdate: TypedUpdate) => void;
    meetingId?: string;
};

type PreviousUpdateSectionInputProps = PreviousUpdateSectionInputCommonProps & {
    onTooltipClick?: (typedUpdate: TypedUpdate) => void;
    createUpdateEmitter: (typedUpdate: TypedUpdate) => (text: string) => void;
    bullet?: JSX.Element;
    jiraInteractive?: boolean;
};

export type PreviousUpdateSectionTooltipProps = PreviousUpdateSectionInputCommonProps & {
    onClick: (typedUpdate: TypedUpdate) => void;
    enableKeyDown?: boolean;
};

const BootstrapAccordion = muiStyled((props: AccordionProps & { isHovered?: boolean }) => (
    <Accordion
        style={{ backgroundColor: props.isHovered ? lightTheme.neutrals.grayLight : 'unset', cursor: 'pointer' }}
        elevation={0}
        square
        {...props}
    />
))(() => ({
    border: 'unset',
    [`& .MuiButtonBase-root`]: {
        justifyContent: 'start',
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    [`& .MuiAccordionSummary-content`]: {
        width: '100%',
    },
}));

const BootstrapAccordionSummary = muiStyled((props: AccordionSummaryProps) => (
    <AccordionSummary
        style={{ minHeight: 'unset' }}
        expandIcon={
            <ArrowRight
                style={{
                    color: lightTheme.primary.midnight,
                    height: '20px',
                    width: '20px',
                    paddingRight: '4px',
                }}
            />
        }
        {...props}
    />
))(() => ({
    minHeight: 'unset',
    padding: 'unset',
    margin: 'unset',
    backgroundColor: 'unset',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'unset',
    },
    '& .MuiAccordionSummary-expandIcon': {
        margin: 'unset',
        padding: 'unset',
        marginLeft: '-6px',
        marginRight: '-15px',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
        margin: 'unset',
        padding: 'unset',
        marginLeft: '-6px',
        marginRight: '-15px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: '8px',
        marginTop: '2px',
        marginBottom: '2px',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: '8px',
        marginTop: '2px',
        marginBottom: '2px',
    },
}));

const BootstrapAccordionDetails = muiStyled((props: AccordionDetailsProps) => <AccordionDetails {...props} />)(() => ({
    paddingTop: '4px',
    paddingBottom: '4px',
}));

const PreviousCustomUpdateSectionTooltip = ({
    onClick,
    typedUpdate,
    onConfirmation,
    meetingId,
}: PreviousUpdateSectionTooltipProps): JSX.Element => {
    const [user] = useGlobalAuthedUser();

    const onButtonClick = async (
        typedUpdate: TypedUpdate,
        updateType: SpinachUpdateType,
        keyboardEvent: boolean = false
    ) => {
        if (onConfirmation) {
            onConfirmation({ ...typedUpdate, isRolledOver: true });
        }
        // I wonder if we should have a `correlationId` or `referenceId` to tie it to the
        // typedUpdate this came from so we can correlate them together
        onClick({ ...typedUpdate, id: v4(), updateType, isRolledOver: false });
        const payload: ContextItemClickPayload = {
            ...user.toUserIdentityPayload(),
            TicketSource: getTicketSource(typedUpdate),
            IsResolverItem: Boolean(typedUpdate.creatorId && typedUpdate.creatorId !== user.spinachUserId),
            NumberOfSubItems: typedUpdate.subItems?.length ?? 0,
            MeetingID: meetingId,
            YTBUpdateType: typedUpdate.updateType,
            YTBItemAddedTo: updateType,
            UpdateId: typedUpdate.id,
            KeyboardEvent: keyboardEvent,
        };
        await postExperienceEvent({ eventType: ClientEventType.ContextItemClick, payload });
    };

    return (
        <Stationary>
            <Row>
                <Tooltip title={'Add this to your check-in'} placement={'top'} arrow>
                    <CustomCopyButton onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Custom)}>
                        <TextContainer>Reuse</TextContainer>
                    </CustomCopyButton>
                </Tooltip>
            </Row>
        </Stationary>
    );
};

export function IssueStatusButtons({
    typedUpdate,
    createUpdateEmitter,
    onConfirmation,
}: {
    typedUpdate: TypedUpdate;
    createUpdateEmitter: (typedUpdate: TypedUpdate) => (text: string) => void;
    onConfirmation?: (typedUpdate: TypedUpdate) => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const isHovered = useRef(false);

    const [issueBasedTimeout, setIssueBasedTimeout] = useState<NodeJS.Timeout | null>(null);

    const disposeTimeout = () => {
        if (issueBasedTimeout) {
            clearTimeout(issueBasedTimeout);
            setIssueBasedTimeout(null);
        }
    };

    useEffect(() => {
        return () => {
            isHovered.current = false;
        };
    }, []);

    function onUnarchive(updatedValue: TypedUpdate): boolean {
        if (!updatedValue.goalMetadata) {
            return false;
        }

        const updated: TypedUpdate = {
            ...updatedValue,
            goalMetadata: {
                ...updatedValue.goalMetadata,
                isArchived: false,
            },
        };

        onConfirmation?.(updated);
        const emit = createUpdateEmitter(updated);
        emit(updated.text);
        track(ClientEventType.OnUnarchiveItem, {
            UpdateId: typedUpdate.id,
            GoalId: updatedValue.goalMetadata.goalId,
        });
        return true;
    }

    return (
        <>
            {isHovered.current ? (
                <IssueBasedStationary>
                    <IssueBasedAttachments
                        isInNavDrawer={true}
                        typedUpdate={typedUpdate}
                        saveFullTypedUpdate={(updatedValue: TypedUpdate) => {
                            const unarchive = onUnarchive(updatedValue);
                            if (!unarchive) {
                                const emitter = createUpdateEmitter(updatedValue);
                                emitter(updatedValue.text);
                            }
                        }}
                    />
                </IssueBasedStationary>
            ) : (
                <></>
            )}

            <IssueBasedBulletContainer
                onMouseEnter={() => {
                    disposeTimeout();

                    const timeout = setTimeout(() => {
                        isHovered.current = true;
                        setIssueBasedTimeout(null);
                    }, 250);
                    setIssueBasedTimeout(timeout);
                }}
                onMouseLeave={() => {
                    disposeTimeout();
                    isHovered.current = false;
                }}
            >
                <IconButton size="small" style={{ height: '28.5px' }}>
                    <VisibilityOffIcon style={{ fontSize: '12px' }} />
                </IconButton>
            </IssueBasedBulletContainer>
        </>
    );
}

export const DemoRolloverButtons = ({
    onClick,
    typedUpdate,
    onConfirmation,
    meetingId,
}: PreviousUpdateSectionTooltipProps): JSX.Element => {
    const [liveSeries] = useGlobalLiveSeries();
    const [user] = useGlobalAuthedUser();

    const onButtonClick = async (
        typedUpdate: TypedUpdate,
        updateType: SpinachUpdateType,
        keyboardEvent: boolean = false
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {
            subItems,
            sentiment: liveItemSentiment,
            sentimentDetails: liveItemSentimentDetails,
            resolvers,
            ...typedUpdateWithoutSubItem
        } = typedUpdate;
        const { ticketData } = typedUpdateWithoutSubItem;
        const sentiment = liveSeries.isDemo ? Sentiment.Good : undefined;

        // roll over updates if rolling over to yesterday, and it's a jira item or the feature flag for regular items is enabled
        /** @TODO Should asanaData be here? */
        const isRolledOverToYesterday = updateType === SpinachUpdateType.Yesterday;
        const newSubItems = isRolledOverToYesterday && subItems ? subItems : [];

        const liveItemSentimentToUse = liveItemSentiment !== Sentiment.Good ? liveItemSentiment : undefined;
        const liveItemSentimentDetailsToUse =
            liveItemSentiment !== Sentiment.Good ? liveItemSentimentDetails : undefined;

        const rolledOverUpdate: TypedUpdate = {
            ...typedUpdateWithoutSubItem,
            // don't roll over sentiment into today
            sentiment: liveItemSentimentToUse,
            sentimentDetails: liveItemSentimentDetailsToUse,
            // previously we only had sentiment for jira items, but going forward we'll have for regular items as well
            // this will help shifiting to using the top level sentiment property
            ticketData: ticketData ? { ...ticketData, sentiment } : undefined,
            isRolledOver: true,
            subItems: newSubItems,
        };
        if (onConfirmation) {
            onConfirmation(rolledOverUpdate);
        }

        // I wonder if we should have a `correlationId` or `referenceId` to tie it to the
        // typedUpdate this came from so we can correlate them together
        onClick({
            ...typedUpdateWithoutSubItem,
            creatorId: user.spinachUserId,
            subItems,
            updateType,
            sentiment: rolledOverUpdate.sentiment,
        });
        const payload: ContextItemClickPayload = {
            ...user.toUserIdentityPayload(),
            IsResolverItem: Boolean(typedUpdate.creatorId && typedUpdate.creatorId !== user.spinachUserId),
            TicketSource: getTicketSource(typedUpdate),
            NumberOfSubItems: typedUpdate.subItems?.length ?? 0,
            MeetingID: meetingId,
            YTBUpdateType: typedUpdate.updateType,
            YTBItemAddedTo: updateType,
            UpdateId: typedUpdate.id,
            KeyboardEvent: keyboardEvent,
        };
        await postExperienceEvent({ eventType: ClientEventType.ContextItemClick, payload });
    };

    return (
        <Stationary style={{ width: '150px' }}>
            <Row>
                <PulsingHint
                    containerStyles={{ top: '10px', left: '50px' }}
                    isVisible={liveSeries.isDemo && liveSeries.currentDemoGuide === DemoGuide.Rollover}
                />
                <Tooltip title={Titles.CopyToToday} placement={'top'} arrow>
                    <YTBCopyButton
                        style={{ width: '90px' }}
                        width={60}
                        onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Today)}
                    >
                        <TextContainer>Still working</TextContainer>
                    </YTBCopyButton>
                </Tooltip>
                <Tooltip title={Titles.CopyToYesterday} placement={'top'} arrow>
                    <DoneDemoButton
                        style={{ width: '45px' }}
                        width={0}
                        onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Yesterday)}
                    >
                        <TextContainer>Done</TextContainer>
                    </DoneDemoButton>
                </Tooltip>
            </Row>
        </Stationary>
    );
};

export const PreviousUpdateSectionTooltip = ({
    onClick,
    typedUpdate,
    onConfirmation,
    meetingId,
    enableKeyDown = true,
}: PreviousUpdateSectionTooltipProps): JSX.Element => {
    const [liveSeries] = useGlobalLiveSeries();
    const [user] = useGlobalAuthedUser();

    const enabledReservedLists = liveSeries.enabledReservedRoundtableLists;

    const onKeyDown = (e: KeyboardEvent) => {
        if (liveSeries.isDemo) {
            return;
        }
        if (e.code === 'Digit1' || e.code === 'KeyY') {
            onButtonClick(typedUpdate, SpinachUpdateType.Yesterday);
        }
        if (e.code === 'Digit2' || e.code === 'KeyT') {
            onButtonClick(typedUpdate, SpinachUpdateType.Today);
        }
        if (e.code === 'Digit3' || e.code === 'KeyB') {
            onButtonClick(typedUpdate, SpinachUpdateType.Challenge);
        }
    };

    const onButtonClick = async (
        typedUpdate: TypedUpdate,
        updateType: SpinachUpdateType,
        keyboardEvent: boolean = false
    ) => {
        if (!liveSeries.isComponentEnabled(updateType)) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {
            subItems,
            sentiment: liveItemSentiment,
            sentimentDetails: liveItemSentimentDetails,
            resolvers,
            ...typedUpdateWithoutSubItem
        } = typedUpdate;
        const { ticketData } = typedUpdateWithoutSubItem;
        const sentiment = liveSeries.isDemo ? Sentiment.Good : undefined;

        // roll over updates if rolling over to yesterday, and it's a jira item or the feature flag for regular items is enabled
        /** @TODO Should asanaData be here? */
        const isRolledOverToYesterday = updateType === SpinachUpdateType.Yesterday;
        const newSubItems = isRolledOverToYesterday && subItems ? subItems : [];

        const liveItemSentimentToUse = liveItemSentiment !== Sentiment.Good ? liveItemSentiment : undefined;
        const liveItemSentimentDetailsToUse =
            liveItemSentiment !== Sentiment.Good ? liveItemSentimentDetails : undefined;

        const rolledOverUpdate: TypedUpdate = {
            ...typedUpdateWithoutSubItem,
            // don't roll over sentiment into today
            sentiment: liveItemSentimentToUse,
            sentimentDetails: liveItemSentimentDetailsToUse,
            // previously we only had sentiment for jira items, but going forward we'll have for regular items as well
            // this will help shifiting to using the top level sentiment property
            ticketData: ticketData ? { ...ticketData, sentiment } : undefined,
            isRolledOver: true,
            subItems: newSubItems,
        };
        if (onConfirmation) {
            onConfirmation(rolledOverUpdate);
        }

        // I wonder if we should have a `correlationId` or `referenceId` to tie it to the
        // typedUpdate this came from so we can correlate them together
        onClick({
            ...typedUpdateWithoutSubItem,
            creatorId: user.spinachUserId,
            subItems,
            updateType,
            sentiment: liveItemSentiment,
            sentimentDetails: liveItemSentimentDetails,
        });
        const payload: ContextItemClickPayload = {
            ...user.toUserIdentityPayload(),
            TicketSource: getTicketSource(typedUpdate),
            IsResolverItem: Boolean(typedUpdate.creatorId && typedUpdate.creatorId !== user.spinachUserId),
            NumberOfSubItems: typedUpdate.subItems?.length ?? 0,
            MeetingID: meetingId,
            YTBUpdateType: typedUpdate.updateType,
            YTBItemAddedTo: updateType,
            UpdateId: typedUpdate.id,
            KeyboardEvent: keyboardEvent,
        };
        await postExperienceEvent({ eventType: ClientEventType.ContextItemClick, payload });
    };

    useEffect(() => {
        if (enableKeyDown) {
            document.addEventListener('keydown', onKeyDown);
            return () => {
                document.removeEventListener('keydown', onKeyDown);
            };
        }
    }, [enableKeyDown]);

    const buttonWidthPercentage = 100 / enabledReservedLists.length;

    return (
        <Stationary>
            <Row>
                {liveSeries.isComponentEnabled(SpinachUpdateType.Yesterday) ? (
                    <Tooltip title={Titles.CopyToYesterday} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Yesterday)}
                        >
                            <TextContainer>Y</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}

                <PulsingHint
                    containerStyles={{ top: '15px', left: '15px' }}
                    isVisible={liveSeries.isDemo && liveSeries.currentDemoGuide === DemoGuide.Rollover}
                />

                {liveSeries.isComponentEnabled(SpinachUpdateType.Today) ? (
                    <Tooltip title={Titles.CopyToToday} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Today)}
                        >
                            <TextContainer>T</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}

                {liveSeries.isComponentEnabled(SpinachUpdateType.Challenge) ? (
                    <Tooltip title={Titles.CopyToBlockers} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Challenge)}
                        >
                            <TextContainer>B</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </Row>
        </Stationary>
    );
};

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

const getPreviousUpdateNotificationMessage = (typedUpdate: TypedUpdate) =>
    `Added to ${typedUpdate.updateType === SpinachUpdateType.Challenge ? 'Blocker' : typedUpdate.updateType}${
        typedUpdate.updateType === SpinachUpdateType.Today &&
        (typedUpdate.jiraData || typedUpdate.ticketData?.ticket) &&
        typedUpdate.subItems?.length &&
        typedUpdate.subItems.some((item) => !!item.text)
            ? ' (sub-bullets not rolled over)'
            : ''
    }`;

type ItemWithRolloverProps = {
    eventType: ClientEventType;
    isHovered: boolean;
    typedUpdate: TypedUpdate;
    isReadOnlyItem: boolean;
    children: JSX.Element;
};

const DimmableText = styled(BodyRegular)<{ isReadOnly?: boolean }>`
    text-align: left;
`;

function ItemWithSubBullets({
    eventType,
    isHovered,
    typedUpdate,
    children,
    isReadOnlyItem,
}: ItemWithRolloverProps): JSX.Element {
    const isIssueBasedEnabled = useIssueBasedEnablement();
    const track = useExperienceTracking();
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <BootstrapAccordion
            expanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            onChange={(_, expanded: boolean) => {
                track(eventType, {
                    Action: expanded ? 'Open' : 'Close',
                    ItemId: typedUpdate.id,
                    JiraId: typedUpdate.jiraData?.id || typedUpdate.ticketData?.ticket.id,
                    NumberOfSubItems: typedUpdate.subItems?.length,
                    NumberOfNonEmptySubItems: typedUpdate.subItems?.filter((i) => !!i.text).length ?? 0,
                });
            }}
            isHovered={isHovered && !isIssueBasedEnabled}
        >
            <BootstrapAccordionSummary>{children}</BootstrapAccordionSummary>
            <BootstrapAccordionDetails>
                <Column>
                    {typedUpdate.subItems?.map((subItem) => (
                        <HoveredRow isSubItem={true} style={{ marginLeft: '20px' }}>
                            <BulletMark key={subItem.id} style={{ marginTop: '5px' }}>
                                <Bullet />
                            </BulletMark>
                            <DimmableText isReadOnly={isReadOnlyItem} className={withFullStoryMasking()}>
                                {subItem.text}
                            </DimmableText>
                        </HoveredRow>
                    ))}
                </Column>
            </BootstrapAccordionDetails>
        </BootstrapAccordion>
    );
}

export function PreviousUpdateSectionInputWithRollover({
    createUpdateEmitter,
    meetingId,
    onConfirmation,
    onTooltipClick,
    bullet,
    typedUpdate,
}: PreviousUpdateSectionInputProps): JSX.Element {
    const update = useTypedUpdateWrapper(typedUpdate);
    const isIssueBasedEnabled = useIssueBasedEnablement();
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [storedSeries] = useGlobalStoredSeries();
    // eslint-disable-next-line prefer-const
    let [isHovered, setIsHovered] = useState(false);
    const [previousUpdateNotificationMessage, setPreviousUpdateNotificationMessage] = useState<null | string>(null);
    const [isRolledOver, setIsRolledOver] = useState(typedUpdate.isRolledOver);
    const isJiraEnabled = useJiraEnablement();
    const { isDemoSeries } = useSeriesReality();

    isHovered = isHovered || isDemoSeries;

    /** @TODO Should asanaData be here? */
    const issueData = typedUpdate.jiraData || typedUpdate.ticketData?.ticket;
    const hasSubItems = !!typedUpdate.subItems?.some((subItem) => !!subItem.text);

    const goal = update.asGoal;
    const isReadOnlyItem = isIssueBasedEnabled && !!goal && (goal.status === GoalStatus.Unset || goal.isArchived);

    const onClick = (typedUpdate: TypedUpdate) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { subItems, sentiment: itemSentiment, sentimentDetails, ...typedUpdateWithoutSubItems } = typedUpdate;
        const { jiraData, ticketData } = typedUpdateWithoutSubItems;
        const typedUpdateIssueData = jiraData || ticketData?.ticket;
        let subItemsToUse: TypedUpdate[] = [];
        // carry sub items if rolled over to yesterday or custom
        if (
            typedUpdate.updateType === SpinachUpdateType.Yesterday ||
            typedUpdate.updateType === SpinachUpdateType.Custom
        ) {
            subItemsToUse = [...(subItems ?? [])];
        } else if (subItems && subItems.length > 0) {
            // if we had sub items, don't add them but add an empty one to encourage to user to update
            subItemsToUse = [
                getNewTypedUpdate({
                    creatorId: user.spinachUserId,
                    updateType: typedUpdate.updateType,
                    text: '',
                }),
            ];
        }
        const liveItemSentimentToUse =
            // all sentiment values rollover to yesterday
            typedUpdate.updateType === SpinachUpdateType.Yesterday ? itemSentiment : undefined;

        const liveItemSentimentDetailsToUse =
            // all sentiment details rollover to yesterday
            typedUpdate.updateType === SpinachUpdateType.Yesterday ? sentimentDetails : undefined;

        const typedUpdateToUse: TypedUpdate = {
            ...typedUpdateWithoutSubItems,
            subItems: subItemsToUse,
            creatorId: user.spinachUserId,
            id: v4(),
            sentiment: liveItemSentimentToUse,
            sentimentDetails: liveItemSentimentDetailsToUse,
            updateType: typedUpdate.updateType,
            isRolledOver: false,
        };
        onTooltipClick?.(typedUpdateToUse);

        setPreviousUpdateNotificationMessage(getPreviousUpdateNotificationMessage(typedUpdate));

        const sentiment = isDemoSeries ? Sentiment.Good : undefined;
        const emitTypedUpdateSave = createUpdateEmitter({
            ...typedUpdateToUse,
            ticketData: ticketData ? { ...ticketData, sentiment } : undefined,
            subItems: subItemsToUse,
        });
        emitTypedUpdateSave(typedUpdateToUse.text);
        setIsRolledOver(true);
        if (typedUpdateIssueData) {
            const payload = {
                SeriesId: storedSeries.slug,
                FeatureToggles: user.featureToggles,
                SelectionType: JiraIssueSelectionType.RolledOver,
            };
            track(ClientEventType.JiraIssueSelected, payload);
        }
    };

    return (
        <Row onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <HoveredRow isHovered={isHovered && !isIssueBasedEnabled}>
                {isIssueBasedEnabled ? (
                    typedUpdate.goalMetadata?.status === GoalStatus.Unset || typedUpdate.goalMetadata?.isArchived ? (
                        <IssueStatusButtons
                            typedUpdate={typedUpdate}
                            createUpdateEmitter={createUpdateEmitter}
                            onConfirmation={onConfirmation}
                        />
                    ) : (
                        <></>
                    )
                ) : !hasSubItems ? (
                    bullet ? (
                        bullet
                    ) : typedUpdate.isRolledOver || isRolledOver ? (
                        <BulletMarkNoPadding>
                            <CheckIcon stroke={lightTheme.tertiary.midnight} />
                        </BulletMarkNoPadding>
                    ) : (
                        <BulletMarkNoMargin>
                            <Bullet />
                        </BulletMarkNoMargin>
                    )
                ) : null}

                <Notification
                    isOpen={Boolean(previousUpdateNotificationMessage)}
                    onClose={() => setPreviousUpdateNotificationMessage(null)}
                    message={previousUpdateNotificationMessage}
                    duration={1800}
                    icon={
                        <CheckCircleIcon
                            style={{ color: lightTheme.neutrals.white, height: '20px' }}
                            htmlColor={lightTheme.neutrals.white}
                        />
                    }
                    containerStyle={{
                        position: 'absolute',
                        top: 'unset',
                        bottom: 'unset',
                        width: 'calc(100% - 100px)',
                        left: 'unset',
                        right: 'unset',
                        transform: 'unset',
                    }}
                    contentStyle={{
                        width: '100%',
                        height: '23px',
                        borderRadius: '3px',
                        border: 'solid',
                        borderWidth: 'thin',
                    }}
                    messageStyle={{
                        padding: 'unset',
                    }}
                    transitionComponent={TransitionLeft}
                />
                <Column>
                    {issueData && isJiraEnabled ? (
                        hasSubItems ? (
                            <ItemWithSubBullets
                                isHovered={isHovered}
                                typedUpdate={typedUpdate}
                                eventType={ClientEventType.JiraRolloverExpansionClick}
                                isReadOnlyItem={isReadOnlyItem}
                            >
                                <JiraInput
                                    issueData={issueData}
                                    interactive={true}
                                    isReadOnlyItem={isReadOnlyItem}
                                    isOpenable={true}
                                />
                            </ItemWithSubBullets>
                        ) : (
                            <JiraInput issueData={issueData} interactive={true} isReadOnlyItem={isReadOnlyItem} />
                        )
                    ) : hasSubItems ? (
                        <ItemWithSubBullets
                            isHovered={isHovered}
                            typedUpdate={typedUpdate}
                            eventType={ClientEventType.RegularItemRolloverExpansionClick}
                            isReadOnlyItem={isReadOnlyItem}
                        >
                            <DimmableText
                                style={{ padding: '3px 4px 3px 4px' }}
                                isReadOnly={isReadOnlyItem}
                                className={withFullStoryMasking()}
                            >
                                {typedUpdate.text}
                            </DimmableText>
                        </ItemWithSubBullets>
                    ) : (
                        <DimmableText
                            style={{ padding: '3px 4px 3px 4px' }}
                            isReadOnly={isReadOnlyItem}
                            className={withFullStoryMasking()}
                        >
                            {typedUpdate.text}
                        </DimmableText>
                    )}
                </Column>
                {isHovered ? (
                    isDemoSeries ? (
                        <DemoRolloverButtons
                            onClick={onClick}
                            onConfirmation={onConfirmation}
                            meetingId={meetingId}
                            typedUpdate={typedUpdate}
                        />
                    ) : isIssueBasedEnabled && update.typedUpdate.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID ? (
                        <></>
                    ) : typedUpdate.updateType === SpinachUpdateType.Custom ? (
                        <PreviousCustomUpdateSectionTooltip
                            onClick={onClick}
                            onConfirmation={onConfirmation}
                            meetingId={meetingId}
                            typedUpdate={typedUpdate}
                        />
                    ) : (
                        <PreviousUpdateSectionTooltip
                            onClick={onClick}
                            onConfirmation={onConfirmation}
                            meetingId={meetingId}
                            typedUpdate={typedUpdate}
                        />
                    )
                ) : (
                    <></>
                )}
            </HoveredRow>
        </Row>
    );
}
