import { TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useEffect } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { postExperienceEvent } from '../../apis';
import { ReactComponent as LogoSpinachio } from '../../assets/logo-spinachio.svg';
import { BodyBigOnboard, BodyRegularOnboard, ButtonSize, HeaderThreeOnboard, lightTheme } from '../../styles';
import { SetValue } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { Spacing, SpinachStationary, ViewContainer } from '../common';
import { useSpinachInputStyles } from '../input';
import { Notification, PrimaryButton } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';

export type VerifyCodeRouteProps = {
    email: string;
    code: string;
    notification: string;
    setCode: SetValue<string>;
    onCodeSubmit: () => Promise<void>;
    onResendCode: () => Promise<void>;
    onRouteToEmailEntry: () => Promise<void>;
};

export function VerifyCodeRoute({
    email,
    code,
    setCode,
    notification,
    onCodeSubmit,
    onResendCode,
    onRouteToEmailEntry,
}: VerifyCodeRouteProps): JSX.Element {
    useEffect(() => {
        postExperienceEvent({
            eventType: ClientEventType.UserLandedOnCodeEntryView,
            payload: {
                Email: email,
                UserId: '',
                UserName: '',
            },
        });
    }, []);

    const classes = useSpinachInputStyles({ value: code });

    const isDisabled = !code || code.length !== 6;

    return (
        <ViewContainer>
            <SpinachStationary>
                <LogoSpinachio />

                <Spacing />
                <HeaderThreeOnboard>You’re almost there</HeaderThreeOnboard>

                <BodyBigOnboard style={{ paddingTop: '2%' }}>Check your email for the code</BodyBigOnboard>

                <Spacing />

                <TextField
                    autoFocus
                    style={{ width: '115px' }}
                    InputProps={{ classes: { root: classes.base } }}
                    className={withFullStoryMasking(classes.root)}
                    placeholder="Six digit code"
                    value={code}
                    onChange={(e) => {
                        setCode(e.target.value.replace(/[^\d]/g, '').substring(0, 6));
                    }}
                    onKeyDown={async (e) => {
                        if (!isDisabled && e.key === 'Enter') {
                            await onCodeSubmit();
                        }
                    }}
                />

                <Spacing />

                <PrimaryButton onClick={() => onCodeSubmit()} title="Continue" disabled={isDisabled} />

                <Spacing />

                <BodyRegularOnboard>Trouble finding or using your code?</BodyRegularOnboard>
                <BodyRegularOnboard className={withFullStoryMasking()}>
                    We sent it to <b>{email}</b>
                </BodyRegularOnboard>

                <Spacing factor={1 / 2} />

                <div style={{ display: 'flex' }}>
                    <SecondaryButton
                        size={ButtonSize.Mini}
                        title={'Resend Code'}
                        onClick={async () => {
                            await onResendCode();
                        }}
                    />

                    <BodyRegularOnboard style={{ padding: '5px' }}>or</BodyRegularOnboard>

                    <SecondaryButton
                        size={ButtonSize.Mini}
                        title={'Retype Email'}
                        onClick={async () => {
                            onRouteToEmailEntry();
                        }}
                    />
                </div>
                <Spacing />

                <Notification
                    containerStyle={{ position: 'relative', bottom: 'unset' }}
                    isOpen={!!notification}
                    onClose={() => null}
                    message={notification}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            </SpinachStationary>
        </ViewContainer>
    );
}
