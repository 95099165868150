import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SlackLogo } from '../../../assets/slack-logo.svg';
import SpinachSlackUsageSrc from '../../../assets/spinach-slack-usage.png';
import { useExperienceTracking, useIntegrationDetection, useLandingAnalytic } from '../../../hooks';
import { useGlobalSlack } from '../../../hooks/useSlack';
import { BodyBigOnboard, BodyRegular, HeaderThreeOnboard } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { AuthorizeIntegrationButton } from '../../common/integrations';
import { ScrollView } from '../../series';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButton, Stationary, StepProps } from './common';

export type SlackSetupProps = Omit<StepProps, 'onSubmit'> & {
    onBack: () => void;
    onSkip: (reason: string) => void;
    progressToSlackDefaults: () => void;
};

export function SlackSetup({ onBack, direction, onSkip, progressToSlackDefaults }: SlackSetupProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedConnectSlackStep);
    const { slackState } = useGlobalSlack();
    const startDetection = useIntegrationDetection(progressToSlackDefaults);
    const track = useExperienceTracking();
    return (
        <Stationary style={{ maxWidth: '600px', minWidth: '300px', width: 'unset' }}>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>Great! Now let's connect Slack</HeaderThreeOnboard>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <img
                        src={SpinachSlackUsageSrc}
                        alt="Slack Spinach Integration with action items"
                        style={{ width: '110%' }}
                    />
                    <Spacing factor={1 / 2} />

                    <Row centered>
                        <BodyBigOnboard>Get your meeting summaries and to-dos in Slack</BodyBigOnboard>
                    </Row>

                    <Spacing factor={1 / 2} />

                    <AuthorizeIntegrationButton
                        style={{ width: '200px', padding: '5px' }}
                        onClick={() => {
                            track(ClientEventType.UserClickedConnectToSlack);
                            startDetection();
                            URLUtil.openURL(slackState.installUrl);
                        }}
                    >
                        <SlackLogo style={{ height: '25px' }} />
                        <BodyRegular style={{ marginLeft: '20px' }}>
                            <b>Connect to Slack</b>
                        </BodyRegular>
                    </AuthorizeIntegrationButton>

                    <Spacing />

                    <SecondaryButton title="I don't have permission" onClick={() => onSkip('need-permissions')} />
                    <Spacing />

                    <SecondaryButton title="We don't use Slack" onClick={() => onSkip('other-messaging-platform')} />
                    <Spacing />
                </SlidingColumn>
            </ScrollView>
        </Stationary>
    );
}
