import { ButtonGroup, ButtonProps, Divider, Paper, Tooltip, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css, keyframes } from 'styled-components';

import { parkingLotUpdateSectionTypeProps, teamTopicsUpdateSectionTypeProps } from '@spinach-shared/constants';
import { ActiveAgendaProps, BaseMeetingProps, SpinachSeriesProps, YTBHistoryProps } from '@spinach-shared/models';
import {
    ClientEventType,
    ClientSocketEvent,
    DrawerAction,
    MeetingStatus,
    NavDrawer,
    NavDrawerClickPayload,
    SpinachUpdateType,
    TypedUpdate,
    YTBParticipantReadyRequest,
} from '@spinach-shared/types';

import { postExperienceEvent } from '../../apis';
import { ReactComponent as MyUpdatesIcon } from '../../assets/my-updates-icon.svg';
import { ReactComponent as SummariesIcon } from '../../assets/summaries-icon.svg';
import { ReactComponent as TeamTopicsIcon } from '../../assets/team-topics-icon.svg';
import { DemoModal } from '../../atoms';
import { DRAWER_ANIMATION_DURATION_MS, ElementId } from '../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalNavDrawer,
    useIssueBasedEnablement,
    useMeetingFormatText,
    usePersonaDemo,
    usePrevious,
    useSeriesReality,
    useTeamTopics2Enablement,
    useWindowSize,
} from '../../hooks';
import { useLiveUpdateCheckInSection } from '../../hooks/useLiveUpdateCheckInSection';
import { useUpdateCounters } from '../../hooks/useUpdateCounters';
import { ButtonLabel, HeaderThree, lightTheme } from '../../styles';
import { SpinachStandUpProps } from '../../types';
import { createWebsocketPayload } from '../../utils/analytics';
import { CloseButton, OverlayHint, Row, Spacer } from '../common';
import { CurrentUpdateSection } from './CurrentUpdateSection';
import { IssueBasedCheckIn } from './IssueBasedCheckIn';
import { LiveUpdateViewProps } from './LiveUpdateSection';
import { Notification } from './Notification';
import { PreviousUpdatesSection } from './PreviousUpdateSection';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import { Summaries } from './Summaries';
import { TeamTopicsUpdateSection } from './TeamTopicsUpdateSection';

const backdropStyles: { [id: string]: React.CSSProperties } = {
    entering: { backgroundColor: 'rgba(0,0,0,0.5)' },
    entered: { backgroundColor: 'rgba(0,0,0,0.5)' },
    exiting: { backgroundColor: 'rgba(0,0,0,0)' },
    exited: { backgroundColor: 'rgba(0,0,0,0)' },
};

const isDynamicDrawerDisabled = true; // isProductionStage();

const paperResizeUp = (wasOpen: boolean, height: number, prevHeight: number, fillContainer: boolean) => {
    if (isDynamicDrawerDisabled) {
        return keyframes`
            0% {
                height: 65px;
                width: 100%;
            }
            100% {
                height: ${fillContainer ? '100%' : '85%'};
                width: 100%;
            }
            `;
    } else {
        return keyframes` 
    0% {
        height: ${wasOpen ? `${prevHeight}px` : '65px'};
        width: 100%;
    }
    100% {
        height: ${`${height}px`};
        width: 100%;
    }
`;
    }
};
// TODO: clean up these props
const paperResizeDown = (wasOpen: boolean, height: number, fillContainer: boolean) => {
    if (isDynamicDrawerDisabled) {
        return keyframes`
        0% {
            height: ${fillContainer ? '100%' : '85%'};
            width:100%;
        }
        100% {
            height: ${'65px'};
            width: 100%;
        }
    `;
    } else {
        return keyframes`
        0% {
            height: ${`${height}px`};
            width:100%;
        }
        100% {
            height: ${'65px'};
            width: 100%;
        }
    `;
    }
};

const grow = (wasOpen: boolean, height: number, prevHeight: number, fillContainer: boolean) => css`
    animation: ${paperResizeUp(wasOpen, height, prevHeight, fillContainer)};
`;

const shrink = (wasOpen: boolean, height: number, fillContainer: boolean) => css`
    animation: ${paperResizeDown(wasOpen, height, fillContainer)};
`;

const NavBarPlaceholder = styled.div`
    height: 65px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const NavPaper = styled(Paper)<{
    isOpen: boolean;
    height: number;
    wasOpen: boolean;
    prevHeight: number;
    isDemoSeries: boolean;
    fillContainer: boolean;
}>`
    height: 65px;
    max-height: ${(props) => (props.fillContainer ? '100%' : '85%')};
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    place-self: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0px;

    ${(props) =>
        props.isOpen
            ? grow(props.wasOpen, props.height, props.prevHeight, props.fillContainer)
            : shrink(props.wasOpen, props.height, props.fillContainer)}
    animation-duration: ${DRAWER_ANIMATION_DURATION_MS}ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

const Backdrop = styled.div`
    transition: background-color ${DRAWER_ANIMATION_DURATION_MS}ms ease-in-out;
    opacity: 1;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    z-index: 1100;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
`;

const DrawerButton = styled.button<{ zIndex: number; isOpen: boolean }>`
    width: 120px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${(props) => (props.disabled ? props.theme.neutrals.grayDark : lightTheme.neutrals.white)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
    border: none;
    padding-top: 12px;

    position: absolute;
    bottom: 0px;

    height: 58px;
    z-index: ${(props) => (props.zIndex > 0 ? props.zIndex + 1 : props.zIndex)};
`;

const DrawerButtonLabel = styled(ButtonLabel)<{ color?: string }>`
    color: ${(props) => (props.color ? props.color : props.theme.primary.greenLight)};
    font-weight: 700;
    font-size: 13px;
`;

const NavButtonGroup = styled(ButtonGroup)<{ isOpen: boolean }>`
    width: 100%;
    align-self: center;
    min-width: 86px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    height: 65px;
    bottom: 0px;
    position: absolute;
`;

const NavOpenDivider = styled(Divider)`
    height: 2px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
`;

const TeamTopicsBadge = styled.div`
    position: absolute;
    right: calc(50% - 30px);
    top: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: green;
    color: white;
    font-weight: bold;
    font-size: 12px;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: '130px',
        fontSize: '12px',
    },
    tooltipPlacementTop: {
        marginBottom: 5,
    },
}));

const NinePlus = styled.span`
    font-size: 11px;
`;

const determineFill = ({
    isInactive,
    isOpen,
    isHovering,
}: {
    isInactive: boolean;
    isOpen: boolean;
    isHovering: boolean;
}) => {
    if (isInactive && isOpen) {
        return isHovering ? lightTheme.primary.greenLight : lightTheme.neutrals.midnight;
    } else {
        return isHovering ? lightTheme.primary.greenDark : lightTheme.primary.greenLight;
    }
};

export function NavButton({
    title,
    onClick,
    disabled = false,
    isHovering = false,
    icon,
    // TODO: Remove this
    isTeamTopicsButton,
    isInactive,
    isOpen,
    teamTopicsCount,
    ...rest
}: {
    isHovering: boolean;
    isTeamTopicsButton?: boolean;
    icon: JSX.Element;
    isInactive: boolean;
    isOpen: boolean;
    teamTopicsCount?: number;
} & ButtonProps): JSX.Element {
    return (
        <DrawerButton
            style={{ position: 'relative' }}
            tabIndex={0}
            onClick={onClick}
            disabled={disabled}
            zIndex={1300}
            isOpen={isOpen}
            {...rest}
        >
            {isTeamTopicsButton ? (
                <>
                    {icon}
                    <DrawerButtonLabel color={determineFill({ isInactive, isOpen, isHovering })}>
                        {title}
                    </DrawerButtonLabel>
                    {teamTopicsCount && teamTopicsCount > 0 ? (
                        <TeamTopicsBadge style={{ background: determineFill({ isInactive, isOpen, isHovering }) }}>
                            {teamTopicsCount > 9 ? <NinePlus>9+</NinePlus> : teamTopicsCount}
                        </TeamTopicsBadge>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <>
                    {icon}
                    <DrawerButtonLabel color={determineFill({ isInactive, isOpen, isHovering })}>
                        {title}
                    </DrawerButtonLabel>
                </>
            )}
        </DrawerButton>
    );
}

const ReadyButtonRow = styled(Row)`
    margin-bottom: 64px;
    display: flex;
    align-items: center;
`;

const NavButtonWrapper = styled.span`
    display: flex;
    align-items: stretch;
    width: auto;
`;

export function NavView({
    series,
    socket,
    containerRef,
    seriesHistories,
    history,
}: {
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
    history?: YTBHistoryProps;
    seriesHistories: BaseMeetingProps[];
} & SpinachStandUpProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { drawer, closeDrawer, openDrawer } = useGlobalNavDrawer();
    const isOpen = !!drawer;
    const [timeOpened, setTimeOpened] = useState<number>();
    const [openedSummary, setOpenedSummary] = useState<number | null>(seriesHistories.length ? 0 : null);
    const zIndex = 1300;
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [isHoveringOnTeamTopics, setIsHoveringOnTeamTopics] = useState<boolean>(false);
    const [isHoveringOnSummaries, setIsHoveringOnSummaries] = useState<boolean>(false);
    const { isDemoSeries } = useSeriesReality();
    const { focusedFeature, demoState, setDemoState } = useGlobalDemoState();
    const { text: formatText, isAsync } = useMeetingFormatText();
    const asyncNotificationText = isAsync ? formatText : null;
    const isIssueBasedEnabled = useIssueBasedEnablement();
    const isPersonaDemo = usePersonaDemo();

    const topicsTitle = 'Parking Lot';

    const { spinachUserId } = user;

    const userItem = series.thisParticipantsYTBAgendaItem;
    const {
        incrementUpdateCount,
        resetCounters,
        todayItemsPopulated,
        yesterdayItemsPopulated,
        challengeItemsPopulated,
        teamTopicItemsPopulated,
    } = useUpdateCounters();

    const {
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
        typedUpdatesMap,
        setTypedUpdatesMap,
        onToggleConfirmation,
    } = useLiveUpdateCheckInSection(userItem, socket, series.slug, series.currentMeeting.agenda, incrementUpdateCount);

    const {
        [SpinachUpdateType.Yesterday]: statefulYesterdays,
        [SpinachUpdateType.Today]: statefulTodays,
        [SpinachUpdateType.Challenge]: statefulChallenges,
        [SpinachUpdateType.Custom]: statefulCustomUpdates,
    } = typedUpdatesMap as unknown as Record<SpinachUpdateType, TypedUpdate[]>;

    const wasOpen = usePrevious(isOpen);
    const drawerHeight = useDrawerAutoSizing(series, openedSummary);
    const prevDrawerHeight = usePrevious(drawerHeight);

    useDrawerAutoOpenerForPrepping(series, setTimeOpened);
    useDrawerAutoCloseOnUsersTurn(series, timeOpened, history, statefulYesterdays, setTimeOpened);
    const teamTopicsCount = useMemoizedTeamTopic2Count(series);

    const liveUpdateViewProps: LiveUpdateViewProps = {
        standUpUpdate: userItem.standUpUpdate,
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
        rolloverProps: {
            containerRef,
            userIdentity: user.toUserIdentity(),
            history,
            onToggleConfirmation,
        },
    };

    const getReadyRequest = (): YTBParticipantReadyRequest =>
        createWebsocketPayload<YTBParticipantReadyRequest>({
            seriesSlug: series.slug,
            meetingId: series.currentMeeting.id,
            spinachUserId,
        });

    const onNavDrawerButtonPress = (buttonsDrawer: NavDrawer) => {
        if (drawer === buttonsDrawer) {
            onDrawerClose();
        } else if (drawer) {
            trackTabSwitch(buttonsDrawer);
            openDrawer(buttonsDrawer);
        } else {
            onDrawerOpen(buttonsDrawer);
        }
    };

    const userIdentityPayload = user.toUserIdentityPayload();

    const trackTabSwitch = (drawerAction: NavDrawer) => {
        const sendAnalytics = async () => {
            const payload: NavDrawerClickPayload = {
                ...userIdentityPayload,
                SeriesId: series.slug,
                MeetingID: series.currentMeeting.id,
                MeetingStatus: series.currentMeeting.status,
                DrawerAction: `To ${drawerAction}`,
                AgendaItemSource: series.currentAgendaItem?.source,
            };
            await postExperienceEvent({ eventType: ClientEventType.NavTabSwitch, payload });
        };
        sendAnalytics();
    };

    const onDrawerClose = () => {
        const sendAnalytics = async () => {
            const payload: NavDrawerClickPayload = {
                ...userIdentityPayload,
                SeriesId: series.slug,
                MeetingID: series.currentMeeting.id,
                MeetingStatus: series.currentMeeting.status,
                TimeOpened: `${Math.abs(Date.now() - (timeOpened ?? Date.now()))}ms`,
                DrawerAction: DrawerAction.Close,
                AgendaItemSource: series.currentAgendaItem?.source,
                Panel: drawer ?? undefined,
            };

            if (drawer) {
                drawer === NavDrawer.TeamTopics
                    ? (payload.ParkingLotItemsPopulated = teamTopicItemsPopulated)
                    : ((payload.YesterdayItemsPopulated = yesterdayItemsPopulated),
                      (payload.TodayItemsPopulated = todayItemsPopulated),
                      (payload.ChallengeItemsPopulated = challengeItemsPopulated));

                (payload.ContextItemsAvailable = history?.mostRecentTodays.length ?? 0),
                    (payload.ContextItemsPopulated = statefulYesterdays.reduce((total, update) => {
                        if (update.isRolledOver) {
                            total += 1;
                        }
                        return total;
                    }, 0)),
                    resetCounters();
            }

            await postExperienceEvent({ eventType: ClientEventType.NavCloseButtonClick, payload }); // Total Nav closed clicks either button
        };

        const hasSufficientCheckInData =
            (!series.spinachParticipant.isReady &&
                (statefulTodays.filter((today) => today.text !== '').length ||
                    statefulChallenges.filter((challenge) => challenge.text !== '').length)) ||
            statefulCustomUpdates.filter((update) => update.text !== '').length;

        if (hasSufficientCheckInData || isDemoSeries) {
            socket.emit(ClientSocketEvent.YTBParticipantReadying, createWebsocketPayload(getReadyRequest()));
        }

        sendAnalytics();
        setTimeOpened(undefined);
        closeDrawer();
    };

    const onDrawerOpen = (openingDrawer: NavDrawer) => {
        const sendAnalytics = async () => {
            const payload: NavDrawerClickPayload = {
                ...userIdentityPayload,
                SeriesId: series.slug,
                MeetingID: series.currentMeeting.id,
                MeetingStatus: series.currentMeeting.status,
                TimeOpened: `${Math.abs(Date.now() - (timeOpened ?? Date.now()))}ms`,
                DrawerAction: `To ${openingDrawer}`,
                AgendaItemSource: series.currentAgendaItem?.source,
                Panel: drawer ?? undefined,
            };

            await postExperienceEvent({ eventType: ClientEventType.NavOpenClick, payload }); // Total Nav opened clicks either button
        };

        sendAnalytics();

        setTimeOpened(Date.now());
        openDrawer(openingDrawer);
    };

    const isReadyButtonVisible = drawer === NavDrawer.EditCheckIn && !series.spinachParticipant.isReady;

    const { setHasOpenedSummariesTabInDemo } = useGlobalDemoState();
    const { isDemoCheckInFinishable } = series;

    const track = useExperienceTracking();
    const tooltipClasses = useTooltipStyles();
    const shouldShowPreviousCheckinHint =
        focusedFeature === ElementId.PreviousCheckinContainer && isDemoSeries && user.isOnSecondPracticeRound;

    const { width } = useWindowSize();

    return (
        <>
            {isPersonaDemo && !series.spinachParticipant.isReady ? null : (
                <Transition in={isOpen} timeout={{ enter: 0, exit: DRAWER_ANIMATION_DURATION_MS }} unmountOnExit>
                    {(status: TransitionStatus) => (
                        <Backdrop
                            onClick={() => {
                                if (!series.isDemo || series.spinachParticipant.isReady) {
                                    onDrawerClose();
                                }
                            }}
                            style={{ ...backdropStyles[status] }}
                        />
                    )}
                </Transition>
            )}

            <NavBarPlaceholder />

            <NavPaper
                key={`nav-drawer-${isOpen}`}
                isDemoSeries={isDemoSeries}
                elevation={24}
                isOpen={isOpen}
                prevHeight={prevDrawerHeight}
                wasOpen={wasOpen}
                height={drawerHeight}
                fillContainer={isPersonaDemo}
                style={{
                    zIndex,
                    borderRadius: 8,
                    background: lightTheme.neutrals.offWhite,
                    display: 'flex',
                    minWidth: '300px',
                }}
            >
                {isOpen ? (
                    <>
                        <Spacer style={{ height: '70px' }}>
                            <HeaderThree style={{ marginTop: '17px' }}>
                                {drawer === NavDrawer.TeamTopics
                                    ? topicsTitle
                                    : drawer === NavDrawer.EditCheckIn
                                    ? 'Check-in'
                                    : 'Summaries'}
                            </HeaderThree>
                            {!series.isDemo || series.spinachParticipant.isReady ? (
                                <CloseButton
                                    onClick={() => {
                                        onDrawerClose();
                                    }}
                                />
                            ) : null}
                        </Spacer>

                        <ScrollArea
                            id={ElementId.ScrollviewNav}
                            stretch={true}
                            balancePadding={true}
                            style={{
                                display: 'flex',
                                flexGrow: 1,
                                marginBottom: isReadyButtonVisible ? 'initial' : '60px',
                            }}
                            useGrayShading={true}
                        >
                            {drawer === NavDrawer.TeamTopics ? (
                                <TeamTopicsUpdateSection
                                    id={ElementId.TeamTopicsDrawerContainer}
                                    typeProps={teamTopicsUpdateSectionTypeProps}
                                    typedUpdates={typedUpdatesMap[SpinachUpdateType.Topic]}
                                    currentAgendaItem={series.currentAgendaItem}
                                    agenda={series.agenda as ActiveAgendaProps}
                                    setTypedUpdates={setTypedUpdatesMap[SpinachUpdateType.Topic]}
                                    updateViewProps={liveUpdateViewProps}
                                    isInNavDrawer={true}
                                />
                            ) : drawer === NavDrawer.EditCheckIn ? (
                                <>
                                    {shouldShowPreviousCheckinHint ? (
                                        <OverlayHint
                                            style={{
                                                marginLeft: '-10px',
                                                boxShadow: 'box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.01)',
                                            }}
                                            extraHeight={-30}
                                            idOfElement={ElementId.PreviousCheckinContainer}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {isDemoSeries || !isIssueBasedEnabled ? (
                                        <>
                                            <PreviousUpdatesSection
                                                updateViewProps={liveUpdateViewProps}
                                                meetingId={series.currentMeeting.id}
                                            />
                                            <CurrentUpdateSection socket={socket} isInNavDrawer={true} />
                                        </>
                                    ) : (
                                        <IssueBasedCheckIn />
                                    )}
                                </>
                            ) : drawer === NavDrawer.Summaries ? (
                                <Summaries
                                    openedSummary={openedSummary}
                                    setOpenedSummary={setOpenedSummary}
                                    seriesHistories={seriesHistories}
                                />
                            ) : (
                                <></>
                            )}
                        </ScrollArea>

                        {isReadyButtonVisible ? (
                            <Tooltip
                                classes={tooltipClasses}
                                disableHoverListener={isDemoCheckInFinishable}
                                disableFocusListener={isDemoCheckInFinishable}
                                disableTouchListener={isDemoCheckInFinishable}
                                title={series.readyButtonHint}
                                arrow={true}
                                placement="top"
                                onClose={() => {
                                    track(ClientEventType.DemoTooltipHover, { Location: 'Disabled ready button' });
                                }}
                            >
                                <ReadyButtonRow style={{ height: asyncNotificationText ? '96px' : '60px' }} centered>
                                    <PrimaryButton
                                        title="I'm ready"
                                        style={{
                                            marginTop: asyncNotificationText ? '-35px' : 'initial',
                                            marginBottom: width < 550 ? '10px' : 'inital',
                                        }}
                                        onClick={() => {
                                            onDrawerClose();
                                            track(ClientEventType.UserClickImReadyPracticeRound);

                                            if (isDemoSeries) {
                                                setDemoState({
                                                    ...demoState,
                                                    hasClickedImReady: true,
                                                    demoModal: !isPersonaDemo
                                                        ? DemoModal.JoinStandup
                                                        : demoState.demoModal,
                                                });
                                            }
                                        }}
                                        disabled={!isDemoCheckInFinishable}
                                    />
                                </ReadyButtonRow>
                            </Tooltip>
                        ) : null}
                        {!series.isDemo || series.spinachParticipant.isReady ? (
                            <NavOpenDivider
                                variant="middle"
                                orientation="horizontal"
                                style={{
                                    height: '2px',
                                    width: '100%',
                                    marginLeft: '0px',
                                    marginRight: '0px',
                                    position: 'absolute',
                                    bottom: '60px',
                                }}
                            />
                        ) : null}
                    </>
                ) : (
                    <></>
                )}
                {asyncNotificationText && !isReadyButtonVisible ? (
                    <Row
                        style={{
                            height: width < 550 ? '56px' : '38px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Notification
                            isOpen={!!(asyncNotificationText && isOpen)}
                            containerStyle={{ bottom: '65px' }}
                            messageStyle={{ display: 'flex', textAlign: 'center' }}
                            contentStyle={{ display: 'flex', textAlign: 'center' }}
                            message={asyncNotificationText}
                        />
                    </Row>
                ) : (
                    <Notification
                        isOpen={!!(asyncNotificationText && isOpen)}
                        containerStyle={{ bottom: '65px' }}
                        messageStyle={{ display: 'flex', textAlign: 'center' }}
                        contentStyle={{ display: 'flex', textAlign: 'center' }}
                        message={asyncNotificationText}
                    />
                )}
                <Notification
                    isOpen={!series.isRoundtableEnabled && drawer === NavDrawer.EditCheckIn}
                    containerStyle={{ bottom: '65px' }}
                    messageStyle={{ display: 'flex', textAlign: 'center' }}
                    contentStyle={{ display: 'flex', textAlign: 'center' }}
                    message={'Roundtable is off. Your check-in will still be included in the summary.'}
                />
                {!series.isDemo || demoState.hasClickedImReady || drawer !== NavDrawer.EditCheckIn ? (
                    <NavButtonGroup isOpen={isOpen}>
                        {!series.isRoundtableEnabled ? (
                            <></>
                        ) : (
                            <Tooltip
                                classes={tooltipClasses}
                                disableHoverListener={!series.isDemo}
                                disableFocusListener={!series.isDemo}
                                disableTouchListener={!series.isDemo}
                                title="Writing helps you articulate thoughts clearly and save time."
                                arrow={true}
                                placement="top"
                                onClose={() => {
                                    track(ClientEventType.DemoTooltipHover, { Location: 'Check-in' });
                                }}
                            >
                                <NavButtonWrapper>
                                    <NavButton
                                        id="check-in-btn"
                                        title="Check-in"
                                        icon={
                                            <MyUpdatesIcon
                                                fill={determineFill({
                                                    isInactive: drawer !== NavDrawer.EditCheckIn,
                                                    isOpen,
                                                    isHovering,
                                                })}
                                            />
                                        }
                                        onClick={() => onNavDrawerButtonPress(NavDrawer.EditCheckIn)}
                                        onMouseEnter={() => setIsHovering(true)}
                                        onMouseLeave={() => setIsHovering(false)}
                                        isHovering={isHovering}
                                        isInactive={drawer !== NavDrawer.EditCheckIn}
                                        isOpen={isOpen}
                                    />
                                </NavButtonWrapper>
                            </Tooltip>
                        )}

                        {!series.isRoundtableEnabled ? null : (
                            <Divider
                                orientation="vertical"
                                variant="inset"
                                style={{ zIndex, minWidth: '4px', height: '40px', marginTop: '10px' }}
                            />
                        )}
                        <Tooltip
                            classes={tooltipClasses}
                            disableHoverListener={!series.isDemo}
                            disableFocusListener={!series.isDemo}
                            disableTouchListener={!series.isDemo}
                            title="We save discussion topics until the end to keep things moving!"
                            arrow={true}
                            placement="top"
                            onClose={() => {
                                track(ClientEventType.DemoTooltipHover, { Location: 'Team topics' });
                            }}
                        >
                            <NavButtonWrapper>
                                <NavButton
                                    id={ElementId.TeamTopicButton}
                                    title={topicsTitle}
                                    icon={
                                        <TeamTopicsIcon
                                            fill={determineFill({
                                                isInactive: drawer !== NavDrawer.TeamTopics,
                                                isOpen,
                                                isHovering: isHoveringOnTeamTopics,
                                            })}
                                        />
                                    }
                                    onClick={() => onNavDrawerButtonPress(NavDrawer.TeamTopics)}
                                    onMouseEnter={() => setIsHoveringOnTeamTopics(true)}
                                    onMouseLeave={() => setIsHoveringOnTeamTopics(false)}
                                    isHovering={isHoveringOnTeamTopics}
                                    isTeamTopicsButton={true}
                                    isInactive={drawer !== NavDrawer.TeamTopics}
                                    isOpen={isOpen}
                                    teamTopicsCount={teamTopicsCount}
                                />
                            </NavButtonWrapper>
                        </Tooltip>

                        {!isPersonaDemo ? (
                            <Divider
                                orientation="vertical"
                                variant="inset"
                                style={{ zIndex, minWidth: '4px', height: '40px', marginTop: '10px' }}
                            />
                        ) : null}

                        {!isPersonaDemo ? (
                            <Tooltip
                                classes={tooltipClasses}
                                disableHoverListener={!series.isDemo}
                                disableFocusListener={!series.isDemo}
                                disableTouchListener={!series.isDemo}
                                title="Refer back to all previous standups to see what you or others said."
                                placement="top"
                                arrow={true}
                                onClose={() => {
                                    track(ClientEventType.DemoTooltipHover, { Location: 'Summaries' });
                                }}
                            >
                                <NavButtonWrapper>
                                    <NavButton
                                        id="summary-btn"
                                        title="Summaries"
                                        icon={
                                            <SummariesIcon
                                                fill={determineFill({
                                                    isInactive: drawer !== NavDrawer.Summaries,
                                                    isOpen,
                                                    isHovering: isHoveringOnSummaries,
                                                })}
                                            />
                                        }
                                        onClick={() => {
                                            onNavDrawerButtonPress(NavDrawer.Summaries);
                                            setHasOpenedSummariesTabInDemo();
                                        }}
                                        onMouseEnter={() => setIsHoveringOnSummaries(true)}
                                        onMouseLeave={() => setIsHoveringOnSummaries(false)}
                                        isHovering={isHoveringOnSummaries}
                                        isInactive={drawer !== NavDrawer.Summaries}
                                        isOpen={isOpen}
                                    />
                                </NavButtonWrapper>
                            </Tooltip>
                        ) : null}
                    </NavButtonGroup>
                ) : null}
            </NavPaper>
        </>
    );
}

function useMemoizedTeamTopicCount(series: SpinachSeriesProps) {
    return useMemo(
        () =>
            series.currentMeeting.agenda.YTBItemsWithParkingLotUpdates.map(
                (item) => item.standUpUpdate.getUpdatesForType(SpinachUpdateType.ParkingLot).length
            ).reduce((previousValue, currentValue) => previousValue + currentValue, 0),
        [series.currentMeeting.agenda]
    );
}

function useMemoizedTeamTopic2Count(series: SpinachSeriesProps) {
    return useMemo(() => series.currentMeeting.agenda.topicUpdates.length, [series.currentMeeting.agenda]);
}

function useDrawerAutoCloseOnUsersTurn(
    series: SpinachSeriesProps,
    timeOpened: number | undefined,
    history: YTBHistoryProps | undefined,
    statefulYesterdays: TypedUpdate[],
    setTimeOpened: React.Dispatch<React.SetStateAction<number | undefined>>
) {
    const { drawer, closeDrawer } = useGlobalNavDrawer();
    const [user] = useGlobalAuthedUser();
    const userItem = series.thisParticipantsYTBAgendaItem;

    useEffect(() => {
        const sendNavCloseAnalytics = async () => {
            const payload: NavDrawerClickPayload = {
                ...user.toUserIdentityPayload(),
                SeriesId: series.slug,
                MeetingID: series.currentMeeting.id,
                TimeOpened: `${Math.abs(Date.now() - (timeOpened ?? Date.now()))}ms`,
                MeetingStatus: series.currentMeeting.status,
                DrawerAction: DrawerAction.Close,
                AutoAction: true,
                ContextItemsAvailable: history?.mostRecentTodays.length ?? 0,
                ContextItemsPopulated: statefulYesterdays.reduce((total, update) => {
                    if (update.isRolledOver) {
                        total += 1;
                    }
                    return total;
                }, 0),
                AgendaItemSource: series.currentAgendaItem?.source,
            };

            await postExperienceEvent({ eventType: ClientEventType.NavCloseOnMyTurn, payload });
        };

        if (series.currentAgendaItem?.id === userItem.id) {
            if (drawer) {
                sendNavCloseAnalytics();
                setTimeOpened(undefined);
            }
            closeDrawer();
        }
    }, [series.currentAgendaItem?.id]);
}

export function useDrawerAutoSizing(series: SpinachSeriesProps, openedSummary: number | null): number {
    const { drawer } = useGlobalNavDrawer();
    const [drawerHeight, setDrawerHeight] = useState(isDynamicDrawerDisabled ? 0 : 65);
    const heightOfDrawerHeaderAndFooter = 60 + 70;
    const extraPadding = 65;

    useEffect(() => {
        if (isDynamicDrawerDisabled) {
            return;
        }

        const timeout = setTimeout(() => {
            if (drawer === NavDrawer.EditCheckIn) {
                const currentCheckinHeight =
                    document.getElementById(ElementId.CurrentCheckinContainer)?.offsetHeight ?? 0;
                const previousCheckinHeight =
                    document.getElementById(ElementId.PreviousCheckinContainer)?.offsetHeight ?? 0;

                setDrawerHeight(
                    currentCheckinHeight + previousCheckinHeight + heightOfDrawerHeaderAndFooter + extraPadding
                );
            } else if (drawer === NavDrawer.TeamTopics) {
                const height = document.getElementById(ElementId.TeamTopicsDrawerContainer)?.offsetHeight ?? 0;
                const paddingHeight = 0;

                setDrawerHeight(height + heightOfDrawerHeaderAndFooter + paddingHeight + extraPadding);
            } else if (drawer === NavDrawer.Summaries) {
                const extraHeaderHeightForSummaries = 62;
                const height = document.getElementById(ElementId.SummaryContainer)?.offsetHeight ?? 0;
                const bottomScrollviewPadding = 20;

                setDrawerHeight(
                    height + heightOfDrawerHeaderAndFooter + extraHeaderHeightForSummaries + bottomScrollviewPadding
                );
            } else {
                setDrawerHeight(65);
            }
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [drawer, series, openedSummary]);

    return drawerHeight;
}

function useDrawerAutoOpenerForPrepping(
    series: SpinachSeriesProps,
    setTimeOpened: React.Dispatch<React.SetStateAction<number | undefined>>
) {
    const { drawer, openCheckIn } = useGlobalNavDrawer();

    useEffect(() => {
        if (
            drawer === null &&
            !series.spinachParticipant.isReady &&
            series.meetingStatus === MeetingStatus.Initialized
        ) {
            openCheckIn();
            setTimeOpened(Date.now());
        }
    }, [series.spinachParticipant.standUpStatus]);
}
