import styled from 'styled-components';

import { ButtonSize, SecondaryButtonLabel } from '../../styles';
import { ButtonProps, OutlineButtonSizing } from '../../types/StandUp';
import { getButtonFontSize } from '../stand-up';
import { FadeInAnimationProps, fadeInAnimation } from './animation';

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
`;

export const BaseSecondaryButton = styled.button<FadeInAnimationProps>`
    text-align: center;
    align-items: center;
    font-weight: ${(props) => (props.disabled && !props.fadeInDuration ? 400 : 600)};
    border: none;
    background: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    text-decoration: underline;
    text-decoration-color: ${(props) => (props.color ? props.color : 'initial')};
    padding: 0;
    letter-spacing: 0.35px;
    color: ${(props) => props.color ?? 'initial'};
    ${(props) => (props.disabled && props.fadeInDuration ? fadeInAnimation(props.fadeInDuration) : undefined)};

    &:focus {
        font-weight: 700;
    }
`;

const AdjustableLabel = styled(SecondaryButtonLabel)<OutlineButtonSizing>`
    font-size: ${(props) => getButtonFontSize(props.size)}px;

    &:hover {
        font-weight: 700;
    }
`;

type SecondaryButtonProps = ButtonProps & Partial<OutlineButtonSizing>;

function SecondaryButton({
    title,
    onClick,
    disabled,
    fadeInDuration,
    size = ButtonSize.Normal,
    containerStyles,
    labelStyles,
    color,
    ...rest
}: SecondaryButtonProps): JSX.Element {
    return (
        <Container style={containerStyles}>
            <BaseSecondaryButton
                onClick={() => onClick()}
                disabled={disabled}
                fadeInDuration={fadeInDuration}
                tabIndex={0}
                color={color}
                {...rest}
            >
                <AdjustableLabel size={size} disabled={disabled} style={{ ...labelStyles, color }}>
                    {title}
                </AdjustableLabel>
            </BaseSecondaryButton>
        </Container>
    );
}

export default SecondaryButton;
