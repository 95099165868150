import { ButtonHTMLAttributes, DetailedHTMLProps, SVGProps, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/close-settings-icon.svg';

const CloseButtonButton = styled.button`
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    border: none;
    left: 8px;
    top: 20px;
`;

export const CloseButton = ({
    onClick,
    style,
    ...rest
}: {
    onClick: () => void;
    style?: React.CSSProperties & SVGProps<SVGElement>;
} & Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'ref'>): JSX.Element => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <CloseButtonButton
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={style}
            id="close-button"
            onClick={onClick}
            tabIndex={0}
            {...rest}
        >
            <CloseIcon
                style={{ width: '20px', height: '20px', filter: isHovered ? 'brightness(0.8)' : 'initial' }}
                fill={style?.fill || '#F69921'}
            />
        </CloseButtonButton>
    );
};
