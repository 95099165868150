import { Avatar, ListItemAvatar } from '@material-ui/core';
import styled from 'styled-components';

import { BodyRegular, lightTheme } from '../../styles';
import { ItemListProps } from '../../types';
import { BootstrapTooltip } from './BootstrapTooltip';
import { Column, Row } from './framing';

const ListItemRow = styled(Row)<{ shouldHover?: boolean; disabled?: boolean }>`
    width: 100%;
    align-items: center;
    flex: unset;
    flex-shrink: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : props.shouldHover ? 'pointer' : 'auto')};

    &:hover {
        border-color: ${(props) => (props.shouldHover ? '#b8b8c5' : 'none')};
        background-color: ${(props) => (props.shouldHover ? lightTheme.neutrals.grayLight : 'none')};
    }
`;

const ListItemText = styled(BodyRegular)`
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
`;

export const ItemList = <T extends string | number | null>({
    values,
    handleSelection,
    textStyle,
    shouldListItemHover,
    ...props
}: ItemListProps<T>) => {
    return (
        <Column style={{ width: '100%' }}>
            {values.map(({ label, postContent, code, preContent, tooltip, disabled, isTooltipInteractive }) => (
                <BootstrapTooltip title={tooltip ?? ''} interactive={isTooltipInteractive}>
                    <ListItemRow
                        key={code}
                        shouldHover={shouldListItemHover}
                        disabled={disabled}
                        onClick={() =>
                            handleSelection?.(code, { label, postContent, code, preContent, tooltip, disabled })
                        }
                        {...props}
                    >
                        {!!preContent && (
                            <ListItemAvatar>
                                <Avatar>{preContent}</Avatar>
                            </ListItemAvatar>
                        )}
                        <ListItemText style={{ ...textStyle }}>{label}</ListItemText>
                        {postContent}
                    </ListItemRow>
                </BootstrapTooltip>
            ))}
        </Column>
    );
};
