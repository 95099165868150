import { SpinachAPIPath } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { postSpinachAPI } from '..';
import { getClientPlatform } from './platform';

type ClientLoggerMetadata = {
    label: string;
    level: 'info' | 'error';
    [key: string]: any;
};

export type LogMessage = {
    message: string;
    metadata: ClientLoggerMetadata;
};

export async function postLogMessage(request: LogMessage): Promise<void> {
    try {
        await postSpinachAPI(SpinachAPIPath.Logging, {
            ...request,
            metadata: { ...request.metadata, label: 'client', platform: getClientPlatform() },
        });
    } catch (error) {}
}

export class ClientLogger {
    static async info(message: string, meta: any = {}): Promise<void> {
        meta['level'] = 'info';

        if (isLocalStage()) {
            console.info(message);
        }

        await postLogMessage({ message: message, metadata: meta });
    }

    static async error(message: string, meta: any = {}): Promise<void> {
        meta['level'] = 'error';

        if (isLocalStage()) {
            console.error(message);
        }

        await postLogMessage({ message: message, metadata: meta });
    }
}
