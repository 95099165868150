import { useState } from 'react';

import { ClientEventType, SpinachAPIPath } from '@spinach-shared/types';

import { getUser } from '../../../apis';
import { deleteSpinachAPI } from '../../../apis/deleteSpinachAPI';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { BodyLarge, BodyRegular, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { Direction, SlidingColumn } from '../SlidingColumn';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row, Spacing } from '../framing';

export function ClickUpIntegrationSettingsModal() {
    const { setSubview } = useGlobalMeetingSettings();
    const [direction, setDirection] = useState(Direction.Forward);

    function onBack() {
        setDirection(Direction.Forward);
        setSubview(null);
    }

    return (
        <SpinachModalContent
            onBack={onBack}
            style={{
                maxWidth: '80%',
                padding: '20px',
                minHeight: 'unset',
            }}
        >
            <SlidingColumn centered direction={direction}>
                <ClickUpIntegrationSettings />
            </SlidingColumn>
        </SpinachModalContent>
    );
}

export function ClickUpIntegrationSettings(): JSX.Element {
    const [, setUser] = useGlobalAuthedUser();
    const { setSubview } = useGlobalMeetingSettings();
    const [isLoading, setIsLoading] = useState(false);
    const track = useExperienceTracking();

    const [isConfirmedToDelete, setIsConfirmedToDelete] = useState(false);

    return (
        <Column>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>ClickUp Settings</b>
                </ResponsiveModalTitle>
            </Row>

            <Hairline />

            <Spacing factor={1 / 2} />

            <ScrollArea style={{ height: '40vh' }} sidePadding={10}>
                <Row>
                    <Column>
                        <BodyLarge>Disconnect ClickUp</BodyLarge>
                        <BodyRegular>After disconnecting, we'll no longer add ClickUp tasks</BodyRegular>
                        <Spacing factor={1 / 3} />
                        <OutlinedButton
                            title="Remove ClickUp Connection"
                            style={{
                                width: '260px',
                            }}
                            disabled={isConfirmedToDelete}
                            onClick={() => {
                                track(ClientEventType.DeleteClickUpIntegrationClick);
                                setIsConfirmedToDelete(true);
                            }}
                        />
                        {isConfirmedToDelete ? (
                            <Row style={{ opacity: 1, transition: '500ms', marginTop: '10px' }}>
                                <PrimaryButton
                                    title={'Yes, Remove it'}
                                    color={lightTheme.status.negative}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    isLoading={isLoading}
                                    onClick={async () => {
                                        setIsLoading(true);

                                        track(ClientEventType.DeleteClickUpIntegrationConfirmClick);

                                        await deleteSpinachAPI(SpinachAPIPath.ClickUpDisconnect);

                                        // then we refetch and hydrate user in state, now without the integration
                                        const userResponse = await getUser();
                                        if (userResponse.user) {
                                            setUser(userResponse.user);
                                        }

                                        setIsLoading(false);
                                        setSubview(null);
                                    }}
                                />

                                <span style={{ display: 'flex', width: '10px' }} />

                                <OutlinedButton
                                    title={'Cancel'}
                                    disabled={isLoading}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    onClick={() => {
                                        track(ClientEventType.CancelClickUpDeleteClick);
                                        setIsConfirmedToDelete(false);
                                    }}
                                />
                            </Row>
                        ) : null}
                    </Column>
                </Row>
                <Spacing />
            </ScrollArea>
        </Column>
    );
}
