import { ArrowRight, InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { ReactComponent as Check } from '../../../assets/green-circle-check.svg';
import SpinachCalendarConnectionSrc from '../../../assets/spinach-calendar-connection.png';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
} from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard, lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { GoogleLoginComponent } from '../../common/GoogleLoginComponent';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButton, DoItManually, Stationary, StepProps } from './common';

export type CalendarPermissionsStep = StepProps & {
    onBack: () => void;
    onSkip: () => void;
    onProgressToAddEventToMeeting: (user: ClientUser) => void;
};

const Callout = styled(Row)`
    width: 94%;
    padding: 7px;
    background-color: #f3f3f3;
    border-radius: 8px;
`;

export function CalendarPermissionsStep({
    onBack,
    direction,
    onSkip,
    onSubmit,
    onProgressToAddEventToMeeting,
}: CalendarPermissionsStep): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(onProgressToAddEventToMeeting);
    useLandingAnalytic(ClientEventType.UserViewedCalendarPermissionsStep);
    const [isManualInstructionOpen, setIsManualInstructionOpen] = useState(false);
    const track = useExperienceTracking();
    return (
        <Stationary>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>We'd love your permission</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />

                    <Row centered>
                        <img src={SpinachCalendarConnectionSrc} style={{ height: '60px' }} />
                    </Row>

                    <Spacing />

                    <Callout>
                        <InfoOutlined style={{ marginRight: '5px' }} />
                        <BodyBigOnboard>Make sure to check all boxes to connect.</BodyBigOnboard>
                    </Callout>

                    <Spacing />

                    <Row>
                        <Check
                            style={{
                                marginRight: '10px',
                                transform: 'scale(1.5)',
                                marginLeft: '10px',
                                marginTop: '6px',
                            }}
                        />
                        <BodyBigOnboard>Select meetings you would like Spinach to join</BodyBigOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row>
                        <Check
                            style={{
                                marginRight: '10px',
                                transform: 'scale(1.5)',
                                marginLeft: '10px',
                                marginTop: '6px',
                            }}
                        />
                        <BodyBigOnboard>Gain access to summary templates</BodyBigOnboard>
                    </Row>

                    <Spacing factor={1 / 2} />

                    <Row centered>
                        <GoogleLoginComponent
                            text="Connect Google Calendar"
                            onClick={() => {
                                track(ClientEventType.UserClickedIntegrateWithGoogleCalendar);
                                startDetection();
                                URLUtil.openURL(
                                    `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                );
                            }}
                        />
                    </Row>

                    <Spacing />

                    <Row
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            track(ClientEventType.UserClickedManualToggle, {
                                Action: isManualInstructionOpen ? 'close' : 'open',
                            });
                            setIsManualInstructionOpen(!isManualInstructionOpen);
                        }}
                    >
                        <BodyBigOnboard style={{ fontWeight: 600, color: lightTheme.primary.greenDark }}>
                            Or do it manually
                        </BodyBigOnboard>
                        <ArrowRight
                            style={{
                                color: lightTheme.primary.greenDark,
                                marginTop: '3px',
                                height: '20px',
                                width: '20px',
                                paddingRight: '4px',
                                transition: '200ms',
                                transform: isManualInstructionOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                            }}
                        />
                    </Row>

                    {isManualInstructionOpen ? (
                        <div style={{ transition: '200ms' }}>
                            <DoItManually />
                        </div>
                    ) : null}
                </SlidingColumn>
            </ScrollView>
            <Spacing />
            <Row centered>
                <PrimaryButton title={'Done'} onClick={onSubmit} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
