import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { SpinachSeriesProps } from '@spinach-shared/models';
import { ClientSocketEvent, MeetingInactiveRequest, TimeInMillis } from '@spinach-shared/types';
import { isProductionStage, isStagingStage, isTestStage } from '@spinach-shared/utils';

import { createWebsocketPayload } from '@spinach-clients/shared/src/utils/analytics';

const MINUTES_OF_INACTIVITY = isProductionStage() ? 120 : isTestStage() || isStagingStage() ? 30 : 5;
const ALLOWED_INACTIVITY_DURATION: TimeInMillis = MINUTES_OF_INACTIVITY * SECONDS_IN_MINUTES * MILLIS_IN_SECONDS;

export function useInactivityModalEmitter(
    series: SpinachSeriesProps | null,
    socket: Socket | null,
    isNotificationToastOpen: boolean
) {
    const [timeoutNotificationRef, setTimeoutNotificationRef] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (!(series && socket)) {
            return;
        }

        if (timeoutNotificationRef) {
            clearTimeout(timeoutNotificationRef);
        }

        const timeoutNotification = setTimeout(() => {
            const payload = createWebsocketPayload<MeetingInactiveRequest>({
                seriesSlug: series.slug,
                meetingId: series.currentMeeting.id,
                spinachUserId: series.spinachParticipant.spinachUserId,
            });

            socket.emit(ClientSocketEvent.TriggerMeetingInactiveModal, payload);
        }, ALLOWED_INACTIVITY_DURATION);

        setTimeoutNotificationRef(timeoutNotification);

        return () => {
            if (timeoutNotificationRef) {
                clearTimeout(timeoutNotificationRef);
            }

            if (timeoutNotification) {
                clearTimeout(timeoutNotification);
            }
        };
    }, [isNotificationToastOpen]);
}
