import { useEffect, useState } from 'react';

type Size = {
    width: number;
    height: number;
};

export function useWindowSize(): Size {
    const [windowSize, setWindowSize] = useState<Size>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

/**
 *
 * @returns true if window is inactive, false if window is active
 */
export function useWindowInactivity(): boolean {
    const [isInactive, setIsInactive] = useState(false);
    useEffect(() => {
        function handleVisibilityChange() {
            if (document.visibilityState === 'hidden') {
                setIsInactive(true);
            } else if (document.visibilityState === 'visible') {
                setIsInactive(false);
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, []);

    return isInactive;
}
