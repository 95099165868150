export function getRedactedEmail(email: string): string {
    try {
        const components = email.split('@');

        if (components.length === 2) {
            const domain = components[1];
            return `redacted@${domain}`;
        } else {
            return 'unknown';
        }
    } catch {
        return 'unknown';
    }
}

export function getLowercaseDomainFromEmail(email: string): string {
    const loweredEmailDomain = email.toLowerCase().split('@')[1];
    const fullLoweredEmailDomain = `@${loweredEmailDomain}`;

    return fullLoweredEmailDomain;
}

export function isEmailValid(email: string): boolean {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )?.length;
}

const calendarResourceEmailSuffixes = ['calendar.yahoo.com', 'calendar.apple.com', 'calendar.google.com'];

export function isCalendarResourceEmail(email: string): boolean {
    return (
        !!email && calendarResourceEmailSuffixes.some((suffix) => email.toLowerCase().endsWith(suffix.toLowerCase()))
    );
}
