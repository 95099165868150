import React from 'react';
import styled from 'styled-components';

import MountainBackground from '../../assets/demo/mountain_bg.jpg';
import LeftDesign from '../../assets/left-side-bg.svg';
import RightDesign from '../../assets/right-side-bg.svg';
import { useSeriesReality } from '../../hooks';
import { responsiveness } from '../../styles';

const BackgroundTerrain = styled.img`
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    min-width: 100vw;
    min-height: 100%;
`;

const BackgroundTerrainRight = styled.img`
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    min-height: 100%;

    @media ${responsiveness.thinnerThanLG} {
        height: 480px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 240px;
    }
`;

const BackgroundTerrainLeft = styled.img`
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    min-height: 100%;

    @media ${responsiveness.thinnerThanLG} {
        height: 480px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 240px;
    }
`;

export function AbstractBackground(): JSX.Element {
    const { isDemoSeries } = useSeriesReality();

    if (isDemoSeries) {
        return <BackgroundTerrain src={MountainBackground} alt="" />;
    }

    return (
        <>
            <BackgroundTerrainLeft id="background-left" src={LeftDesign} alt="" />
            <BackgroundTerrainRight id="background-right" src={RightDesign} alt="" />
        </>
    );
}
