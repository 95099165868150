function createCopyListener(text: string) {
    return function listener(e: ClipboardEvent) {
        e.clipboardData?.setData('text/html', text);
        e.clipboardData?.setData('text/plain', text);
        e.preventDefault();
    };
}

/**
 *
 * @param text Text to be copied to clipboard
 * @param customId an element ID to append the copy scratch text area in case body is blocked by a modal or some such element
 */
export function copyTextToClipboard(text: string, customId?: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.contentEditable = 'true';
    textArea.readOnly = true;

    if (customId) {
        document.getElementById(customId)!.appendChild(textArea);
    } else {
        document.body.appendChild(textArea);
    }

    textArea.focus();
    textArea.select();

    const listener = createCopyListener(text);

    try {
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    } catch (_) {}

    if (customId) {
        document.getElementById(customId)!.removeChild(textArea);
    } else {
        document.body.removeChild(textArea);
    }
}
