import { ClickAwayListener, Tooltip } from '@material-ui/core';

import { SeriesUserMetadata, StoredSeries, UserSeriesMetadata } from '@spinach-shared/types';
import { isDemoSeries, isLocalStage } from '@spinach-shared/utils';

import { Column } from '..';
import { useGlobalRouting } from '../../hooks';
import { BodyRegular } from '../../styles';
import { SetValue } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { withAccessibleSubmitProps } from '../../utils/dom';
import { SeriesCardOptions } from './SeriesCardOptions';
import { SeriesCard } from './common';

export function SeriesDashboardItem({
    series,
    storedSeries,
    setOptionsOpen,
    noElementsOpened,
    areOptionsOpen,
    setHoveredSeries,
    hoveredSeries,
    onClickRemove,
    onClickRename,
}: {
    series: UserSeriesMetadata;
    storedSeries?: StoredSeries;
    setOptionsOpen: SetValue<boolean>;
    noElementsOpened: boolean;
    areOptionsOpen: boolean;
    setHoveredSeries: SetValue<UserSeriesMetadata | null>;
    hoveredSeries: UserSeriesMetadata | null;
    onClickRemove: () => void;
    onClickRename: () => void;
}): JSX.Element {
    const { routeToSeriesExperience } = useGlobalRouting();
    const isHovered = hoveredSeries?.id === series.id;
    const onClick = () => {
        if (storedSeries) {
            routeToSeriesExperience(storedSeries.slug);
        }
    };
    return (
        <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
            <Tooltip
                key={series.id}
                className={withFullStoryMasking()}
                title={
                    storedSeries && noElementsOpened ? (
                        <span className={withFullStoryMasking()}>
                            Teammates in here:{' '}
                            {(storedSeries.userMetadataList as SeriesUserMetadata[]).map((u) => u.email).join(', ')}
                        </span>
                    ) : (
                        ''
                    )
                }
                placement={'bottom'}
                arrow
            >
                <SeriesCard
                    id={`series-card-${series.id}`}
                    onMouseEnter={() => {
                        if (!areOptionsOpen) {
                            setHoveredSeries(series);
                        }
                    }}
                    onMouseLeave={() => {
                        if (!areOptionsOpen) {
                            setHoveredSeries(null);
                        }
                    }}
                    {...withAccessibleSubmitProps(onClick)}
                >
                    {/* Hide options for AI series, so they can't rename or  */}
                    {isHovered ? (
                        <SeriesCardOptions
                            seriesId={series.id}
                            isHovered={isHovered}
                            areOptionsOpen={areOptionsOpen}
                            setOptionsOpen={setOptionsOpen}
                            onClickRemove={onClickRemove}
                            onClickRename={onClickRename}
                            hideCopyLink={!!storedSeries?.metadata?.scribeMetadata}
                        />
                    ) : null}
                    <Column>
                        <BodyRegular>{series.name}</BodyRegular>
                    </Column>
                </SeriesCard>
            </Tooltip>
        </ClickAwayListener>
    );
}
