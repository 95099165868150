import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { ReactComponent as CopyIcon } from '../../../assets/copy-icon.svg';
import { BodyBigOnboard, lightTheme, responsiveness } from '../../../styles';
import { copyTextToClipboard } from '../../../utils';
import { Direction, Row, Spacing, SpinachStationary } from '../../common';
import { ButtonContainer } from '../styles';

export enum OnboardingStep {
    About = 'about',
    CalendarPermissions = 'calendar-permissions',
    SelectEventForMeeting = 'select-event-for-meeting',
    ManualInvite = 'manual-invite',
    Slack = 'slack-integration',
    SlackDefaults = 'slack-defaults',
    Tickets = 'tickets',
    Finished = 'finished',
}

export type StepProps = {
    direction: Direction;
    onSubmit: () => Promise<void>;
    loadingMessage: string;
};

export const Stationary = styled(SpinachStationary)`
    position: relative;
    min-height: 40%;
    max-height: 80%;
    @media ${responsiveness.lg} {
        width: 500px;
    }
`;

export function BackButton({ onBack, style = {} }: { onBack: () => void; style?: CSSProperties }): JSX.Element {
    return (
        <ButtonContainer>
            <IconButton
                aria-label="back to previous step"
                size="small"
                style={{ marginTop: '-5px', marginLeft: '-5px', ...style }}
                onClick={() => onBack()}
            >
                <ChevronLeft fontSize="default" />
            </IconButton>
        </ButtonContainer>
    );
}

export function CopiableBotEmail(): JSX.Element {
    return (
        <span
            style={{
                margin: '0px 5px',
                padding: '5px',
                borderRadius: '8px',
                backgroundColor: lightTheme.neutrals.gray,
                cursor: 'pointer',
                fontWeight: 600,
                color: lightTheme.primary.greenDark,
                display: 'inline-flex',
                alignItems: 'center',
            }}
            onClick={() => {
                copyTextToClipboard(process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS!);
            }}
        >
            {process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS}
            <CopyIcon style={{ marginLeft: '5px' }} />
        </span>
    );
}

export function DoItManually(): JSX.Element {
    return (
        <>
            <Spacing factor={1 / 2} />

            <Row>
                {/* TODO NUMBER */}
                <BodyBigOnboard style={{ fontWeight: 500 }}>
                    1. Add <CopiableBotEmail /> to your calendar meeting.
                </BodyBigOnboard>
            </Row>
            <Spacing />
            <Row>
                {/* TODO NUMBER */}
                <BodyBigOnboard style={{ fontWeight: 500 }}>
                    2. Spinach will automatically join your meeting and provide a summary in your inbox.
                </BodyBigOnboard>
            </Row>
            <Spacing />
            <Row>
                {/* TODO NUMBER */}
                <BodyBigOnboard style={{ fontWeight: 500 }}>
                    3. Hit confirm once you've added to your meeting.
                </BodyBigOnboard>
            </Row>
            <Spacing />
        </>
    );
}
