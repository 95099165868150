import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getStoredSeries } from '../../../apis';
import { useGlobalNullableStoredSeries, useGlobalRouting, useLocationalSeriesId } from '../../../hooks';

export function useLoadSeriesOnMount() {
    const locationalSeriesId = useLocationalSeriesId();
    const location = useLocation();
    const [series, setSeries] = useGlobalNullableStoredSeries();
    const { routeToDashboard } = useGlobalRouting();

    useEffect(() => {
        async function loadSeries(): Promise<void> {
            if (locationalSeriesId) {
                const fetchedSeries = await getStoredSeries(locationalSeriesId);

                if (fetchedSeries) {
                    setSeries(fetchedSeries);
                } else {
                    routeToDashboard({ replace: true });
                }
            }
        }
        if (!series) {
            loadSeries();
        }
    }, [location.pathname, series]);

    return series;
}
