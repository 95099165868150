import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Info } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CalendarEvent, ClientEventType } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { deleteGoogleCalendarSettings } from '../../apis/deleteGoogleCalendarSettings';
import { ReactComponent as AttendeeIcon } from '../../assets/attendee.svg';
import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { useExperienceTracking, useGlobalAuthedUser } from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { useGoogleCalendarEvents, useUpdateScribeOnEvent } from '../../hooks/useGoogleCalendarEvents';
import { BodyHuge, HeaderThreeOnboard, lightTheme } from '../../styles';
import { ClientLogger } from '../../utils';
import { BootstrapTooltip, Row } from '../common';
import { ScrollView } from '../series/common';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import { PrimaryButton } from '../stand-up/PrimaryButton';
import SecondaryButton from '../stand-up/SecondaryButton';
import { ButtonContainer, Container, ContentContainer } from './styles';

const MeetingContainer = styled.li<{ isInteractive: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid #eaeaea;
    box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    cursor: ${(props) => (props.isInteractive ? 'pointer' : 'default')};
`;

const MeetingsContainer = styled.ul`
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const MeetingsScrollView = styled(ScrollView)<{ hasError: boolean }>`
    position: relative;
    ${(props) => props.hasError && 'overflow:hidden;'}
`;

const MeetingsScrollViewContainer = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    margin-bottom: 16px;
    margin-top: 8px;
    overflow: hidden;
    width: 100%;
`;
const ProgressOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.95;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BottomButtonContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const MeetingCheck = styled.div<{ isChecked: boolean; isInteractive: boolean }>`
    width: 30px;
    height: 30px;
    background: ${(props) =>
        props.isChecked && props.isInteractive ? props.theme.secondary.green : props.theme.neutrals.gray};
    justify-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
`;

const ProgressMessage = styled.div`
    color: ${(props) => props.theme.primary.green};
`;

const ErrorMessage = styled.div`
    background: #f6cbcb;
    padding: 16px;
    border-radius: 12px;
`;

function getUniqueRecurringEvents(events: CalendarEvent[]): CalendarEvent[] {
    const onlyRecurring = events.filter((event) => !!event.recurringEventId);
    const recurringUnique: CalendarEvent[] = [];
    const iCalUidSet = new Set();
    onlyRecurring.forEach((event) => {
        if (iCalUidSet.has(event.iCalUID)) {
            return;
        }

        recurringUnique.push(event);
        iCalUidSet.add(event.iCalUID);
    });

    return recurringUnique;
}

export function isScribeOnEvent(event: CalendarEvent) {
    return !!event.attendees?.find((attendee) => attendee.email === process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS);
}

function MeetingItem({
    event,
    showTooltip,
    isInteractive,
    isChecked,
}: {
    event: CalendarEvent;
    showTooltip: boolean;
    isInteractive: boolean;
    isChecked: boolean;
}): JSX.Element {
    return (
        <>
            <Row style={{ alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>{event.summary}</span>
                {event.attendees?.length ? (
                    <>
                        <AttendeeIcon style={{ marginLeft: '16px' }} />
                        <span style={{ marginLeft: '8px' }}>{event.attendees?.length}</span>
                    </>
                ) : (
                    <></>
                )}

                {showTooltip ? (
                    <></>
                ) : (
                    <BootstrapTooltip
                        title="Contact the meeting owner to remove spinach from the invite."
                        interactive={true}
                        placement="bottom"
                        style={{ fontSize: '16px' }}
                    >
                        <Info style={{ marginLeft: '16px' }} />
                    </BootstrapTooltip>
                )}
                <span style={{ flex: 1 }} />
                <BootstrapTooltip title={!isInteractive && isChecked ? 'Spinach is already on this meeting' : ''}>
                    <MeetingCheck isChecked={isChecked} isInteractive={isInteractive}>
                        {isChecked ? <CheckIcon stroke={'white'} style={{ width: '66%', height: 'auto' }} /> : <></>}
                    </MeetingCheck>
                </BootstrapTooltip>
            </Row>
        </>
    );
}

export function ScribeCalendarPage(): JSX.Element {
    const { data, isFetching, error, refetch, queryKey } = useGoogleCalendarEvents({
        startISOString: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
        endISOString: moment.tz(TimeUtils.getTimezoneRegion()).add(7, 'days').endOf('day').toISOString(),
    });
    const [isConfirmedToDelete, setIsConfirmedToDelete] = useState(false);
    const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();

    const { mutate: updateScribeOnEvent, error: mutateError } = useUpdateScribeOnEvent(queryKey);
    const [progressStageName, setProgressStageName] = useState<string | null>(null);
    const relevantEvents = getUniqueRecurringEvents(data || []);
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!user.isAuthedForGoogleCalendar) {
            ClientLogger.error("User in ScribeCalendarPage but isn't authed for Google Calendar", {
                spinachUserId: user.spinachUserId,
            });
        }
    }, [user.isAuthedForGoogleCalendar]);
    const hasError = !!error || !!mutateError;

    useEffect(() => {
        setProgressStageName(isFetching ? 'Fetching meetings...' : null);
    }, [isFetching]);

    return (
        <Container>
            <ContentContainer style={{ height: '65vh', alignItems: 'center' }}>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <HeaderThreeOnboard>Choose Your Team Meetings</HeaderThreeOnboard>
                <BodyHuge>Choose the meetings you want to add Spinach.io to.</BodyHuge>
                <MeetingsScrollViewContainer>
                    <MeetingsScrollView hasError={hasError}>
                        <MeetingsContainer>
                            {relevantEvents?.map((event) => {
                                const isOrganizer = user.isUserTheOrganizer(event);
                                const isScribeOn = isScribeOnEvent(event);
                                const isInteractive =
                                    isOrganizer ||
                                    // all users can add scribe to events
                                    !isScribeOn;
                                return (
                                    <MeetingContainer
                                        isInteractive={isInteractive}
                                        key={event.iCalUID}
                                        onClick={() => {
                                            track(ClientEventType.GoogleCalendarMeetingItemClick, {
                                                ICalUid: event.iCalUID,
                                                Action: isScribeOn ? 'remove' : 'add',
                                                MeetingTitle: event.summary,
                                                IsCurrentUserTheOrganizer: isOrganizer,
                                                IsOnboardingFlow: false,
                                                AttendeeCount: event.attendees?.length,
                                            });

                                            if (!isInteractive || !event.iCalUID) {
                                                return;
                                            }

                                            updateScribeOnEvent({
                                                iCalUid: event.iCalUID,
                                                addToEvent: !isScribeOn,
                                            }); // toggle scribe on event
                                        }}
                                    >
                                        <MeetingItem
                                            event={event}
                                            isChecked={isScribeOn}
                                            isInteractive={isInteractive}
                                            showTooltip={isOrganizer}
                                        />
                                    </MeetingContainer>
                                );
                            })}
                        </MeetingsContainer>
                    </MeetingsScrollView>
                    {progressStageName || hasError ? (
                        <ProgressOverlay>
                            {hasError ? (
                                <ErrorMessage>There was an error. Please reload the page.</ErrorMessage>
                            ) : progressStageName ? (
                                <ProgressMessage>{progressStageName}</ProgressMessage>
                            ) : (
                                <></>
                            )}
                        </ProgressOverlay>
                    ) : (
                        <></>
                    )}
                </MeetingsScrollViewContainer>
                <BottomButtonContainer>
                    <OutlinedButton
                        title="Disconnect Calendar"
                        style={{
                            width: '250px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        onClick={() => {
                            track(ClientEventType.DisconnectGoogleCalendarIntegrationClick);
                            setIsConfirmedToDelete(true);
                        }}
                        disabled={isFetching}
                    />
                    <span style={{ flex: 1 }} />
                    <SecondaryButton
                        title="Refresh"
                        onClick={() => {
                            track(ClientEventType.RefreshGoogleCalendarMeetingsClick);
                            refetch();
                        }}
                        disabled={isFetching}
                        style={{ marginBottom: '8px', textDecoration: 'none' }}
                    />
                </BottomButtonContainer>
                <Row style={{ opacity: 1, transition: '500ms', marginTop: '10px', height: '42px' }}>
                    {isConfirmedToDelete ? (
                        <>
                            <PrimaryButton
                                title={'Yes, remove it'}
                                isLoading={isLoadingDisconnect}
                                color={lightTheme.status.negative}
                                style={{ opacity: 1, transition: '500ms' }}
                                onClick={async () => {
                                    setIsLoadingDisconnect(true);

                                    track(ClientEventType.ConfirmDisconnectGoogleCalendarIntegrationClick);

                                    const updatedUser = await deleteGoogleCalendarSettings();

                                    if (updatedUser) {
                                        setUser(updatedUser);
                                    }

                                    setIsLoadingDisconnect(false);
                                    setSubview(null);
                                }}
                            />

                            <span style={{ display: 'flex', width: '10px' }} />

                            <OutlinedButton
                                title={'Cancel'}
                                disabled={isLoadingDisconnect}
                                style={{ opacity: 1, transition: '500ms' }}
                                onClick={() => {
                                    track(ClientEventType.CancelDeleteGoogleCalendarIntegrationClick);
                                    setIsConfirmedToDelete(false);
                                }}
                            />
                        </>
                    ) : null}
                </Row>
            </ContentContainer>
        </Container>
    );
}

export function SelectCalendarEventsForSpinach({
    isOnboardingFlow,
    loadingMessage,
    hasError,
    onEventClick,
    data,
    onboardingEventsToAddScribeTo,
}: {
    isOnboardingFlow: boolean;
    loadingMessage: string | null;
    hasError: boolean;
    onEventClick: (event: CalendarEvent) => void;
    data: CalendarEvent[] | undefined;
    onboardingEventsToAddScribeTo: CalendarEvent[];
}): JSX.Element {
    const [user] = useGlobalAuthedUser();

    const relevantEvents = getUniqueRecurringEvents(data || []);

    return (
        <MeetingsScrollViewContainer>
            <MeetingsScrollView hasError={hasError}>
                <MeetingsContainer>
                    {relevantEvents?.map((event) => {
                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);
                        const isInteractive = isOnboardingFlow
                            ? // in onboarding you can add/remove as much as you want, bulk action is performed on continue click
                              !isScribeOn || onboardingEventsToAddScribeTo.includes(event)
                            : // organizer can always add/remove scribe
                              isOrganizer ||
                              // all users can add scribe to events
                              !isScribeOn;
                        return (
                            <MeetingContainer
                                isInteractive={isInteractive}
                                key={event.iCalUID}
                                onClick={() => {
                                    onEventClick(event);
                                }}
                            >
                                <MeetingItem
                                    event={event}
                                    isChecked={
                                        isScribeOn ||
                                        (isOnboardingFlow && onboardingEventsToAddScribeTo.includes(event))
                                    }
                                    isInteractive={isInteractive}
                                    showTooltip={isOrganizer}
                                />
                            </MeetingContainer>
                        );
                    })}
                </MeetingsContainer>
            </MeetingsScrollView>
            {loadingMessage || hasError ? (
                <ProgressOverlay>
                    {hasError ? (
                        <ErrorMessage>There was an error. Please reload the page.</ErrorMessage>
                    ) : loadingMessage ? (
                        <ProgressMessage>{loadingMessage}</ProgressMessage>
                    ) : (
                        <></>
                    )}
                </ProgressOverlay>
            ) : (
                <></>
            )}
        </MeetingsScrollViewContainer>
    );
}
