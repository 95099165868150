import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useEffect } from 'react';
import styled from 'styled-components';

import { MeetingSettingsSubview, SpinachIntegration, TicketIntegration } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import {
    useAISeriesAsanaIntegrationEnablement,
    useAISeriesJiraIntegrationEnablement,
    useClickUpEnablement,
    useGlobalAuthedUser,
    useLinearEnablement,
    useTrelloEnablement,
} from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../styles';
import { MeetingSettings, Spacer } from '../common';
import { AsanaIntegrationRow } from '../common/settings/AsanaIntegrationRow';
import { AsanaIntegrationSettings } from '../common/settings/AsanaIntegrationSettings';
import { ClickUpIntegrationRow } from '../common/settings/ClickUpIntegrationRow';
import { ClickUpIntegrationSettings } from '../common/settings/ClickUpIntegrationSettings';
import { JiraIntegrationRow } from '../common/settings/JiraIntegrationRow';
import { JiraIntegrationSettings } from '../common/settings/JiraIntegrationSettings';
import { LinearIntegrationRow } from '../common/settings/LinearIntegrationRow';
import { LinearIntegrationSettings } from '../common/settings/LinearIntegrationSettings';
import { SlackIntegrationRow } from '../common/settings/SlackIntegrationRow';
import { SlackIntegrationSettings } from '../common/settings/SlackIntegrationSettings';
import { TicketIntegrationSettings } from '../common/settings/TicketIntegrationSettings';
import { TrelloIntegrationRow } from '../common/settings/TrelloIntegrationRow';
import { useLoadSeriesOnMount } from './hooks/useLoadSeriesOnMount';
import { Container, ContentContainer } from './styles';

const ButtonContainer = styled.div`
    top: 30px;
    left: 45px;
    position: absolute;
`;

function Settings({ series }: { series: StoredSpinachSeries | undefined | null }): JSX.Element {
    const isJiraIntegrationEnabled = useAISeriesJiraIntegrationEnablement();
    const isAsanaAIIntegrationEnabled = useAISeriesAsanaIntegrationEnablement();
    const isLinearEnabled = useLinearEnablement();
    const isClickUpEnabled = useClickUpEnablement();
    const isTrelloEnabled = useTrelloEnablement();

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <MeetingSettings />
                </ButtonContainer>

                <HeaderThreeOnboard>Spinach.io Settings</HeaderThreeOnboard>
                <Spacer />
                <BodyBigOnboard style={{ textTransform: 'uppercase', letterSpacing: '4px' }}>
                    Summary Output
                </BodyBigOnboard>
                <Spacer />
                {!!series ? (
                    <>
                        <SlackIntegrationRow
                            connectedMessage="Summaries connected to"
                            notConnectedMessage={
                                <span>
                                    Summaries are <b>not connected</b> to a channel yet
                                </span>
                            }
                        />
                        {isJiraIntegrationEnabled ? (
                            <JiraIntegrationRow message={'Add Jira tickets to your summary'} isAi={true} />
                        ) : (
                            <></>
                        )}
                        {isAsanaAIIntegrationEnabled ? <AsanaIntegrationRow /> : null}
                        {isLinearEnabled ? <LinearIntegrationRow /> : null}
                        {isClickUpEnabled ? <ClickUpIntegrationRow /> : null}
                        {isTrelloEnabled ? <TrelloIntegrationRow /> : null}
                    </>
                ) : null}
            </ContentContainer>
        </Container>
    );
}

function SlackSettings() {
    const series = useLoadSeriesOnMount();
    const [user] = useGlobalAuthedUser();
    const { isAuthedForSlack } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForSlack) {
            setSubview(null);
        }
    }, [isAuthedForSlack]);
    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                {!!series ? <SlackIntegrationSettings showSlackRemindersSection={false} /> : <></>}
            </ContentContainer>
        </Container>
    );
}
export function AsanaSettings(): JSX.Element {
    const { setSubview } = useGlobalMeetingSettings();
    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <AsanaIntegrationSettings />
            </ContentContainer>
        </Container>
    );
}

function JiraSettings(): JSX.Element {
    const series = useLoadSeriesOnMount();
    const [user] = useGlobalAuthedUser();
    const { isAuthedForJira } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForJira || !series) {
            setSubview(null);
        }
    }, [isAuthedForJira]);
    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                {!!series ? <JiraIntegrationSettings /> : <></>}
            </ContentContainer>
        </Container>
    );
}

function LinearSettings(): JSX.Element {
    const series = useLoadSeriesOnMount();
    const [user] = useGlobalAuthedUser();
    const { isAuthedForLinear } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForLinear || !series) {
            setSubview(null);
        }
    }, [isAuthedForLinear]);

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                {!!series ? <LinearIntegrationSettings /> : <></>}
            </ContentContainer>
        </Container>
    );
}

function ClickUpSettings(): JSX.Element {
    const series = useLoadSeriesOnMount();
    const [user] = useGlobalAuthedUser();
    const { isAuthedForClickUp } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForClickUp || !series) {
            setSubview(null);
        }
    }, [isAuthedForClickUp]);

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                {!!series ? <ClickUpIntegrationSettings /> : <></>}
            </ContentContainer>
        </Container>
    );
}

export function TicketSettingsSubview({
    integration,
    integrationName,
    isAuthedForIntegration,
}: {
    integration: TicketIntegration;
    integrationName: string;
    isAuthedForIntegration: boolean;
}) {
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForIntegration) {
            setSubview(null);
        }
    }, [isAuthedForIntegration]);

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <TicketIntegrationSettings integrationName={integrationName} integration={integration} /> : <></>
            </ContentContainer>
        </Container>
    );
}

export function ScribeSeriesSettingsPage() {
    const series = useLoadSeriesOnMount();
    const [user] = useGlobalAuthedUser();
    const { subview } = useGlobalMeetingSettings();

    if (!subview) {
        return <Settings series={series} />;
    } else if (subview === MeetingSettingsSubview.SlackSettings) {
        return <SlackSettings />;
    } else if (subview === MeetingSettingsSubview.JiraSettings) {
        return <JiraSettings />;
    } else if (subview === MeetingSettingsSubview.AsanaSettings) {
        return <AsanaSettings />;
    } else if (subview === MeetingSettingsSubview.LinearSettings) {
        return <LinearSettings />;
    } else if (subview === MeetingSettingsSubview.ClickUpSettings) {
        return <ClickUpSettings />;
    } else if (subview === MeetingSettingsSubview.TrelloSettings) {
        return (
            <TicketSettingsSubview
                isAuthedForIntegration={user.isAuthedForTrello}
                integrationName={'Trello'}
                integration={SpinachIntegration.Trello}
            />
        );
    }
    return <></>;
}
