import { AIPaths } from '../spinach-ai/SpinachAIRoutes';

export enum CommonClientPath {
    Root = '/',
    Onboarding = '/welcome',
    Demo = '/demo',
    Meeting = '/meeting',
    SeriesIdParam = ':seriesId',
    MeetingPath = '/meeting/:seriesId',
    SchedulePath = '/meeting/:seriesId/schedule',
    SummariesPath = '/meeting/:seriesId/summaries',
    CreateSeriesFlow = '/create-meeting',
    Verify = '/verify',
    VerifyGoogleCode = '/verify-google-code',
    SignUp = '/signup',
    TrySpinach = '/try-spinach',
    Unknown = '*',
    Experiment = '/experiment',
}

export const ClientPath = { ...CommonClientPath, ...AIPaths };
export type ClientPath = typeof ClientPath;
