import { TypedUpdate } from '@spinach-shared/types';

export function getInFlightUpdates(
    incomingUpdates: { id: string; text: string }[],
    updatesInState: { id: string; text: string }[]
): { id: string; text: string }[] {
    const savedIds = incomingUpdates.map((u) => u.id);
    const inflights = updatesInState.filter((u) => !savedIds.includes(u.id));
    return inflights;
}
