import { Route } from 'react-router-dom';

import { ScribeSeriesSettingsPage } from './ScribeSeriesSettingsPage';
import { ScribeUserSettingsPage } from './ScribeUserSettingsPage';
import { OnboardingFlowContainer } from './onboarding/OnboardingFlow';

export enum AIPaths {
    AISeriesSettingsPath = '/ai/meeting/:seriesId/settings',
    AIUserSettingsPath = '/ai/settings',
    Onboarding = '/ai/welcome',
}

export const SpinachAIRoutes = [
    <Route path={AIPaths.AISeriesSettingsPath} element={<ScribeSeriesSettingsPage />} />,
    <Route path={AIPaths.AIUserSettingsPath} element={<ScribeUserSettingsPage />} />,
    <Route path={AIPaths.Onboarding} element={<OnboardingFlowContainer />} />,
];
