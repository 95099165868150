import { Check, Lens, VisibilityOff } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
    Goal,
    GoalStatus,
    OmniboxToggleTrigger,
    Sentiment,
    SentimentPickerLocation,
    SpinachUpdateType,
    TooltipFeatureDiscoveryId,
    TypedUpdate,
    TypedUpdateWrapper,
} from '@spinach-shared/types';

import { ReactComponent as DeleteButton } from '../../assets/omnibox/delete-button.svg';
import { ReactComponent as MinusButton } from '../../assets/omnibox/minus-button-hover.svg';
import { ReactComponent as PlusButton } from '../../assets/omnibox/plus-button-hover.svg';
import { ElementId } from '../../constants';
import {
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useReadOnlyPresentation,
    useRegularItemSentimentEnabled,
} from '../../hooks';
import { lightTheme } from '../../styles';
import { BulletMark, SixDot } from '../common';
import { IssueBasedAttachments } from './Attachments';
import { SentimentComponent } from './SentimentPicker';

export const WideBulletContainer = styled.span`
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    line-height: 0;
`;

const Stationary = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 3px;
    border: solid;
    border-width: thin;
    border-color: ${(props) => props.theme.neutrals.midnight};
    background-color: ${(props) => props.theme.neutrals.white};
    box-shadow: 0px 2px 8px rgba(0, 69, 62, 0.32);
    z-index: 1500;
`;

export type LiveItemBulletProps = {
    typedUpdateWrapper: TypedUpdateWrapper;
    toggleShowOmnibox: (show: boolean, trigger?: OmniboxToggleTrigger) => void;
    isOmniboxEnabled?: boolean;
    index?: number;
    isJiraMagicInputCalloutVisible?: boolean;
    isMouseIn?: boolean;
    dragRef: any;
    disabled?: boolean;
    onDelete?: () => void;
    isInNavDrawer?: boolean;
    shouldShowJiraMagic: boolean;
    showOmnibox: boolean;
    setTypedUpdate?: (tu: TypedUpdate) => void;
    saveFullTypedUpdate?: (tu: TypedUpdate) => void;
};

const CustomPlusButton = styled(PlusButton)`
    cursor: pointer;
    margin-left: 2px;
`;

const HoveredDeleteButton = styled(DeleteButton)`
    cursor: pointer;
    margin-left: 2px;
    &:hover {
        background-color: #f0f0f0;
        border-radius: 4px;
    }
`;

export function LiveItemBullet({
    typedUpdateWrapper,
    toggleShowOmnibox,
    isOmniboxEnabled,
    index,
    isMouseIn,
    dragRef,
    disabled,
    onDelete,
    shouldShowJiraMagic,
    isInNavDrawer,
    showOmnibox,
    isJiraMagicInputCalloutVisible,
    saveFullTypedUpdate,
    setTypedUpdate,
}: LiveItemBulletProps) {
    const isReadOnlyPresentationEnabled = useReadOnlyPresentation();
    const [liveSeries] = useGlobalLiveSeries();
    const { currentAgendaItem } = liveSeries;
    const [user] = useGlobalAuthedUser();

    const goal = typedUpdateWrapper.asGoal;
    const [isPlusButtonHovered, setIsPlusButtonHovered] = useState(false);
    const [isMinusButtonHovered, setIsMinusButtonHovered] = useState(false);
    const isRegularItemSentimentEnabled = useRegularItemSentimentEnabled();
    const updateHasText = !!typedUpdateWrapper.typedUpdate.text;
    const shouldShowOmniboxButtons =
        isInNavDrawer && (!typedUpdateWrapper.typedUpdate.text || isJiraMagicInputCalloutVisible);

    if (goal && isInNavDrawer && !!goal.text) {
        return (
            <IssueBasedCheckInBullet
                dragRef={dragRef}
                goal={goal}
                isInNavDrawer={isInNavDrawer}
                typedUpdateWrapper={typedUpdateWrapper}
                isMouseIn={isMouseIn}
                setTypedUpdate={setTypedUpdate}
                saveFullTypedUpdate={saveFullTypedUpdate}
            />
        );
    }

    if (goal && !isInNavDrawer) {
        if (goal.status === GoalStatus.Complete) {
            return <PresentingCheckBullet />;
        }
        if (typedUpdateWrapper.sentiment) {
            return <SentimentBullet sentiment={typedUpdateWrapper.sentiment} />;
        }
    }

    if (!isMouseIn && typedUpdateWrapper.sentiment && isRegularItemSentimentEnabled && !isInNavDrawer) {
        return <SentimentBullet sentiment={typedUpdateWrapper.sentiment} />;
    }

    if (isReadOnlyPresentationEnabled && !isInNavDrawer && !!currentAgendaItem?.isParticipantAgendaItem) {
        return <></>;
    }

    if (shouldShowJiraMagic && !!typedUpdateWrapper.ticket?.id) {
        // show delete button for a jira update
        if (isMouseIn || shouldShowOmniboxButtons) {
            return (
                <WideBulletContainer>
                    {dragRef ? (
                        <div ref={dragRef}>
                            <SixDot />
                        </div>
                    ) : null}
                    <HoveredDeleteButton onClick={onDelete} style={{ height: '16px', width: '16px' }} />
                </WideBulletContainer>
            );
        }
    } else if (isOmniboxEnabled && shouldShowJiraMagic && (isMouseIn || shouldShowOmniboxButtons) && !dragRef) {
        // omnibox is enalbed + mouse is in and item not being dragged
        if (showOmnibox) {
            // omnibox is showing, show minus button to close it
            return (
                <WideBulletContainer>
                    <MinusButton
                        onMouseEnter={() => setIsMinusButtonHovered(true)}
                        onMouseLeave={() => setIsMinusButtonHovered(false)}
                        fill={isMinusButtonHovered ? '#F0F0F0' : 'none'}
                        style={{
                            cursor: 'pointer',
                            height: '16px',
                            width: '16px',
                            marginTop: '-1px',
                        }}
                        onClick={() => {
                            toggleShowOmnibox(false, OmniboxToggleTrigger.Button);
                        }}
                    />
                </WideBulletContainer>
            );
        } else {
            // omnibox is not showing, show plus button to open it
            return (
                <WideBulletContainer
                    id={
                        typedUpdateWrapper.typedUpdate.updateType === SpinachUpdateType.Yesterday && index === 0
                            ? TooltipFeatureDiscoveryId.JiraMagicInputId
                            : ElementId.CustomPlusButton
                    }
                    style={{ height: '19px' }}
                >
                    <CustomPlusButton
                        onMouseEnter={() => setIsPlusButtonHovered(true)}
                        onMouseLeave={() => setIsPlusButtonHovered(false)}
                        fill={isPlusButtonHovered ? '#F0F0F0' : 'none'}
                        style={{ width: '16px', height: '16px' }}
                        onClick={() => {
                            toggleShowOmnibox(true, OmniboxToggleTrigger.Button);
                        }}
                    />
                </WideBulletContainer>
            );
        }
    } else if ((isMouseIn || shouldShowOmniboxButtons) && dragRef && !disabled) {
        if (isOmniboxEnabled && shouldShowJiraMagic) {
            if (showOmnibox) {
                return (
                    <WideBulletContainer>
                        <MinusButton
                            onMouseEnter={() => setIsMinusButtonHovered(true)}
                            onMouseLeave={() => setIsMinusButtonHovered(false)}
                            fill={isMinusButtonHovered ? '#F0F0F0' : 'none'}
                            style={{ cursor: 'pointer', height: '16px', width: '16px' }}
                            onClick={() => {
                                toggleShowOmnibox(false, OmniboxToggleTrigger.Button);
                            }}
                        />
                    </WideBulletContainer>
                );
            } else {
                return (
                    <WideBulletContainer
                        id={
                            typedUpdateWrapper.typedUpdate.updateType === SpinachUpdateType.Yesterday && index === 0
                                ? TooltipFeatureDiscoveryId.JiraMagicInputId
                                : ElementId.CustomPlusButton
                        }
                        style={{ height: '19px' }}
                    >
                        <div ref={dragRef}>
                            <SixDot />
                        </div>
                        <CustomPlusButton
                            onMouseEnter={() => setIsPlusButtonHovered(true)}
                            onMouseLeave={() => setIsPlusButtonHovered(false)}
                            fill={isPlusButtonHovered ? '#F0F0F0' : 'none'}
                            style={{ width: '16px', height: '16px' }}
                            onClick={() => {
                                toggleShowOmnibox(true, OmniboxToggleTrigger.Button);
                            }}
                        />
                    </WideBulletContainer>
                );
            }
        } else {
            return (
                <BulletMark style={{ height: '19px' }} ref={dragRef}>
                    <SixDot />
                </BulletMark>
            );
        }
    } else {
        return <></>;
    }

    if (isOmniboxEnabled && !updateHasText) {
        return (
            <WideBulletContainer
                id={
                    typedUpdateWrapper.typedUpdate.updateType === SpinachUpdateType.Yesterday && index === 0
                        ? TooltipFeatureDiscoveryId.JiraMagicInputId
                        : ElementId.CustomPlusButton
                }
                style={{ height: '19px' }}
            >
                <CustomPlusButton
                    onMouseEnter={() => setIsPlusButtonHovered(true)}
                    onMouseLeave={() => setIsPlusButtonHovered(false)}
                    fill={isPlusButtonHovered ? '#F0F0F0' : 'none'}
                    style={{ width: '16px', height: '16px' }}
                    onClick={() => {
                        toggleShowOmnibox(true, OmniboxToggleTrigger.Button);
                    }}
                />
            </WideBulletContainer>
        );
    }

    return <></>;
}

const goalBulletStyles = {
    height: '12px',
    width: '12px',
    marginTop: '1px',
};

const goalStatusIconComponentMap: Record<
    Extract<GoalStatus, GoalStatus.Complete | GoalStatus.InProgress | GoalStatus.Unset>,
    JSX.Element
> = {
    [GoalStatus.Complete]: <Check style={{ ...goalBulletStyles }} htmlColor={lightTheme.secondary.green} />,
    [GoalStatus.InProgress]: <Lens style={{ ...goalBulletStyles }} htmlColor={lightTheme.status.info} />,
    [GoalStatus.Unset]: <VisibilityOff style={{ ...goalBulletStyles }} htmlColor={lightTheme.neutrals.grayLight} />,
};

function IssueBasedCheckInBullet({
    goal,
    typedUpdateWrapper,
    isMouseIn,
    isInNavDrawer,
    saveFullTypedUpdate,
    setTypedUpdate,
    dragRef,
}: Pick<
    LiveItemBulletProps,
    'isMouseIn' | 'typedUpdateWrapper' | 'saveFullTypedUpdate' | 'dragRef' | 'isInNavDrawer' | 'setTypedUpdate'
> & {
    goal: Goal;
}): JSX.Element {
    /**
     * @NOTE isHovered needs to be a ref due to the fact that the component gets removed from
     * the DOM and _then_ re-rendered. A stateful value would be reset back to false
     */
    const isHovered = useRef(false);
    /** @NOTE this needs to be stateful so that the component is re-rendered */
    const [issueBasedTimeout, setIssueBasedTimeout] = useState<NodeJS.Timeout | null>(null);

    const disposeTimeout = () => {
        if (issueBasedTimeout) {
            clearTimeout(issueBasedTimeout);
            setIssueBasedTimeout(null);
        }
    };

    useEffect(() => {
        return () => {
            isHovered.current = false;
        };
    }, []);

    const icon =
        goal.text && Object.keys(goalStatusIconComponentMap).includes(goal.status) ? (
            <>
                {isHovered.current ? (
                    <Stationary>
                        <IssueBasedAttachments
                            isInNavDrawer={isInNavDrawer}
                            typedUpdate={typedUpdateWrapper.typedUpdate}
                            setTypedUpdate={setTypedUpdate}
                            saveFullTypedUpdate={saveFullTypedUpdate}
                        />
                    </Stationary>
                ) : (
                    <></>
                )}
                {
                    goalStatusIconComponentMap[
                        goal.status as Extract<
                            GoalStatus,
                            GoalStatus.Complete | GoalStatus.InProgress | GoalStatus.Unset
                        >
                    ]
                }
            </>
        ) : (
            <></>
        );

    return (
        <WideBulletContainer
            onMouseEnter={() => {
                disposeTimeout();

                const timeout = setTimeout(() => {
                    isHovered.current = true;
                    setIssueBasedTimeout(null);
                }, 250);
                setIssueBasedTimeout(timeout);
            }}
            onMouseLeave={() => {
                disposeTimeout();
                isHovered.current = false;
            }}
            style={{ marginLeft: dragRef && false ? '-30px' : '-23px' }}
        >
            {/* TODO: enable when ready for reorder goals */}
            {dragRef && false ? (
                <div ref={dragRef} style={{ marginRight: '5px', marginLeft: '-5px' }}>
                    {isMouseIn ? <SixDot /> : <div style={{ width: '10px' }}></div>}
                </div>
            ) : null}
            {icon}
        </WideBulletContainer>
    );
}

export function SentimentBullet({ sentiment }: { sentiment: Sentiment }): JSX.Element {
    return (
        //unfortunately the margin top is needed because the SentimentComponent has some padding
        <WideBulletContainer style={{ marginTop: '-2px' }}>
            <SentimentComponent
                location={SentimentPickerLocation.UpdateFooter}
                sentiment={sentiment}
                selectedSentiment={sentiment}
            />
        </WideBulletContainer>
    );
}

function PresentingCheckBullet(): JSX.Element {
    return (
        <WideBulletContainer style={{ marginLeft: '-23px', marginTop: 'unset', marginRight: 'unset', width: '23px' }}>
            <Check
                style={{ height: '14px', width: '14px', marginRight: '3px' }}
                htmlColor={lightTheme.secondary.green}
            />
        </WideBulletContainer>
    );
}
