import {
    CalendarEvent,
    ClientUserSlackSettings,
    DismissableHints,
    ExperimentKey,
    FeatureFlagValue,
    FeatureToggle,
    IClientUser,
    ISOString,
    IntegrationCode,
    IntegrationLabelMap,
    UUID,
    UserIdentity,
    UserIdentityPayload,
    UserIntegrationSettings,
    UserMetadata,
    UserSeriesMetadata,
} from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

export class ClientUser {
    spinachUserId: UUID;
    email: string;
    zoomUserId?: string;
    googleId?: string;
    metadata: UserMetadata;
    seriesMetadataList: UserSeriesMetadata[];
    integrationSettings?: UserIntegrationSettings;
    private _featureToggles?: Record<FeatureToggle, FeatureFlagValue>;

    constructor(props: IClientUser) {
        this.spinachUserId = props._id;
        this.email = props.email;
        this.seriesMetadataList = props.seriesMetadataList ?? [];
        this.integrationSettings = props.integrationSettings;
        this.zoomUserId = props.zoomUserId;
        this.googleId = props.googleId;
        this._featureToggles = props.featureToggles;

        this.metadata = { ...props.metadata } ?? {};
        this.metadata.preferredName = props.metadata?.preferredName ?? props.preferredName;
        this.metadata.companyName = props.metadata?.companyName;
        this.metadata.lastLoggedOn = props.metadata?.lastLoggedOn ?? props.lastLoggedOn;
        this.metadata.lastEditedOn = props.metadata?.lastEditedOn ?? props.lastEditedOn;
        this.metadata.intercomHash = props.metadata?.intercomHash ?? props.intercomHash;
        this.metadata.createdOn = props.metadata?.createdOn ?? props.createdOn;
    }

    get preferredName(): string {
        return this.metadata.firstName?.trim() && this.metadata.lastName?.trim()
            ? `${this.metadata.firstName.trim()} ${this.metadata.lastName.trim()}`
            : this.metadata.preferredName ?? '';
    }

    get firstName(): string {
        return this.metadata.firstName?.trim() ?? this.metadata.preferredName?.split(' ')[0]?.trim() ?? '';
    }

    get lastName(): string {
        return this.metadata.lastName?.trim() ?? this.metadata.preferredName?.split(' ')[1]?.trim() ?? '';
    }

    get initials(): string {
        return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
    }

    get companyName(): string {
        return this.metadata.companyName ?? '';
    }

    get howDidYouHear(): string {
        return this.metadata.howDidYouHear ?? '';
    }

    get howDidYouHearOther(): string {
        return this.metadata.howDidYouHearOther ?? '';
    }

    get integrationsVideo(): string {
        return this.metadata.integrationsVideo ?? '';
    }

    get videoTool(): string {
        if (this.metadata.integrationsVideo && this.metadata.integrationsVideo !== IntegrationCode.Other) {
            return IntegrationLabelMap[this.metadata.integrationsVideo];
        }

        return 'your video tool';
    }

    get dismissedHints(): DismissableHints[] {
        return this.metadata.dismissedHints ?? [];
    }

    get featureToggles(): Record<FeatureToggle, FeatureFlagValue> {
        return (this._featureToggles || {}) as Record<FeatureToggle, FeatureFlagValue>;
    }

    get selectedSlackAsMessagingIntegraton(): boolean {
        return this.integrationsMessaging === IntegrationCode.Slack;
    }

    get integrationsMessaging(): string {
        return this.metadata.integrationsMessaging ?? '';
    }

    get integrationsProjectMgmt(): string[] {
        return this.metadata.integrationsProjectMgmt ?? [];
    }

    get integrationsVideoOther(): string {
        return this.metadata.integrationsVideoOther ?? '';
    }

    get integrationsMessagingOther(): string {
        return this.metadata.integrationsMessagingOther ?? '';
    }

    get integrationsProjectMgmtOther(): string {
        return this.metadata.integrationsProjectMgmtOther ?? '';
    }

    get createdOn(): ISOString {
        return this.metadata.createdOn!;
    }

    get realSeries(): UserSeriesMetadata[] {
        return this.seriesMetadataList.filter((series) => !isDemoSeries(series.id));
    }

    get isOnFirstPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 0;
    }

    get isOnSecondPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 1;
    }

    get isAnonymous(): boolean {
        return !!this.metadata.isAnonymousUser;
    }

    get isAuthedForJira(): boolean {
        return !!this.integrationSettings?.jiraSettings?.isAuthed;
    }

    /** @note We use the bot token for Slack integration, so there is no `isAuthed` value here */
    get isAuthedForSlack(): boolean {
        return !!this.integrationSettings?.slackSettings;
    }

    get isAuthedForGoogleCalendar(): boolean {
        return (
            !!this.integrationSettings?.googleSettings?.isAuthed &&
            !!this.integrationSettings?.googleSettings?.hasCalendarScopes
        );
    }

    get isAuthedForTrello(): boolean {
        return !!this.integrationSettings?.trelloSettings?.isAuthed;
    }

    get isAuthedForAsana(): boolean {
        return !!this.integrationSettings?.asanaSettings?.isAuthed;
    }

    get isAuthedForLinear(): boolean {
        return !!this.integrationSettings?.linearSettings?.isAuthed;
    }

    get isAuthedForClickUp(): boolean {
        return !!this.integrationSettings?.clickUpSettings?.isAuthed;
    }

    get slackSettings(): ClientUserSlackSettings | undefined {
        return this.integrationSettings?.slackSettings;
    }

    get shouldAuthBeforeDemo(): boolean {
        return this.metadata.signupBeforeDemo === true;
    }

    get isDemoing(): boolean {
        return (
            this.metadata.isAnonymousUser === true ||
            (this.shouldAuthBeforeDemo && !this.metadata.isOnboarded && this.metadata.isAnonymousUser === false)
        );
    }

    get isScribeUser(): boolean {
        return !!this.metadata.experimentCodes?.includes(ExperimentKey.StandupScribe);
    }

    areIntegrationsUpdated(latestIntegrations: UserIntegrationSettings | undefined) {
        const serializedLatest = JSON.stringify(latestIntegrations);
        const serializedPrevious = JSON.stringify(this.integrationSettings);
        return serializedLatest !== serializedPrevious;
    }

    getSeriesById(seriesId: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.id === seriesId);
    }

    getSeriesByName(seriesName: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.name === seriesName);
    }

    getSeriesIdOfOnlySeries(): string | undefined {
        if (this.realSeries.length === 1) {
            return this.realSeries[0].id;
        } else {
            return undefined;
        }
    }

    isUserTheOrganizer(event: CalendarEvent): boolean {
        return event.organizer?.email?.toLocaleLowerCase() === this.email.toLocaleLowerCase();
    }

    // Should we really be passing all this information back and forth?
    toUserIdentityPayload(): UserIdentityPayload {
        return {
            Email: this.email,
            UserId: this.spinachUserId,
            FirstName: this.firstName,
            LastName: this.lastName,
            Company: this.companyName,
            HowDidYouHearSource: this.howDidYouHear,
            UserName: this.preferredName,
            UserMetadata: this.metadata,
        };
    }

    toUserIdentity(): UserIdentity {
        return {
            userId: this.spinachUserId,
            firstName: this.firstName,
            lastName: this.lastName,
            company: this.companyName,
            howDidYouHearSource: this.howDidYouHear,
            userName: this.preferredName,
            userEmail: this.email,
            seriesMetadataList: this.seriesMetadataList,
            metadata: this.metadata,
        };
    }

    toIClientUser(): IClientUser {
        return {
            _id: this.spinachUserId,
            email: this.email,
            metadata: this.metadata,
            seriesMetadataList: this.seriesMetadataList,
            preferredName: this.preferredName,
            createdOn: this.metadata.createdOn!,
            zoomUserId: this.zoomUserId,
            lastEditedOn: this.metadata.lastEditedOn,
            lastLoggedOn: this.metadata.lastLoggedOn,
            integrationSettings: this.integrationSettings,
            intercomHash: this.metadata.intercomHash,
        };
    }
}
