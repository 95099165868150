import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
    ChangelogRequestPayload,
    ClientEventType,
    JiraIssueChangeLogField,
    JiraProject,
    JiraUser,
    JiraUserListResponse,
    SpinachAPIPath,
} from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { getSpinachAPI, postSpinachAPI } from '../../../../apis';
import { ReactComponent as SLogo } from '../../../../assets/s-logo.svg';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { BodyBigger, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { URLUtil } from '../../../../utils';
import { ErrorBodySubtitle } from '../../../series/common';
import { PrimaryButton } from '../../../stand-up';
import { Column, Spacing } from '../../framing';
import { SummaryBySection, SummaryBySectionState } from './GenerateWeeklySummary';

const now = moment();
const DEFAULT_TO_DATE = now.format('YYYY-MM-DD');
const DEFAULT_FROM_DATE = now.subtract(7, 'days').format('YYYY-MM-DD');

export function SummarySidebar({
    setIsLoadingSummary,
    isLoadingSummary,
    setSummary,
    selectedProject,
    setSelectedProject,
    selectedUsers,
    setSelectedUsers,
    setGenerationCount,
    generationCount,
}: {
    setIsLoadingSummary: SetValue<boolean>;
    isLoadingSummary: boolean;
    setSummary: SetValue<SummaryBySectionState>;
    selectedProject: JiraProject | null;
    setSelectedProject: SetValue<JiraProject | null>;
    selectedUsers: JiraUser[];
    setSelectedUsers: SetValue<JiraUser[]>;
    setGenerationCount: SetValue<number>;
    generationCount: number;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [isLoadingProjects, setIsLoadingProjects] = useState(false);
    const [projectData, setProjectData] = useState<JiraProject[]>([]);

    const [isLoadingProjectUsers, setIsLoadingProjectUsers] = useState(false);
    const [userData, setUserData] = useState<JiraUser[]>([]);

    const [toDate, setToDate] = useState(DEFAULT_TO_DATE);
    const [fromDate, setFromDate] = useState(DEFAULT_FROM_DATE);

    useEffect(() => {
        async function fetchProjects() {
            setIsLoadingProjects(true);

            const response = await getSpinachAPI<{ projects: JiraProject[] | null }>(`${SpinachAPIPath.JiraProjects}`);

            setProjectData(response?.projects || []);
            setIsLoadingProjects(false);
        }

        fetchProjects();
    }, []);

    const isLoadingSomething = isLoadingProjects || isLoadingProjectUsers || isLoadingSummary;
    const isDateRangeInvalid = moment(toDate).diff(fromDate) <= 0;
    const dateError = isDateRangeInvalid ? 'Please select a proper activity window' : ' ';

    const isGenerationDisabled = isLoadingSomething || !selectedProject || !selectedUsers.length || isDateRangeInvalid;

    return (
        <Column style={{ height: '100%' }}>
            <SLogo
                style={{ width: '50px', height: '47px', marginLeft: '-12px', marginBottom: '22px', cursor: 'pointer' }}
                onClick={() => {
                    track(ClientEventType.SpinachMarketingSiteClick, { Location: 'generate-summary-logo' });
                    URLUtil.openURL('https://spinach.io');
                }}
            />

            <BodyBigger style={{ fontWeight: 'bold' }}>Select your project</BodyBigger>
            <Spacing factor={1 / 3} />
            <Autocomplete
                disabled={isLoadingSomething}
                id="project-id-selection"
                options={projectData}
                getOptionLabel={(option: JiraProject) => option.name}
                value={selectedProject}
                size="small"
                style={{ width: '100%', marginBottom: '10px' }}
                loading={isLoadingProjects}
                loadingText="Loading..."
                autoComplete
                onChange={async (event, project) => {
                    setSelectedProject(project);
                    setSelectedUsers([]);

                    if (!project) {
                        track(ClientEventType.UserSelectedProjectForGeneration, { Action: 'removing ' });
                        return;
                    }

                    track(ClientEventType.UserSelectedProjectForGeneration, { Action: 'adding' });

                    setIsLoadingProjectUsers(true);
                    // trigger fetch of user ids
                    const userListResponse = await getSpinachAPI<JiraUserListResponse>(
                        `${SpinachAPIPath.JiraUsersForProject.replace(':projectId', project.id)}`
                    );

                    if (userListResponse) {
                        setUserData(userListResponse.users || []);
                        track(ClientEventType.UserSelectedProjectForGeneration);
                        const thisUserFromList = userListResponse.users?.find(
                            (user) => user.accountId === userListResponse.jiraUserId
                        );
                        if (thisUserFromList) {
                            setSelectedUsers([thisUserFromList]);
                        }
                    }
                    setIsLoadingProjectUsers(false);
                }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Jira Project" placeholder="Jira Project" />
                )}
            />

            <Spacing />

            <BodyBigger
                style={{ fontWeight: 'bold', color: selectedProject ? 'initial' : lightTheme.tertiary.midnight }}
            >
                Add your teammates
            </BodyBigger>
            <Spacing factor={1 / 3} />
            <Autocomplete
                disabled={isLoadingSomething || !selectedProject}
                multiple
                size="small"
                limitTags={1}
                loading={isLoadingProjectUsers}
                loadingText="Loading..."
                value={selectedUsers}
                id="jira-user-selection"
                options={userData}
                getOptionLabel={(option) => option.displayName}
                onChange={(event, actors) => {
                    setSelectedUsers(actors);
                    track(ClientEventType.UserSelectedJiraUserForGeneration);
                }}
                style={{ width: '100%', marginRight: '10px' }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Teammate" />}
            />

            <Spacing />
            <BodyBigger style={{ fontWeight: 'bold' }}>Activity window</BodyBigger>
            <Spacing factor={1 / 3} />
            <TextField
                id="date"
                value={fromDate}
                label="From"
                type="date"
                fullWidth={false}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    style: {
                        width: '130px',
                    },
                }}
                onKeyPress={(e) => {
                    // This prevents users from typing silly numbers into date fields
                    e.preventDefault();
                }}
                onChange={(event) => {
                    track(ClientEventType.ChangedActivityWindowDate, { DateEnd: 'From', SelectedDate: fromDate });
                    setFromDate(event.target.value);
                }}
            />
            <Spacing factor={1 / 3} />

            <TextField
                id="date"
                value={toDate}
                label="To"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    style: {
                        width: '130px',
                    },
                }}
                onKeyPress={(e) => {
                    // This prevents users from typing silly numbers into date fields
                    e.preventDefault();
                }}
                onChange={(event) => {
                    track(ClientEventType.ChangedActivityWindowDate, { DateEnd: 'To', SelectedDate: toDate });
                    setToDate(event.target.value);
                }}
            />
            <ErrorBodySubtitle>{dateError}</ErrorBodySubtitle>
            <div style={{ flexGrow: 1, display: 'flex' }} />
            <PrimaryButton
                disabled={isGenerationDisabled}
                isLoading={isLoadingSummary}
                containerStyles={{
                    height: '40px',
                }}
                title="Generate"
                onClick={async () => {
                    if (isGenerationDisabled) {
                        return;
                    }

                    track(ClientEventType.GenerateSummaryClick);

                    const now = moment();
                    const fromMoment = moment(fromDate).hours(now.get('hours'));
                    const toMoment = moment(toDate).hours(now.get('hours'));

                    setIsLoadingSummary(true);
                    setGenerationCount(generationCount + 1);

                    const before = new Date().getTime();
                    const request: ChangelogRequestPayload = {
                        email: user.email,
                        emails: selectedUsers.map((u) => u.displayName),
                        fieldFilters: [JiraIssueChangeLogField.Status, JiraIssueChangeLogField.IssueType],
                        runOpenAi: true,
                        projectId: selectedProject.id,
                        fromDate: fromMoment.toISOString(),
                        toDate: toMoment.toISOString(),
                        timezone: TimeUtils.getTimezoneRegion(),
                        withComments: false,
                        withTeam: false,
                    };
                    const response = await postSpinachAPI<{ chatGPT3Response: { text: string }[] }>(
                        '/changelog',
                        request
                    );

                    let summary = null;

                    if (response?.chatGPT3Response?.[0]?.text) {
                        summary = mapGeneratedSummaryResponse(response.chatGPT3Response[0].text);
                    }

                    setSummary(summary);

                    const after = new Date().getTime();
                    const duration = (after - before) / 1000;

                    track(ClientEventType.ReceivedGeneratedSummaryResults, {
                        Duration: duration,
                    });

                    setIsLoadingSummary(false);
                }}
            />
        </Column>
    );
}

export function mapGeneratedSummaryResponse(input: string): SummaryBySection {
    const splitSections = input.split('\n\n').filter((section) => !!section);

    const mappedLinesBySection = splitSections.reduce<SummaryBySection>((sectionDetails, currentSection) => {
        const splitLinesOfSection = currentSection.split('\n');

        if (splitLinesOfSection.length > 1) {
            const [sectionTitle, ...lineItems] = splitLinesOfSection;
            sectionDetails[sectionTitle.replace(':', '')] = lineItems;
        }

        return sectionDetails;
    }, {});

    return mappedLinesBySection;
}
