import { CallMade, CheckCircle, ChevronRight } from '@material-ui/icons';
import { ReactNode } from 'react';

import { ElementId } from '../../../constants';
import { BodyRegular, ButtonSize, lightTheme } from '../../../styles';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { Column, Row } from '../framing';

export type AppIntegrationRowProps = TooltipIconProps & {
    onCTAClick: () => Promise<void>;
    subtext: NonNullable<ReactNode>;
    label: NonNullable<ReactNode>;
    icon: NonNullable<ReactNode>;
    buttonText: string;
    id?: ElementId;
    isButtonDisabled?: boolean;
    isConnectViewOnly?: boolean;
};

type TooltipIconProps = {
    isConnected?: boolean;
};

function AppIntegrationConnection({ isConnected }: TooltipIconProps): JSX.Element {
    if (isConnected) {
        return <CheckCircle fontSize="small" style={{ marginLeft: '5px' }} htmlColor={lightTheme.primary.greenLight} />;
    } else {
        return <></>;
    }
}

export enum IntegrationButtonText {
    Configure = 'Configure',
    Connect = 'Connect',
    Add = 'Add',
}

export function AppIntegrationRow({
    subtext,
    label,
    onCTAClick,
    icon,
    isConnected,
    buttonText,
    id,
    isButtonDisabled,
    isConnectViewOnly,
}: AppIntegrationRowProps): JSX.Element {
    const ctaIcon =
        buttonText === IntegrationButtonText.Configure ? (
            <ChevronRight style={{ fontSize: '14px', marginLeft: '5px' }} />
        ) : (
            <CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />
        );

    return (
        <Row style={{ marginBottom: '5px' }}>
            <Column style={{ justifyContent: 'flex-start', width: '40px', paddingBottom: '5px', paddingTop: '10px' }}>
                {icon}
            </Column>
            <Column style={{ borderBottom: `1px solid ${lightTheme.neutrals.grayDark}` }}>
                <Row style={{ alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
                    <BodyRegular style={{ fontWeight: 700, fontSize: '18px' }}>{label}</BodyRegular>
                    <AppIntegrationConnection isConnected={isConnected} />
                    <span style={{ flexGrow: 1 }} />
                    {isConnectViewOnly && buttonText === IntegrationButtonText.Configure ? null : (
                        <OutlinedButton
                            disabled={isButtonDisabled}
                            id={id}
                            title={buttonText}
                            onClick={onCTAClick}
                            size={ButtonSize.Mini}
                            endIcon={ctaIcon}
                            labelStyles={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        />
                    )}
                </Row>
                <Row style={{ paddingBottom: '10px' }}>{subtext}</Row>
            </Column>
        </Row>
    );
}
