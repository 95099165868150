import { Box } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AuthoredUpdate, ClientEventType, SpinachUpdateTypeYTB, TypedUpdate } from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import {
    useExperienceTracking,
    useGlobalLiveSeries,
    useReadOnlyPresentation,
    useTypedUpdateWrapper,
} from '../../hooks';
import { Bullet, BulletMark, Column, Row, SixDot } from '../common';
import { BaseInput } from './BaseInput';

export type LiveSubItemProps = {
    placeholder: string;
    typedUpdate: TypedUpdate;
    isFocused: boolean;
    isInNavDrawer?: boolean;
    index?: number;
    autoFocusOnMountAfter?: number;
    isMouseIn?: boolean;
    subItemEmptyInputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    emptyInputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    setTypedUpdate: (typedUpdate: TypedUpdate) => void;
    setFocusedInput: (nullable?: null) => void;
    saveTypedUpdate: (text: string) => void;
    saveFullTypedUpdate: (update: TypedUpdate, updates?: TypedUpdate[]) => void;
    createTabbedItem?: () => void;
    removeTabbedItem?: () => void;
    dragRef?: any;
};

export type LiveSubItemPresentationProps = {
    onBlur: () => void;
    onFocus: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
    onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
    placeholder: string;
    isTabbedLine?: boolean;
    typedUpdate: TypedUpdate | AuthoredUpdate;
    emptyInputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    autoFocus?: boolean;
    isAttachable?: boolean;
    isFocused: boolean | null;
    disabled?: boolean;
    isMouseIn?: boolean;
    index?: number;
    dragRef?: any;
    updateOwnerId?: string;
    isCurrentAgendaItem?: boolean;
    topicTitleInput?: boolean;
    hideBullet?: boolean;
    isInNavDrawer?: boolean;
};

export const UpdateRow = styled(Row)<{
    isMouseIn?: boolean;
    updateType?: SpinachUpdateTypeYTB;
}>`
    width: 'calc(100% - 24px)';
    background-color: 'none';
`;

function useDelayedAutoFocus(autoFocusOnMountAfter: number | undefined, typedUpdate: TypedUpdate) {
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (autoFocusOnMountAfter !== undefined) {
            timeout = setTimeout(() => {
                const element = document.getElementById(`TU-SI-${typedUpdate.id}`);
                if (element) {
                    element.focus();
                }
            }, autoFocusOnMountAfter);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);
}

export function SubItemBullet({ isMouseIn, dragRef }: { isMouseIn?: boolean; dragRef: any }): JSX.Element {
    if (isMouseIn && dragRef) {
        return (
            <BulletMark style={{ marginTop: 'unset', marginLeft: '-3px', height: '19px' }} ref={dragRef}>
                <SixDot />
            </BulletMark>
        );
    } else {
        return (
            <div
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    marginTop: '9px',
                    paddingRight: '5px',
                }}
            >
                <Bullet />
            </div>
        );
    }
}

export function LiveSubItem({
    typedUpdate,
    setTypedUpdate,
    isFocused,
    emptyInputRef,
    setFocusedInput,
    subItemEmptyInputRef,
    saveTypedUpdate,
    saveFullTypedUpdate,
    createTabbedItem,
    removeTabbedItem,
    placeholder,
    index,
    isInNavDrawer,
    onBlur,
    autoFocusOnMountAfter,
    isMouseIn,
    dragRef,
}: LiveSubItemProps & { onBlur: () => void }): JSX.Element {
    useDelayedAutoFocus(autoFocusOnMountAfter, typedUpdate);
    const track = useExperienceTracking();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    function disposeTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }

    useEffect(() => {
        return () => {
            disposeTimeout();
        };
    }, []);

    function onPaste(e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) {
        const lineStartMatch = /^[\*\-\s]+/gi;
        if (!e.clipboardData.types.includes('text/plain')) {
            return;
        }

        const data = e.clipboardData.getData('text/plain');

        const lines = data
            .split('\n')
            .filter((line) => !!line.trim().length)
            .map((line) => getNewTypedUpdate({ ...typedUpdate, text: line.replace(lineStartMatch, '').trim() }));

        if (lines.length <= 1) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        saveFullTypedUpdate(lines[0], lines);
        track(ClientEventType.UserPastedMultilineUpdate, {
            NumberOfLines: lines.length,
        });
    }

    return (
        <LiveSubItemPresentation
            onPaste={onPaste}
            placeholder={placeholder}
            typedUpdate={typedUpdate}
            emptyInputRef={typedUpdate.text === '' ? subItemEmptyInputRef : undefined}
            isFocused={isFocused}
            onFocus={() => {
                setFocusedInput();
            }}
            isInNavDrawer={isInNavDrawer}
            onChange={async (e) => {
                disposeTimeout();
                setTypedUpdate({
                    ...typedUpdate,
                    text: e.target.value,
                });
                timeoutRef.current = setTimeout(() => {
                    if (isFocused) {
                        saveTypedUpdate(e.target.value);
                    }
                    timeoutRef.current = null;
                }, 3000);
            }}
            onBlur={() => {
                disposeTimeout();
                onBlur();
            }}
            onKeyDown={(e) => {
                if (e.key === 'Backspace' && !typedUpdate.text && index !== undefined) {
                    e.preventDefault();
                    disposeTimeout();
                    removeTabbedItem?.();

                    setTimeout(() => {
                        emptyInputRef?.current?.focus();
                    }, 10);
                }

                if (e.key === 'Enter' && e.shiftKey && typedUpdate.text && index !== undefined) {
                    e.preventDefault();
                    disposeTimeout();
                    createTabbedItem?.();

                    setTimeout(() => {
                        subItemEmptyInputRef?.current?.focus();
                    }, 10);
                } else if (e.key === 'Enter' || e.key === 'Tab') {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }

                    disposeTimeout();

                    if (e.key === 'Enter') {
                        setTimeout(() => {
                            if (!subItemEmptyInputRef?.current || typedUpdate.text === '') {
                                emptyInputRef?.current?.focus();
                            } else {
                                subItemEmptyInputRef?.current?.focus();
                            }
                        }, 10);
                    }
                }
            }}
            index={index}
            isMouseIn={isMouseIn}
            dragRef={dragRef}
        />
    );
}

function LiveSubItemPresentation(props: LiveSubItemPresentationProps): JSX.Element {
    const {
        emptyInputRef,
        typedUpdate,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        placeholder,
        autoFocus,
        isMouseIn,
        dragRef,
        onPaste,
        isInNavDrawer,
    } = props;
    const typedUpdateWrapper = useTypedUpdateWrapper(typedUpdate);
    const [liveSeries] = useGlobalLiveSeries();
    const isReadonlyPresentationEnabled = useReadOnlyPresentation();

    const currentAgendaItem = liveSeries.currentAgendaItem;

    const isDisabledWhilePresenting =
        isReadonlyPresentationEnabled && !isInNavDrawer && currentAgendaItem?.isParticipantAgendaItem;

    return (
        <>
            <Box
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'end',
                    width: '100%',
                    border: 'unset',
                }}
            >
                <UpdateRow
                    updateType={typedUpdateWrapper.typedUpdate.updateType as SpinachUpdateTypeYTB}
                    isMouseIn={isMouseIn}
                >
                    <SubItemBullet isMouseIn={isMouseIn} dragRef={dragRef} />

                    <Column style={{ width: '100%' }}>
                        <Row style={{ alignItems: 'center' }}>
                            <BaseInput
                                id={`TU-SI-${typedUpdateWrapper.typedUpdate.id}`}
                                typedUpdate={{
                                    ...typedUpdateWrapper.typedUpdate,
                                    text: typedUpdateWrapper.typedUpdate.text,
                                }}
                                disabled={isDisabledWhilePresenting}
                                spellCheck={true}
                                autoFocus={autoFocus}
                                inputRef={emptyInputRef}
                                onPaste={onPaste}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                onFocus={() => {
                                    onFocus();
                                }}
                                onBlur={() => {
                                    onBlur();
                                }}
                                onKeyDown={(e) => {
                                    onKeyDown(e);
                                }}
                                placeholder={placeholder}
                                isMouseIn={isMouseIn}
                            />
                        </Row>
                    </Column>
                </UpdateRow>
            </Box>
        </>
    );
}
