import { CalendarEvent, GoogleCalendarEventListOpts, SpinachAPIPath } from '@spinach-shared/types';

import { patchSpinachAPI } from '../apis/patchSpinachAPI';

export const patchGoogleCalendarEvents = async (eventId: string, body: Partial<CalendarEvent>) => {
    const response = await patchSpinachAPI<{ event: CalendarEvent }>(
        `${SpinachAPIPath.GoogleCalendarEvents}/${eventId}`,
        body
    );

    if (!response?.event || !response.event.id) {
        return undefined;
    }

    return response.event;
};
