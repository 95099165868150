import { useState } from 'react';

import { ClientEventType, TicketIntegration } from '@spinach-shared/types';

import { deleteIntegration, getUser } from '../../../apis';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { BodyLarge, BodyRegular, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { Column, Hairline, Row, Spacing } from '../framing';

export function TicketIntegrationSettings({
    integration,
    integrationName,
}: {
    integration: TicketIntegration;
    integrationName: string;
}): JSX.Element {
    const [, setUser] = useGlobalAuthedUser();
    const { setSubview } = useGlobalMeetingSettings();
    const [isLoading, setIsLoading] = useState(false);
    const track = useExperienceTracking();

    const [isConfirmedToDelete, setIsConfirmedToDelete] = useState(false);

    return (
        <Column>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>{integrationName} Settings</b>
                </ResponsiveModalTitle>
            </Row>

            <Hairline />

            <Spacing factor={1 / 2} />

            <ScrollArea style={{ height: '40vh' }} sidePadding={10}>
                <Row>
                    <Column>
                        <BodyLarge>Disconnect {integrationName}</BodyLarge>
                        <BodyRegular>
                            After disconnecting, we'll no longer connect to {integrationName} on your behalf
                        </BodyRegular>
                        <Spacing factor={1 / 3} />
                        <OutlinedButton
                            title={`Remove ${integrationName} Connection`}
                            style={{
                                width: '260px',
                            }}
                            disabled={isConfirmedToDelete}
                            onClick={() => {
                                track(ClientEventType.DeleteIntegrationClick, { OauthProvider: integration });
                                setIsConfirmedToDelete(true);
                            }}
                        />
                        {isConfirmedToDelete ? (
                            <Row style={{ opacity: 1, transition: '500ms', marginTop: '10px' }}>
                                <PrimaryButton
                                    title={'Yes, Remove it'}
                                    color={lightTheme.status.negative}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    isLoading={isLoading}
                                    onClick={async () => {
                                        setIsLoading(true);

                                        track(ClientEventType.DeleteIntegrationConfirmClick, {
                                            OauthProvider: integration,
                                        });

                                        await deleteIntegration(integration);

                                        // then we refetch and hydrate user in state, now without the integration
                                        const userResponse = await getUser();
                                        if (userResponse.user) {
                                            setUser(userResponse.user);
                                        }

                                        setIsLoading(false);
                                        setSubview(null);
                                    }}
                                />

                                <span style={{ display: 'flex', width: '10px' }} />

                                <OutlinedButton
                                    title={'Cancel'}
                                    disabled={isLoading}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    onClick={() => {
                                        track(ClientEventType.CancelIntegrationDeleteClick, {
                                            OauthProvider: integration,
                                        });
                                        setIsConfirmedToDelete(false);
                                    }}
                                />
                            </Row>
                        ) : null}
                    </Column>
                </Row>
                <Spacing />
            </ScrollArea>
        </Column>
    );
}
