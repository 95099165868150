import { Box } from '@material-ui/core';
import { CheckCircle, MoreHoriz, Reply } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import { Titles } from '@spinach-clients/constants';
import moment from 'moment';
import { MouseEventHandler, useEffect, useState } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

// no typsecript support

/* tslint:enable:no-var-requires */
import { DetailedTicket, SpinachUpdateType, Ticket, TypedUpdate } from '@spinach-shared/types';
import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../../apis';
import { BodyBigger, lightTheme } from '../../styles';
import { URLUtil, withFullStoryMasking } from '../../utils';
import { TransitionLeft } from '../common';
import { ScrollView } from '../series/common';
import { Notification } from '../stand-up';
import { PreviousUpdateSectionTooltip } from './PreviousUpdateSectionInput';

const AsanaPreview = styled.div<{ onClick?: any; interactive?: boolean }>`
    padding: 0px 5px;
    white-space: nowrap;
    display: flex;
    text-overflow: ellipsis;
    position: relative;
    margin-bottom: 5px;
    text-align: left;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.interactive ? props.theme.neutrals.grayDark : '')};
    }

    ${(props) =>
        !props.onClick && props.interactive
            ? css`
                  cursor: auto;
                  &:hover {
                      background-color: white;
                  }
              `
            : undefined}
`;

const AsanaLink = styled.span`
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        color: purple;
    }
`;

interface AsanaPreviewContainerProps {
    interactive: boolean;
    update: TypedUpdate;
    issueData: DetailedTicket;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDetailsClick?: MouseEventHandler<HTMLDivElement>;
    onConfirmation?: (update: TypedUpdate) => void;
    saveFullTypedUpdate?: (update: TypedUpdate) => void;
    createUpdateEmitter?: (update: TypedUpdate) => (text: string) => void;
}

const RemoveAsanaButton = styled(CancelIcon)`
    margin-left: auto;
    z-index: 1000;
    color: gray;
    background-color: white;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

const ExpensionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const LabelContainer = styled.div`
    display: flex;
    align-self: start;
    flex-direction: column;
    width: 100%;
    padding: 5px 0px;
`;

const LabelRowContainer = styled(LabelContainer)`
    flex-direction: row;
    align-items: center;
`;

const LabelColumnContainer = styled(LabelContainer)``;

const LabelKeyContainer = styled.div`
    color: #b8b8c5;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
`;

const LabelValue = styled.div`
    vertical-align: middle;
`;

const LabelValueText = styled(LabelValue)`
    font-size: 14px;
`;

const LabelValuePill = styled(LabelValue)`
    border-radius: 9px;
    font-weight: bold;
    font-size: 12px;
    background: #b3ded3;
    color: black;
    padding: 2px 5px;
    margin-left: 4px;
    margin-right: 4px;
`;

const formatDiff = (date: string) => {
    const a = moment(Date.now());
    const b = moment(date);
    const days = a.diff(b, 'days');
    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}`;
    }
    const hours = a.diff(b, 'hours');
    if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    const minutes = a.diff(b, 'minutes');
    if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return undefined;
};

export const AsanaPreviewContainer = ({
    update,
    issueData,
    saveFullTypedUpdate,
    onConfirmation,
    createUpdateEmitter,
    onClick,
    onDetailsClick,
    interactive,
}: AsanaPreviewContainerProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { title, projectName, project, jiraAccountUrl } = issueData;
    const [previousUpdateNotificationMessage, setPreviousUpdateNotificationMessage] = useState<null | string>(null);
    const asanaProject = projectName || project?.name;
    const ref = React.useRef(null);

    const showCloseButton = update && saveFullTypedUpdate && isHovered;

    const onTooltipClick = (typedUpdate: TypedUpdate) => {
        const { ...typedUpdateWithoutSubItems } = typedUpdate;
        setPreviousUpdateNotificationMessage(
            `Added to ${typedUpdate.updateType === SpinachUpdateType.Challenge ? 'Blocker' : typedUpdate.updateType}`
        );
        const emitTypedUpdateSave = createUpdateEmitter?.({
            ...typedUpdateWithoutSubItems,
            text: Titles.AsanaTicket,
            asanaData: typedUpdate.asanaData,
        });
        emitTypedUpdateSave?.(Titles.AsanaTicket);
    };

    return (
        <>
            <AsanaPreview
                ref={ref}
                className={withFullStoryMasking()}
                onMouseEnter={() => interactive && setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                interactive={interactive}
            >
                <Notification
                    isOpen={Boolean(previousUpdateNotificationMessage)}
                    onClose={() => setPreviousUpdateNotificationMessage(null)}
                    message={previousUpdateNotificationMessage}
                    duration={1200}
                    icon={
                        <CheckCircle
                            style={{ color: lightTheme.neutrals.white, height: '20px' }}
                            htmlColor={lightTheme.neutrals.white}
                        />
                    }
                    containerStyle={{
                        position: 'absolute',
                        top: 'unset',
                        bottom: 'unset',
                        width: 'calc(100% - 100px)',
                        left: 'unset',
                        right: 'unset',
                        transform: 'unset',
                    }}
                    contentStyle={{
                        width: '100%',
                        height: '23px',
                        borderRadius: '3px',
                        border: 'solid',
                        borderWidth: 'thin',
                    }}
                    messageStyle={{
                        padding: 'unset',
                    }}
                    transitionComponent={TransitionLeft}
                />
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    <Box
                        style={{
                            display: 'flex',
                            height: '16px',
                            width: '16px',
                            alignItems: 'center',
                            borderColor: lightTheme.tertiary.midnight,
                            cursor: 'pointer',
                            borderStyle: 'solid',
                            borderWidth: 'thin',
                            borderRadius: '50%',
                            marginRight: '5px',
                        }}
                        onClick={onDetailsClick}
                    >
                        <MoreHoriz style={{ height: '16px', width: '16px' }} />
                    </Box>

                    <AsanaLink
                        onClick={() => {
                            if (interactive) {
                                URLUtil.openURL(
                                    `${process.env.REACT_APP_AUTH_URL!}${
                                        SpinachAPIPath.Redirect
                                    }?redirect_url=${jiraAccountUrl}`
                                );
                            }
                        }}
                    >
                        <b>{asanaProject}</b>
                    </AsanaLink>
                    <span
                        onClick={onClick}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer',
                        }}
                    >
                        {title}
                    </span>

                    {showCloseButton ? (
                        <RemoveAsanaButton
                            // When put in the RemoveJiraButton styled component MUI overrides it
                            style={{ height: '19px' }}
                            onClick={() => {
                                saveFullTypedUpdate?.({
                                    ...update,
                                    asanaData: undefined,
                                    text: !update.text.replace(/ /g, '') ? '' : update.text,
                                });
                            }}
                        />
                    ) : null}
                </Box>

                {interactive && isHovered && onConfirmation ? (
                    <PreviousUpdateSectionTooltip
                        onClick={onTooltipClick}
                        onConfirmation={(update: TypedUpdate) => {
                            onConfirmation?.({
                                ...update,
                                text: Titles.AsanaTicket,
                                asanaData: issueData,
                            });
                        }}
                        enableKeyDown={false}
                        typedUpdate={update}
                    />
                ) : (
                    <></>
                )}
            </AsanaPreview>
        </>
    );
};

export const AsanaDetails = ({ issueData }: { issueData: DetailedTicket }) => {
    const [detailedIssue, setDetailedIssue] = useState<DetailedTicket>(issueData);
    const { title, jiraAccountUrl, status, statusLastUpdated, description, assignee, project } = detailedIssue;
    const timeInStatusRaw = statusLastUpdated ? formatDiff(statusLastUpdated) : undefined;
    const timeInStatus = timeInStatusRaw ? ` (${timeInStatusRaw})` : undefined;

    useEffect(() => {
        const fetchDetailedIssue = async () => {
            const issue = await getSpinachAPI<DetailedTicket>(`/asana/issue/${issueData.id}`);

            if (!issue) {
                return;
            }
            setDetailedIssue(issue);
        };

        fetchDetailedIssue();
    }, [issueData]);

    return (
        <ExpensionContainer>
            <ScrollView>
                <LabelContainer>
                    <LabelRowContainer>
                        <BodyBigger style={{ fontWeight: 'bold' }}>
                            {project?.name && `[${project?.name}]`} {title}
                        </BodyBigger>
                        <Reply
                            style={{ cursor: 'pointer', height: '16px', transform: 'rotateY(180deg)' }}
                            onClick={() =>
                                URLUtil.openURL(
                                    `${process.env.REACT_APP_AUTH_URL!}${
                                        SpinachAPIPath.Redirect
                                    }?redirect_url=${jiraAccountUrl}`
                                )
                            }
                        />
                    </LabelRowContainer>

                    <LabelRowContainer>
                        <LabelKeyContainer>Status</LabelKeyContainer>
                        <LabelValuePill>{`${status ?? 'Unknown'}${timeInStatus ?? ''}`}</LabelValuePill>
                    </LabelRowContainer>
                    <LabelRowContainer>
                        {assignee && (
                            <>
                                <LabelKeyContainer>Assigned to</LabelKeyContainer>
                                <LabelValuePill>{assignee}</LabelValuePill>
                            </>
                        )}
                    </LabelRowContainer>
                    <LabelColumnContainer>
                        <LabelKeyContainer>Description</LabelKeyContainer>
                        <LabelValueText>{description}</LabelValueText>
                    </LabelColumnContainer>
                </LabelContainer>
            </ScrollView>
        </ExpensionContainer>
    );
};
