import { useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { ClientEventType, UTMSource, WebUrlQuery } from '@spinach-shared/types';

import { GlobalModal } from '../../atoms';
import {
    useEmailSearchRemoval,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNavDrawer,
    useGlobalRouting,
    useLocationalSeriesId,
    usePreventBackspace,
    useUserIdentification,
} from '../../hooks';
import { useSlackInstallLink } from '../../hooks/useSlack';
import { useStartupProcesses } from '../../hooks/useStartupProcesses';
import { useZoomCollabRedirect } from '../../hooks/useZoomCollabRedirect';
import { isChromeExtensionPlatform, isWebPlatform } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { WebAuthRouter } from '../auth';
import { VerifyGoogleCode } from '../auth/VerifyGoogleCode';
import { TrySpinachContainer } from '../demo';
import { OnboardingRouter } from '../onboarding';
import { FirstSeriesFlowContainer, SeriesDashboard } from '../series';
import { SpinachAIRoutes } from '../spinach-ai/SpinachAIRoutes';
import { ClientPath } from './ClientPaths';
import { FYI, FYIState } from './FYI';
import { GlobalModalContainer } from './GlobalModalContainer';
import { SpinachMeetingValidator } from './SpinachMeetingValidator';
import { ExperimentRouter } from './experiments';

export function useInitialAuthRouting() {
    const location = useLocation();
    const [user] = useGlobalAuthedUser();
    const {
        routeToOnboarding,
        routeToDirectExperience,
        routeToSeriesExperience,
        routeToExperiment,
        routeToScribeSlackSettings,
        routeToScribeUserSettings,
        routeToScribeOnboarding,
    } = useGlobalRouting();
    const { openSummaries } = useGlobalNavDrawer();
    const [, setModal] = useGlobalModal();
    const seriesId = useLocationalSeriesId();
    const [params] = useSearchParams();
    const track = useExperienceTracking();
    const experimentCode = params.get(WebUrlQuery.Experiment);
    const fromSlack = params.get(WebUrlQuery.UTMSource) && params.get(WebUrlQuery.UTMSource) === UTMSource.Slack;
    const fromAiEmail =
        params.get(WebUrlQuery.UTMSource) === UTMSource.AiEmail || params.get(WebUrlQuery.Source) === UTMSource.AiEmail;

    const fromAiVideoChatMessage =
        params.get(WebUrlQuery.UTMSource) === UTMSource.AiVideoChat ||
        params.get(WebUrlQuery.Source) === UTMSource.AiVideoChat;

    useEffect(() => {
        if (!user.metadata.isOnboarded && user.isScribeUser) {
            routeToScribeOnboarding();
            return;
        }
        /**
         * Prevent anonymous users from being redirected anywhere
         * They aren't supposed to be.
         */
        if (user.isDemoing) {
            return;
        }
        if (
            location.pathname.match(
                new RegExp(ClientPath.MeetingPath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
            ) &&
            fromSlack
        ) {
            track(ClientEventType.UserRedirectedFromSlackUrl, {
                UTMSource: params.get(WebUrlQuery.UTMSource),
                UTMMedium: params.get(WebUrlQuery.UTMMedium),
                UTMCampaign: params.get(WebUrlQuery.UTMCampaign),
            });
        }
        if (fromAiEmail) {
            track(ClientEventType.UserRedirectedFromAiEmail, {
                UTMSource: params.get(WebUrlQuery.UTMSource),
                Meeting: params.get(WebUrlQuery.Meeting),
                Source: params.get(WebUrlQuery.Source),
                UTMMedium: params.get(WebUrlQuery.UTMMedium),
                UTMCampaign: params.get(WebUrlQuery.UTMCampaign),
            });
            routeToScribeSlackSettings({ replace: true });
            return;
        }

        if (fromAiVideoChatMessage) {
            track(ClientEventType.UserRedirectedFromVideoChatMessage, {
                UTMSource: params.get(WebUrlQuery.UTMSource),
                Meeting: params.get(WebUrlQuery.Meeting),
                Source: params.get(WebUrlQuery.Source),
                UTMMedium: params.get(WebUrlQuery.UTMMedium),
                UTMCampaign: params.get(WebUrlQuery.UTMCampaign),
            });
            routeToScribeSlackSettings({ replace: true });
            return;
        }

        if (user.isScribeUser && !seriesId) {
            if (user.seriesMetadataList.length === 0) {
                routeToScribeUserSettings({ replace: true });
                return;
            } else {
                routeToDirectExperience({ replace: true });
                return;
            }
        }

        if (experimentCode) {
            routeToExperiment();
            return;
        }

        if (user.metadata.isOnboarded !== true && !user.isScribeUser) {
            routeToOnboarding({ replace: true });
        } else if (location.pathname === ClientPath.Onboarding && user.metadata.isOnboarded) {
            routeToDirectExperience({ replace: true });
        } else if (
            location.pathname.match(
                new RegExp(ClientPath.SummariesPath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
            )
        ) {
            openSummaries();
            routeToSeriesExperience(seriesId, { replace: true });
        } else if (
            location.pathname.match(
                new RegExp(ClientPath.SchedulePath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
            )
        ) {
            setModal(GlobalModal.Schedule);
            routeToSeriesExperience(seriesId, { replace: true });
        } else if (location.pathname !== ClientPath.Demo) {
            routeToDirectExperience({ replace: true });
        }
    }, []);
}

export function AuthedUserRouter(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    useInitialAuthRouting();
    useUserIdentification(user);
    useEmailSearchRemoval();
    usePreventBackspace();
    useZoomCollabRedirect();
    useSlackInstallLink();

    useEffect(() => {
        setTimeout(() => {
            TagManager.destroy();
        }, 5000);
    }, []);

    return (
        <>
            <Routes>
                <Route path={ClientPath.Root} element={<SeriesDashboard />} />
                <Route path={ClientPath.Experiment} element={<ExperimentRouter />} />
                {SpinachAIRoutes}
                <Route path={ClientPath.Onboarding} element={<OnboardingRouter />} />
                <Route path={ClientPath.CreateSeriesFlow} element={<FirstSeriesFlowContainer />} />
                <Route path={ClientPath.Demo} element={<SpinachMeetingValidator />} />
                <Route path={ClientPath.Meeting}>
                    <Route path={ClientPath.SeriesIdParam} element={<SpinachMeetingValidator />} />
                </Route>
                <Route path={ClientPath.Unknown} element={<SeriesDashboard />} />
                <Route path={ClientPath.TrySpinach} element={<TrySpinachContainer />} />
            </Routes>
            <GlobalModalContainer />
        </>
    );
}

export function SpinachStartup({
    isLoadingNativeDependencies,
}: {
    isLoadingNativeDependencies?: boolean;
}): JSX.Element {
    const { user, isLoading } = useStartupProcesses();
    const location = useLocation();

    if (isLoadingNativeDependencies || isLoading || user === undefined) {
        return <FYI state={FYIState.Loading} />;
    } else if (user === null || (user.isAnonymous && location.pathname === ClientPath.Verify)) {
        if (isWebPlatform() || isChromeExtensionPlatform()) {
            return (
                <Routes>
                    <Route path={ClientPath.VerifyGoogleCode} element={<VerifyGoogleCode />} />
                    <Route path={ClientPath.Verify} element={<WebAuthRouter />} />
                    <Route path={ClientPath.TrySpinach} element={<TrySpinachContainer />} />
                    <Route path={ClientPath.Unknown} element={<WebAuthRouter />} />
                </Routes>
            );
        } else {
            return (
                <FYI
                    state={FYIState.Error}
                    header={'Bear with us'}
                    body={
                        'Your Spinach installation appears to be wilted. Please re-install from the Zoom App Marketplace.'
                    }
                />
            );
        }
    } else {
        return <AuthedUserRouter />;
    }
}
