import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Fragment } from 'react';

import { BaseMeetingProps, SeriesHistoriesProps } from '@spinach-shared/models';
import { SpinachUpdateTypeYTB, UUID } from '@spinach-shared/types';
import { getFormattedDate } from '@spinach-shared/utils';

import { useSummaryFilterEnablement, useWindowSize } from '../../hooks';
import { BodyLarge, ButtonLabel, ButtonSize, lightTheme } from '../../styles';
import { BootstrapTooltip, Row, Spacing } from '../common';
import { OutlinedButton } from './OutlinedButton';
import { ScrollArea } from './ScrollArea';
import { StandupSummary } from './SummaryView';

type SummaryWrapperProps = {
    participantFilter: UUID[];
    meeting: BaseMeetingProps;
};

function SummaryWrapper({ meeting, participantFilter }: SummaryWrapperProps) {
    const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(meeting, participantFilter);
    const { endedAt } = filteredMeeting;
    const formattedDate = endedAt ? getFormattedDate(endedAt) : 'Live Summary ⚡️';

    if (!filteredMeeting.agenda.YTBItems.length) {
        return <></>;
    }

    return (
        <Fragment>
            <Row>
                <BodyLarge>{formattedDate}</BodyLarge>
            </Row>
            <Spacing factor={0.5} />
            <Row>
                <StandupSummary currentMeeting={filteredMeeting} withMood={false} />
            </Row>
            <Spacing factor={0.5} />
        </Fragment>
    );
}

type SummaryFilterViewProps = {
    participantFilter: UUID[];
    categoryFilter: SpinachUpdateTypeYTB[];
    openedSummary: number | null;
    onCopyClick: () => void;
    summaries: BaseMeetingProps[];
};

export function SummaryFilterView({
    participantFilter,
    categoryFilter,
    openedSummary,
    onCopyClick,
    summaries,
}: SummaryFilterViewProps): JSX.Element {
    const isSummaryFiltersEnabled = useSummaryFilterEnablement();
    const hasFilters = !!(participantFilter.length || categoryFilter.length);
    const { width } = useWindowSize();
    const tooltipPlacement = width < 600 ? 'top' : 'left';

    if (!summaries || !hasFilters || openedSummary === null || !isSummaryFiltersEnabled) {
        return <></>;
    }

    const selectedSeries = openedSummary === -1 ? summaries : [summaries[openedSummary]];

    return (
        <>
            <Spacing factor={0.75} />
            <Row style={{ justifyContent: 'end' }}>
                <BootstrapTooltip title="Copy a formatted summary to your clipboard" placement={tooltipPlacement}>
                    <OutlinedButton
                        size={ButtonSize.Small}
                        onClick={onCopyClick}
                        title={
                            <>
                                <FileCopyOutlinedIcon htmlColor={lightTheme.primary.greenLight} fontSize="small" />
                                <ButtonLabel size={ButtonSize.Small} style={{ marginLeft: '5px' }}>
                                    Copy
                                </ButtonLabel>
                            </>
                        }
                    />
                </BootstrapTooltip>
            </Row>
            <ScrollArea
                style={{ height: '10vh', flexGrow: 1, paddingBottom: '20px', marginTop: '20px' }}
                sidePadding={0}
                useGrayShading={true}
            >
                {selectedSeries.map((meeting) => (
                    <SummaryWrapper key={meeting.id} meeting={meeting} participantFilter={participantFilter} />
                ))}
            </ScrollArea>
        </>
    );
}
