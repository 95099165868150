import { Tooltip } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Scroll from 'react-scroll';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { ActiveAgendaProps, BaseAgendaProps, SpinachSeriesProps } from '@spinach-shared/models';
import { AgendaItemBaseRequest, ClientEventType, ClientSocketEvent, MeetingStatus } from '@spinach-shared/types';
import { formatTime } from '@spinach-shared/utils';

import { ReactComponent as PopoutLinkIcon } from '../../assets/popout-icon.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalNullableLiveSeries,
    useGlobalStoredSeries,
    usePersonaDemo,
    useTimer,
    useWindowSearchParam,
} from '../../hooks';
import { BodyRegular, lightTheme } from '../../styles';
import { DEMO_PATH, URLUtil, createWebsocketPayload } from '../../utils';
import { isWebPlatform } from '../../utils/platform';
import { MeetingSettings } from '../common';
import { Notification } from './Notification';
import { ProgressIndication } from './ProgressIndication';
import { ScrollArea } from './ScrollArea';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    z-index: 20;
`;

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const StopWatchContainer = styled(BodyRegular)`
    display: flex;
    color: ${(props) => props.theme.primary.green};
    flex-shrink: 0;
    min-width: 40px;
    padding-left: 5px;
`;

type ProgressRowProps = {
    series: SpinachSeriesProps;
    socket: Socket;
};

function getStartTimerAt(series: SpinachSeriesProps): string {
    if (series.currentMeeting.isMeetingEnding) {
        return series.currentMeeting.startedAt!;
    } else if (series.agenda instanceof ActiveAgendaProps) {
        return series.agenda.currentItem?.talkTimes?.latest?.startedAt;
    } else {
        return series.currentMeeting.createdAt;
    }
}
const StopWatch = ({ series }: { series: SpinachSeriesProps }): JSX.Element => {
    const timerOffset = series.agenda instanceof ActiveAgendaProps ? series.agenda.currentItem.totalTime : 0;
    const startTimerAt = getStartTimerAt(series);
    const timer = useTimer(startTimerAt, series.currentMeeting.status);
    const isStaticTimer = series.currentMeeting.isMeetingEnding;

    const time = isStaticTimer
        ? (new Date(series.currentMeeting.endedAt ?? series.currentMeeting.updatedAt).getTime() -
              new Date(series.currentMeeting.startedAt!).getTime()) /
          1000
        : timer;

    return <StopWatchContainer>{formatTime(time + timerOffset)}</StopWatchContainer>;
};

function useScrollToProgressIndicatorOnAgendaUpdate(agenda: BaseAgendaProps) {
    useEffect(() => {
        if (agenda instanceof ActiveAgendaProps) {
            const itemId = agenda.currentItem.id;

            const containerWidth =
                document.getElementById('progress-indication-scroller')?.getBoundingClientRect().width ?? 0;
            const scrollElementWidth =
                document.getElementById(`progress-element-${itemId}`)?.getBoundingClientRect().width ?? 0;

            Scroll.scroller.scrollTo(`progress-element-${itemId}`, {
                horizontal: true,
                containerId: 'progress-indication-scroller',
                duration: 150,
                smooth: true,
                offset: -(containerWidth / 2) + scrollElementWidth / 2,
            });
        }
    }, [agenda]);
}

export function ProgressRow({ series, socket }: ProgressRowProps): JSX.Element {
    const agendaItemId = series.thisParticipantsYTBAgendaItem.id;
    const [storedSeries] = useGlobalStoredSeries();
    const windowSearchParam = useWindowSearchParam();
    const agenda = series.agenda;
    const showStopWatch = series.currentMeeting.status !== MeetingStatus.Initialized;
    const [popoutButtonBackgroundColor, setPopoutButtonBackgroundColor] = useState<string>('unset');
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const track = useExperienceTracking();
    const [wasPopupBlocked, setWasPopupBlocked] = useState(false);
    const [isNotificationToastOpen, setIsNotificationToastOpen] = useState(false);
    const isPersonaDemo = usePersonaDemo();

    const handleExternalLinkClick = async () => {
        let openedWindow: Window | null = null;

        if (series.isDemo) {
            openedWindow = URLUtil.openURLPopUp(`${window.location.origin}${DEMO_PATH}?&window=popout`);
        } else {
            openedWindow = URLUtil.openURLPopUp(
                `${window.location.origin}/meeting/${encodeURIComponent(storedSeries?.id ?? series.slug)}?window=popout`
            );
        }

        if (!openedWindow) {
            setIsNotificationToastOpen(true);
            setWasPopupBlocked(true);
        }
    };

    const popUpBlockedMessage = <>Enable pop-ups for the best experience.</>;

    useScrollToProgressIndicatorOnAgendaUpdate(series.agenda);

    return (
        <Container>
            <MeetingSettings />

            <Notification
                containerStyle={{ position: 'relative', top: '30px', height: '0' }}
                isOpen={isNotificationToastOpen}
                duration={wasPopupBlocked ? null : 4000}
                onClose={() => setIsNotificationToastOpen(false)}
                message={wasPopupBlocked ? popUpBlockedMessage : "Oops, I couldn't connect to that session."}
                icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
            />

            <ProgressContainer>
                <ScrollArea
                    id="progress-indication-scroller"
                    horizontal={true}
                    style={{
                        flexDirection: 'row-reverse',
                        width: '100%',
                        paddingBottom: '10px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        backgroundImage: 'unset',
                        backgroundColor: 'rgba(0,0,0,0)',
                    }}
                    stretch={false}
                    sidePadding={0}
                >
                    {agenda && series.currentMeeting.status !== MeetingStatus.Initialized && !isPersonaDemo ? (
                        <ProgressIndication
                            spinachParticipantAgendaItemId={agendaItemId}
                            series={series}
                            onProgressElementClick={(itemId: string) => {
                                track(ClientEventType.UserNavigatedUsingProgressElement, { itemId });

                                const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
                                    spinachUserId: user.spinachUserId,
                                    meetingId: liveSeries?.currentMeeting.id,
                                    seriesSlug: series.slug,
                                    itemId,
                                });
                                socket.emit(ClientSocketEvent.AgendaNavigating, agendaNavigationRequest);
                            }}
                        />
                    ) : null}
                </ScrollArea>

                {showStopWatch ? <StopWatch key={series.currentAgendaItem?.id ?? ''} series={series} /> : null}
                {isWebPlatform() && !series.isDemo && !windowSearchParam ? (
                    <Tooltip
                        title="Resize this window to position alongside your video platform. Note: Popups must be enabled on your browser"
                        placement="top"
                    >
                        <PopoutLinkIcon
                            id="pop-out-window"
                            onMouseEnter={() => setPopoutButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                            onMouseLeave={() => setPopoutButtonBackgroundColor('unset')}
                            onMouseDown={() => setPopoutButtonBackgroundColor(lightTheme.neutrals.grayDark)}
                            onMouseUp={() => setPopoutButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                            onClick={handleExternalLinkClick}
                            style={{
                                backgroundColor: popoutButtonBackgroundColor,
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                                marginLeft: '4px',
                                verticalAlign: 'middle',
                            }}
                            fill={lightTheme.primary.greenDark}
                            width={14}
                            height={14}
                        />
                    </Tooltip>
                ) : null}
            </ProgressContainer>
        </Container>
    );
}
