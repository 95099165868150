import { DemoVersion, GoogleVerificationResponse, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../apis';

export async function postVerifyGoogle(
    tokenId: string | undefined,
    experimentCode: string | null,
    deepLinkedSeriesId: string,
    demoVersion?: DemoVersion
): Promise<GoogleVerificationResponse> {
    try {
        const response = await postSpinachAPI<GoogleVerificationResponse>(SpinachAPIPath.VerifyGoogle, {
            tokenId,
            experimentCode,
            demoVersion,
            deepLinkedSeriesId,
        });
        if (!response) {
            return { user: null };
        }
        return response;
    } catch {
        return { user: null };
    }
}

export async function postVerifyGoogleWithCode(code: string | undefined): Promise<GoogleVerificationResponse> {
    try {
        const response = await postSpinachAPI<GoogleVerificationResponse>(SpinachAPIPath.VerifyGoogleWithCode, {
            code,
        });
        if (!response) {
            return { user: null };
        }
        return response;
    } catch {
        return { user: null };
    }
}
