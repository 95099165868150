import { CalendarEvent, ClientEventType } from '@spinach-shared/types';

import { useLandingAnalytic } from '../../../hooks';
import { BodyRegularOnboard, HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SelectCalendarEventsForSpinach } from '../ScribeCalendarPage';
import { BackButton, Stationary, StepProps } from './common';

export type SelectEventForMeetingProps = StepProps & {
    onBack: () => void;
    hasError: boolean;
    onEventClick: (event: CalendarEvent) => void;
    data: CalendarEvent[] | undefined;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    onSkip: () => void;
};

export function AddToMeetingsStep({
    onBack,
    onSkip,
    direction,
    onSubmit,
    loadingMessage,
    hasError,
    data,
    onEventClick,
    onboardingEventsToAddScribeTo,
}: SelectEventForMeetingProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAddSpinachToMeetingsStep);
    return (
        <Stationary style={{ maxHeight: '650px' }}>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>Add Spinach to your agile meetings</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard>Select each meeting you'd like to add Spinach to.</BodyRegularOnboard>
            <Spacing factor={1 / 2} />
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <SelectCalendarEventsForSpinach
                        isOnboardingFlow={true}
                        loadingMessage={loadingMessage}
                        hasError={hasError}
                        onEventClick={onEventClick}
                        data={data}
                        onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    />
                </SlidingColumn>
            </ScrollView>
            <Row centered>
                <PrimaryButton
                    style={{ zIndex: 1000, marginTop: '30px' }}
                    title={'Done'}
                    onClick={onSubmit}
                    loadingText={loadingMessage}
                    isLoading={!!loadingMessage}
                    disabled={!onboardingEventsToAddScribeTo.length}
                />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title="Skip" onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
