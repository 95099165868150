import { Button, ButtonProps } from '@material-ui/core';

export function FloatingButton(buttonProps: ButtonProps): JSX.Element {
    return (
        <Button
            variant="outlined"
            color="primary"
            disableRipple={true}
            style={{
                textTransform: 'none',
                backgroundColor: 'white',
                boxShadow: '2px 2px 12px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                border: 'none',
                color: 'black',
                fontWeight: 'bold',
                width: '250px',
                marginBottom: '10px',
            }}
            {...buttonProps}
        >
            {buttonProps.children}
        </Button>
    );
}
