import { Box } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { DEMO_TICKET_ID_PREFIX, OMNIBOX_DEMO_JIRA_TICKETS } from '@spinach-shared/constants';
import {
    AuthoredUpdate,
    ClientEventType,
    JiraIssueHasNoTitlePayload,
    JiraIssueSelectionType,
    OmniboxSearchOptions,
    OmniboxToggleAction,
    OmniboxToggleTrigger,
    Sentiment,
    SpinachUpdateType,
    SpinachUpdateTypeYTB,
    Ticket,
    TicketSource,
    TypedUpdate,
    UserIdentityPayload,
    UserInitiateOmniboxPayload,
    UserIntegrations,
} from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import { fetchJiraIssue } from '../../apis/jira';
import { jiraColorMap } from '../../constants';
import {
    useAsanaEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalJiraIssues,
    useGlobalLiveSeries,
    useGlobalStoredSeries,
    useIssueBasedEnablement,
    useJiraBoardEnablement,
    useJiraEnablement,
    useJiraSearchAllEnablement,
    useJiraSentimentInFooterEnablement,
    usePersonaDemo,
    useReadOnlyPresentation,
    useSeriesReality,
    useTypedUpdateWrapper,
    useUserAuthMap,
} from '../../hooks';
import { lightTheme } from '../../styles';
import {
    AsanaAttachmentProps,
    AttachmentProps,
    JiraAttachmentProps,
    LiveUpdateSearchProps,
    MultiLineAttachmentProps,
    OmniboxItem,
    OmniboxOnClickProps,
    OmniboxSearchResults,
    SetValue,
} from '../../types';
import { ClientLogger, useFetchJiraIssues, useSearchJiraIssues } from '../../utils';
import { Column, Row } from '../common';
import { useJiraMagicInputCallout } from '../common/feature-discovery/JiraMagicInputCallout';
import { LiveItemSentimentSelectionComponent } from '../stand-up/LiveItemSentimentSelectionComponent';
import { AsanaDetailsModal } from './AsanaDetailsModal';
import { AsanaPreviewContainer } from './AsanaPreviewContainer';
import { Attachments } from './Attachments';
import { BaseInput } from './BaseInput';
import { LiveItemBullet } from './LiveItemBullet';
import { OmniboxSearchOptionSelector } from './OmniboxSearchOptionsSelector';
import { JiraDetailsModal, JiraInput, OldJiraPreviewContainer } from './jira';

export type LiveItemProps = {
    placeholder: string;
    typedUpdate: TypedUpdate;
    isFocused: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    isAttachable?: boolean;
    isInNavDrawer?: boolean;
    isOmniboxSearchEnabled: boolean;
    index?: number;
    autoFocusOnMountAfter?: number;
    isMouseIn?: boolean;
    dragRef?: any;
    subItemEmptyInputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    isCurrentAgendaItem?: boolean;
    updateOwnerId?: string;
    topicTitleInput?: boolean;
    hint?: ReactNode;
    emptyInputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    hideBullet?: boolean;
    setTypedUpdate: (typedUpdate: TypedUpdate) => void;
    setFocusedInput: (nullable?: null) => void;
    saveTypedUpdate: (text: string) => void;
    // TODO: consolidate args here
    saveFullTypedUpdate: (update: TypedUpdate, updates?: TypedUpdate[]) => void;
    createTabbedItem?: () => void;
    removeTabbedItem?: () => void;
    removeTabbedItemByIndex?: (index: number) => () => void;
};

export type LiveItemPresentationProps = {
    onBlur: () => void;
    onFocus: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
    onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
    placeholder: string;
    typedUpdate: TypedUpdate | AuthoredUpdate;
    emptyInputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    autoFocus?: boolean;
    isAttachable?: boolean;
    isFocused: boolean | null;
    disabled?: boolean;
    isMouseIn?: boolean;
    searchProps: LiveUpdateSearchProps;
    index?: number;
    dragRef?: any;
    jiraProps: JiraAttachmentProps;
    asanaProps: AsanaAttachmentProps;
    multiLineProps: MultiLineAttachmentProps;
    updateOwnerId?: string;
    isCurrentAgendaItem?: boolean;
    topicTitleInput?: boolean;
    hideBullet?: boolean;
};

// this is what helps the top level bullets be positioned absolutely
const BulletContainer = styled.div`
    position: absolute;
    top: 5px;
    left: -24px;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

export const UpdateRow = styled(Row)<{
    isTicketItem?: boolean;
    isMouseIn?: boolean;
    updateType?: SpinachUpdateTypeYTB;
    isJiraBoardEnabled?: boolean;
}>`
    background-color: ${(props) =>
        props.isTicketItem && props.isJiraBoardEnabled && props.isMouseIn && props.updateType
            ? !jiraColorMap[props.updateType]
                ? '#4285F433'
                : `${jiraColorMap[props.updateType]}33`
            : 'none'};
`;

type OmniboxSearchOptionConfiguration = {
    keyboardShortcut: string;
    searchMethod: (
        setSearchedIssues: SetValue<OmniboxSearchResults[] | null>,
        searchParams?: string,
        setIsLoading?: SetValue<boolean>
    ) => Promise<void>;
    defaultSearchResults: OmniboxSearchResults[] | null;
    defaultHeaderText: string;
    defaultHeaderTextNoItems: string;
};

function useDelayedAutoFocus(autoFocusOnMountAfter: number | undefined, typedUpdate: TypedUpdate) {
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (autoFocusOnMountAfter !== undefined) {
            timeout = setTimeout(() => {
                const element = document.getElementById(`TU-${typedUpdate.id}`);
                if (element) {
                    element.focus();
                }
            }, autoFocusOnMountAfter);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);
}

const useOmniboxSearchOptionsMap = (): [
    Record<OmniboxSearchOptions, OmniboxSearchOptionConfiguration> | null,
    string[]
] => {
    const { isDemoSeries } = useSeriesReality();
    let [jiraTickets] = useGlobalJiraIssues();
    const track = useExperienceTracking();

    if (isDemoSeries) {
        jiraTickets = OMNIBOX_DEMO_JIRA_TICKETS.map((title, i) => ({
            title,
            id: `${DEMO_TICKET_ID_PREFIX}${i + 2}`,
            description: '',
            ticketSource: TicketSource.Jira,
            ticketUrl: '',
            displayName: `${DEMO_TICKET_ID_PREFIX}${i + 2}`,
            avatarUrl: '',
            jiraAccountUrl: '',
        }));
    }

    const { fetchJiraIssues } = useFetchJiraIssues();
    const { searchJiraIssues } = useSearchJiraIssues();

    const jiraSearchMethod = async (searchParams: string) => {
        /**
         * @NOTE this JQL searches text in the title of a ticket
         * we can also search the description of a ticket like so
         * `summary ~ "${searchParams}*"`
         * */
        const searchByTextQuery = `text ~ "${searchParams}*"`;

        /** @NOTE issue key regex matches things like the following `SP-234` */
        const issueKeyRegex = new RegExp(/(.*?)-[0-9]+$/g);

        /**
         * @NOTE numeric key regex matches things like the following 1234,
         * it does not match things like 1234h or 1234 h
         *  */
        const numericRegex = new RegExp(/[0-9]+$/g);

        /** 
         * @NOTE projectNameSpaceNumericRegex matches things like the following `SP 234`
         * it does not match things like 123 343, or SP 234 h or SP 2h3m
        `*/
        const projectNameSpaceNumericRegex = new RegExp(/^[A-Za-z]+ [0-9]+$/g);

        const isIssueKeySearch = issueKeyRegex.test(searchParams);
        const isNumericRegex = numericRegex.test(searchParams);
        const isProjectNameSpaceNumericRegex = projectNameSpaceNumericRegex.test(searchParams);

        let issueResponse: Ticket[] = [] as Ticket[];

        const isUserSearchingForSpecificIssue = isIssueKeySearch || isProjectNameSpaceNumericRegex || isNumericRegex;

        const jiraIssueHasNoTitleMetadata: Omit<JiraIssueHasNoTitlePayload, keyof UserIdentityPayload> = {
            IssueId: '',
            IsJiraSearchAllEnabled: isJiraSearchAllEnabled,
            IsNumericRegex: isNumericRegex,
            IsIssueKeySearch: isIssueKeySearch,
            IsProjectNameSpaceNumericRegex: isProjectNameSpaceNumericRegex,
            IsUserSearchingForSpecificIssue: isUserSearchingForSpecificIssue,
        };

        if (isUserSearchingForSpecificIssue) {
            track(ClientEventType.UserSearchedForSpecificTicketNumber, {
                NumberOfCharacters: searchParams.length,
                IsIssueKeySearch: isIssueKeySearch,
                IsNumericSearch: isNumericRegex,
                IsProjectNameSpaceNumericSearch: isProjectNameSpaceNumericRegex,
            });
        }

        if (isIssueKeySearch || isProjectNameSpaceNumericRegex) {
            let issueId = searchParams;

            if (isProjectNameSpaceNumericRegex) {
                issueId = searchParams.replace(' ', '-');
            }
            const response = await fetchJiraIssue(issueId);
            issueResponse = response ? [response] : ([] as Ticket[]);
        } else if (isNumericRegex) {
            // We get all project prefixes in the recommended tickets (i.e. SP-123, SB-892)
            const projectsInSuggestions = [...new Set(jiraTickets?.map((t) => t.id.split('-')[0]))];
            jiraIssueHasNoTitleMetadata.ProjectsInSuggestions = projectsInSuggestions.join(',');

            if (projectsInSuggestions.length) {
                // We then search for any key in their Jira account that may match any combination of
                // the numeric value they entered and the project ids in their recommended tickets
                const jql = `key in (${projectsInSuggestions?.map((s) => `"${s}-${searchParams}"`)})`;

                issueResponse = await searchJiraIssues({
                    jql,
                    query: searchParams,
                });
            } else {
                issueResponse = await fetchJiraIssues({
                    query: searchParams,
                });
            }
        } else {
            const issuesFromTextSearch = await searchJiraIssues({
                jql: searchByTextQuery,
                query: searchParams,
            });

            issueResponse = issuesFromTextSearch;
        }

        issueResponse.map(async (i) => {
            if (!i.title) {
                ClientLogger.error(ClientEventType.JiraIssueHasNoTitle, {
                    ...jiraIssueHasNoTitleMetadata,
                    IssueId: i.id,
                });

                track(ClientEventType.JiraIssueHasNoTitle, {
                    ...jiraIssueHasNoTitleMetadata,
                    IssueId: i.id,
                });
            }
        });

        return issueResponse;
    };

    const jiraLegacySearchMethod = async (searchParams: string) => {
        return await fetchJiraIssues({
            query: searchParams,
            showSubTasks: true,
            showSubTaskParent: true,
        });
    };

    const isJiraSearchAllEnabled = useJiraSearchAllEnablement();

    const omniboxMap = {
        [UserIntegrations.Jira]: {
            keyboardShortcut: '/',
            searchMethod: async (
                setSearchedIssues: SetValue<Ticket[] | null>,
                searchParams?: string,
                setIsLoading?: SetValue<boolean>
            ) => {
                setIsLoading?.(true);
                if (searchParams) {
                    let issueResponse: Ticket[] = [] as Ticket[];
                    if (isJiraSearchAllEnabled) {
                        issueResponse = await jiraSearchMethod(searchParams);
                    } else {
                        issueResponse = await jiraLegacySearchMethod(searchParams);
                    }
                    if (issueResponse) {
                        // Cleaning HTML off issues
                        const cleanedIssues: Ticket[] = await Promise.all(
                            issueResponse.map(async (issue) => {
                                return { ...issue, title: issue.title?.replace(/(<([^>]+)>)/gi, '') ?? '' };
                            })
                        );
                        setSearchedIssues(cleanedIssues.filter((i) => !!i.title));
                    } else {
                        setSearchedIssues(jiraTickets);
                    }
                } else {
                    setSearchedIssues(jiraTickets);
                }
                setIsLoading?.(false);
            },
            defaultSearchResults: jiraTickets,
            defaultHeaderText: 'Recent Tickets:',
            defaultHeaderTextNoItems: 'No Recent Tickets',
        },
    };

    const omniboxKeyboardShortcuts = Object.values(omniboxMap).map((o) => o.keyboardShortcut);

    return [omniboxMap, omniboxKeyboardShortcuts];
};

const MoreIconContainer = styled(Box)`
    display: flex;
    height: 16px;
    width: 16px;
    align-items: center;
    align-self: center;
    border-color: ${(props) => props.theme.tertiary.midnight};
    cursor: pointer;
    border-style: solid;
    border-width: thin;
    border-radius: 50%;
    margin-right: 5px;
`;

export function LiveItem({
    typedUpdate,
    setTypedUpdate,
    isFocused,
    emptyInputRef,
    setFocusedInput,
    subItemEmptyInputRef,
    saveTypedUpdate,
    saveFullTypedUpdate,
    createTabbedItem,
    removeTabbedItemByIndex,
    autoFocus,
    isOmniboxSearchEnabled,
    isAttachable,
    placeholder,
    index,
    disabled,
    isCurrentAgendaItem,
    isInNavDrawer,
    onBlur,
    autoFocusOnMountAfter,
    isMouseIn,
    dragRef,
    updateOwnerId,
    topicTitleInput,
    hideBullet,
}: LiveItemProps & { onBlur: () => void }): JSX.Element {
    useDelayedAutoFocus(autoFocusOnMountAfter, typedUpdate);
    const track = useExperienceTracking();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const omniboxSearchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [searchedOmniboxResults, setSearchedOmniboxResults] = useState<OmniboxSearchResults[] | null>(null);
    const [selectedOmniboxSearchOption, setSelectedOmniboxSearchOption] = useState<OmniboxSearchOptions | null>(null);
    const [searchText, setSearchText] = useState('');
    const isJiraEnabled = useJiraEnablement();
    const [showOmnibox, setShowOmnibox] = useState(false);
    const [isOmniboxSearchLoading, setIsOmniboxSearchLoading] = useState(false);

    const sentimentPickerRef = useRef<HTMLDivElement | null>(null);

    const [omniboxSearchOptionsMap] = useOmniboxSearchOptionsMap();
    const isPersonaDemo = usePersonaDemo();

    const selectedOmniboxSearchOptionConfiguration =
        selectedOmniboxSearchOption && omniboxSearchOptionsMap
            ? omniboxSearchOptionsMap[selectedOmniboxSearchOption]
            : null;

    function disposeTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (omniboxSearchTimeoutRef.current) {
            clearTimeout(omniboxSearchTimeoutRef.current);
            omniboxSearchTimeoutRef.current = null;
        }
    }

    useEffect(() => {
        return () => {
            disposeTimeout();
        };
    }, []);

    function onPaste(e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) {
        const lineStartMatch = /^[\*\-\s]+/gi;
        if (!e.clipboardData.types.includes('text/plain')) {
            return;
        }

        const data = e.clipboardData.getData('text/plain');

        const lines = data
            .split('\n')
            .filter((line) => !!line.trim().length)
            .map((line) => getNewTypedUpdate({ ...typedUpdate, text: line.replace(lineStartMatch, '').trim() }));

        if (lines.length <= 1) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        saveFullTypedUpdate(lines[0], lines);
        track(ClientEventType.UserPastedMultilineUpdate, {
            NumberOfLines: lines.length,
        });
    }

    return (
        <LiveItemPresentation
            onPaste={onPaste}
            disabled={disabled}
            topicTitleInput={topicTitleInput}
            placeholder={placeholder}
            typedUpdate={typedUpdate}
            emptyInputRef={typedUpdate.text === '' ? emptyInputRef : undefined}
            autoFocus={autoFocus}
            isAttachable={isAttachable}
            isFocused={isFocused}
            hideBullet={hideBullet}
            onFocus={() => {
                setFocusedInput();
            }}
            onChange={async (e) => {
                disposeTimeout();
                if (!showOmnibox) {
                    setTypedUpdate({
                        ...typedUpdate,
                        text: e.target.value,
                    });
                    timeoutRef.current = setTimeout(() => {
                        if (isFocused) {
                            saveTypedUpdate(e.target.value);
                        }
                        timeoutRef.current = null;
                    }, 3000);
                } else {
                    if (showOmnibox) {
                        setSearchText(e.target.value);
                        if (isJiraEnabled && selectedOmniboxSearchOptionConfiguration) {
                            omniboxSearchTimeoutRef.current = setTimeout(() => {
                                selectedOmniboxSearchOptionConfiguration.searchMethod(
                                    setSearchedOmniboxResults,
                                    e?.target.value.startsWith(
                                        selectedOmniboxSearchOptionConfiguration.keyboardShortcut
                                    )
                                        ? e?.target.value.slice(1)
                                        : e?.target.value,
                                    setIsOmniboxSearchLoading
                                );
                                omniboxSearchTimeoutRef.current = null;
                            }, 500);
                        }
                    }
                }
            }}
            onBlur={() => {
                disposeTimeout();
                onBlur();
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey && typedUpdate.text && index !== undefined) {
                    e.preventDefault();
                    disposeTimeout();
                    createTabbedItem?.();

                    setTimeout(() => {
                        subItemEmptyInputRef?.current?.focus();
                    }, 10);
                } else if (e.key === 'Enter' || e.key === 'Tab') {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }

                    disposeTimeout();

                    if (e.key === 'Enter') {
                        setTimeout(() => {
                            emptyInputRef?.current?.focus();
                        }, 10);
                    }
                }
            }}
            index={index}
            isMouseIn={isMouseIn}
            isCurrentAgendaItem={isCurrentAgendaItem}
            dragRef={dragRef}
            updateOwnerId={updateOwnerId}
            multiLineProps={{
                typedUpdate,
                saveFullTypedUpdate,
                handleClick: () => {
                    disposeTimeout();
                    if (typedUpdate.subItems?.length && !typedUpdate.subItems[typedUpdate.subItems.length - 1].text) {
                        const subItemIndexToRemove = typedUpdate.subItems.length - 1;

                        setTimeout(() => {
                            removeTabbedItemByIndex?.(subItemIndexToRemove)();
                            emptyInputRef?.current?.focus();
                        }, 100);
                    } else {
                        setTimeout(() => {
                            createTabbedItem?.();
                            subItemEmptyInputRef?.current?.focus();
                        }, 100);
                    }
                },
            }}
            jiraProps={{
                typedUpdate,
                setTypedUpdate,
                saveFullTypedUpdate,
                isInNavDrawer,
                sentimentPickerRef,
                subItemEmptyInputRef,
            }}
            asanaProps={{
                typedUpdate,
                setTypedUpdate,
                saveFullTypedUpdate,
                subItemEmptyInputRef,
            }}
            searchProps={{
                typedUpdate,
                isFocused,
                searchText,
                showOmnibox,
                subItemEmptyInputRef,
                setTypedUpdate,
                selectedOmniboxSearchOption,
                setSelectedOmniboxSearchOption,
                isOmniboxEnabled: isOmniboxSearchEnabled && !isPersonaDemo,
                setShowOmnibox,
                isOmniboxSearchLoading,
                searchResults: searchedOmniboxResults,
            }}
        />
    );
}

function LiveItemPresentation(props: LiveItemPresentationProps): JSX.Element {
    const {
        emptyInputRef,
        typedUpdate,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        placeholder,
        autoFocus,
        isAttachable = true,
        disabled,
        isMouseIn,
        dragRef,
        jiraProps,
        asanaProps,
        multiLineProps,
        isFocused,
        isCurrentAgendaItem,
        index,
        searchProps,
        topicTitleInput,
        onPaste,
        hideBullet,
    } = props;
    const { isDemoSeries } = useSeriesReality();
    const typedUpdateWrapper = useTypedUpdateWrapper(typedUpdate);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    /**
     * @NOTE It seems as though this stateful value is unnecessary,
     * and it technically is from a functional standpoint. However,
     * without this stateful value the sentiment selection process is
     * not always smooth when the user selects multiple sentiment values
     * for different tickets in quick succession
     *
     * This is primarily due to race condition issues in which the `typedUpdate.ticketData?.sentiment` value
     * that comes back from one SeriesUpdated socket event _temporarily_ sets the sentiment back to the previous
     * value until the SeriesUpdated socket event from the next selection sets it back to its proper value
     *
     * Setting the stateful typedUpdate does not help either as when the SeriesUpdated socket event comes
     * back we merge the typedUpdate values in a way in which overrides the ticketData
     * We could go back and fix that hook to deep merge the ticket data in a more effective way, but for
     * now this works very well and doesn't have the same potential for performance issues as updating
     * the merge hook does.
     * */
    const [liveUpdateSentiment, setLiveUpdateSentiment] = useState<Sentiment | undefined>(typedUpdateWrapper.sentiment);

    const [selectedJiraData, setSelectedJiraData] = useState(typedUpdateWrapper.ticket);

    const [isAsanaDetailedModalOpen, setIsAsanaDetailedModalOpen] = useState(false);

    const [omniboxSearchOptionsMap, omniboxKeyboardShortcuts] = useOmniboxSearchOptionsMap();
    const [jiraSaveTimeoutRef, setJiraSaveTimeoutRef] = useState<null | NodeJS.Timeout>(null);

    const isJiraBoardEnabled = useJiraBoardEnablement();
    const isJiraEnabled = useJiraEnablement();
    const [user] = useGlobalAuthedUser();
    const [series] = useGlobalStoredSeries();
    const [liveSeries] = useGlobalLiveSeries();
    const jiraMagicInputCallout = useJiraMagicInputCallout();
    const track = useExperienceTracking();
    const isReadonlyPresentationEnabled = useReadOnlyPresentation();
    const { isInNavDrawer, saveFullTypedUpdate, sentimentPickerRef } = jiraProps;
    const isJiraSentimentInFooterEnabled = useJiraSentimentInFooterEnablement();
    const isIssueBasedEnabled = useIssueBasedEnablement();

    const isJiraMagicInputCalloutVisible =
        jiraMagicInputCallout.isVisible &&
        typedUpdateWrapper.typedUpdate.updateType === SpinachUpdateType.Yesterday &&
        index === 0;

    const currentAgendaItem = liveSeries.currentAgendaItem;

    const isDisabledWhilePresenting =
        isReadonlyPresentationEnabled && !isInNavDrawer && currentAgendaItem?.isParticipantAgendaItem;

    const {
        searchResults,
        setTypedUpdate,
        setShowOmnibox,
        isOmniboxEnabled,
        showOmnibox,
        isOmniboxSearchLoading,
        selectedOmniboxSearchOption,
        setSelectedOmniboxSearchOption,
        searchText,
    } = searchProps;

    const isAsanaEnabled = useAsanaEnablement() && user.isAuthedForAsana;
    const selectedOmniboxSearchOptionConfiguration =
        selectedOmniboxSearchOption && omniboxSearchOptionsMap
            ? omniboxSearchOptionsMap[selectedOmniboxSearchOption]
            : null;

    // Don't show Jira magic if user does not have Jira enabled
    // This will ensure once we turn jira magic to 100% teams like Asurion who may not use Jira
    // Do not see Jira magic
    const shouldShowJiraMagic = (isJiraEnabled && isOmniboxEnabled) || isDemoSeries;
    const isTicketItem = isJiraEnabled && !!typedUpdateWrapper.ticket?.id;

    // either it's a regular item and we have sentiments for regular items enabled
    // or it's a jira item and we have jira sentiment in footer enabled
    // and we're in the nav drawer
    // and the update has text
    const showSentimentPicker =
        !isJiraSentimentInFooterEnabled &&
        typedUpdateWrapper.ticket &&
        isInNavDrawer &&
        typedUpdateWrapper.typedUpdate.updateType !== SpinachUpdateType.Icebreaker;

    let searchValue: OmniboxSearchResults[] | null;
    if (searchResults?.length) {
        searchValue = searchResults;
    } else {
        if (
            selectedOmniboxSearchOptionConfiguration &&
            (!searchText ||
                isOmniboxSearchLoading ||
                (searchText.length === 1 &&
                    searchText.startsWith(selectedOmniboxSearchOptionConfiguration.keyboardShortcut)))
        ) {
            searchValue = selectedOmniboxSearchOptionConfiguration.defaultSearchResults;
        } else {
            searchValue = null;
        }
    }

    function disposeTimeout() {
        if (jiraSaveTimeoutRef) {
            clearTimeout(jiraSaveTimeoutRef);
            setJiraSaveTimeoutRef(null);
        }
    }

    useEffect(() => {
        return () => {
            disposeTimeout();
        };
    }, []);

    useEffect(() => {
        const handleClick = () => {
            sentimentPickerRef?.current?.blur();
        };

        document.addEventListener('click', handleClick);

        // Don't forget to clean up
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    /** @TODO for a more generic solution, we would want type guards here for every typ ein the OmniboxSearchResults union and map accordingly */
    /** This may look like a factory to convert `searchResults` into a usable OmniboxItem type */
    const searchItems =
        searchValue?.map(
            (i) =>
                ({
                    item: i,
                    title: `${i.id} ${i.title}`,
                    endIcon: isDemoSeries ? undefined : (
                        <MoreIconContainer
                            onClick={() => {
                                setSelectedJiraData(i);
                                setIsDetailsModalOpen(!isDetailsModalOpen);
                            }}
                        >
                            <MoreHoriz style={{ height: '16px', width: '16px' }} />
                        </MoreIconContainer>
                    ),
                } as OmniboxItem<Ticket>)
        ) ?? null;

    useEffect(() => {
        setSelectedJiraData(typedUpdateWrapper.ticket);
    }, [typedUpdateWrapper.ticket]);

    const toggleShowOmnibox = (show: boolean, trigger?: OmniboxToggleTrigger) => {
        const newShowOmniboxValue = show ?? !showOmnibox;
        if (show !== showOmnibox) {
            const action = newShowOmniboxValue ? OmniboxToggleAction.Open : OmniboxToggleAction.Close;
            const isAutomatedClose = !trigger || trigger === OmniboxToggleTrigger.Automated;
            // user explicitly closed omnibox and they had typed something in to search for a ticket
            const didUserCloseOmniboxWithoutItemSelection =
                action === OmniboxToggleAction.Close && !isAutomatedClose && !!searchValue;

            let userInitiateOmniboxPayload: Omit<UserInitiateOmniboxPayload, keyof UserIdentityPayload> = {
                SeriesSlug: series.slug,
                Trigger: isAutomatedClose ? OmniboxToggleTrigger.Automated : trigger,
                UserClosedWithoutItemSelection: didUserCloseOmniboxWithoutItemSelection,
                Action: newShowOmniboxValue ? OmniboxToggleAction.Open : OmniboxToggleAction.Close,
            };

            if (show && isJiraMagicInputCalloutVisible) {
                jiraMagicInputCallout.onEngageClick();
                userInitiateOmniboxPayload = {
                    ...userInitiateOmniboxPayload,
                    FromCallout: true,
                };
            }

            track(ClientEventType.UserInitiateOmnibox, userInitiateOmniboxPayload);
        }

        setShowOmnibox(newShowOmniboxValue);
        if (newShowOmniboxValue) {
            onFocus();
            /** @TODO this is temporary until we add new search options in which we will not set this and allow the user to choose */
            if (user.isAuthedForJira || isDemoSeries) {
                setSelectedOmniboxSearchOption(UserIntegrations.Jira);
            }
            const element = document.getElementById(`TU-${typedUpdateWrapper.typedUpdate.id}`);
            if (element) {
                element.focus();
            }
        }
    };

    const reactionsAttachmentProps: AttachmentProps = {
        typedUpdate: typedUpdateWrapper.typedUpdate,
        saveFullTypedUpdate,
        setTypedUpdate,
    };

    let endIcon = null;

    if (
        shouldShowJiraMagic &&
        !!typedUpdateWrapper.ticket?.id &&
        !(topicTitleInput && (!isMouseIn || disabled || !dragRef))
    ) {
        endIcon = isDemoSeries ? undefined : (
            <Box
                style={{
                    display: 'flex',
                    height: '16px',
                    width: '16px',
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderColor: lightTheme.tertiary.midnight,
                    cursor: 'pointer',
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    borderRadius: '50%',
                    marginRight: '5px',
                }}
                onClick={() => setIsDetailsModalOpen(!isDetailsModalOpen)}
            >
                <MoreHoriz style={{ height: '16px', width: '16px' }} />
            </Box>
        );
    }

    const [userAuthMap] = useUserAuthMap();

    const getOmniboxHeaderText = (omniboxOption: OmniboxSearchOptions) => {
        if (!searchValue?.length) {
            if (!!searchText) {
                track(ClientEventType.NoOmniboxItemsFound, {
                    SeriesId: series.id,
                    OmniboxSearchOption: omniboxOption,
                });
                return 'No Results';
            }
            if (omniboxSearchOptionsMap && omniboxSearchOptionsMap[omniboxOption]) {
                return omniboxSearchOptionsMap[omniboxOption].defaultHeaderTextNoItems;
            }
        }

        if (
            searchText &&
            omniboxSearchOptionsMap &&
            !(searchText.length === 1 && searchText.startsWith(omniboxSearchOptionsMap[omniboxOption].keyboardShortcut))
        ) {
            return 'Search Results';
        }
        if (omniboxSearchOptionsMap && omniboxSearchOptionsMap[omniboxOption]) {
            return omniboxSearchOptionsMap[omniboxOption].defaultHeaderText;
        }
        return 'No Results';
    };

    return (
        <>
            <Box
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'end',
                    width: '100%',
                    border:
                        shouldShowJiraMagic && showOmnibox && !typedUpdateWrapper.ticket
                            ? `1px solid ${lightTheme.tertiary.midnight}`
                            : 'unset',
                }}
            >
                <UpdateRow
                    isJiraBoardEnabled={isJiraBoardEnabled}
                    updateType={typedUpdateWrapper.typedUpdate.updateType as SpinachUpdateTypeYTB}
                    isTicketItem={isTicketItem}
                    isMouseIn={isMouseIn}
                >
                    {!hideBullet ? (
                        <BulletContainer>
                            <LiveItemBullet
                                {...{
                                    typedUpdateWrapper,
                                    toggleShowOmnibox,
                                    isOmniboxEnabled,
                                    isCurrentAgendaItem,
                                    index,
                                    topicTitleInput,
                                    isJiraMagicInputCalloutVisible,
                                    isMouseIn,
                                    dragRef,
                                    disabled,
                                    isInNavDrawer,
                                    shouldShowJiraMagic,
                                    showOmnibox,
                                    setTypedUpdate,
                                    saveFullTypedUpdate,
                                }}
                                onDelete={() => {
                                    disposeTimeout();
                                    setTypedUpdate({
                                        ...typedUpdateWrapper.typedUpdate,
                                        jiraData: undefined,
                                        ticketData: undefined,
                                        text: '',
                                        subItems: [],
                                    });

                                    const timeoutRef = setTimeout(() => {
                                        setTypedUpdate?.({
                                            ...typedUpdateWrapper.typedUpdate,
                                            jiraData: undefined,
                                            ticketData: undefined,
                                            text: '',
                                            subItems: [],
                                        });
                                        saveFullTypedUpdate?.({
                                            ...typedUpdateWrapper.typedUpdate,
                                            jiraData: undefined,
                                            ticketData: undefined,
                                            text: '',
                                            subItems: [],
                                        });
                                        setJiraSaveTimeoutRef(null);
                                    }, 500);

                                    setJiraSaveTimeoutRef(timeoutRef);

                                    track(ClientEventType.UserRemovedJiraTicket, {
                                        SeriesSlug: series.slug,
                                        ItemId: typedUpdateWrapper.ticket?.id,
                                    });
                                }}
                            />
                        </BulletContainer>
                    ) : null}
                    <Column style={{ width: typedUpdateWrapper.ticket ? 'calc(100% - 28px)' : '100%' }}>
                        {isJiraEnabled && typedUpdateWrapper.ticket ? (
                            <>
                                <Row style={{ alignItems: 'center' }}>
                                    <JiraInput interactive={!disabled} issueData={typedUpdateWrapper.ticket} />
                                </Row>
                            </>
                        ) : (
                            <Row style={{ alignItems: 'center' }}>
                                <BaseInput
                                    id={`TU-${typedUpdateWrapper.typedUpdate.id}`}
                                    typedUpdate={{
                                        ...typedUpdateWrapper.typedUpdate,
                                        text:
                                            showOmnibox && selectedOmniboxSearchOptionConfiguration
                                                ? searchText.startsWith(
                                                      selectedOmniboxSearchOptionConfiguration.keyboardShortcut
                                                  )
                                                    ? searchText
                                                    : `${selectedOmniboxSearchOptionConfiguration.keyboardShortcut}${searchText}`
                                                : typedUpdateWrapper.typedUpdate.text,
                                    }}
                                    disabled={
                                        disabled ||
                                        (shouldShowJiraMagic && showOmnibox && !selectedOmniboxSearchOption) ||
                                        isDisabledWhilePresenting
                                    }
                                    spellCheck={true}
                                    autoFocus={autoFocus}
                                    inputRef={emptyInputRef}
                                    onPaste={onPaste}
                                    onChange={(e) => {
                                        if (shouldShowJiraMagic && selectedOmniboxSearchOptionConfiguration) {
                                            const keyboardShortcut =
                                                selectedOmniboxSearchOptionConfiguration.keyboardShortcut;

                                            /** @description This prevents another keyboard shortcut from being typed when the user opens up the omnibox search **/

                                            /**
                                             * For example, a user presses / to open up the omnibox search. That opens the search and also puts a / in as a prefix to subsequent search data.
                                             * The user does not type anything in the search box, then closes the omnibox search via the minus button.
                                             * The user then presses / again to open up the omnibox search. We do not want the search value to look like //, we instead want it to still be /.
                                             * This logic here accomplishes that by checking to see if the target value is omniboxKeyboardShortcut*2 (i.e. //) and then prevents another keyboard shortcut (in this case /) from being written */
                                            if (omniboxKeyboardShortcuts.find((i) => e.target.value === i + i)) {
                                                e.preventDefault();
                                                return;
                                            }
                                            if (
                                                keyboardShortcut &&
                                                showOmnibox &&
                                                (searchText.startsWith(keyboardShortcut) || !searchText) &&
                                                (!e.target.value.startsWith(keyboardShortcut) || !e.target.value)
                                            ) {
                                                toggleShowOmnibox(false, OmniboxToggleTrigger.KeyboardShortcut);
                                            }
                                        }
                                        onChange(e);
                                    }}
                                    onFocus={() => {
                                        onFocus();
                                    }}
                                    onBlur={() => {
                                        sentimentPickerRef?.current?.blur();
                                        onBlur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            isOmniboxEnabled &&
                                            omniboxKeyboardShortcuts.includes(e.key) &&
                                            omniboxSearchOptionsMap &&
                                            !typedUpdateWrapper.typedUpdate.text
                                        ) {
                                            const [selectedOmniboxOption] =
                                                Object.entries(omniboxSearchOptionsMap).find(
                                                    ([, val]) => val.keyboardShortcut === e.key
                                                ) ?? [];
                                            // Should never be undefined due to the above check
                                            /** @TODO check map for is authed, not manually checking jira */
                                            if (
                                                selectedOmniboxOption &&
                                                userAuthMap[selectedOmniboxOption as unknown as OmniboxSearchOptions]
                                                    .authed
                                            ) {
                                                e.preventDefault();
                                                setSelectedOmniboxSearchOption(
                                                    selectedOmniboxOption as unknown as OmniboxSearchOptions
                                                );
                                                toggleShowOmnibox(true, OmniboxToggleTrigger.KeyboardShortcut);
                                            }
                                        } else if (e.key === 'Escape' && showOmnibox) {
                                            toggleShowOmnibox(false, OmniboxToggleTrigger.KeyboardShortcut);
                                        } else {
                                            onKeyDown(e);
                                        }
                                    }}
                                    // TODO: Turn into a placeholder map for the ability to use other search mechanisms
                                    placeholder={showOmnibox ? 'Search for Jira Tickets' : placeholder}
                                    isMouseIn={isMouseIn}
                                />
                            </Row>
                        )}

                        {!shouldShowJiraMagic ? (
                            isJiraEnabled && typedUpdateWrapper.ticket ? (
                                <OldJiraPreviewContainer
                                    interactive={true}
                                    saveFullTypedUpdate={saveFullTypedUpdate}
                                    update={typedUpdateWrapper.typedUpdate}
                                    issueData={typedUpdateWrapper.ticket}
                                />
                            ) : null
                        ) : null}
                        {selectedJiraData && (
                            <JiraDetailsModal
                                onClose={() => {
                                    setIsDetailsModalOpen(!isDetailsModalOpen);
                                    setSelectedJiraData(typedUpdateWrapper.ticket);
                                }}
                                issueData={selectedJiraData}
                                isOpen={isDetailsModalOpen}
                            />
                        )}
                        {typedUpdateWrapper.typedUpdate.asanaData && isAsanaEnabled && (
                            <>
                                <AsanaPreviewContainer
                                    interactive={true}
                                    saveFullTypedUpdate={asanaProps.saveFullTypedUpdate}
                                    update={typedUpdateWrapper.typedUpdate}
                                    issueData={typedUpdateWrapper.typedUpdate.asanaData}
                                    onDetailsClick={() => setIsAsanaDetailedModalOpen(!isAsanaDetailedModalOpen)}
                                />
                                <AsanaDetailsModal
                                    onClose={() => setIsAsanaDetailedModalOpen(!isAsanaDetailedModalOpen)}
                                    issueData={typedUpdateWrapper.typedUpdate.asanaData}
                                    isOpen={isAsanaDetailedModalOpen}
                                />
                            </>
                        )}
                        {isAttachable && (
                            <Attachments
                                isInNavDrawer={!!isInNavDrawer}
                                jiraProps={{ ...jiraProps, isDisabled: true }}
                                asanaProps={{ ...asanaProps, isDisabled: !isAsanaEnabled }}
                                issueResolutionProps={{
                                    ...reactionsAttachmentProps,
                                    isInNavDrawer,
                                }}
                                reactionsProps={{ ...reactionsAttachmentProps }}
                                multiLineProps={{ ...multiLineProps }}
                                shouldShow={!!isMouseIn}
                            />
                        )}
                    </Column>
                    {endIcon}
                </UpdateRow>
                {shouldShowJiraMagic && showOmnibox ? (
                    <OmniboxSearchOptionSelector
                        setSelectedOmniboxSearchOption={setSelectedOmniboxSearchOption}
                        selectedOmniboxSearchOption={selectedOmniboxSearchOption}
                        onClose={() => toggleShowOmnibox(false)}
                        searchOptionOptions={{
                            [UserIntegrations.Jira]: {
                                headerText: getOmniboxHeaderText(UserIntegrations.Jira),
                                onClick: ({ item, title, index }: OmniboxOnClickProps<Ticket>) => {
                                    if (typeof title !== 'string') {
                                        return;
                                    }

                                    const selectionType = searchText
                                        ? JiraIssueSelectionType.Linked
                                        : JiraIssueSelectionType.Suggested;

                                    track(ClientEventType.JiraIssueSelected, {
                                        SelectionType: selectionType,
                                        Index: index,
                                    });

                                    disposeTimeout();
                                    const subItem = getNewTypedUpdate({
                                        creatorId: user.spinachUserId,
                                        updateType: typedUpdateWrapper.typedUpdate.updateType,
                                        text: '',
                                    });

                                    let typedUpdateSubItems = [
                                        ...(typedUpdateWrapper.typedUpdate.subItems ?? []),
                                        subItem,
                                    ];
                                    if (typedUpdateWrapper.typedUpdate.text) {
                                        const existingParentItem = getNewTypedUpdate({
                                            creatorId: user.spinachUserId,
                                            text: typedUpdateWrapper.typedUpdate.text,
                                            updateType: typedUpdateWrapper.typedUpdate.updateType,
                                        });
                                        typedUpdateSubItems = [existingParentItem, ...typedUpdateSubItems];
                                    }

                                    setTypedUpdate?.({
                                        ...typedUpdateWrapper.typedUpdate,
                                        jiraData: item,
                                        ticketData: {
                                            ticket: item,
                                            sentiment: isDemoSeries ? Sentiment.Good : liveUpdateSentiment,
                                            source: TicketSource.Jira,
                                        },
                                        text: title,
                                        subItems: [...typedUpdateSubItems],
                                    });

                                    if (isDemoSeries) {
                                        setLiveUpdateSentiment(Sentiment.Good);
                                    }

                                    const timeoutRef = setTimeout(() => {
                                        saveFullTypedUpdate?.({
                                            ...typedUpdateWrapper.typedUpdate,
                                            jiraData: item,
                                            ticketData: {
                                                ticket: item,
                                                sentiment: isDemoSeries ? Sentiment.Good : liveUpdateSentiment,
                                                source: TicketSource.Jira,
                                            },
                                            text: title,
                                            subItems: [...typedUpdateSubItems],
                                        });
                                        setJiraSaveTimeoutRef(null);
                                    }, 500);

                                    setJiraSaveTimeoutRef(timeoutRef);

                                    if (!isDemoSeries) {
                                        setTimeout(() => {
                                            sentimentPickerRef?.current?.focus();
                                        }, 100);
                                    }

                                    toggleShowOmnibox(false);
                                },
                                items: searchItems,
                            },
                        }}
                        searchText={searchText}
                    />
                ) : (
                    <></>
                )}
            </Box>
            {showSentimentPicker ? (
                <LiveItemSentimentSelectionComponent
                    isInNavDrawer={isInNavDrawer}
                    isParentHovered={isFocused || isMouseIn}
                    typedUpdate={typedUpdateWrapper}
                    setTypedUpdate={setTypedUpdate}
                    saveFullTypedUpdate={saveFullTypedUpdate}
                />
            ) : (
                <></>
            )}
        </>
    );
}
