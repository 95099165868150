import { Switch, SwitchProps, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { DefaultTheme } from 'styled-components';

import { lightTheme } from '../../styles';

const useSwitchStyles = makeStyles<
    DefaultTheme,
    { isToggleHovered: boolean; isChecked: boolean; disabled: boolean; scale?: number }
>(() => ({
    root: {
        width: 42,
        height: (props) => (props.isChecked ? 22 : 20),
        marginBottom: (props) => (props.isChecked ? 'unset' : '2px'),
        padding: 0,
        margin: '2px',
        overflow: 'unset',
        filter: (props) => (props.isToggleHovered ? 'brightness(0.8)' : 'initial'),
        transform: (props) => (props.scale ? `scale(${props.scale})` : 'initial'),
    },
    switchBase: {
        padding: 1,
        top: '2px',
        right: 'unset',
        paddingLeft: '2px',
        left: (props) => (props.isChecked ? '5px' : '2px'),
        '&$checked': {
            transform: 'translateX(16px)',
            color: 'white',
            '& + $track': {
                backgroundColor: (props) => (props.disabled ? 'gray' : lightTheme.primary.greenLight),
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: lightTheme.primary.greenLight,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid gray`,
        backgroundColor: 'gray',
        opacity: 1,

        // transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}));

export function SpinachSwitch(props: SwitchProps & { scale?: number }): JSX.Element {
    const [isToggleHovered, setIsToggleHovered] = useState(false);

    const classes = useSwitchStyles({
        isToggleHovered,
        isChecked: !!props.checked,
        disabled: !!props.disabled,
        scale: props.scale,
    });
    return (
        <Switch
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            onMouseOver={() => {
                setIsToggleHovered(true);
            }}
            onMouseOut={() => {
                setIsToggleHovered(false);
            }}
            {...props}
        />
    );
}
