import React from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useSeriesReality } from '../../hooks';

const IntercomCustomDiv = styled.div`
    position: fixed;
    left: -30px;
    z-index: 3;
    background: ${(props) => props.theme.secondary.greenDark};
    transform: rotate(270deg);
    color: white;
    height: 25px;
    width: 80px;
    text-align: center;
    padding-top: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
`;

export const IntercomCustom = () => {
    const track = useExperienceTracking();
    const { isDemoSeries } = useSeriesReality();

    return (
        <IntercomCustomDiv
            style={{ bottom: isDemoSeries ? '120px' : '100px' }}
            onClick={() => {
                track(ClientEventType.SupportTabClicked);
            }}
            id="spinach_intercom"
        >
            Support
        </IntercomCustomDiv>
    );
};

//Note: force an intercom tour to start (for testing) by pasting `window.Intercom('startTour', 334255)` into the console, just replace the tour id
