import { ReactNode } from 'react';

import { ClientEventType, MeetingSettingsSubview } from '@spinach-shared/types';

import { ReactComponent as SlackLogo } from '../../../assets/slack-logo.svg';
import { ElementId } from '../../../constants';
import {
    useExperienceTracking,
    useFreeTierLimitations,
    useGlobalAuthedUser,
    useGlobalNullableStoredSeries,
    useGlobalStoredSeries,
    useIntegrationDetection,
} from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { useGlobalSlack } from '../../../hooks/useSlack';
import { URLUtil } from '../../../utils';
import { FreeTierLimitationMessage, LimitationIntent } from '../FreeTierMessages';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { UseIntegrationRow } from './common';

export function useSlackIntegrationRow(
    notConnectedMessage: NonNullable<ReactNode>,
    connectedMessage: NonNullable<ReactNode>,
    isUserDefaultChannel: boolean
): UseIntegrationRow {
    const [storedSeries] = useGlobalNullableStoredSeries();
    const [user] = useGlobalAuthedUser();
    const { slackState } = useGlobalSlack();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();
    const { isAuthedForSlack } = user;
    const isFreeTierLimited = useFreeTierLimitations();

    function openSettingsOnSuccess() {
        setSubview(MeetingSettingsSubview.SlackSettings);
    }

    const startDetection = useIntegrationDetection(openSettingsOnSuccess);

    const userSlack = user.slackSettings;

    const channelNames = isUserDefaultChannel
        ? [userSlack?.defaultChannelName]
        : [storedSeries!.slackChannel?.channelName];

    const isConnectFlow = !userSlack || userSlack.shouldReinstall;

    async function onClick() {
        if (isConnectFlow) {
            // @TODO do we want to pass in a callback upon successful auth to slack settings subview to select a channel?
            startDetection();
            track(ClientEventType.UserAuthorizeSlackClick);
            URLUtil.openURL(slackState.installUrl);
        } else {
            setSubview(MeetingSettingsSubview.SlackSettings);
            track(ClientEventType.OpenSlackSettingsSubviewClick);
        }
    }

    let subtext: ReactNode = notConnectedMessage;

    if (channelNames?.[0]) {
        subtext = `${connectedMessage} #${channelNames?.map((channelName, i) =>
            i === 0 ? channelName : ` and #${channelName}`
        )}`;
        if (!userSlack) {
            subtext = `${subtext}. Connect to manage this standup's Slack settings`;
        }
    } else if (userSlack) {
        subtext = notConnectedMessage;
    }

    if (isFreeTierLimited) {
        subtext = <FreeTierLimitationMessage intent={LimitationIntent.Slack} />;
    }

    return {
        subtext,
        onClick,
        isConnected: isAuthedForSlack,
        buttonText: isAuthedForSlack ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
    };
}

export function SlackIntegrationRow({
    notConnectedMessage = '⚠️ Finish setting up your Slack summary output.',
    connectedMessage = 'This standup is connected to',
    isUserDefaultChannel = false,
}: {
    connectedMessage?: ReactNode;
    notConnectedMessage?: ReactNode;
    isUserDefaultChannel?: boolean;
}): JSX.Element {
    const { subtext, onClick, isConnected, buttonText } = useSlackIntegrationRow(
        notConnectedMessage!,
        connectedMessage!,
        isUserDefaultChannel
    );

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Slack'}
            icon={<SlackLogo style={{ width: '25px', height: '25px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            id={ElementId.SlackConnectButton}
        />
    );
}
