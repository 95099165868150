import { OAuthV2AccessViaCodeRequest } from '@spinach-backend/types';

import { TicketSource } from './Tickets';
import { ISOString } from './Time';

// This type represents the uderlying Jira statuses that cannot be changed
export type TicketStatusCategory = {
    self: string;
    id: number;
    key: string;
    colorName: 'medium-gray' | 'green' | 'yellow' | 'brown' | 'warm-red' | 'blue-gray';
    name: string;
};

// Jira issue status is the _custom_ status values
// Underlying these are some basic Jira status categories that cannot be changed
// So, each status is associated with a status category
export type TicketStatus = {
    self: string;
    description: string;
    iconUrl: string;
    name: string;
    untranslatedName: string;
    id: string;
    statusCategory: TicketStatusCategory;
    scope?: TicketStatusScope;
};

export type MinimalTicketStatus = Pick<TicketStatus, 'name' | 'id'>;
export type ProjectId = string;
export type StatusesByProjectId = Record<ProjectId, MinimalTicketStatus[]>;
export type StatusName = string;
export type TicketsByStatus = Record<StatusName, DetailedTicket[]>;

export type TicketStatusScope = {
    type: 'PROJECT';
    project: {
        id: string;
    };
};

export type JiraProjectComponent = {
    self: string;
    id: string;
    name: string;
    isAssigneeTypeValid: boolean;
};

export type JiraProjectVersion = {
    self: string;
    id: string;
    name: string;
    archived: boolean;
    released: boolean;
    startDate: string; // YYY-MM-DD
    releaseDate: string; // YYY-MM-DD
    userStartDate: string; // DD/ShortMonth (e.g. Apr)/DD
    userReleaseDate: string;
    projectId: number;
};
export type JiraProject = {
    expand: string;
    self: string;
    id: string;
    description: string;
    key: string;
    name: string;
    projectTypeKey: 'software';
    simplified: boolean;
    avatarUrls: JiraAvatarUrls;
    lead: Pick<JiraUser, 'self' | 'accountId' | 'avatarUrls' | 'displayName' | 'active'>;
    components: JiraProjectComponent[];
    issueTypes: ProjectIssueType[];
    assigneeType: string;
    style: string;
    isPrivate: boolean;
    properties: Record<string, any>;
    versions: JiraProjectVersion[];
    roles: Record<string, string>;
};

export enum BaseIssueTypeName {
    Bug = 'Bug',
    Task = 'Task',
    SubTask = 'Subtask',
    Story = 'Story',
    Epic = 'Epic',
}

export type IssueType = {
    self: string;
    id: string;
    description: string;
    iconUrl: string;
    name: BaseIssueTypeName;
    untranslatedName: string;
    subtask: boolean;
    avatarId: string;
    hierarchyLevel: number;
    scope: {
        type: string;
        project: {
            id: string;
        };
    };
};

export type ProjectIssueType = Omit<IssueType, 'scope'>;

export type JiraAvatarUrls = {
    '48x48': string;
    '24x24': string;
    '16x16': string;
    '32x32': string;
};

export type Ticket = {
    title: string;
    id: string;
    avatarUrl?: string;
    description: string;
    projectName?: string;
    ticketSource: TicketSource;
    jiraAccountUrl: string;
    ticketUrl: string;
    /**
     * @NOTE this property is used to have different ticket sources
     * compose a human readable display name
     * For Jira that may be SP-123, for Asana it may be "This is a task", etc.
     * Since these sources differ in the way they represent the ticket name we should
     * not rely upon the `id` or `title` fields for this purpose
     */
    displayName: string;
};

export enum JiraIssueChangeLogField {
    Assignee = 'assignee',
    Comment = 'comment',
    Project = 'project',
    User = 'user',
    Components = 'components',
    Description = 'description',
    Environment = 'environment',
    Summary = 'summary',
    Status = 'status',
    Priority = 'priority',
    IssueType = 'issuetype',
}

export enum CreateMeatadataFieldSchema {
    Assignee = 'assignee',
    Comment = 'comment',
    Project = 'project',
    Components = 'components',
    Description = 'description',
    Environment = 'environment',
    Summary = 'summary',
    Status = 'status',
    Priority = 'priority',
    IssueType = 'issuetype',
    String = 'string',
    Array = 'array',
    Option = 'option',
    Any = 'any',
    Number = 'number',
    Date = 'date',
}
export enum JiraIssueChangeLogFieldType {
    Jira = 'jira',
    Custom = 'custom',
}

export type IssueChangeLogHistoryItem = {
    field: JiraIssueChangeLogField;
    fieldtype: JiraIssueChangeLogFieldType;
    fieldId: string;
    from: string | null;
    fromString: string | null;
    to: string | null;
    toString: string | null;
};

export type JiraUser = {
    self: string;
    accountId: string;
    emailAddress: string;
    avatarUrls: JiraAvatarUrls;
    displayName: string;
    active: boolean;
    timeZone: string;
    accountType: string;
};

export type IssueChangeLogHistory = {
    id: string;
    author: JiraUser;
    created: ISOString;
    items: IssueChangeLogHistoryItem[];
};

export type IssueComment = {
    self: string;
    id: string;
    author: JiraUser;
    body: string;
    updateAuthor: JiraUser;
    created: string;
    updated: string;
    visibility: {
        type: string;
        value: string;
    };
    jsdPublic: boolean;
};

export type MappedChangelog = Omit<IssueChangeLogHistory, 'author'> & {
    author: Omit<JiraUser, 'avatarUrls'>;
    summary: string;
    key: string;
    issueSelf: string;
    description: string;
    issuetype: string;
};

export type MappedIssueChangeLogHistoryItem = IssueChangeLogHistoryItem &
    Pick<MappedChangelog, 'issueSelf' | 'key' | 'summary' | 'created'> & {
        issueId: string;
        author: string;
        description: string;
        issuetype: string;
        comments?: IssueComment[];
    };

export enum JiraRoles {
    AtlassianAddons = 'atlassian-addons-project-access',
    Administrators = 'Administrators',
}

export const In_PROGRESS_STATUS = 'In Progress';

export type DetailedTicket = Partial<Pick<Ticket, 'avatarUrl'>> &
    Omit<Ticket, 'avatarUrl'> & {
        assignee?: string;
        status?: typeof In_PROGRESS_STATUS | string;
        statusLastUpdated?: string;
        project?: Partial<Pick<JiraProject, 'avatarUrls'>> &
            Omit<
                JiraProject,
                | 'expand'
                | 'description'
                | 'lead'
                | 'components'
                | 'assigneeType'
                | 'avatarUrls'
                | 'style'
                | 'isPrivate'
                | 'properties'
                | 'versions'
                | 'roles'
                | 'issueTypes'
            >;
        statusColor?: 'medium-gray' | 'green' | 'yellow' | 'brown' | 'warm-red' | 'blue-gray';
    };

export type DetailedTicketWithProject = Omit<DetailedTicket, 'project'> & {
    project: JiraProject;
};

export type JiraOAuthRequest = OAuthV2AccessViaCodeRequest & {
    redirect_uri: string;
    grant_type: 'authorization_code';
};

export type JiraOAuthRefreshTokenRequest = Omit<OAuthV2AccessViaCodeRequest, 'code'> & {
    grant_type: 'refresh_token';
    refresh_token: string;
};

export type JiraOAuthTokenResponse = {
    access_token: string;
    scope: string;
    expires_in: number;
    token_Type: 'Bearer';
    refresh_token: string;
};

export type JiraUserListResponse = {
    users: JiraUser[] | null;
    jiraUserId: string | null;
};

export type JiraGetAvailableResourceItem = {
    id: string;
    name: string;
    url: string;
    scopes: string[];
    avatarUrl: string;
};

export type JiraSearchOpts = {
    jql?: string;
    startAt?: number;
    maxResults?: number;
    fields?: string[];
    /**
     * Only used to support legacy users who do not have search functionality
     * There are 372 users (spinach users included) in production who have the
     * legacy permissions*/
    query?: string;
    expand?: string;
};

export type JiraPickerOpts = {
    query?: string;
    currentJQL?: string;
    currentIssueKey?: string;
    currentProjectId?: string;
    showSubTasks?: boolean;
    showSubTaskParent?: boolean;
};

export type JiraRoleActor = {
    id: number;
    displayName: string;
    type: JiraRoles;
    actorUser: {
        accountId: string;
    };
};

export type CreateMeatadataField = {
    required: boolean;
    schema: {
        type: CreateMeatadataFieldSchema;
        items: string;
        custom?: string;
        customId?: number;
        system: string;
    };
    name: string;
    key: string;
    hasDefaultValue: boolean;
    operations: ('set' | 'add' | 'remove')[];
    allowedValues?: object[];
};
export type CreateMeatadataIssueType = IssueType & {
    fields: Record<string, CreateMeatadataField>;
};

export type CreateMetadataProject = {
    expand: JiraIssueChangeLogField;
    self: string;
    id: string;
    key: string;
    name: string;
    avatarUrls: JiraAvatarUrls;
    issuetypes: CreateMeatadataIssueType[];
};

export type CreateMetadataResponse = {
    expand: JiraIssueChangeLogField;
    projects: CreateMetadataProject[];
};
