import { Popover, makeStyles } from '@material-ui/core';
import { Check, Delete, Edit, Link } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getSharedLink } from '@spinach-shared/utils';

import { lightTheme } from '../../styles';
import { copyTextToClipboard } from '../../utils';
import { isZoomPlatform } from '../../utils/platform';
import { BorderedSpacing, Column, Row } from '../common/framing';
import { RegularCardOptionItem } from './common';

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        backgroundColor: lightTheme.neutrals.grayLight,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

const OptionRow = styled(Row)`
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    border-radius: 8px;
    align-items: center;

    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

export function SeriesCardOptionsMenu({
    isOpen,
    seriesId,
    onClose,
    anchorEl,
    onClickRename,
    onClickRemove,
    hideCopyLink,
}: {
    isOpen: boolean;
    seriesId: string;
    onClose: () => void;
    anchorEl: Element | undefined | null;
    onClickRename: () => void;
    onClickRemove: () => void;
    hideCopyLink: boolean;
}): JSX.Element {
    const popoverStyles = usePopoverStyles();

    const [isCopiedLinkShowing, setIsCopiedLinkShowing] = useState<boolean>(false);
    const [copyLinkTimeout, setCopyLinkTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!copyLinkTimeout && isCopiedLinkShowing) {
            const timeout = setTimeout(() => {
                setIsCopiedLinkShowing(false);
                setCopyLinkTimeout(null);
            }, 3000);
            setCopyLinkTimeout(timeout);
        }

        return () => {
            if (copyLinkTimeout) {
                clearTimeout(copyLinkTimeout);
            }
        };
    }, [isCopiedLinkShowing, copyLinkTimeout]);

    return (
        <Popover
            style={{
                padding: '10px',
            }}
            classes={popoverStyles}
            id={'popover'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Column style={{ padding: '5px', width: '130px' }}>
                {!isZoomPlatform() && !hideCopyLink ? (
                    <>
                        <OptionRow
                            onClick={(e) => {
                                e.stopPropagation();
                                copyTextToClipboard(getSharedLink(seriesId), 'popover');
                                setIsCopiedLinkShowing(true);
                            }}
                        >
                            {isCopiedLinkShowing ? (
                                <>
                                    <Check htmlColor={lightTheme.primary.greenLight} />
                                    <RegularCardOptionItem
                                        style={{
                                            color: lightTheme.primary.greenLight,
                                        }}
                                    >
                                        Copied
                                    </RegularCardOptionItem>
                                </>
                            ) : (
                                <>
                                    <Link />
                                    <RegularCardOptionItem>Copy link</RegularCardOptionItem>
                                </>
                            )}
                        </OptionRow>

                        <BorderedSpacing factor={1 / 4} />
                    </>
                ) : null}

                <OptionRow onClick={onClickRename}>
                    <Edit />
                    <RegularCardOptionItem>Rename</RegularCardOptionItem>
                </OptionRow>

                <OptionRow onClick={onClickRemove}>
                    <Delete />
                    <RegularCardOptionItem>Remove</RegularCardOptionItem>
                </OptionRow>
            </Column>
        </Popover>
    );
}
