import { Modal, TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, EmailReferralResponseCode } from '@spinach-shared/types';
import { StoredSpinachSeries, isDemoSeries, isEmailValid } from '@spinach-shared/utils';

import { postReferralEmail } from '../../../apis';
import CopyLinkSrc from '../../../assets/copy-link-button.png';
import LinkedInSrc from '../../../assets/copy-linked-in-button.png';
import TwitterSrc from '../../../assets/copy-twitter-button.png';
import WhatsAppSrc from '../../../assets/copy-whats-app-button.png';
import { GlobalModal } from '../../../atoms';
import { ElementId } from '../../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
} from '../../../hooks';
import { BodyRegular, HeaderThree, lightTheme } from '../../../styles';
import { URLUtil, copyTextToClipboard, withFullStoryMasking } from '../../../utils';
import { useSpinachInputStyles } from '../../input';
import { ErrorBodySubtitle } from '../../series/common';
import { Notification, PrimaryButton } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

const CircleButton = styled.button`
    outline: none;
    border-radius: 32px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

enum LinkDestination {
    SpinachSite = 'spinach-site',
    LinkedIn = 'linkedin',
    Twitter = 'twitter',
    WhatsApp = 'whatsapp',
}

type CopyLinkButtonProps = { src: string; link: string; destination: LinkDestination };

const CLICK_TO_COPY_DESTINATIONS = [LinkDestination.SpinachSite, LinkDestination.LinkedIn];

function CopyLinkButton({
    src,
    link,
    setLinkCopied,
    destination,
}: CopyLinkButtonProps & { setLinkCopied: () => void }): JSX.Element {
    const track = useExperienceTracking();
    return (
        <CircleButton
            style={{ outline: 'none', borderRadius: '32px' }}
            onClick={() => {
                if (CLICK_TO_COPY_DESTINATIONS.includes(destination)) {
                    setLinkCopied();
                    track(ClientEventType.ReferralLinkCopied, { Destination: destination });
                    copyTextToClipboard(link);
                } else {
                    track(ClientEventType.SendReferralLinkOnAnotherPlatformClicked, { Destination: destination });
                    URLUtil.openURL(link, { redirectThroughBackendWhenOnZoom: true });
                }
            }}
        >
            <img src={src} style={{ width: '32px', height: '32px' }} />
        </CircleButton>
    );
}

const COPY_BUTTON_PROPS: CopyLinkButtonProps[] = [
    {
        src: CopyLinkSrc,
        destination: LinkDestination.SpinachSite,
        link: 'https://spinach.io/',
    },
    {
        src: LinkedInSrc,
        destination: LinkDestination.LinkedIn,
        link: 'https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fspinach.io%2F%3Ftw&title=This is testing stuff',
    },
    {
        src: TwitterSrc,
        destination: LinkDestination.Twitter,
        link: 'https://twitter.com/intent/tweet?url=https%3A%2F%2Fspinach.io%2F&text=',
    },
    {
        src: WhatsAppSrc,
        destination: LinkDestination.WhatsApp,
        link: 'https://api.whatsapp.com/send?text=https://spinach.io/',
    },
];

export function SubscribeToSeriesOutput({ autoFocusDelay = 10 }: { autoFocusDelay?: number }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();

    const [emailInput, setEmailInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useSpinachInputStyles({ value: emailInput });
    const [notificationCode, setNotificationCode] = useState<null | EmailReferralResponseCode>(null);
    const [linkCopied, setLinkCopied] = useState(false);

    const [lastSentEmail, setLastSentEmail] = useState('');

    const error = !isEmailValid(emailInput) && !!emailInput ? 'Invalid email' : '';

    useEffect(() => {
        setTimeout(() => {
            const inputElement = document.getElementById(ElementId.InviteEmailInput);
            if (inputElement) {
                inputElement.focus();
            }
        }, autoFocusDelay);
    }, []);

    async function onSubmitEmail() {
        setIsLoading(true);
        setNotificationCode(null);

        track(ClientEventType.ReferralEmailSubmitted, { Email: emailInput });

        const code = await postReferralEmail(emailInput, user.preferredName);

        setNotificationCode(code);

        if (code === EmailReferralResponseCode.Success) {
            setLastSentEmail(emailInput);
            setEmailInput('');
        }

        setIsLoading(false);
    }

    return (
        <>
            <BodyRegular>Are your standups better with Spinach? If so, tell a friend and share the love.</BodyRegular>

            <Spacing factor={1 / 2} />

            <Row style={{ flex: 'unset' }}>
                <TextField
                    id={ElementId.InviteEmailInput}
                    InputProps={{ classes: { root: classes.base } }}
                    className={withFullStoryMasking(classes.root)}
                    fullWidth
                    placeholder="Add email"
                    value={emailInput}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            onSubmitEmail();
                        }
                    }}
                    onChange={(e) => {
                        setEmailInput(e.target.value.toLowerCase());
                    }}
                />

                <PrimaryButton
                    title="Share"
                    isLoading={isLoading}
                    loadingText={''}
                    onClick={onSubmitEmail}
                    disabled={!!error || !emailInput.trim()}
                />
            </Row>

            <ErrorBodySubtitle>{error}</ErrorBodySubtitle>

            {notificationCode === EmailReferralResponseCode.Success ? (
                <Notification
                    containerStyle={{ position: 'relative', bottom: 'unset' }}
                    isOpen={!!notificationCode}
                    onClose={() => null}
                    message={`Referral sent to ${lastSentEmail}`}
                    icon={
                        <CheckCircleIcon
                            style={{ color: lightTheme.neutrals.white }}
                            htmlColor={lightTheme.neutrals.white}
                        />
                    }
                />
            ) : notificationCode === EmailReferralResponseCode.Failed ? (
                <Notification
                    containerStyle={{ position: 'relative', bottom: 'unset' }}
                    isOpen={!!notificationCode}
                    onClose={() => null}
                    message={`There was a problem sending to ${emailInput}`}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            ) : (
                <Row style={{ height: '50px', fontWeight: 'bold', justifyContent: 'center' }}>OR</Row>
            )}

            {notificationCode ? <Spacing factor={1 / 3} /> : null}

            <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                <BodyRegular>
                    <b>Copy</b>
                </BodyRegular>
                {COPY_BUTTON_PROPS.map((props) => (
                    <CopyLinkButton
                        key={props.src}
                        {...props}
                        setLinkCopied={() => {
                            if (linkCopied) {
                                setLinkCopied(false);
                                setTimeout(() => {
                                    setLinkCopied(true);
                                }, 200);
                            } else {
                                setLinkCopied(true);
                            }
                        }}
                    />
                ))}
            </Row>

            <Spacing factor={1 / 3} />

            <Notification
                containerStyle={{ position: 'relative', bottom: 'unset' }}
                isOpen={!!linkCopied}
                onClose={() => null}
                message={`Link copied!`}
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />
        </>
    );
}

export function SpinachReferralModal({
    isOpen,
    onClose,
    series,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: StoredSpinachSeries;
}): JSX.Element {
    const track = useExperienceTracking();
    const onModalClose = async () => {
        track(ClientEventType.SpinachReferralModalClosed);
        onClose();
    };

    if (isDemoSeries(series.slug)) {
        return <></>;
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onModalClose();
            }}
        >
            <SpinachModalContent
                onClose={onClose}
                style={{
                    width: '310px',
                    padding: '30px',
                    height: '270px',
                    minHeight: 'unset',
                    minWidth: 'unset',
                    overflow: 'initial',
                }}
            >
                <Column>
                    <HeaderThree>Like Spinach? Tell a friend!</HeaderThree>
                    <SubscribeToSeriesOutput />

                    <Spacing factor={1 / 3} />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}

export function GlobalSpinachReferralModal(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const [openModal, setOpenModal] = useGlobalModal();

    if (!series) {
        return <></>;
    }

    return (
        <SpinachReferralModal
            isOpen={openModal === GlobalModal.SpinachReferral}
            onClose={() => {
                setOpenModal(null);
            }}
            series={series}
        />
    );
}
