import { WebUrlQuery } from '@spinach-shared/types';

import { isProductionStage, isStagingStage, isTestStage } from '.';

// TODO: update when chrome comes in between subdomains
export function getWebAppHost(): string {
    const url = isProductionStage()
        ? 'https://app.spinach.io'
        : isStagingStage()
        ? 'https://staging.app.spinach.io'
        : isTestStage()
        ? 'https://internal.app.spinach.io'
        : `http://localhost:${process.env.REACT_APP_PORT!}`;

    return url;
}

export function getSharedLink(code: string): string {
    return `${getWebAppHost()}/?${WebUrlQuery.Meeting}=${code}`;
}
