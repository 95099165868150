import styled from 'styled-components';

/* tslint:enable:no-var-requires */
import { DetailedTicket } from '@spinach-shared/types';
import { SpinachAPIPath } from '@spinach-shared/types';

import { useSeriesReality } from '../../../hooks';
import { URLUtil, withFullStoryMasking } from '../../../utils';
import { BootstrapTooltip } from '../../common';

const JiraPreview = styled.div<{ onClick?: any; interactive?: boolean; isOpenable?: boolean }>`
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    padding: 3px 4px 3px 4px;
    text-decoration: none;
    text-overflow: ellipsis;
    text-align: left;
    align-items: center;
    cursor: ${(props) => (props.isOpenable ? 'cursor' : 'default')};
    flex-direction: row;
    font-size: 15px;
`;

const JiraLink = styled.span`
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        color: purple;
    }
`;

const DisabledJiraLink = styled.span`
    margin-right: 5px;
`;

interface JiraInputProps {
    interactive: boolean;
    issueData: DetailedTicket;
    className?: string;
    isReadOnlyItem?: boolean;
    isOpenable?: boolean;
}

export const JiraInput = ({ issueData, interactive, className, isReadOnlyItem, isOpenable }: JiraInputProps) => {
    const { title, id, jiraAccountUrl } = issueData;
    const { isDemoSeries } = useSeriesReality();

    return (
        <JiraPreview className={withFullStoryMasking(className)} interactive={interactive} isOpenable={isOpenable}>
            {isDemoSeries ? (
                <BootstrapTooltip title={isDemoSeries ? 'Open Jira tickets directly in Spinach' : ''} placement="top">
                    <DisabledJiraLink>
                        <b>{id}</b>
                    </DisabledJiraLink>
                </BootstrapTooltip>
            ) : (
                <JiraLink
                    onClick={() => {
                        if (interactive) {
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL!}${
                                    SpinachAPIPath.Redirect
                                }?redirect_url=${jiraAccountUrl}/browse/${id}`
                            );
                        }
                    }}
                >
                    <b>{id}</b>
                </JiraLink>
            )}

            <span
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {title}
            </span>
        </JiraPreview>
    );
};
