import { IClientUser, SlackTeamType, SpinachAPIPath, UpsertSlackChannelRequest } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postSlackDefaultUserChannel(
    teamId: string,
    teamType: SlackTeamType,
    channelId: string,
    channelName: string
): Promise<{ user: IClientUser | null } | undefined> {
    const request: UpsertSlackChannelRequest = {
        channelName,
        channelId,
        teamId,
        teamType,
    };

    return await postSpinachAPI<{ user: IClientUser | null } | undefined>(
        SpinachAPIPath.SlackDefaultUserChannel,
        request
    );
}
