import { useEffect, useState } from 'react';

import { ISOString, MeetingStatus } from '@spinach-shared/types';
import { getSecondsElapsedSince } from '@spinach-shared/utils';

import { Globals } from '../utils/Globals';

export function useTimer(startedAt?: ISOString, meetingStatus?: MeetingStatus): number {
    const startingTime = startedAt ? Math.abs(getSecondsElapsedSince(startedAt, Globals.clientServerTimeOffset)) : 0;
    const [timer, setTimer] = useState<number>(startingTime);
    const [timeout, setTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (startedAt && meetingStatus !== MeetingStatus.Initialized) {
            setTimeout(
                setInterval(() => {
                    const timeDifferenceInSeconds = getSecondsElapsedSince(startedAt, Globals.clientServerTimeOffset);
                    setTimer(timeDifferenceInSeconds);
                }, 1000)
            );
        }

        if (meetingStatus === MeetingStatus.MeetingComplete || meetingStatus === MeetingStatus.Initialized) {
            if (timeout) {
                clearInterval(timeout);
                setTimeout(null);
            }
        }

        return () => {
            timeout && clearInterval(timeout);
            setTimeout(null);
        };
    }, [startedAt, meetingStatus]);

    return startedAt ? timer : 0;
}
