import { useState } from 'react';
import styled from 'styled-components';

import GoogleLogo from '../../assets/GoogleIcon';
import { AuthorizeIntegrationButton } from './integrations';

const GoogleText = styled.span`
    padding-right: 10px;
    font-weight: 700;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const GoogleLoginComponent = ({
    onClick,
    text = 'Sign in with Google',
}: {
    onClick: () => void;
    text?: string;
}) => {
    return (
        <AuthorizeIntegrationButton onClick={onClick}>
            <GoogleLogo />
            <GoogleText>{text}</GoogleText>
        </AuthorizeIntegrationButton>
    );
};
