import { useGlobalAuthedUser } from '../../../../hooks';
import { GenerateWeeklySummary } from './GenerateWeeklySummary';
import { IntegrateWithJiraForExperiment } from './IntegrateWithJiraForExperiment';

export function WeeklySummaryExperiment(): JSX.Element {
    const [user] = useGlobalAuthedUser();

    if (!user.isAuthedForJira) {
        return <IntegrateWithJiraForExperiment />;
    }

    return <GenerateWeeklySummary />;
}
