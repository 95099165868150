import { ClientEventType } from '@spinach-shared/types';

import { useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButton, DoItManually, Stationary, StepProps } from './common';

export type ManualInviteProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
};

export function ManualInvite({ onBack, direction, onSkip, onSubmit }: ManualInviteProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedManualSpinachSetupStep);
    return (
        <Stationary>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>Add Spinach to your calendar</HeaderThreeOnboard>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <Spacing />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 600 }}>Do it manually</BodyBigOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />

                    <DoItManually />
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={1 / 2} />
            <Row centered>
                <PrimaryButton title={'Done'} onClick={onSubmit} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
