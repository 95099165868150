import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import LinkedInSrc from '../../../assets/copy-linked-in-button.png';
import TwitterSrc from '../../../assets/copy-twitter-button.png';
import { useExperienceTracking, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import { BackButton, CopiableBotEmail, Stationary, StepProps } from './common';

const CircleButton = styled.button`
    outline: none;
    border-radius: 36px;
    border: none;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export type FinishedFlowProps = StepProps & {
    onBack: () => void;
};

const TWITTER_URL =
    'https://twitter.com/intent/tweet?text=%22I%20just%20tried%20Spinach.io.%20It%20summarizes%20agile%20meetings%20and%20suggests%20to-dos%20and%20tickets.%20You%20have%20to%20try%20this%22';
const LINKEDIN_URL =
    'https://www.linkedin.com/feed/?shareActive&mini=true&text=I%20just%20tried%20Spinach.io.%20It%20gives%20you%20super%20accurate%20meeting%20summaries%20and%20suggests%20to-dos%20and%20tickets%20based%20on%20the%20discussion.%20You%20have%20to%20try%20it';

export function FinishedFlow({ onBack, direction, onSubmit, loadingMessage }: FinishedFlowProps): JSX.Element {
    const track = useExperienceTracking();
    useLandingAnalytic(ClientEventType.UserViewedFinishOnboardingStep);
    return (
        <Stationary>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>You're all set!</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 600, width: '100%' }}>Some things to remember:</BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <BodyBigOnboard>
                        💡 Ensure <CopiableBotEmail /> is on the calendar invite
                    </BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <BodyBigOnboard>💡 Allow Spinach into your video call from the waiting room</BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <BodyBigOnboard>
                        💡 Meeting summaries will be sent to all participants' inboxes once the meeting ends.
                    </BodyBigOnboard>
                    <Spacing factor={4} />
                    <BodyBigOnboard style={{ fontWeight: 600 }}>Help spread the word</BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <Row style={{ justifyContent: 'center' }}>
                        <CircleButton
                            style={{ outline: 'none', borderRadius: '32px' }}
                            onClick={() => {
                                track(ClientEventType.UserClickedShareSpinachOnSocial, {
                                    Network: 'Twitter',
                                });
                                URLUtil.openURL(TWITTER_URL, { redirectThroughBackendWhenOnZoom: true });
                            }}
                        >
                            <img src={TwitterSrc} style={{ width: '32px', height: '32px' }} />
                        </CircleButton>
                        <span style={{ display: 'flex', width: '20px' }} />
                        <CircleButton
                            style={{ outline: 'none', borderRadius: '32px' }}
                            onClick={() => {
                                track(ClientEventType.UserClickedShareSpinachOnSocial, {
                                    Network: 'LinkedIn',
                                });
                                URLUtil.openURL(LINKEDIN_URL, { redirectThroughBackendWhenOnZoom: true });
                            }}
                        >
                            <img src={LinkedInSrc} style={{ width: '32px', height: '32px' }} />
                        </CircleButton>
                    </Row>
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={2} />
            <Row centered>
                <PrimaryButton
                    title={'Go to Dashboard'}
                    onClick={onSubmit}
                    isLoading={!!loadingMessage}
                    loadingText={loadingMessage}
                />
            </Row>
        </Stationary>
    );
}
