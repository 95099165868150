import { useSearchParams } from 'react-router-dom';

import { ExperimentKey, WebUrlQuery } from '@spinach-shared/types';

import { WeeklySummaryExperiment } from './weekly-summary';

export function ExperimentRouter(): JSX.Element {
    const [searchParams] = useSearchParams();
    const experimentCode = searchParams.get(WebUrlQuery.Experiment) as ExperimentKey | null;

    if (!experimentCode) {
        return <></>;
    }

    switch (experimentCode) {
        case ExperimentKey.AISummary:
            return <WeeklySummaryExperiment />;
        default:
            return <></>;
    }
}
