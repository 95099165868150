import { MeetingSettingsSubview, OAuthProvider } from '@spinach-shared/types';

import { ReactComponent as TrelloLogo } from '../../../assets/trello-logo.svg';
import { useGlobalAuthedUser } from '../../../hooks';
import { useIntegrationRow } from '../../../hooks/useIntegrationRow';
import { AppIntegrationRow } from './AppIntegrationRow';

export function TrelloIntegrationRow(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { subtext, onClick, isConnected, buttonText } = useIntegrationRow(
        OAuthProvider.Trello,
        MeetingSettingsSubview.TrelloSettings,
        user.isAuthedForTrello,
        'Link Trello cards in your summaries.'
    );

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Trello'}
            icon={<TrelloLogo style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
        />
    );
}
