import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ClientEventType, ScribeType } from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import { getUser, postSeries, postUserSeriesAssociation } from '../../apis';
import { getStoredSeries } from '../../apis/getStoredSeries';
import { GlobalModal } from '../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useLocationalSeriesId,
    useSeriesReality,
} from '../../hooks';
import { ClientLogger, DEMO_PATH } from '../../utils';
import { FYI, FYIState } from './FYI';
import { SpinachMeeting } from './SpinachMeeting';

function useLoadSeriesOnMount() {
    const [user, setUser] = useGlobalAuthedUser();
    const locationalSeriesId = useLocationalSeriesId();
    const location = useLocation();
    const [, setSeries] = useGlobalNullableStoredSeries();
    const { routeToDashboard } = useGlobalRouting();

    async function loadDemoSeries() {
        const demoSeriesOnAccount = user.seriesMetadataList.find((sm) => isDemoSeries(sm.id));

        if (demoSeriesOnAccount) {
            const storedSeries = await getStoredSeries(demoSeriesOnAccount.id);

            setSeries(storedSeries);
        } else {
            const newDemoSeries = await postSeries({
                createdBy: user.spinachUserId,
                name: 'Practice Round',
                isDemo: true,
            });

            const userResponse = await getUser();

            if (userResponse.user) {
                setUser(userResponse.user);
                setSeries(newDemoSeries);
            }
        }
    }

    useEffect(() => {
        async function loadSeries(): Promise<void> {
            if (location.pathname.includes(DEMO_PATH)) {
                await loadDemoSeries();
            } else if (locationalSeriesId) {
                const fetchedSeries = await getStoredSeries(locationalSeriesId);

                if (fetchedSeries) {
                    setSeries(fetchedSeries);
                } else {
                    routeToDashboard({ replace: true });
                }
            }
        }

        loadSeries();
    }, [location.pathname]);
}

function useAssociateUserAndSeries(): boolean {
    const [isAssociating, setIsAssociating] = useState(false);
    const [, setSeries] = useGlobalNullableStoredSeries();
    const [user, setUser] = useGlobalAuthedUser();
    const { routeToDashboard } = useGlobalRouting();
    const locationalSeriesId = useLocationalSeriesId();
    const track = useExperienceTracking();

    useEffect(() => {
        async function addSeriesToUserAndRouteToLobby(seriesId: string) {
            try {
                await postUserSeriesAssociation({ seriesId });
            } catch (err) {
                ClientLogger.error('failed to associate user to series and vice versa', {
                    seriesId,
                    userId: user.spinachUserId,
                });
                routeToDashboard({ replace: true });
            }

            const userResponse = await getUser();
            const updatedSeries = await getStoredSeries(seriesId);

            if (!updatedSeries || !userResponse.user) {
                routeToDashboard({ replace: true });
                return;
            }

            track(ClientEventType.UserAssociatedWithSeries, {
                seriesId: updatedSeries.slug,
            });

            setUser(userResponse.user);
            setSeries(updatedSeries);

            setIsAssociating(false);
        }

        if (locationalSeriesId && !user.getSeriesById(locationalSeriesId)) {
            setIsAssociating(true);
            addSeriesToUserAndRouteToLobby(locationalSeriesId);
        }
    }, []);

    return isAssociating;
}

function useClearGlobalModal() {
    const location = useLocation();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { isRealSeries, isDemoSeries } = useSeriesReality();

    useEffect(() => {
        if (isRealSeries) {
            if (globalModal === GlobalModal.ConfirmLeaveDemo) {
                setGlobalModal(null);
            }
        } else if (isDemoSeries) {
            if (globalModal === GlobalModal.ShareSeries) {
                setGlobalModal(null);
            }
        }
    }, [location.pathname]);
}

export function SpinachMeetingValidator(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const { routeToDashboard, routeToScribeSeriesExperience } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    const location = useLocation();

    useLoadSeriesOnMount();
    useClearGlobalModal();
    const isAssociating = useAssociateUserAndSeries();

    if (!series || isAssociating) {
        return <FYI state={FYIState.Loading} />;
    }

    const seriesMetadata = user.getSeriesById(series.id);

    if (!seriesMetadata) {
        routeToDashboard({ replace: true });
        return <></>;
    }

    if (series.metadata?.scribeMetadata?.scribeType === ScribeType.Standup) {
        routeToScribeSeriesExperience(series.id);
        return <></>;
    }

    return (
        <SpinachMeeting
            seriesMetadata={seriesMetadata}
            // key is used to remount spinachMeeting if changes from demo to real series
            // TODO: do we still want this?
            key={location.pathname}
        />
    );
}
