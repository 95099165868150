import { NavigateOptions, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { MeetingSettingsSubview, WebUrlQuery } from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import { ClientPath } from '../components';
import { AIPaths } from '../components/spinach-ai/SpinachAIRoutes';
import { useGlobalMeetingSettings } from './useGlobalMeetingSettings';
import { useLocationalSeriesId } from './useGlobalSearchParams';
import { useGlobalAuthedUser } from './useGlobalUser';

export type GlobalRouting = {
    routeToScribeSeriesExperience: (seriesId: string, navigateOptions?: NavigateOptions) => void;
    routeToScribeSlackSettings: (navigateOptions?: NavigateOptions) => void;
    routeToScribeOnboarding: (navigateOptions?: NavigateOptions) => void;
    routeToScribeUserSettings: (navigateOptions?: NavigateOptions) => void;
    routeToOnboarding: (navigateOptions?: NavigateOptions) => void;
    routeToSeriesExperience: (seriesId: string, navigateOptions?: NavigateOptions) => void;
    routeToDashboard: (navigateOptions?: NavigateOptions) => void;
    routeToDirectExperience: (navigateOptions?: NavigateOptions) => void;
    routeToDemoExperience: (navigateOptions?: NavigateOptions) => void;
    routeToSignUpExperience: (navigateOptions?: NavigateOptions) => void;
    routeToFirstSeriesFlow: (navigateOptions?: NavigateOptions) => void;
    routeToExperiment: (navigateOptions?: NavigateOptions) => void;
    routeToVerify: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
    routeToVerifyGoogleCode: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
};

/**
 *
 * @returns returns thin wrappers around react router navigate calls
 */
export function useGlobalRouting(): GlobalRouting {
    const navigate = useNavigate();
    const location = useLocation();
    const { setSubview } = useGlobalMeetingSettings();
    const [searchParams] = useSearchParams();
    const seriesIdToRoute = useLocationalSeriesId();
    const [user] = useGlobalAuthedUser();

    let search = searchParams.toString();

    function routeToOnboarding(navigateOptions: NavigateOptions = {}) {
        navigate(
            {
                pathname: ClientPath.Onboarding,
                search,
            },
            navigateOptions
        );
    }

    function routeToFirstSeriesFlow(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.CreateSeriesFlow, search }, navigateOptions);
    }

    function routeToSeriesExperience(seriesId: string, navigateOptions: NavigateOptions = {}) {
        if (!isDemoSeries(seriesId)) {
            navigate({ pathname: `${ClientPath.Meeting}/${seriesId}`, search }, navigateOptions);
        } else {
            navigate({ pathname: ClientPath.Demo, search }, navigateOptions);
        }
    }

    function routeToDashboard(navigateOptions: NavigateOptions = {}) {
        if (location.pathname !== ClientPath.Root) {
            navigate(
                { pathname: ClientPath.Root, search },
                {
                    ...navigateOptions,
                }
            );
        }
    }

    function routeToDirectExperience(navigateOptions: NavigateOptions = {}) {
        const onlySeriesId = user.getSeriesIdOfOnlySeries();

        if (seriesIdToRoute) {
            /**
             * Clean up legacy or deeplink search params
             */
            if (searchParams.get(WebUrlQuery.Secret)) {
                searchParams.delete(WebUrlQuery.Secret);
                search = searchParams.toString();
            }

            if (searchParams.get(WebUrlQuery.Where)) {
                searchParams.delete(WebUrlQuery.Where);
                search = searchParams.toString();
            }

            if (searchParams.get(WebUrlQuery.Meeting)) {
                searchParams.delete(WebUrlQuery.Meeting);
                search = searchParams.toString();
            }

            navigate(
                { pathname: `${ClientPath.Meeting}/${seriesIdToRoute}`, search },
                {
                    ...navigateOptions,
                    replace: true,
                }
            );
        } else if (onlySeriesId) {
            navigate(
                { pathname: `${ClientPath.Meeting}/${onlySeriesId}`, search },
                {
                    ...navigateOptions,
                }
            );
        } else {
            routeToDashboard(navigateOptions);
        }
    }

    function routeToDemoExperience(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.Demo, search }, navigateOptions);
    }

    function routeToSignUpExperience(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.SignUp, search }, navigateOptions);
    }

    /**
     *  Redirects user to verify page and replaces history by default.
     */
    function routeToVerify(navigateOptions: NavigateOptions = {}, searchOpts?: URLSearchParams) {
        if (searchOpts) {
            search = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(searchOpts),
            }).toString();
        } else {
            search = searchParams.toString();
        }

        navigate(
            {
                pathname: ClientPath.Verify,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }
    function routeToVerifyGoogleCode(navigateOptions: NavigateOptions = {}, searchOpts?: URLSearchParams) {
        if (searchOpts) {
            search = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(searchOpts),
            }).toString();
        } else {
            search = searchParams.toString();
        }

        navigate(
            {
                pathname: ClientPath.VerifyGoogleCode,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToExperiment(navigateOptions: NavigateOptions = {}) {
        navigate(
            {
                pathname: ClientPath.Experiment,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToScribeSlackSettings(navigateOptions: NavigateOptions = {}) {
        const seriesId = searchParams.get(WebUrlQuery.Meeting);
        routeToScribeSeriesExperience(seriesId!, navigateOptions, { subview: MeetingSettingsSubview.SlackSettings });
    }

    function routeToScribeOnboarding(navigateOptions: NavigateOptions = {}) {
        navigate(
            {
                pathname: AIPaths.Onboarding,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToScribeUserSettings(navigateOptions: NavigateOptions = {}) {
        navigate(
            {
                pathname: ClientPath.AIUserSettingsPath,
                search, // clear out any query params if this is some flow
            },
            {
                ...navigateOptions,
            }
        );
    }

    function routeToScribeSeriesExperience(
        seriesId: string,
        navigateOptions: NavigateOptions = {},
        options: { subview?: MeetingSettingsSubview } = {}
    ) {
        if (options.subview && Object.values(MeetingSettingsSubview).includes(options.subview)) {
            setSubview(options.subview);
        }

        navigate(
            {
                pathname: ClientPath.AISeriesSettingsPath.replace(':seriesId', seriesId),
                search: '',
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    return {
        routeToDashboard,
        routeToOnboarding,
        routeToFirstSeriesFlow,
        routeToSeriesExperience,
        routeToDirectExperience,
        routeToDemoExperience,
        routeToSignUpExperience,
        routeToVerify,
        routeToVerifyGoogleCode,
        routeToExperiment,
        routeToScribeSlackSettings,
        routeToScribeSeriesExperience,
        routeToScribeUserSettings,
        routeToScribeOnboarding,
    };
}
