import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, IClientUser, IntegrationCode, PlatformSource, WebUrlQuery } from '@spinach-shared/types';
import { getLowercaseDomainFromEmail } from '@spinach-shared/utils';

import { patchUser } from '../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../hooks';
import { useLocationalSeriesId } from '../../hooks/useGlobalSearchParams';
import { SetValue } from '../../types';
import { isZoomPlatform, minimumLoadingDuration } from '../../utils';
import { FYI, FYIState } from '../common';
import { IntegrationSelection } from './IntegrationSelection';
import { PersonalAndCompany } from './PersonalAndCompany';

export enum OnboardingStep {
    Personal,
    IntegrationSelection,
}

type UseExistingOnboardingProgressProps = {
    onboardingStep: OnboardingStep;
    loadingMessage: string;
    setOnboardingStep: SetValue<OnboardingStep>;
    setLoadingMessage: SetValue<string>;
};

function useExistingOnboardingProgress(user: ClientUser): UseExistingOnboardingProgressProps {
    const [onboardingStep, setOnboardingStep] = useState(OnboardingStep.Personal);
    const [loadingMessage, setLoadingMessage] = useState('');

    useEffect(() => {
        if (!user.firstName || !user.lastName || !user.companyName || !user.howDidYouHear) {
            setOnboardingStep(OnboardingStep.Personal);
        } else if (!user.integrationsVideo || !user.integrationsMessaging || !user.integrationSettings) {
            setOnboardingStep(OnboardingStep.IntegrationSelection);
        }
    }, []);

    return {
        onboardingStep,
        setOnboardingStep,
        loadingMessage,
        setLoadingMessage,
    };
}

export function OnboardingRouter(): JSX.Element {
    const { routeToDirectExperience } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();
    const deepLinkedSeriesId = useLocationalSeriesId();
    const [params] = useSearchParams();

    const track = useExperienceTracking();

    const { onboardingStep, loadingMessage, setOnboardingStep, setLoadingMessage } =
        useExistingOnboardingProgress(user);

    const [firstName, setFirstName] = useState(user.shouldAuthBeforeDemo ? '' : user.firstName);
    const [lastName, setLastName] = useState(user.shouldAuthBeforeDemo ? '' : user.lastName);
    const [companyName, setCompanyName] = useState(user.companyName);
    const [howDidYouHear, setHowDidYouHear] = useState(user.howDidYouHear);
    const [howDidYouHearOther, setHowDidYouHearOther] = useState(user.howDidYouHearOther ?? '');
    const initialVideo = isZoomPlatform() ? IntegrationCode.Zoom : user.integrationsVideo;
    const [integrationsVideo, setIntegrationsVideo] = useState(initialVideo);
    const [integrationsMessaging, setIntegrationsMessaging] = useState(user.integrationsMessaging);
    const [integrationsProjectMgmt, setIntegrationsProjectMgmt] = useState(user.integrationsProjectMgmt);
    const [integrationsMessagingOther, setMessagingOther] = useState('');
    const [integrationsVideoOther, setVideoOther] = useState('');
    const [integrationsProjectMgmtOther, setProjectMgmtOther] = useState('');

    const onPersonalViewSubmit = async () => {
        setLoadingMessage(`Great to meet you, ${firstName}!`);

        const isJoiningSeriesUponOnboarding = !!deepLinkedSeriesId || !!user.realSeries.length;
        const platformSource = params.get(WebUrlQuery.PlatformSource) as PlatformSource | undefined;
        const updatedUserMetadata: Partial<IClientUser> = {
            metadata: {
                firstName,
                lastName,
                preferredName: `${firstName} ${lastName}`,
                companyName,
                howDidYouHear,
                howDidYouHearOther,
                isJoiningSeriesUponOnboarding,
                platformSource,
            },
        };

        const [updatedUserResponse] = await Promise.all([patchUser(updatedUserMetadata), minimumLoadingDuration()]);

        if (updatedUserResponse.user) {
            const updatedUser = new ClientUser(updatedUserResponse.user);

            if (!isJoiningSeriesUponOnboarding && user.metadata.isFirstUserInACompanyAssociation) {
                track(ClientEventType.NewCompanyAssociation, {
                    Domain: getLowercaseDomainFromEmail(user.email ?? ''),
                    CompanyName: updatedUser.metadata.companyName,
                });
            }

            track(ClientEventType.PersonalPageSubmitted, {
                ...updatedUser.toUserIdentityPayload(),
                PlatformSource: platformSource,
            });

            setUser(updatedUserResponse.user);
        } else {
            // send back to web auth flow
            setUser(null as unknown as IClientUser);
        }
        setOnboardingStep(OnboardingStep.IntegrationSelection);

        setLoadingMessage('');
    };

    const onIntegrationsViewSubmit = async () => {
        setLoadingMessage('');

        const isJoiningSeriesUponOnboarding = !!deepLinkedSeriesId || !!user.seriesMetadataList.length;
        const updatedUserMetadata: Partial<IClientUser> = {
            metadata: {
                integrationsMessaging: integrationsMessaging as IntegrationCode,
                integrationsMessagingOther: integrationsMessagingOther,
                integrationsVideo: integrationsVideo as IntegrationCode,
                integrationsVideoOther: integrationsVideoOther,
                integrationsProjectMgmt: integrationsProjectMgmt as IntegrationCode[],
                integrationsProjectMgmtOther: integrationsProjectMgmtOther,
                isOnboarded: true,
            },
        };

        const [updatedUserResponse] = await Promise.all([patchUser(updatedUserMetadata), minimumLoadingDuration()]);

        if (updatedUserResponse.user) {
            const updatedUser = new ClientUser(updatedUserResponse.user);

            track(ClientEventType.IntegrationPageSubmitted, {
                IntegrationsMessaging: integrationsMessaging,
                IntegrationsMessagingOther: integrationsMessagingOther,
                IntegrationsVideo: integrationsVideo,
                IntegrationsVideoOther: integrationsVideoOther,
                IntegrationsProjectMgmt: integrationsProjectMgmt.join(', '),
                IntegrationsProjectMgmtOther: integrationsProjectMgmtOther,
            });

            track(ClientEventType.OnboardingCompleted, {
                ...updatedUser.toUserIdentityPayload(),
                IsJoiningSeries: isJoiningSeriesUponOnboarding,
            });

            setUser(updatedUserResponse.user);

            routeToDirectExperience({ replace: true });
        } else {
            // send back to web auth flow
            setUser(null as unknown as IClientUser);
        }

        setLoadingMessage('');
    };

    if (loadingMessage) {
        return <FYI state={FYIState.Loading} header={loadingMessage} />;
    }

    switch (onboardingStep) {
        case OnboardingStep.Personal:
            return (
                <PersonalAndCompany
                    user={user}
                    firstName={firstName}
                    lastName={lastName}
                    companyName={companyName}
                    howDidYouHear={howDidYouHear}
                    howDidYouHearOther={howDidYouHearOther}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setCompanyName={setCompanyName}
                    setHowDidYouHear={setHowDidYouHear}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    onSubmit={onPersonalViewSubmit}
                />
            );
        case OnboardingStep.IntegrationSelection:
            return (
                <IntegrationSelection
                    integrationsVideo={integrationsVideo}
                    setIntegrationsVideo={setIntegrationsVideo}
                    integrationsMessaging={integrationsMessaging}
                    setIntegrationsMessaging={setIntegrationsMessaging}
                    integrationsProjectMgmt={integrationsProjectMgmt}
                    setIntegrationsProjectMgmt={setIntegrationsProjectMgmt}
                    onSubmit={onIntegrationsViewSubmit}
                    integrationsMessagingOther={integrationsMessagingOther}
                    integrationsVideoOther={integrationsVideoOther}
                    integrationsProjectMgmtOther={integrationsProjectMgmtOther}
                    setMessagingOther={setMessagingOther}
                    setVideoOther={setVideoOther}
                    setProjectMgmtOther={setProjectMgmtOther}
                />
            );
    }
}
