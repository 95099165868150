import { useEffect, useState } from 'react';

export type UseButtonCooldownsProps = {
    isNavigationDisabled: boolean;
    bypassCooldown: () => void;
    cooldown: number;
};

const BUTTON_COOLDOWN_MS = 1250;

export function useButtonCooldowns(itemId?: string): UseButtonCooldownsProps {
    const [didUserClick, setDidUserClick] = useState(false);
    const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (!didUserClick) {
            setIsNavigationDisabled(true);

            timer = setTimeout(() => {
                setIsNavigationDisabled(false);
            }, BUTTON_COOLDOWN_MS);
        }

        setDidUserClick(false);

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [itemId]);

    return {
        isNavigationDisabled,
        bypassCooldown: () => setDidUserClick(true),
        cooldown: BUTTON_COOLDOWN_MS,
    };
}
