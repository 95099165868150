import { atom } from 'recoil';

export type GlobalSlackState = {
    installUrl: string;
    shouldSendSummaryOnChannelConnect: boolean;
};

/**
 * Created this atom so that we can access the install URL wherever we need,
 * without needing to re-fetch the generated URL at time of need.
 */

export const atomSlack = atom<GlobalSlackState>({
    key: 'slackState',
    default: {
        installUrl: '',
        shouldSendSummaryOnChannelConnect: false,
    },
});
