import { useEffect, useState } from 'react';

import { SetValue } from '../types';

export function useCountdownTimer(
    shouldStartCountdownTimer: boolean,
    countdownTime: number = 30
): [secondsLeftUntilRestart: number, setSecondsLeftUntilRestart: SetValue<number>] {
    const [timerRef, setTimerRef] = useState<NodeJS.Timeout | null>(null);
    const [secondsLeftUntilRestart, setSecondsLeftUntilRestart] = useState<number>(countdownTime);
    useEffect(() => {
        if (shouldStartCountdownTimer) {
            if (secondsLeftUntilRestart > 0) {
                setTimerRef(setInterval(() => setSecondsLeftUntilRestart(secondsLeftUntilRestart - 1), 1000));
            }
        } else {
            setSecondsLeftUntilRestart(countdownTime);
            if (timerRef) {
                clearTimeout(timerRef);
            }
        }

        return () => {
            if (timerRef) {
                clearTimeout(timerRef);
            }
        };
    }, [shouldStartCountdownTimer, secondsLeftUntilRestart]);

    return [secondsLeftUntilRestart, setSecondsLeftUntilRestart];
}
