import { CheckCircle } from '@material-ui/icons';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useEffect, useState } from 'react';

import { ClientEventType, FeatureDiscoveryKey } from '@spinach-shared/types';
import { getSharedLink } from '@spinach-shared/utils';

import { ReactComponent as ClickButton } from '../../../assets/feature-hint-click.svg';
import InviteUserSrc from '../../../assets/invite-user-icon.png';
import { GlobalModal } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalStoredSeries,
    useInviteViaSlackEnablement,
    useLandingAnalytic,
} from '../../../hooks';
import { BodyRegular } from '../../../styles';
import { copyTextToClipboard } from '../../../utils';
import { FeatureCallout, FeatureCalloutClickProps, UseCustomCalloutResult } from './FeatureCallout';

export function useInviteSecondUserCallout(): UseCustomCalloutResult {
    const track = useExperienceTracking();
    const [, setModal] = useGlobalModal();
    const [storedSeries] = useGlobalStoredSeries();
    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();
    const [user] = useGlobalAuthedUser();

    const shouldShowInviteViaSlackModal =
        isInviteViaSlackEnabled &&
        !user.isAuthedForSlack &&
        storedSeries.userMetadataList.length === 1 &&
        user.selectedSlackAsMessagingIntegraton;

    const isVisible = storedSeries.userMetadataList.length === 1;

    async function onEngageClick() {
        track(ClientEventType.InviteSecondUserCalloutEngageClick);
        if (shouldShowInviteViaSlackModal) {
            setModal(GlobalModal.InviteViaSlack);
        } else {
            setModal(GlobalModal.ShareSeries);
        }
    }

    async function onCloseClick() {}

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: FeatureDiscoveryKey.SecondUserInvite,
    };
}

export function InviteSecondUserFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    useLandingAnalytic(ClientEventType.InviteSecondUserCalloutDisplayed);
    const [storedSeries] = useGlobalStoredSeries();
    const [user] = useGlobalAuthedUser();

    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();

    const shouldShowInviteViaSlackModal =
        isInviteViaSlackEnabled &&
        storedSeries.userMetadataList.length === 1 &&
        user.selectedSlackAsMessagingIntegraton;

    const [isConfirming, setIsConfirming] = useState(false);
    useEffect(() => {
        if (isConfirming) {
            setTimeout(() => {
                setIsConfirming(false);
            }, 3000);
        }
    }, [isConfirming]);

    return (
        <FeatureCallout
            icon={<img src={InviteUserSrc} style={{ height: '32px', width: '32px' }} />}
            title={'Try Spinach with a teammate'}
            subtitle={
                !shouldShowInviteViaSlackModal ? (
                    <BodyRegular style={{ display: 'flex', alignItems: 'center' }}>
                        Copy and share the link to your new space{' '}
                        <span
                            onClick={() => {
                                setIsConfirming(true);
                                const url = getSharedLink(storedSeries.slug);
                                copyTextToClipboard(url);
                            }}
                        >
                            {isConfirming ? (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircle fontSize="small" style={{ marginLeft: '5px', marginRight: '5px' }} />
                                    Copied!
                                </span>
                            ) : (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <FileCopyOutlinedIcon
                                        fontSize="small"
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </span>
                            )}
                        </span>
                    </BodyRegular>
                ) : (
                    <BodyRegular style={{ display: 'flex', alignItems: 'center' }}>
                        Invite your team to start using Spinach
                    </BodyRegular>
                )
            }
            isCloseable={false}
            ctaIcon={<ClickButton />}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
        />
    );
}
