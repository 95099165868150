import { Tooltip, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    ClientExperienceEventPayload,
    SpinachUpdateType,
    Ticket,
    TypedUpdate,
} from '@spinach-shared/types';

import { postExperienceEvent } from '../../apis';
import { ElementId } from '../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGoogleCalendarEnablement,
    useSeriesReality,
    useWindowSize,
} from '../../hooks';
import { BodyLarge, BodySubtitle, lightTheme } from '../../styles';
import { Column, Hairline, Row, Spacing } from '../common';
import { JiraPickerModalWithAssignment } from '../input/jira';
import { JiraDetailsModal } from '../input/jira/JiraDetailsModal';
import { PreviousUpdateSectionInputWithRollover } from '../input/jira/PreviousUpdateSectionInputWithRollover';
import { LiveUpdateViewProps } from './LiveUpdateSection';

type PreviousUpdateSectionProps = {
    updateViewProps: LiveUpdateViewProps;
    meetingId?: string;
};

const ColumnAlignStart = styled(Column)`
    text-align: start;
`;

const Container = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: '130px',
        fontSize: '12px',
    },
    tooltipPlacementTop: {
        marginBottom: 5,
    },
}));

export function PreviousUpdatesSection({ updateViewProps, meetingId }: PreviousUpdateSectionProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);
    const [issueData] = useState<Ticket | null>(null);

    const [showMoreJiraTickets, setShowMoreJiraTickets] = useState(false);

    const isGoogleCalendarIntegrationEnabled = useGoogleCalendarEnablement();

    const shouldShowCalendarUpdateSection =
        isGoogleCalendarIntegrationEnabled && user.integrationSettings?.googleSettings?.isAuthed;

    const enabledReservedTypes = liveSeries.enabledReservedRoundtableLists.map((c) => c.reservedType);

    const enabledCustomLists = liveSeries.enabledCustomRoundtableLists;

    const rolledYesterdays: TypedUpdate[] = enabledReservedTypes.includes(SpinachUpdateType.Today)
        ? updateViewProps.rolloverProps?.history?.mostRecentTodays ?? []
        : [];

    const rolledBlockers: TypedUpdate[] = enabledReservedTypes.includes(SpinachUpdateType.Challenge)
        ? updateViewProps.rolloverProps?.history?.mostRecentBlockers ?? []
        : [];

    const rolledCustomUpdates: TypedUpdate[] =
        updateViewProps.rolloverProps?.history?.getMostRecentCustom(enabledCustomLists) ?? [];

    const shouldShowPreviousUpdateSection =
        rolledYesterdays.length || rolledBlockers.length || rolledCustomUpdates.length;

    const tooltipClasses = useTooltipStyles();
    const { width } = useWindowSize();
    const track = useExperienceTracking();
    const { isDemoSeries } = useSeriesReality();

    useEffect(() => {
        if (shouldShowPreviousUpdateSection) {
            const payload: ClientExperienceEventPayload = {
                ...user.toUserIdentityPayload(),
                MeetingID: meetingId,
                NumberOfPreviousUpdates: rolledBlockers.length + rolledYesterdays.length,
                NumberOfPreviousYesterdays: rolledYesterdays.length,
                NumberOfPreviousBlockers: rolledBlockers.length,
            };
            postExperienceEvent({ eventType: ClientEventType.ContextItemsShown, payload });
        }
    }, []);

    const buildPreviousUpdateSection = (typedUpdates: TypedUpdate[], title?: string) => {
        return !typedUpdates.length ? (
            <></>
        ) : (
            <>
                {title ? (
                    <Row>
                        <BodySubtitle color={lightTheme.tertiary.midnight} fontWeight={'bold'}>
                            {title}
                        </BodySubtitle>
                    </Row>
                ) : (
                    <></>
                )}

                {typedUpdates.map((typedUpdate) => {
                    /** @TODO Should asanaData be here? */
                    return (
                        <PreviousUpdateSectionInputWithRollover
                            createUpdateEmitter={updateViewProps.createUpdateEmitter}
                            meetingId={meetingId}
                            onConfirmation={updateViewProps.rolloverProps?.onToggleConfirmation}
                            typedUpdate={{ ...typedUpdate, reactions: undefined }}
                            key={typedUpdate.id}
                        />
                    );
                })}
            </>
        );
    };

    const PreviousUpdateYesterdayComponents = buildPreviousUpdateSection(rolledYesterdays);
    const PreviousUpdateBlockerComponents = buildPreviousUpdateSection(rolledBlockers);
    const PreviousCustomUpdateComponents = !rolledCustomUpdates.length ? (
        <></>
    ) : (
        <>
            <Spacing factor={0.25} />
            {enabledCustomLists
                .filter((l) => rolledCustomUpdates.filter((u) => u.customListId === l.id).length)
                .map((list) => {
                    const customUpdatesForList = rolledCustomUpdates.filter((u) => u.customListId === list.id);
                    return buildPreviousUpdateSection(customUpdatesForList, list.title);
                })}
        </>
    );

    if (!shouldShowPreviousUpdateSection) {
        return <></>;
    }

    return (
        <Container id={ElementId.PreviousCheckinContainer}>
            <Row id="previous-checkin-row">
                <ColumnAlignStart>
                    <Row style={{ alignItems: 'center' }}>
                        <BodyLarge style={{ paddingBottom: '5px', fontWeight: 'bold', fontSize: '10px' }}>
                            Previous check-in
                        </BodyLarge>
                        {isDemoSeries ? (
                            <Tooltip
                                classes={tooltipClasses}
                                title="Add any previous updates to today with one click. No starting over!"
                                arrow={true}
                                placement="top"
                                onClose={() => {
                                    track(ClientEventType.DemoTooltipHover, { Location: 'Previous check-in' });
                                }}
                            >
                                <InfoOutlinedIcon
                                    style={{
                                        marginLeft: '5px',
                                        marginBottom: '1px',
                                        fontSize: width > 400 ? '22px' : '16px',
                                    }}
                                    htmlColor={lightTheme.primary.orangeDark}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Row>
                </ColumnAlignStart>
            </Row>

            {PreviousUpdateYesterdayComponents}

            {PreviousUpdateBlockerComponents}

            {PreviousCustomUpdateComponents}
            <Hairline />
            {issueData && (
                <JiraDetailsModal
                    onClose={() => setIsDetailedModalOpen(!isDetailedModalOpen)}
                    issueData={issueData}
                    isOpen={isDetailedModalOpen}
                />
            )}
            <JiraPickerModalWithAssignment
                onConfirmation={updateViewProps.rolloverProps?.onToggleConfirmation}
                createUpdateEmitter={updateViewProps.createUpdateEmitter}
                isModalOpen={showMoreJiraTickets}
                onClose={() => {
                    setShowMoreJiraTickets(false);
                }}
            />
        </Container>
    );
}
