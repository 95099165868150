import { SlackTeamType, SpinachAPIPath, StoredSeries, UpsertSlackChannelRequest } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postSlackChannel(
    teamId: string,
    teamType: SlackTeamType,
    channelId: string,
    channelName: string,
    seriesId: string
): Promise<StoredSeries | null> {
    try {
        const request: UpsertSlackChannelRequest = {
            seriesId,
            channelName,
            channelId,
            teamId,
            teamType,
        };

        const updatedSeries = await postSpinachAPI<StoredSeries | null>(SpinachAPIPath.SlackChannelSelection, request);

        return updatedSeries || null;
    } catch (error) {
        return null;
    }
}
