import { SpinachIntegration } from './Integration';
import { DetailedTicket } from './Jira';

export enum TicketSource {
    Jira = 'jira',
    Asana = 'asana',
    Linear = 'linear',
    ClickUp = 'clickup',
    Trello = 'trello',
}

export type ClickUpDetailedTicket = DetailedTicket & {
    assigneeEmails: string[];
    creatorEmail: string;
};

export type TicketIntegration = Extract<
    SpinachIntegration,
    | SpinachIntegration.Asana
    | SpinachIntegration.Clickup
    | SpinachIntegration.Jira
    | SpinachIntegration.Linear
    | SpinachIntegration.Trello
>;
