import { GetStoredSeriesResponse, SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getStoredSeries(seriesId: string): Promise<StoredSeries | undefined> {
    try {
        const response = await getSpinachAPI<GetStoredSeriesResponse>(`${SpinachAPIPath.Series}/${seriesId}`);
        return response?.storedSeries;
    } catch (err) {
        return undefined;
    }
}
