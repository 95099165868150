import { useCallback, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { teamTopicsUpdateSectionTypeProps } from '@spinach-shared/constants';
import { ActiveAgendaProps, SpinachSeriesProps, YTBUpdateProps } from '@spinach-shared/models';
import { AgendaItemSource, AuthoredUpdate, TypedUpdate } from '@spinach-shared/types';

import { usePrevious } from '../../hooks';
import { useLiveUpdateCheckInSection } from '../../hooks/useLiveUpdateCheckInSection';
import { BodyRegular } from '../../styles/typography';
import { SetValue } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { SixDot } from '../common';
import { TeamTopicsAuthorIndicator } from '../common/TeamTopicsAuthorIndicator';
import Draggable from './Draggable';
import { LiveUpdateViewProps } from './LiveUpdateSection';
import { TeamTopicsUpdateSection } from './TeamTopicsUpdateSection';

const Container = styled.div`
    width: calc(100% - 15px);
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    margin-left: 15px;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    position: relative;
    margin-left: -15px;
`;

const TeamTopicDetails = styled.div`
    display: flex;
    align-items: flex-start;
    height: 100%;
    /* padding-left: 15px; */
    flex-direction: row;
    max-width: 75%;
    word-wrap: anywhere;
    justify-content: space-between;
`;

const Name = styled.div`
    flex-grow: 1;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.primary.midnight};
`;

type TeamStatusProps = {
    series: SpinachSeriesProps;
    socket: Socket;
    createAgendaReorderEmitter: (id: string) => (moveIndex: number) => void;
    isDisabled?: boolean;
};

type ParticipantProps = {
    t: AuthoredUpdate;
    dragRef?: any;
    isMouseIn?: boolean;
};

function TeamTopicStatus({ series, socket }: TeamStatusProps): JSX.Element {
    const [statefulTeamTopics, setStatefulTeamTopics] = useState(series.agenda.topicUpdates);

    const { createReorderEmitter, createFullUpdateEmitter, createUpdateEmitter } = useLiveUpdateCheckInSection(
        series.thisParticipantsYTBAgendaItem,
        socket,
        series.slug,
        series.currentMeeting.agenda
    );

    const userAgendaItems = series.agenda.YTBItems.filter((item) => item.isParticipantAgendaItem !== false);
    const updates = userAgendaItems.map((item) => item.standUpUpdate.updates).flat();
    const standUpUpdate = new YTBUpdateProps({ updates });

    const liveUpdateViewProps: LiveUpdateViewProps = {
        standUpUpdate: standUpUpdate,
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
    };

    const previousTopicUpdates = usePrevious(series.agenda.topicUpdates);
    useEffect(() => {
        if (JSON.stringify(previousTopicUpdates) !== JSON.stringify(series.agenda.topicUpdates)) {
            setStatefulTeamTopics(series.agenda.topicUpdates);
        }
    }, [series.agenda.items.filter((item) => item.source === AgendaItemSource.NewTopic)]);

    const moveDraggable = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const reorderedAgendaItems = statefulTeamTopics.map((t) => t);
            reorderedAgendaItems.splice(dragIndex, 1);
            reorderedAgendaItems.splice(hoverIndex, 0, statefulTeamTopics[dragIndex]);
            setStatefulTeamTopics(reorderedAgendaItems);
        },
        [statefulTeamTopics]
    );

    const isDraggable = !!createReorderEmitter && statefulTeamTopics.length > 1;

    return (
        <Container id="team-topics-container">
            {!statefulTeamTopics.length && (
                <BodyRegular style={{ display: 'flex', alignContent: 'start' }}>No updates today</BodyRegular>
            )}
            {statefulTeamTopics.map((typedUpdate, i) => {
                if (typedUpdate) {
                    const emitOrderedUpdatesSave = createReorderEmitter?.(typedUpdate);
                    return (
                        <Draggable
                            key={typedUpdate.id}
                            index={i}
                            id={typedUpdate.id}
                            moveDraggable={moveDraggable}
                            isDraggable={isDraggable}
                            emitOrderedUpdatesSave={emitOrderedUpdatesSave}
                            type={`${typedUpdate.updateType}`}
                            idList={statefulTeamTopics.map((t) => t.id)}
                        >
                            <TeamTopicRow {...{ t: typedUpdate }} />
                        </Draggable>
                    );
                }
            })}
        </Container>
    );
}

const DragHandle = styled.span`
    width: 18px;
    height: 18px;
`;

const LeftDecorContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: flex-end;
    margin-right: 5px;
`;

function TeamTopicRow({ t, dragRef, isMouseIn }: ParticipantProps) {
    return (
        <Row style={{ position: 'relative' }}>
            <TeamTopicDetails key={t.id}>
                <LeftDecorContainer>
                    {isMouseIn && dragRef ? (
                        <DragHandle ref={dragRef}>
                            <SixDot />
                        </DragHandle>
                    ) : (
                        <TeamTopicsAuthorIndicator name={t.author} />
                    )}
                </LeftDecorContainer>

                <Name className={withFullStoryMasking()}>{t.text}</Name>
            </TeamTopicDetails>
        </Row>
    );
}

export default TeamTopicStatus;
