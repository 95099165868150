import { useEffect, useState } from 'react';

import { ClientEventType, DemoVersion } from '@spinach-shared/types';

import { getUser, postExperienceEvent, postSeries } from '../../../apis';
import { DemoModal } from '../../../atoms';
import {
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
} from '../../../hooks';
import { Direction, ViewContainer } from '../../common';
import { RequestAndRedirect } from './RequestAndRedirectView';
import { StandupName } from './StandupNameView';
import { EmptySeriesStep } from './types';

function FirstSeriesFlow(): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const { routeToSeriesExperience, routeToDemoExperience } = useGlobalRouting();
    const [step, setStep] = useState(EmptySeriesStep.StandupName);
    const [, setSeries] = useGlobalNullableStoredSeries();
    const [standupName, setStandupName] = useState(`${user.firstName}'s Standup`);
    const [slidingDirection, setSlidingDirection] = useState<Direction>(Direction.Forward);
    const [isLoading, setIsLoading] = useState(false);
    const { demoState, setDemoState } = useGlobalDemoState();

    switch (step) {
        case EmptySeriesStep.StandupName:
            return (
                <StandupName
                    user={user}
                    isLoading={isLoading}
                    standupName={standupName}
                    setStandupName={setStandupName}
                    onBack={() => {
                        setStep(EmptySeriesStep.RequestAndRedirect);
                        setSlidingDirection(Direction.Forward);
                    }}
                    onSubmit={async () => {
                        if (user.metadata.demoVersion === DemoVersion.Persona) {
                            setDemoState({
                                ...demoState,
                                demoModal: DemoModal.EnteringPersonaDemoFromOnboarding,
                            });
                        }

                        postExperienceEvent({
                            eventType: ClientEventType.UserSubmittedStandupName,
                            payload: user.toUserIdentityPayload(),
                        });

                        setIsLoading(true);

                        const series = await postSeries({
                            createdBy: user.spinachUserId,
                            name: standupName.trim(),
                        });

                        const userResponse = await getUser();

                        setSeries(series);

                        if (userResponse.user) {
                            setUser(userResponse.user);
                        }

                        if (series && userResponse.user?.metadata?.isAnonymousUser === false) {
                            postExperienceEvent({
                                eventType: ClientEventType.UserSubmittedCelebrateView,
                                payload: user.toUserIdentityPayload(),
                            });

                            routeToSeriesExperience(series.slug, { replace: true });
                        } else if (
                            userResponse.user &&
                            userResponse.user.metadata?.isJoiningSeriesUponOnboarding === false &&
                            userResponse.user.metadata.practiceRoundsComplete === 0
                        ) {
                            routeToDemoExperience({ replace: true });
                        } else {
                            if (series) {
                                routeToSeriesExperience(series.slug, { replace: true });
                            }
                            setIsLoading(false);
                        }
                    }}
                    direction={slidingDirection}
                />
            );
        case EmptySeriesStep.RequestAndRedirect:
            return (
                <RequestAndRedirect
                    user={user}
                    direction={slidingDirection}
                    onBack={() => {
                        setStep(EmptySeriesStep.StandupName);
                        setSlidingDirection(Direction.Backward);
                    }}
                />
            );
    }
}

/**
 *
 * @returns Container for EmptySeriesRouter used in global routing
 */
export function FirstSeriesFlowContainer(): JSX.Element {
    const { routeToDemoExperience } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    useEffect(() => {
        if (user.metadata.isAnonymousUser) {
            routeToDemoExperience({ replace: true });
        }
    }, []);
    return (
        <ViewContainer>
            <FirstSeriesFlow />
        </ViewContainer>
    );
}
