import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { YTBAgendaItemProps } from '@spinach-shared/models';
import {
    ClientEventType,
    DetailedTicket,
    SpinachUpdateType,
    SpinachUpdateTypeYTB,
    UpdatesByReservedType,
} from '@spinach-shared/types';

import { jiraColorMap } from '../../constants';
import { useExperienceTracking } from '../../hooks';
import { BodyLarge, BodyRegular, lightTheme } from '../../styles';
import { JiraDetailsModal } from '../input/jira/JiraDetailsModal';
import { HorizontalScrollShadingCSS, ScrollShadingCSS } from '../stand-up';
import { BootstrapTooltip } from './BootstrapTooltip';
import { Column, Row, Spacing } from './framing';

const JiraPreview = styled.div`
    padding: 5px 5px;
    white-space: nowrap;
    flex-direction: column;
    display: flex;
    text-overflow: ellipsis;
    border-radius: 8px;
    position: relative;
    margin-bottom: 10px;
    max-height: 36px;
    margin-top: 10px;
    text-align: left;
    align-items: center;
    background-color: white;
    align-self: center;
    width: 100%;
    flex-shrink: 0;
    max-width: 200px;
    cursor: pointer;

    padding: 8px;

    &:hover {
        background-color: white;
    }
`;

const JiraPreviewBox = styled.div<{
    updateTypes?: SpinachUpdateTypeYTB[];
}>`
    border-radius: 8px;
    max-width: 200px;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 4px;
    height: 28px;
    display: flex;
    justify-content: center;
    padding: 8px;
    padding-right: 12px;
    padding-left: 12px;
    ${(props) => {
        if (!props.updateTypes?.length) {
            return css`
                background: 4px rgba(0, 0, 0, 0.14);
            `;
        }

        if (props.updateTypes.length === 1) {
            if (!jiraColorMap[props.updateTypes[0]]) {
                return css`
                    background: 4px #4285f480;
                `;
            } else {
                return css`
                    background: 4px ${jiraColorMap[props.updateTypes[0]]}80;
                `;
            }
        }
        if (props.updateTypes?.includes(SpinachUpdateType.Challenge)) {
            return css`
                background: 4px ${jiraColorMap[SpinachUpdateType.Challenge]}80;
            `;
        } else if (
            props.updateTypes.includes(SpinachUpdateType.Yesterday) &&
            props.updateTypes.includes(SpinachUpdateType.Today)
        ) {
            return css<{ updateTypes?: SpinachUpdateTypeYTB[] }>`
                background: linear-gradient(
                    270deg,
                    ${jiraColorMap[SpinachUpdateType.Today]}80 25%,
                    ${jiraColorMap[SpinachUpdateType.Yesterday]}80 75%
                );
            `;
        }

        return css`
            background: 4px rgb(0 0 0 / 14%);
        `;
    }};
`;

const CenteredRow = styled(Row)`
    justify-content: center;
`;

const ShowMoreContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;

    &:hover {
        cursor: pointer;
    }
`;

const SCROLL_PADDING = 20;

const JiraBoardScrollView = styled.div<{
    stretch?: boolean;
    balancePadding?: boolean;
    sidePadding?: number;
    horizontal?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
        color: transparent;
    }
    /* 
        Maybe there is a better way to do this. 
        Need to ensure everything in this container adds
        up to 200px.

     */
    height: calc(250px - ${SCROLL_PADDING}px - 24px);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    position: relative;
    overflow-y: auto;
    overflow-x: ${(props) => (props.disabled ? 'hidden' : props.horizontal ? 'auto' : 'hidden')};
    width: ${(props) => (props.balancePadding ? `calc(100% - ${2 * SCROLL_PADDING}px)` : '100%')};
    flex-grow: ${(props) => (props.stretch ? '1' : 'initial')};

    ${(props) => (props.horizontal ? HorizontalScrollShadingCSS : ScrollShadingCSS)};
`;

const StatusRowScrollArea = styled.div<{
    horizontal?: boolean;
}>`
    display: flex;
    flex-direction: row;
    ::-webkit-scrollbar {
        color: transparent;
    }
    /* 
        Maybe there is a better way to do this. 
        Need to ensure everything in this container adds
        up to 200px.

     */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-y: 'hidden';
    overflow-x: ${(props) => (props.horizontal ? 'auto' : 'hidden')};
    width: 100%;
    height: initial;
    justify-content: space-around;

    ${(props) => (props.horizontal ? HorizontalScrollShadingCSS : ScrollShadingCSS)};
`;

const StatusButton = styled(BodyRegular)<{ isSelected?: boolean }>`
    justify-content: space-evenly;
    color: ${(props) => (props.isSelected ? lightTheme.neutrals.white : lightTheme.secondary.greenDark)};
    font-weight: 600;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? lightTheme.primary.greenLight : 'none')};
    border-radius: 4px;
    padding: 2px 5px 2px 5px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

enum Direction {
    Left = -1,
    Right = 1,
}

const DEFAULT_JIRA_ISSUE_PER_COLUMN_LENGTH = 5;

const generateJiraSpinachUpdateTypeMap = (
    tickets: DetailedTicket[],
    updatesByReservedTypes?: UpdatesByReservedType
) => {
    return updatesByReservedTypes
        ? Object.keys(updatesByReservedTypes).reduce((acc, curr) => {
              const currentUpdatesByReservedType = updatesByReservedTypes[curr as SpinachUpdateType];
              const updateTypeTicktes = tickets.filter((t) =>
                  currentUpdatesByReservedType?.some(
                      (update) => update.jiraData?.id === t?.id || update.ticketData?.ticket.id === t?.id
                  )
              );
              if (updateTypeTicktes.length) {
                  updateTypeTicktes.forEach((ticket) => {
                      acc[ticket.id] = [...new Set([...(acc[ticket.id] ?? []), curr as SpinachUpdateType])];
                  });
                  return acc;
              }
              return acc;
          }, {} as Record<string, SpinachUpdateType[]>)
        : ({} as Record<string, SpinachUpdateType[]>);
};

export const JiraBoardView = ({ currentItem }: { currentItem: YTBAgendaItemProps }) => {
    const tickets = currentItem.ticketsOnBoard;
    const statuses = currentItem.orderedStatuses;

    const spinachUpdateTypeMap = useMemo(
        () => generateJiraSpinachUpdateTypeMap(tickets, currentItem.standUpUpdate.updatesByReservedTypes),
        [tickets, currentItem.standUpUpdate.updatesByReservedTypes]
    );

    const [jiraPageOffset, setJiraPageOffset] = useState(0);

    const [showMore, setShowMore] = useState(false);

    const statusScrollAreaRef = useRef<HTMLDivElement | null>(null);

    const scrollInterval = useRef<NodeJS.Timeout | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const gradualScrollTimeout = useRef<NodeJS.Timeout | null>(null);
    const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
    const track = useExperienceTracking();
    const [isRightDisabled, setIsRightDisabled] = useState(
        statuses?.length ? jiraPageOffset >= statuses.length - 1 : true
    );
    const [isLeftDisabled, setIsLeftDisabled] = useState(jiraPageOffset === 0);
    const [isScrollAreaDisabled, setIsScrollAreaDisabled] = useState(true);
    const [detailedTicket, setDetailedTicket] = useState<DetailedTicket | null>(null);
    const [isJiraDetailedModalOpen, setIsJiraDetailedModalOpen] = useState(false);

    const clearScroll = () => {
        if (scrollInterval.current) {
            setIsScrollAreaDisabled(true);
            clearInterval(scrollInterval.current);
        }
        if (gradualScrollTimeout.current) {
            clearTimeout(gradualScrollTimeout.current);
        }
    };

    useEffect(() => {
        if (scrollRef.current && (scrollRef.current as any).scrollLeftMax === 0) {
            setIsLeftDisabled(true);
            setIsRightDisabled(true);
        } else if (scrollRef.current) {
            if (scrollRef.current.scrollLeft > 0) {
                setIsLeftDisabled(false);
            }
            if (scrollRef.current.scrollLeft < (scrollRef.current as any).scrollLeftMax) {
                setIsRightDisabled(false);
            }
        }
        if (jiraPageOffset === 0) {
            setIsLeftDisabled(true);
        }
        if (jiraPageOffset >= statuses.length - 1) {
            setIsRightDisabled(true);
        }
    }, [scrollRef.current]);

    const onJiraPage = (movement: Direction): [pagedTickets: DetailedTicket[], newOffset: number] => {
        const selectedStatus = currentItem.orderedStatuses[jiraPageOffset + movement];
        const pagedTickets = currentItem.ticketsByStatus[selectedStatus];
        const newOffset = jiraPageOffset + movement;
        setJiraPageOffset(newOffset);
        return [pagedTickets, newOffset];
    };

    const handleSmoothlyScrollToColumn = (columnArea: HTMLElement | null, offset: number) => {
        if (
            columnArea &&
            scrollRef.current &&
            !(
                scrollRef.current.scrollLeft >= (scrollRef.current as any)?.scrollLeftMax &&
                columnArea.offsetLeft > (scrollRef.current as any).scrollLeftMax
            )
        ) {
            clearScroll();
            setIsScrollAreaDisabled(false);
            if (
                (columnArea?.lastChild as HTMLElement)?.offsetTop &&
                scrollRef.current?.scrollTop &&
                scrollRef.current.scrollTop > (columnArea?.lastChild as HTMLElement)?.offsetTop
            ) {
                scrollRef.current?.scroll({
                    left: columnArea?.offsetLeft,
                    top: (columnArea?.lastChild as HTMLElement)?.offsetTop,
                    behavior: 'smooth',
                });
            } else {
                scrollRef.current?.scroll({ left: columnArea?.offsetLeft, behavior: 'smooth' });
            }
            // As long as the area is scrolling this will get called
            // We set a timeout to disable the scroll area after scrolling is complete
            const timeoutCallback = () => {
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
                scrollTimeout.current = setTimeout(function () {
                    setIsScrollAreaDisabled(true);
                    scrollRef.current?.removeEventListener('scroll', timeoutCallback);
                }, 100);
            };
            scrollRef.current?.addEventListener('scroll', timeoutCallback);
        }
        if (columnArea && scrollRef.current) {
            setIsRightDisabled(
                (scrollRef.current as any).scrollLeftMax === 0 ||
                    columnArea.offsetLeft >= (scrollRef.current as any).scrollLeftMax ||
                    offset >= statuses.length - 1
            );
            setIsLeftDisabled(
                (scrollRef.current as any).scrollLeftMax === 0 || columnArea.offsetLeft <= 0 || offset <= 0
            );
        }
    };

    return (
        <>
            <Spacing factor={1 / 3} />
            <Row>
                <KeyboardArrowLeft
                    style={{
                        height: '30px',
                        width: '30px',
                        marginLeft: '-10px',
                        marginRight: '5px',
                        color: isLeftDisabled ? lightTheme.neutrals.midnight : lightTheme.primary.greenLight,
                        cursor: isLeftDisabled ? 'initial' : 'pointer',
                    }}
                    onClick={() => {
                        if (isLeftDisabled) {
                            return;
                        }
                        const [_, offset] = onJiraPage(-1);
                        track(ClientEventType.UserClickJiraBoardNavigation, {
                            ArrowButton: true,
                            Direction: 'left',
                            FromStatus: statuses[jiraPageOffset],
                            ToStatus: statuses[offset],
                        });
                        const columnArea = document.getElementById(`board-column-${offset}`);
                        handleSmoothlyScrollToColumn(columnArea, offset);
                        const statusColumnArea = document.getElementById(`jira-board-status-${offset}`);
                        if (statusColumnArea?.offsetLeft && statusColumnArea?.offsetWidth) {
                            statusScrollAreaRef.current?.scroll({
                                left: statusColumnArea?.offsetLeft - statusColumnArea?.offsetWidth,
                                behavior: 'smooth',
                            });
                        }
                    }}
                />
                <StatusRowScrollArea ref={statusScrollAreaRef} horizontal>
                    {statuses?.map((s, i) => {
                        const isSelected = jiraPageOffset === i;
                        return (
                            <React.Fragment key={s}>
                                <Row id={`jira-board-status-${i}`} style={{ width: 'unset', alignItems: 'center' }}>
                                    <StatusButton
                                        onClick={() => {
                                            const direction: 'right' | 'left' | 'none' =
                                                i > jiraPageOffset ? 'right' : i < jiraPageOffset ? 'left' : 'none';
                                            setJiraPageOffset(i);
                                            const columnArea = document.getElementById(`board-column-${i}`);
                                            track(ClientEventType.UserClickJiraBoardNavigation, {
                                                ArrowButton: false,
                                                Direction: direction,
                                                FromStatus: statuses[jiraPageOffset],
                                                ToStatus: statuses[i],
                                            });
                                            handleSmoothlyScrollToColumn(columnArea, i);
                                            const statusColumnArea = document.getElementById(`jira-board-status-${i}`);

                                            if (statusColumnArea?.offsetLeft && statusColumnArea?.offsetWidth) {
                                                statusScrollAreaRef.current?.scroll({
                                                    left: statusColumnArea?.offsetLeft - statusColumnArea?.offsetWidth,
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }}
                                        isSelected={isSelected}
                                    >
                                        {s}
                                    </StatusButton>
                                </Row>
                            </React.Fragment>
                        );
                    })}
                </StatusRowScrollArea>
                <KeyboardArrowRight
                    style={{
                        height: '30px',
                        width: '30px',
                        marginRight: '-10px',
                        marginLeft: '5px',
                        color: isRightDisabled ? lightTheme.neutrals.midnight : lightTheme.primary.greenLight,
                        cursor: isRightDisabled ? 'initial' : 'pointer',
                    }}
                    onClick={() => {
                        if (isRightDisabled) {
                            return;
                        }
                        const [_, offset] = onJiraPage(1);
                        track(ClientEventType.UserClickJiraBoardNavigation, {
                            ArrowButton: true,
                            Direction: 'right',
                            FromStatus: statuses[jiraPageOffset],
                            ToStatus: statuses[offset],
                        });
                        const columnArea = document.getElementById(`board-column-${offset}`);
                        handleSmoothlyScrollToColumn(columnArea, offset);
                        const statusColumnArea = document.getElementById(`jira-board-status-${offset}`);
                        if (statusColumnArea?.offsetLeft && statusColumnArea?.offsetWidth) {
                            statusScrollAreaRef.current?.scroll({
                                left: statusColumnArea?.offsetLeft - statusColumnArea?.offsetWidth,
                                behavior: 'smooth',
                            });
                        }
                    }}
                />
            </Row>
            <Spacing factor={1 / 3} />

            <JiraBoardScrollView ref={scrollRef} disabled={isScrollAreaDisabled} horizontal={true}>
                <Row
                /* 
                    Offset is the percentage of the scrolling * the scrolling left to be done i.e. 30
                    So, if the scrollLeftMax is 100, and the scrollLeft is 50 (half the scroll width)
                    This will pass 15 into the offset, which will in turn add 15 to 70, decreasing the gradient by half
                */
                >
                    {statuses.map((s, i) => {
                        const ticketsWithStatus = currentItem.ticketsByStatus[s];
                        const ticketsToShow = showMore
                            ? ticketsWithStatus
                            : ticketsWithStatus.slice(0, DEFAULT_JIRA_ISSUE_PER_COLUMN_LENGTH);

                        return (
                            <Column
                                id={`board-column-${i}`}
                                key={s}
                                style={{
                                    minWidth: '200px',
                                    maxHeight: '50%',
                                    margin: '10px',
                                    padding: '5px',
                                }}
                            >
                                <BodyRegular style={{ display: 'flex', alignItems: 'start' }}>{s}</BodyRegular>
                                <Spacing factor={1 / 3} />

                                {!ticketsToShow.length && <BodyLarge>No tickets assigned</BodyLarge>}

                                {ticketsToShow.map((t, i) => {
                                    const { id, title, avatarUrl } = t;

                                    return (
                                        <BootstrapTooltip key={id} title={title} arrow placement="top">
                                            <JiraPreviewBox
                                                updateTypes={spinachUpdateTypeMap[id] as SpinachUpdateTypeYTB[]}
                                            >
                                                <JiraPreview
                                                    onClick={() => {
                                                        setDetailedTicket(t);
                                                        track(ClientEventType.UserClickJiraIssue, {
                                                            Status: s,
                                                            CustomStatus: t.status,
                                                            TicketStatusLastUpdated: t.statusLastUpdated,
                                                            NumberOfTicktsInStatus: ticketsWithStatus.length,
                                                            TicketIndexInStatus: i,
                                                        });
                                                        setIsJiraDetailedModalOpen(!isJiraDetailedModalOpen);
                                                    }}
                                                >
                                                    <Row
                                                        style={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                                                    >
                                                        <b style={{ paddingRight: '2px' }}>{id}</b>
                                                        <b
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {title}
                                                        </b>
                                                        <img
                                                            style={{
                                                                paddingLeft: '2px',
                                                                height: '16px',
                                                                width: '16px',
                                                            }}
                                                            src={avatarUrl}
                                                        />
                                                    </Row>
                                                </JiraPreview>
                                            </JiraPreviewBox>
                                        </BootstrapTooltip>
                                    );
                                })}
                                {ticketsWithStatus.length > DEFAULT_JIRA_ISSUE_PER_COLUMN_LENGTH && (
                                    <>
                                        <CenteredRow
                                            onClick={() => {
                                                track(ClientEventType.UserClickJiraTicketViewMore, {
                                                    action: showMore ? 'collapse' : 'expand',
                                                });
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            <ShowMoreContainer>
                                                <BodyRegular
                                                    style={{ fontWeight: 'bold', color: lightTheme.primary.greenLight }}
                                                >
                                                    {showMore ? 'View Less' : 'View More'}
                                                </BodyRegular>
                                                {showMore ? (
                                                    <KeyboardArrowUp style={{ color: lightTheme.primary.greenLight }} />
                                                ) : (
                                                    <KeyboardArrowDown
                                                        style={{ color: lightTheme.primary.greenLight }}
                                                    />
                                                )}
                                            </ShowMoreContainer>
                                        </CenteredRow>
                                        <Spacing factor={1 / 3} />
                                    </>
                                )}
                            </Column>
                        );
                    })}
                </Row>
            </JiraBoardScrollView>

            <Spacing />
            {isJiraDetailedModalOpen && detailedTicket && (
                <JiraDetailsModal
                    isOpen={isJiraDetailedModalOpen}
                    issueData={detailedTicket}
                    onClose={() => setIsJiraDetailedModalOpen(false)}
                />
            )}
        </>
    );
};
