import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import { ExperimentKey, StoredSeries, UserSeriesMetadata } from '@spinach-shared/types';
import { isDemoSeries, isProductionStage } from '@spinach-shared/utils';

import { getStoredSeries } from '../../apis/getStoredSeries';
import { atomDashboardSocket } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useGlobalAuthedUser,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useUserIdentification,
    useWebsocket,
} from '../../hooks';
import { HeaderThree } from '../../styles';
import { FYI, FYIState, IntercomCustom, SpinachStationary, UserAccountButton, ViewContainer } from '../common';
import { CreateSeriesCard } from './CreateSeriesCard';
import { CreateStandupModal } from './CreateStandupModal';
import { RemoveSeriesModal } from './RemoveSeriesModal';
import { RenameSeriesModal } from './RenameSeriesModal';
import { SeriesDashboardItem } from './SeriesDashboardItem';
import { ShareSeriesModal } from './ShareSeriesModal';
import { ScrollView } from './common';

const Stationary = styled(SpinachStationary)`
    min-height: 65%;
`;

function useAllUsersStoredSeries(user: ClientUser): StoredSeries[] {
    const [storedSeries, setStoredSeries] = useState<StoredSeries[]>([]);
    useEffect(() => {
        const exec = async () => {
            // TODO: with enough series, this could fail to fetch in browser
            const promises = user.seriesMetadataList.map((s) => getStoredSeries(s.id));

            const series = await Promise.all(promises);
            const definedSeries = series.filter((s): s is StoredSeries => !!s);

            setStoredSeries(definedSeries);
        };

        try {
            exec();
        } catch (error) {
            // not deal breaking if this fails -- just no tooltips
        }
    }, []);

    return storedSeries;
}

const showDemoSeriesToo = true;

export function SeriesDashboard(): JSX.Element {
    const { socket } = useWebsocket(atomDashboardSocket);

    const [series] = useGlobalNullableStoredSeries();
    const [user] = useGlobalAuthedUser();
    const [shouldShowInviteModal, setShouldShowInviteModal] = useState(false);
    useUserIdentification(user);
    useRedirectToNoSeriesRouterWhenNoRealSeries();
    useClearSeriesOnMount();

    const { routeToDemoExperience } = useGlobalRouting();
    useEffect(() => {
        if (user.isDemoing) {
            routeToDemoExperience({ replace: true });
        }
    }, []);

    // TODO: make api for fetching bulk series
    const storedSeriesList = useAllUsersStoredSeries(user);
    const [hoveredSeries, setHoveredSeries] = useState<UserSeriesMetadata | null>(null);
    const [isCreateSeriesModalOpen, setIsCreateSeriesModalOpen] = useState(false);
    const [isRemoveSeriesModalOpen, setIsRemoveSeriesModalOpen] = useState(false);
    const [isRenameSeriesModalOpen, setIsRenameSeriesModalOpen] = useState(false);
    const [selectedSeries, setSelectedSeries] = useState<UserSeriesMetadata | null>(null);
    const [areOptionsOpen, setOptionsOpen] = useState<boolean>(false);

    if (storedSeriesList.length < user.seriesMetadataList.length) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <ViewContainer>
            <UserAccountButton />
            <Stationary id={ElementId.SeriesDashboardContainer}>
                <HeaderThree>{user.isScribeUser ? 'Your Meetings' : 'Your Standups'}</HeaderThree>
                <ScrollView stretch={true} sidePadding={0}>
                    {user.seriesMetadataList
                        .filter((series) => (!isProductionStage() ? showDemoSeriesToo : !isDemoSeries(series.id)))
                        .filter(
                            (series) =>
                                storedSeriesList.find((s) => s.id === series.id)?.metadata?.scribeMetadata
                                    ?.isRecurring !== false // this is true for legacy series
                        )
                        .map((series) => {
                            const storedSeriesForCard = storedSeriesList.find((s) => s.id === series.id);
                            const noElementsOpened = !(
                                areOptionsOpen ||
                                isRenameSeriesModalOpen ||
                                isRemoveSeriesModalOpen
                            );

                            return (
                                <SeriesDashboardItem
                                    key={series.id}
                                    series={series}
                                    storedSeries={storedSeriesForCard}
                                    setOptionsOpen={setOptionsOpen}
                                    noElementsOpened={noElementsOpened}
                                    areOptionsOpen={areOptionsOpen}
                                    setHoveredSeries={setHoveredSeries}
                                    hoveredSeries={hoveredSeries}
                                    onClickRemove={() => {
                                        setSelectedSeries(series);
                                        setIsRemoveSeriesModalOpen(true);
                                    }}
                                    onClickRename={() => {
                                        setSelectedSeries(series);
                                        setIsRenameSeriesModalOpen(true);
                                    }}
                                />
                            );
                        })}

                    {user.isScribeUser ? (
                        <></>
                    ) : (
                        <CreateSeriesCard
                            onOpenCreateSeriesModal={() => {
                                setIsCreateSeriesModalOpen(true);
                            }}
                        />
                    )}
                </ScrollView>
            </Stationary>

            {selectedSeries ? (
                <RemoveSeriesModal
                    user={user}
                    series={selectedSeries}
                    isOpen={isRemoveSeriesModalOpen}
                    onClose={() => {
                        setIsRemoveSeriesModalOpen(false);
                    }}
                />
            ) : null}

            <CreateStandupModal
                user={user}
                setShouldShowInviteModal={setShouldShowInviteModal}
                isOpen={isCreateSeriesModalOpen}
                onClose={() => {
                    setIsCreateSeriesModalOpen(false);
                }}
            />

            {selectedSeries ? (
                <RenameSeriesModal
                    socket={socket}
                    series={selectedSeries}
                    isOpen={isRenameSeriesModalOpen}
                    onClose={() => {
                        setIsRenameSeriesModalOpen(false);
                    }}
                />
            ) : null}

            {shouldShowInviteModal && series ? (
                <ShareSeriesModal
                    userId={user.spinachUserId}
                    isOpen={!!shouldShowInviteModal}
                    onClose={() => {}}
                    series={series}
                    redirectOnClose={true}
                />
            ) : null}
            <IntercomCustom />
        </ViewContainer>
    );
}

function useRedirectToNoSeriesRouterWhenNoRealSeries() {
    const [user] = useGlobalAuthedUser();
    const { routeToFirstSeriesFlow, routeToOnboarding, routeToScribeUserSettings } = useGlobalRouting();

    useEffect(() => {
        if (!user.metadata.isOnboarded && !user.isScribeUser) {
            routeToOnboarding();
        } else if (user.realSeries.length === 0) {
            if (user.isScribeUser) {
                routeToScribeUserSettings({ replace: true });
            } else {
                routeToFirstSeriesFlow({ replace: true });
            }
        }
    }, [user.realSeries.length]);
}

function useClearSeriesOnMount() {
    const [, setSeries] = useGlobalNullableStoredSeries();

    useEffect(() => {
        setSeries(undefined);
    }, []);
}
