import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Sound from 'react-sound';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { actionItemTypeProps, detailItemTypeProps } from '@spinach-shared/constants';
import { ActiveAgendaProps, YTBAgendaItemProps } from '@spinach-shared/models';
import {
    AgendaItemBaseRequest,
    ClientSocketEvent,
    SpinachUpdateType,
    YTBUpdateUpdatedRequest,
} from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import {
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    usePersonaDemo,
    useWindowSize,
} from '../../hooks';
import { BodyLarge, BodyRegular } from '../../styles';
import { ActiveAgendaViewProps } from '../../types/StandUp';
import { createWebsocketPayload } from '../../utils/analytics';
import { isWebPlatform } from '../../utils/platform';
import { withFullStoryMasking } from '../../utils/withFullStoryMasking';
import { BootstrapTooltip, CloseButton, PulsingHint, Row, Spacer, Spacing } from '../common';
import { TeamTopicsAuthorIndicator } from '../common/TeamTopicsAuthorIndicator';
import { ModalContent } from '../series/common';
import { DiscussionReaction, DiscussionReactionButtons, useDiscussionReactions } from './DiscussionReactions';
import { LiveReservedUpdateSection } from './LiveReservedUpdateSection';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';
import { StandUpHeader } from './StandUpHeader';

// Could not get the Typescript typings to work for this
const soundfile = require('../../assets/ding.mp3').default;
// disable sound playing logs
(window as any).soundManager?.setup({ debugMode: false });

type StandUpUpdateViewProps = ActiveAgendaViewProps & {
    currentItem: YTBAgendaItemProps;
};

function isLastTopic(agenda: ActiveAgendaProps): boolean {
    return agenda.currentIndex === agenda.lastItemIndex;
}

function getNextButtonText(agenda: ActiveAgendaProps, isDemo: boolean): string {
    if (isLastTopic(agenda)) {
        if (isDemo) {
            return 'Generate Summary';
        } else {
            return 'Finish!';
        }
    } else {
        return 'Next topic';
    }
}

const Content = styled(ModalContent)`
    min-width: 280px;
`;

const AuthorContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: flex-start;
    margin-right: 7px;
    margin-top: 11px;
    justify-content: center;
`;

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    align-content: center;
    text-align: center;
`;

export function TopicView({
    seriesSlug,
    spinachParticipant,
    currentItem,
    agenda,
    socket,
    cooldownProps,
}: StandUpUpdateViewProps): JSX.Element {
    const { spinachUserId } = spinachParticipant;
    const [liveSeries] = useGlobalLiveSeries();
    const { cooldown, isNavigationDisabled, bypassCooldown } = cooldownProps;
    const { width } = useWindowSize();

    const { isDiscussionReactionVisible, reaction, triggeringSpinachUserId } = useDiscussionReactions(socket);

    const nextButtonText = getNextButtonText(agenda, liveSeries.isDemo);

    const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
        spinachUserId,
        seriesSlug,
        meetingId: liveSeries.currentMeeting.id,
        itemId: currentItem.id,
    });

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const progressAgenda = () => {
        bypassCooldown();
        socket.emit(ClientSocketEvent.AgendaProgressing, agendaNavigationRequest);
    };

    const authorsAgendaItem = agenda.YTBItems.find((item) =>
        item.standUpUpdate.getUpdatesForType(SpinachUpdateType.Topic).some((item) => item.id === currentItem.id)
    );
    const author = authorsAgendaItem?.title;

    const isUsersTopic = spinachParticipant.spinachUserId === authorsAgendaItem?.standUpUpdate.spinachUserId;

    const [soundStatus, setSoundStatus] = useState<'PLAYING' | 'STOPPED' | 'PAUSED'>('PLAYING');
    const authorTooltipPlacement = width > 650 ? 'top' : 'right-start';

    const isPersonaDemo = usePersonaDemo();
    usePersonaDemoTopicNotes(agenda, socket);

    return (
        <>
            <DiscussionReaction
                isDiscussionReactionVisible={isDiscussionReactionVisible}
                triggeringSpinachUserId={triggeringSpinachUserId}
                reaction={reaction}
            />
            <Row style={{ width: 'unset', justifyContent: 'start', alignItems: 'center', alignSelf: 'start' }}>
                {author ? (
                    <BootstrapTooltip title={author} placement={authorTooltipPlacement} arrow>
                        <AuthorContainer>
                            <TeamTopicsAuthorIndicator name={author} />
                        </AuthorContainer>
                    </BootstrapTooltip>
                ) : null}
                <StandUpHeader title={currentItem.title} className={withFullStoryMasking()} id="presenting-title" />
            </Row>

            <Modal
                open={showConfirmationModal}
                onClose={() => {
                    setShowConfirmationModal(false);
                }}
            >
                <Content>
                    <Spacer style={{ height: '40px' }} />
                    <CloseButton
                        onClick={() => {
                            setShowConfirmationModal(false);
                        }}
                    />
                    <CenteredContent>
                        <BodyLarge>This will end the meeting for everyone.</BodyLarge>
                        <BodyRegular>Are you sure?</BodyRegular>
                        <PrimaryButton title={'End meeting'} onClick={progressAgenda} />
                    </CenteredContent>
                </Content>
            </Modal>

            <ScrollArea stretch={true} useGrayShading={true}>
                <LiveReservedUpdateSection
                    agendaItem={currentItem}
                    socket={socket}
                    typeProps={detailItemTypeProps}
                    isInNavDrawer={false}
                />
                <LiveReservedUpdateSection
                    agendaItem={currentItem}
                    socket={socket}
                    typeProps={actionItemTypeProps}
                    isInNavDrawer={false}
                />
            </ScrollArea>

            <StandUpFooter key={currentItem.id}>
                <PulsingHint
                    pulseStyles={{
                        marginTop: '35px',
                    }}
                    isVisible={liveSeries.isDemo && !isPersonaDemo}
                />
                <PrimaryButton
                    title={nextButtonText}
                    disabled={isNavigationDisabled}
                    fadeInDuration={cooldown}
                    onClick={() => {
                        if (isLastTopic(agenda) && isWebPlatform()) {
                            if (liveSeries.isDemo) {
                                progressAgenda();
                            } else {
                                setShowConfirmationModal(true);
                            }
                        } else {
                            progressAgenda();
                        }
                    }}
                />

                <Spacing factor={0.83} />

                <SecondaryButton
                    title={'Go back'}
                    disabled={isNavigationDisabled}
                    fadeInDuration={cooldown}
                    onClick={() => {
                        bypassCooldown();

                        socket.emit(ClientSocketEvent.AgendaReversing, agendaNavigationRequest);
                    }}
                />
            </StandUpFooter>

            <DiscussionReactionButtons
                isDiscussionReactionVisible={isDiscussionReactionVisible}
                seriesSlug={seriesSlug}
                socket={socket}
            />

            {isUsersTopic ? (
                <Sound
                    url={soundfile}
                    playStatus={soundStatus}
                    autoLoad
                    volume={15}
                    onFinishedPlaying={() => setSoundStatus('STOPPED')}
                    onError={() => {
                        // swallow error?
                    }}
                />
            ) : undefined}
        </>
    );
}

function usePersonaDemoTopicNotes(agenda: ActiveAgendaProps, socket: Socket) {
    const [user] = useGlobalAuthedUser();
    const { haveAutoTopicNotesFired, demoState, setDemoState } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();
    const [liveSeries] = useGlobalLiveSeries();

    useEffect(() => {
        if (!isPersonaDemo || haveAutoTopicNotesFired) {
            return;
        }

        setTimeout(() => {
            const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
                ...user.toUserIdentity(),
                seriesSlug: liveSeries.slug,
                spinachUserId: user.spinachUserId,
                participantId: agenda.currentItem.id,
                meetingId: liveSeries.currentMeeting.id,
                update: getNewTypedUpdate({
                    creatorId: user.spinachUserId,
                    text: 'Fill in details for tech spec',
                    updateType: SpinachUpdateType.Notes,
                }),
            });
            socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
            setDemoState({
                ...demoState,
                haveAutoTopicNotesFired: true,
            });
        }, 1500);

        setTimeout(() => {
            const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
                ...user.toUserIdentity(),
                seriesSlug: liveSeries.slug,
                spinachUserId: user.spinachUserId,
                participantId: agenda.currentItem.id,
                meetingId: liveSeries.currentMeeting.id,
                update: getNewTypedUpdate({
                    creatorId: user.spinachUserId,
                    text: 'Connect with Alina to define user stories',
                    updateType: SpinachUpdateType.ActionItems,
                }),
            });
            socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
        }, 2500);
    }, []);
}
