import { TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, ResolverMetadata, TypedUpdate } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser } from '../../hooks';
import { BodyRegular, withSelectionPrevention } from '../../styles';
import { IssueResolutionAttachmentProps } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { Row } from '../common';
import { useSpinachNewInputStyles } from './BaseInput';

const ResolverDetails = styled(BodyRegular)`
    ${withSelectionPrevention()};
    line-height: 115%;
    align-items: center;
    color: ${(props) => props.theme.secondary.midnight};
    font-weight: 700;
    border: 1px solid #cbe8e1;
    border-radius: 2px;
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 0px;
    width: fit-content;
    padding-left: 2px;
    background-color: #cbe8e1;
`;

export const IssueResolutionPicker = (issueResolutionProps: IssueResolutionAttachmentProps) => {
    const { setTypedUpdate, typedUpdate, saveFullTypedUpdate } = issueResolutionProps;
    const resolverRef = useRef<HTMLDivElement | null>(null);
    const isResolverDetailsFocused = useRef<boolean>(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const classes = useSpinachNewInputStyles({
        muiInputMultilineOverrides: {
            height: '20px',
        },
        muiBaseInputOverrides: {
            padding: 'unset',
            height: '20px',
        },
        /**
         * @NOTE We don't include a value so the input text field always
         * has an underline available
         * With normal YTB updates, we remove the underline border to clean
         * up some clutter becasue there are multiple text fields beneath the item
         * However, that's not the case here, so we want to keep the underline
         * in all cases
         *  */
        value: '',
    });

    const [user] = useGlobalAuthedUser();

    const track = useExperienceTracking();

    const thisUsersResolverDetails: string | undefined = typedUpdate.resolvers?.find(
        (resolver) => resolver.spinachUserId === user.spinachUserId
    )?.details;

    const [resolverDetails, setResolverDetails] = useState<string | undefined>(thisUsersResolverDetails);

    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    function disposeTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }

    const saveUpdate = (update: TypedUpdate, details?: string) => {
        track(ClientEventType.UserAddResolverDetails, {
            DetailsLength: details?.length,
            UpdateType: typedUpdate.updateType,
        });
        const userResolver = update.resolvers?.find((resolver) => resolver.spinachUserId === user.spinachUserId);

        /**
         * @NOTE userResolver should always be true here
         * since they can't add notes unless they've already
         * been set as one of the resolvers */
        if (userResolver) {
            const updatedOn = new Date().toISOString();

            const updatedResolvers: ResolverMetadata[] = [
                ...(update.resolvers?.filter((resolver) => resolver.spinachUserId !== userResolver.spinachUserId) ??
                    []),
                {
                    ...userResolver,
                    details: details ?? 'I can help',
                    updatedOn,
                },
            ].sort((a, b) => {
                if (!a.createdOn && !b.createdOn) {
                    return 0;
                }
                if (a.createdOn && b.createdOn) {
                    return new Date(a.createdOn).getDate() - new Date(b.createdOn).getDate();
                }
                if (a.createdOn) {
                    return 1;
                }
                return -1;
            });

            setTypedUpdate?.({
                ...update,
                resolvers: updatedResolvers,
            });
            saveFullTypedUpdate?.({
                ...update,
                resolvers: updatedResolvers,
            });
        }
    };

    useEffect(() => {
        setResolverDetails(thisUsersResolverDetails);
    }, [thisUsersResolverDetails]);

    useEffect(() => {
        return () => {
            disposeTimeout();
            isResolverDetailsFocused.current = false;
        };
    }, []);

    useEffect(() => {
        const resolver = typedUpdate.resolvers?.find((resolver) => resolver.spinachUserId === user.spinachUserId);
        if (resolver && !resolver.details) {
            inputRef.current?.focus();
            isResolverDetailsFocused.current = true;
        } else {
            isResolverDetailsFocused.current = false;
        }
    }, [typedUpdate.resolvers]);

    if (!typedUpdate.resolvers?.length) {
        return <></>;
    }

    return (
        <>
            {typedUpdate.resolvers.map((resolver) => (
                <Row style={{ marginBottom: '5px' }}>
                    <ResolverDetails ref={resolverRef}>{resolver.preferredName}:</ResolverDetails>
                    {resolver.spinachUserId === user.spinachUserId ? (
                        <>
                            <TextField
                                aria-label={`sentiment-input-field-${resolver.spinachUserId}`}
                                InputProps={{ classes: { root: classes.base } }}
                                multiline={false}
                                inputProps={{ maxLength: 74, styles: { padding: '3px 0 3px' } }}
                                inputRef={inputRef}
                                style={{
                                    width: `fit-content`,
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                className={withFullStoryMasking(classes.root)}
                                value={resolverDetails}
                                autoFocus={false}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (inputRef.current) {
                                            inputRef.current.blur();
                                        }
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length >= 80) {
                                        return;
                                    }
                                    disposeTimeout();
                                    setResolverDetails(e.target.value);
                                    timeoutRef.current = setTimeout(() => {
                                        saveUpdate(typedUpdate, e.target.value);
                                        disposeTimeout();
                                    }, 500);
                                }}
                                onFocus={() => {
                                    isResolverDetailsFocused.current = true;
                                }}
                                onBlur={() => {
                                    disposeTimeout();
                                    track(ClientEventType.UserAddResolverDetails, {
                                        DetailsLength: resolverDetails?.length,
                                        UpdateType: typedUpdate.updateType,
                                    });
                                    timeoutRef.current = setTimeout(() => {
                                        if (isResolverDetailsFocused.current) {
                                            saveUpdate(typedUpdate, resolverDetails);
                                        }

                                        disposeTimeout();
                                        isResolverDetailsFocused.current = false;
                                    }, 200);
                                }}
                                placeholder={'How can you help?'}
                            />
                        </>
                    ) : resolver.details ? (
                        <BodyRegular style={{ paddingLeft: '5px' }}>{resolver.details}</BodyRegular>
                    ) : (
                        <></>
                    )}
                </Row>
            ))}
        </>
    );
};
