import { SeriesTypePrefix } from '@spinach-shared/constants';
import {
    OutputPlatform,
    ScribeMetadata,
    SeriesCounters,
    SeriesDestinationSettings,
    SeriesIntegrationSettings,
    SeriesMetadata,
    SeriesOutputChannel,
    SeriesOutputContent,
    SeriesOutputOptions,
    SeriesUserMetadata,
    SpinachCode,
    SpinachComponents,
    StoredSeries,
    StoredSpinachSeriesMetadata,
    UUID,
} from '@spinach-shared/types';

import { SeriesSchedule } from './schedule';

export function isDemoSeries(seriesId: string): boolean {
    if (seriesId.includes(SeriesTypePrefix.Demo)) {
        return true;
    } else {
        return false;
    }
}

export function getDailySubscribedEmails(storedSeries: StoredSeries | null): string[] {
    return (
        storedSeries?.outputOptions
            ?.filter(
                (option): option is SeriesOutputOptions =>
                    option.channel === SeriesOutputChannel.Email &&
                    option.content.includes(SeriesOutputContent.DailySummary)
            )
            .map((option) => option.to) ?? []
    );
}

export class StoredSpinachSeries {
    id: string;
    slug: SpinachCode;
    name: string;
    userMetadataList: SeriesUserMetadata[];
    createdBy: UUID;
    metadata: StoredSpinachSeriesMetadata;
    integrationSettings?: SeriesIntegrationSettings;
    components: SpinachComponents[];
    outputOptions: SeriesOutputOptions[];
    counters: Record<SeriesCounters, number>;
    icpId: string | undefined;
    icpEmail: string | undefined;

    constructor(storedSeries: StoredSeries) {
        this.id = storedSeries.id;
        this.slug = storedSeries.slug;
        this.name = storedSeries.name;
        this.userMetadataList = storedSeries.userMetadataList as SeriesUserMetadata[];
        this.createdBy = storedSeries.createdBy;
        this.integrationSettings = storedSeries.integrationSettings;
        this.components = storedSeries.components;
        this.outputOptions = storedSeries.outputOptions;
        this.counters = storedSeries.counters ?? { [SeriesCounters.InVideoMeetingSlackConnectMessage]: 0 };
        this.icpId = storedSeries.icpUserId ?? storedSeries.metadata?.scribeMetadata?.inviterId;
        this.icpEmail =
            this.userMetadataList.find((u) => u._id === this.icpId)?.email ??
            this.userMetadataList.find((u) => u._id === this.createdBy)?.email;

        this.metadata = {
            dateTimes: storedSeries.metadata?.dateTimes
                ? new SeriesSchedule(storedSeries.metadata.dateTimes)
                : undefined,
            reminderSettings: storedSeries.metadata?.reminderSettings,
            scribeMetadata: storedSeries.metadata?.scribeMetadata,
        };
    }

    get isDemoSeries(): boolean {
        if (this.slug.includes(SeriesTypePrefix.Demo)) {
            return true;
        } else {
            return false;
        }
    }

    get schedule(): SeriesSchedule | undefined {
        return this.metadata?.dateTimes;
    }

    get scribeMetadata(): ScribeMetadata | undefined {
        return this.metadata.scribeMetadata;
    }

    get dailySubscribedEmails(): string[] {
        return (
            this.outputOptions
                .filter(
                    (option): option is SeriesOutputOptions =>
                        option.channel === SeriesOutputChannel.Email &&
                        option.content.includes(SeriesOutputContent.DailySummary)
                )
                .map((option) => option.to) ?? []
        );
    }

    get slackChannel(): Required<SeriesDestinationSettings> | undefined {
        const slackIntegration = this.integrationSettings?.summaryOutputSettings?.find(
            (i) => i.platform === OutputPlatform.Slack
        );
        const channels = slackIntegration?.destinationSettings?.filter(
            (d): d is Required<SeriesDestinationSettings> => !!d && !!d.channelName && !!d.channelId
        );

        if (channels && channels.length) {
            return channels[0];
        }

        return undefined;
    }

    get isStandupScribeSeries(): boolean {
        return this.metadata?.scribeMetadata?.scribeType === 'standup';
    }

    get userIds(): string[] {
        return this.userMetadataList.map((u) => u._id);
    }

    toJSON(): StoredSeries {
        return {
            id: this.id,
            slug: this.slug,
            name: this.name,
            userMetadataList: this.userMetadataList,
            createdBy: this.createdBy,
            metadata: this.getJSONMetadata(),
            integrationSettings: this.integrationSettings,
            components: this.components,
            outputOptions: this.outputOptions,
            counters: this.counters,
            icpUserId: this.icpId,
        };
    }

    private getJSONMetadata(): SeriesMetadata | undefined {
        let metadata: SeriesMetadata | undefined = undefined;

        if (this.metadata) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { dateTimes, ...rest } = this.metadata;

            metadata = {
                ...rest,
            };

            if (this.metadata.dateTimes) {
                metadata = {
                    ...metadata,
                    dateTimes: this.metadata.dateTimes.toJSON(),
                };
            }
        }

        return metadata;
    }
}
