import { ClientUser } from '@spinach-shared/models';
import {
    JoiningParticipant,
    ParticipantJoiningRequest,
    StandUpStatus,
    UserSeriesMetadata,
} from '@spinach-shared/types';

import { createWebsocketPayload } from './analytics';
import { getClientPlatform } from './platform';

export function getParticipantJoiningRequest(
    user: ClientUser,
    seriesMetadata: UserSeriesMetadata
): Omit<ParticipantJoiningRequest, 'reconnecting' | 'participant'> & {
    participant: Omit<JoiningParticipant, 'featureFlagMap'>;
} {
    const meetingPlatform = getClientPlatform();

    return createWebsocketPayload<
        Omit<ParticipantJoiningRequest, 'reconnecting' | 'participant'> & {
            participant: Omit<JoiningParticipant, 'featureFlagMap'>;
        }
    >({
        meetingPlatform,
        seriesMetadata,
        seriesSlug: seriesMetadata.id,
        modules: [],
        participant: {
            displayName: user.preferredName,
            id: user.spinachUserId,
            email: user.email,
            spinachUserId: user.spinachUserId,
            metadata: user.metadata,
            participation: [{ startedAt: new Date().toISOString() }],
            integrationSettings: user.integrationSettings,
            platform: meetingPlatform,
            standUpStatus: StandUpStatus.Preparing,
            appVersion: process.env.REACT_APP_APP_VERSION,
        },
    });
}
