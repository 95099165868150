import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { SpinachAPIOptions } from '../types';
import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';

export async function postSpinachAPI<T, U extends object = object, V extends Record<string, any> = Record<string, any>>(
    path: SpinachAPIPath | string,
    request: U,
    opts: SpinachAPIOptions<V> = {}
): Promise<T | undefined> {
    try {
        const { params, headers = {} } = opts;
        const response = await axios.post<T>(`${process.env.REACT_APP_AUTH_URL}${path}`, request, {
            headers: {
                ...headers,
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Content-Type': 'application/json',
                'Cache-Control': 'no-store',
            },
            params,
            withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
        });

        return response.data;
    } catch (e) {
        if (opts.throwOnError) {
            throw e;
        }
        return undefined;
    }
}
