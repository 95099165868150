import { CalendarEvent, GoogleCalendarEventListOpts, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../apis';

export const fetchGoogleCalendarEvents = async (opts?: Partial<GoogleCalendarEventListOpts>) => {
    const response = await getSpinachAPI<{ events: CalendarEvent[] }>(SpinachAPIPath.GoogleCalendarEvents, {
        params: opts,
        throwOnError: true,
    });

    if (!response?.events?.length || !response.events[0].id) {
        return [];
    }

    return response.events;
};
