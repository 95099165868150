import { useState } from 'react';

import { ClientEventType, ExperimentKey, WebUrlQuery } from '@spinach-shared/types';

import SpinachAIJira from '../../../../assets/spinach-ai-jira.png';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
} from '../../../../hooks';
import { BodyLarger, BodyRegular, HeaderThreeOnboard } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Stationary } from '../../../series/first-series/common';
import { PrimaryButton } from '../../../stand-up';
import { ViewContainer } from '../../OnboardContainer';
import { Column, Spacing } from '../../framing';

export function IntegrateWithJiraForExperiment(): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const startDetection = useIntegrationDetection();
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    useLandingAnalytic(ClientEventType.UserLandedOnIntegrateJiraForSummary);

    return (
        <ViewContainer>
            <Stationary style={{ minHeight: 'unset', width: '80vw', maxWidth: '500px' }}>
                <HeaderThreeOnboard>See the magic</HeaderThreeOnboard>
                <Spacing />
                <BodyLarger>Start by connecting Jira</BodyLarger>
                <Spacing />

                <img src={SpinachAIJira} style={{ width: '80%' }} />

                <Spacing />

                <Column style={{ width: '80%' }}>
                    <BodyRegular style={{ textAlign: 'center' }}>
                        We process your Jira change log to get you a natural language summary in seconds.
                    </BodyRegular>
                    <Spacing />
                    <BodyRegular style={{ textAlign: 'center' }}>
                        Your Change Log data is encrypted and not stored anywhere.
                    </BodyRegular>
                </Column>

                <Spacing factor={2} />

                <PrimaryButton
                    title="Connect to Jira"
                    onClick={async () => {
                        setIsLoading(true);

                        track(ClientEventType.UserAuthorizeJiraClick, { Location: ExperimentKey.AISummary });
                        startDetection();
                        URLUtil.openURL(
                            `${process.env.REACT_APP_AUTH_URL}/jira/auth-url/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                        );

                        setIsLoading(false);
                    }}
                    isLoading={isLoading}
                />
            </Stationary>
        </ViewContainer>
    );
}
