import { Popover, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Titles } from '@spinach-clients/constants';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SlackLogo } from '../../assets/slack-logo.svg';
import { GlobalModal } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useEmailSummariesEnablement,
    useExperienceTracking,
    useFreeTierLimitations,
    useGlobalModal,
    useWindowSize,
} from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { lightTheme } from '../../styles';
import { withAccessibleSubmitProps } from '../../utils';
import { OptionRow, RegularCardOptionItem } from '../series/common';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import { BootstrapTooltip } from './BootstrapTooltip';
import { FreeTierLimitationMessage, LimitationIntent } from './FreeTierMessages';
import { Column } from './framing';

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        backgroundColor: lightTheme.neutrals.white,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

export type SendOptionsProps = {
    parentId: ElementId;
    onCopyClick: () => void;
    style?: React.CSSProperties;
};

const ButtonRow = styled(OptionRow)`
    width: inherit;
    padding: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

function CopySummary({ onCopyClick }: { onCopyClick: () => void }): JSX.Element {
    const { width } = useWindowSize();
    const tooltipPlacement = width < 600 ? 'top' : 'left';
    const isFreeTierLimited = useFreeTierLimitations();

    return (
        <BootstrapTooltip
            title={
                isFreeTierLimited ? (
                    <FreeTierLimitationMessage intent={LimitationIntent.SummaryExport} style={{ fontSize: '14px' }} />
                ) : (
                    'Copy a formatted summary to your clipboard'
                )
            }
            placement={tooltipPlacement}
            interactive={isFreeTierLimited}
        >
            <Column style={{ width: '160px' }}>
                <ButtonRow
                    style={{
                        cursor: isFreeTierLimited ? 'not-allowed' : 'pointer',
                    }}
                    {...withAccessibleSubmitProps((e) => {
                        e.stopPropagation();
                        onCopyClick();
                    })}
                >
                    <FileCopyOutlinedIcon
                        htmlColor={lightTheme.primary.greenLight}
                        fontSize="small"
                        style={{ marginRight: '10px' }}
                    />
                    <RegularCardOptionItem>Copy Summary</RegularCardOptionItem>
                </ButtonRow>
            </Column>
        </BootstrapTooltip>
    );
}

function SlackSettingsRow(): JSX.Element {
    const { openIntegrationsSettings } = useGlobalMeetingSettings();

    return (
        <Column style={{ width: '160px' }}>
            <ButtonRow
                {...withAccessibleSubmitProps((e) => {
                    e.stopPropagation();
                    openIntegrationsSettings();
                })}
            >
                <SlackLogo style={{ width: '15px', marginLeft: '4px', marginRight: '10px' }} />
                <RegularCardOptionItem>Slack Output</RegularCardOptionItem>
            </ButtonRow>
        </Column>
    );
}

function SubscribeEmails({ onClick }: { onClick: () => void }): JSX.Element {
    const { width } = useWindowSize();
    const tooltipPlacement = width < 600 ? 'top' : 'left';
    const isFreeTierLimited = useFreeTierLimitations();

    return (
        <BootstrapTooltip
            title={
                isFreeTierLimited ? (
                    <FreeTierLimitationMessage intent={LimitationIntent.SummaryExport} style={{ fontSize: '14px' }} />
                ) : (
                    'Send emails to receive each summary'
                )
            }
            placement={tooltipPlacement}
            interactive={isFreeTierLimited}
        >
            <Column style={{ width: '160px' }}>
                <ButtonRow
                    style={{
                        cursor: isFreeTierLimited ? 'not-allowed' : 'pointer',
                    }}
                    {...withAccessibleSubmitProps((e) => {
                        e.stopPropagation();
                        onClick();
                    })}
                >
                    <EmailOutlinedIcon
                        htmlColor={lightTheme.primary.greenLight}
                        fontSize="small"
                        style={{ marginRight: '10px' }}
                    />
                    <RegularCardOptionItem>Send Emails</RegularCardOptionItem>
                </ButtonRow>
            </Column>
        </BootstrapTooltip>
    );
}

export function SendOptions({ parentId, onCopyClick, style }: SendOptionsProps): JSX.Element {
    const popoverStyles = usePopoverStyles();
    const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
    const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const isFreeTierLimited = useFreeTierLimitations();

    const isEmailSummariesEnabled = useEmailSummariesEnablement();

    const buttonId = `${ElementId.SendOptions}-${parentId}`;

    function onOptionsButtonClick() {
        track(ClientEventType.SendOptionsButtonClick, { Location: parentId });
        setStatusMenuAnchorEl(document.getElementById(buttonId));
        setIsStatusMenuOpen(true);
    }

    function onCopyOptionClick() {
        if (isFreeTierLimited) {
            return;
        }

        onCopyClick();
        setIsStatusMenuOpen(false);
    }

    function onSendEmailsClick() {
        if (isFreeTierLimited) {
            return;
        }

        track(ClientEventType.OpenSubscribeSeriesOutputModalClick, { Location: parentId });
        setGlobalModal(GlobalModal.SubscribeSeriesOutput, { location: parentId });
        setIsStatusMenuOpen(false);
    }

    return (
        <>
            <OutlinedButton
                onClick={() => {
                    onOptionsButtonClick();
                }}
                title={Titles.Send}
                endIcon={<KeyboardArrowDown />}
                id={buttonId}
                style={style}
            />
            <Popover
                style={{
                    padding: '10px',
                }}
                classes={popoverStyles}
                id={`popover-${ElementId.SendOptions}`}
                open={isStatusMenuOpen}
                anchorEl={statusMenuAnchorEl}
                onClose={() => {
                    setIsStatusMenuOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Column>
                    <CopySummary onCopyClick={onCopyOptionClick} />
                    {isEmailSummariesEnabled ? <SubscribeEmails onClick={onSendEmailsClick} /> : null}
                    <SlackSettingsRow />
                </Column>
            </Popover>
        </>
    );
}
