import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postVerifyGoogleWithCode } from '../../apis';
import { useExperienceTracking, useGlobalModal, useGlobalRouting } from '../../hooks';
import { AnonymousUserTracker } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { FYI, FYIState } from '../common';

export function VerifyGoogleCode() {
    const [params] = useSearchParams();
    const [, setGlobalModal] = useGlobalModal();
    const { routeToVerify } = useGlobalRouting();
    const track = useExperienceTracking();

    useEffect(() => {
        async function verifyCode() {
            const code = params.get(WebUrlQuery.Code);
            if (!code) {
                return;
            }

            const response = await postVerifyGoogleWithCode(code);
            if (response?.user) {
                const validatedUser = new ClientUser(response.user);

                AnonymousUserTracker.trackUser(response.user._id);
                AnonymousUserTracker.trackEvent(ClientEventType.UserGoogleCodeVerifySuccess);
                await track(ClientEventType.UserEmailGoogleVerifyCodeSuccessBackend, {
                    ...validatedUser.toUserIdentityPayload(),
                    IsNewUser: response.isNewUser,
                });

                if (response.isNewUser) {
                    TagManager.trackSignUp(response.user.email);
                }

                setGlobalModal(null);

                window.location.href = `${getWebAppHost()}${window.location.search}`;
            }
        }

        verifyCode().catch(() => {
            routeToVerify();
        });
    }, [params.get(WebUrlQuery.Code)]);

    return <FYI state={FYIState.Loading} />;
}
