import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders, isLocalStage } from '@spinach-shared/utils';

import { getMockZoomQueryParams } from '..';
import { SpinachAPIOptions } from '../types';
import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';

export async function getSpinachAPI<T, U extends Record<string, any> = Record<string, any>>(
    path: SpinachAPIPath | string,
    opts: SpinachAPIOptions<U> = {}
): Promise<T | undefined> {
    try {
        const { params, headers = {} } = opts;
        const stubbedParams = isLocalStage() ? { ...getMockZoomQueryParams(), ...params } : params;

        const response = await axios.get<T>(`${process.env.REACT_APP_AUTH_URL}${path}`, {
            headers: {
                ...headers,
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Cache-Control': 'no-store',
            },
            withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
            params: stubbedParams,
        });

        return response.data ?? undefined;
    } catch (error) {
        if (opts.throwOnError) {
            throw error;
        }
        return undefined;
    }
}
