import moment from 'moment';

import { SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS } from '@spinach-shared/constants';
import { OutputPlatform } from '@spinach-shared/types';

import { useGlobalLiveSeries } from './useLiveSeries';
import { useGlobalStoredSeries } from './useSeriesData';

/**
 * /**
 *
 * @returns `text`: a string for the SCHEDULED format text. if an empty string is returned, there is nothing scheduled.
 * if a string is returned, it could be for either sync or async.
 * `isAsync`: whether the current meeting is async or not
 */
export function useMeetingFormatText(): { text: string; isAsync: boolean } {
    const [liveSeries] = useGlobalLiveSeries();
    const [storedSeries] = useGlobalStoredSeries();

    const slackIntegration = storedSeries?.integrationSettings?.summaryOutputSettings?.find(
        (i) => i.platform === OutputPlatform.Slack
    );
    const channels = slackIntegration?.destinationSettings
        ?.filter((d) => !!d)
        .map((destination) => destination.channelName);
    const dateTimeMetadata = liveSeries.currentMeeting.dateTimeMetadata;
    const dateTime = liveSeries.currentMeeting.getNextScheduledStartDate(true);
    const isAsync = liveSeries.currentMeeting.isAsyncMeeting;

    let asyncFormatText = '';
    let liveFormatText = '';

    if (dateTimeMetadata && dateTime) {
        const fullTimeText = dateTime.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[0];
        const amPmText = dateTime.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[1].toLowerCase();
        const timeZoneValueText = dateTime.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[2];
        const timeValueText = fullTimeText.split(':').slice(0, -1).join(':');
        const day = dateTimeMetadata.day;

        const isToday = moment(dateTime).day() === moment().day();
        const todaysScheduledMoment = moment()
            .hour(dateTime.getHours() + SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS)
            .minutes(dateTime.getMinutes());
        const isTodayNextWeek = isToday && moment(dateTime).isAfter(todaysScheduledMoment);

        asyncFormatText = channels?.length
            ? `Updates will be posted to ${channels.map((channel, i) =>
                  i === 0 ? `#${channel}` : ` and #${channel}`
              )} ${
                  !isTodayNextWeek && isToday ? 'today' : `on ${isTodayNextWeek ? 'next' : ''} ${day}`
              } at ${timeValueText}${amPmText} ${timeZoneValueText}`
            : `Updates will be saved to Summary ${
                  !isTodayNextWeek && isToday ? 'today' : `on ${isTodayNextWeek ? 'next' : ''} ${day}`
              } at ${timeValueText}${amPmText} ${timeZoneValueText}`;

        liveFormatText = `Meeting starts ${
            !isTodayNextWeek && isToday ? 'today' : `on ${isTodayNextWeek ? 'next' : ''} ${day}`
        } at ${timeValueText}${amPmText} ${timeZoneValueText}`;
    }

    return { isAsync, text: isAsync ? asyncFormatText : liveFormatText };
}
