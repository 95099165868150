import { ClientEventType, MeetingSettingsSubview, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import GoogleCalendarSrc from '../../../assets/google-cal.png';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';

function useGoogleCalendarIntegrationRow() {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    function openSettingsOnSuccess() {
        setSubview(MeetingSettingsSubview.CalendarSettings);
    }

    const startDetection = useIntegrationDetection(openSettingsOnSuccess);

    const isConnectFlow = !user.isAuthedForGoogleCalendar;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeGoogleCalendarClick);
            startDetection();
            URLUtil.openURL(
                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
            );
        } else {
            setSubview(MeetingSettingsSubview.CalendarSettings);
            track(ClientEventType.OpenGoogleCalendarSettingsSubviewClick);
        }
    }

    return {
        subtext: 'Manage which meetings Spinach is a part of',
        onClick,
        isConnected: user.isAuthedForGoogleCalendar,
        buttonText: user.isAuthedForGoogleCalendar ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
    };
}

export function GoogleCalendarIntegrationRow(): JSX.Element {
    const { onClick, subtext, isConnected, buttonText } = useGoogleCalendarIntegrationRow();

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Google Calendar'}
            icon={<img src={GoogleCalendarSrc} style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
        />
    );
}
