import { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Sentiment, SentimentPickerLocation } from '@spinach-shared/types';

import { ReactComponent as GreenCircle } from '../../assets/sentiment/green-circle.svg';
import { ReactComponent as RedSquare } from '../../assets/sentiment/red-square.svg';
import { ReactComponent as RemoveButton } from '../../assets/sentiment/remove-button.svg';
import { ReactComponent as YellowTriangle } from '../../assets/sentiment/yellow-triangle.svg';
import { useSentimentCopy, useSentimentOpacity } from '../../hooks';
import { BodyRegular, responsiveness } from '../../styles';
import { withFullStoryMasking } from '../../utils';
import { Row } from '../common';

export const sentimentColorMap = {
    [Sentiment.Ok]: '#FBB96C',
    [Sentiment.Good]: '#4DCE33',
    [Sentiment.Bad]: '#EB7656',
};

type SentimentProps = {
    title?: string;
    id?: string;
    isParentHovered?: boolean;
    isHovered?: boolean;
    className?: string;
    location: SentimentPickerLocation;
    selectedSentiment?: Sentiment;
    onSelect?: (sentiment: Sentiment | undefined) => void;
};

const SentimentComponentContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3px 0px;
    height: 14px;
    align-items: center;

    &:focus {
        border: 1px solid #f9a11f;
    }
`;

const sentimentIconMap: Record<Sentiment, (props: SentimentProps) => JSX.Element> = {
    [Sentiment.Good]: ({ location, isHovered }) =>
        location === SentimentPickerLocation.TeamMood ? (
            <>💚</>
        ) : isHovered ? (
            <RemoveButton />
        ) : (
            <GreenCircle style={{ width: '14px', height: '14px' }} />
        ),
    [Sentiment.Ok]: ({ location, isHovered }) =>
        location === SentimentPickerLocation.TeamMood ? (
            <>💛</>
        ) : isHovered ? (
            <RemoveButton />
        ) : (
            <YellowTriangle style={{ width: '14px', height: '14px' }} />
        ),
    [Sentiment.Bad]: ({ location, isHovered }) =>
        location === SentimentPickerLocation.TeamMood ? (
            <>🔴</>
        ) : isHovered ? (
            <RemoveButton />
        ) : (
            <RedSquare style={{ width: '14px', height: '14px' }} />
        ),
};

const BaseSentimentComponent = ({
    sentiment,
    selectedSentiment,
    onSelect,
    isParentHovered,
    isPickerHovered,
    location,
    id,
}: SentimentComponentProps) => {
    const isSentimentOpacityEnabled = useSentimentOpacity();
    const interactive = !!onSelect;
    const [isHovered, setIsHovered] = useState(false);
    return (
        <SentimentButton
            className={withFullStoryMasking()}
            sentiment={sentiment}
            sentimentOpacity={isSentimentOpacityEnabled}
            onMouseEnter={() => {
                if (selectedSentiment === sentiment && interactive) {
                    setIsHovered(true);
                }
            }}
            onMouseLeave={() => setIsHovered(false)}
            location={location}
            selectedSentiment={selectedSentiment}
            interactive={interactive}
            isHovered={isHovered}
            isPickerHovered={isPickerHovered}
            isParentHovered={isParentHovered}
            onClick={() => onSelect?.(sentiment === selectedSentiment ? undefined : sentiment)}
            id={id}
        >
            {sentimentIconMap[sentiment]({ location, isHovered })}
        </SentimentButton>
    );
};

export const SentimentComponent = (props: { id?: string } & SentimentComponentProps) => {
    const { location, selectedSentiment, sentiment } = props;
    return location === SentimentPickerLocation.UpdateFooter &&
        !!selectedSentiment &&
        selectedSentiment !== sentiment ? (
        <></>
    ) : (
        <BaseSentimentComponent {...props} />
    );
};

type SentimentComponentProps = SentimentProps & {
    isPickerHovered?: boolean;
    sentiment: Sentiment;
    interactive?: boolean;
};

const TeamTopicsSentimentCSS = css<SentimentComponentProps>``;

const JiraMagicSentimentCSS = css<SentimentComponentProps>`
    width: ${(props) => (props.interactive ? '32px' : 'unset')};
    height: 16px;
`;

const SentimentButton = styled(BodyRegular)<SentimentComponentProps & { sentimentOpacity?: boolean }>`
    display: flex;
    justify-content: center;
    cursor: ${(props) => (props.interactive ? 'pointer' : 'default')};
    align-items: center;
    padding-left: ${(props) => (props.interactive ? 'unset' : '3px')};
    padding-right: ${(props) => (props.interactive ? 'unset' : '2px')};
    /* max-width: ${(props) => (props.interactive ? '40px' : '12px')}; */
    min-width: ${(props) =>
        props.location === SentimentPickerLocation.UpdateFooter ? '16px' : props.interactive ? '40px' : '12px'};
    height: 21px;

    margin-right: ${(props) => (props.interactive ? '2px' : 'unset')};
    margin-left: ${(props) => (props.interactive ? '2px' : 'unset')};
    opacity: ${(props) => {
        if (!props.sentimentOpacity || (props.isParentHovered && !props.isPickerHovered)) {
            return !!props.selectedSentiment && props.sentiment !== props.selectedSentiment ? '0.25' : 'unset';
        }

        if (props.location === SentimentPickerLocation.TeamMood) {
            if (!!props.selectedSentiment && props.sentiment !== props.selectedSentiment) {
                return '0.25';
            }
            return 'unset';
        }
        if (props.interactive && (!props.selectedSentiment || props.sentiment !== props.selectedSentiment)) {
            return '0.25';
        }

        return 'unset';
    }};

    &:hover {
        opacity: unset;
    }

    @media ${`(max-width: 545px)`} {
        border: unset;
        background-color: unset;
        max-width: 35px;
    }

    @media ${responsiveness.thinnerThanXS} {
        width: ${(props) =>
            props.location === SentimentPickerLocation.UpdateFooter ? '16px' : props.interactive ? '25px' : '12px'};
    }

    @media ${responsiveness.lg} {
        width: ${(props) =>
            props.location === SentimentPickerLocation.UpdateFooter ? '16px' : props.interactive ? '40px' : '12px'};
    }

    ${(props) =>
        props.location === SentimentPickerLocation.JiraMagic ? JiraMagicSentimentCSS : TeamTopicsSentimentCSS};
`;

export const SentimentPicker = forwardRef<HTMLDivElement, SentimentProps>(
    ({ onSelect, selectedSentiment, isParentHovered, location, title, id, className }, ref) => {
        const sentimentCopy = useSentimentCopy();
        const text = title ?? sentimentCopy;

        const [isPickerHovered, setIsPickerHovered] = useState(false);

        return (
            <Row className={className} id={id}>
                {text ? (
                    <BodyRegular
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            minWidth: '40px',
                            paddingRight: '20px',
                            fontSize: '15px',
                            color: '#1D1D27',
                            cursor: 'default',
                        }}
                    >
                        {text}
                    </BodyRegular>
                ) : (
                    <></>
                )}
                <SentimentComponentContainer
                    onMouseEnter={() => setIsPickerHovered(true)}
                    onMouseLeave={() => setIsPickerHovered(false)}
                    onMouseDown={(e) => e.preventDefault()}
                    tabIndex={0}
                    ref={ref}
                >
                    <SentimentComponent
                        location={location}
                        sentiment={Sentiment.Good}
                        isParentHovered={isParentHovered}
                        isPickerHovered={isPickerHovered}
                        selectedSentiment={selectedSentiment}
                        onSelect={onSelect}
                        id={id ? `${id}-${Sentiment.Good}` : undefined}
                    />
                    <SentimentComponent
                        location={location}
                        sentiment={Sentiment.Ok}
                        isParentHovered={isParentHovered}
                        isPickerHovered={isPickerHovered}
                        selectedSentiment={selectedSentiment}
                        onSelect={onSelect}
                        id={id ? `${id}-${Sentiment.Ok}` : undefined}
                    />
                    <SentimentComponent
                        location={location}
                        sentiment={Sentiment.Bad}
                        isParentHovered={isParentHovered}
                        isPickerHovered={isPickerHovered}
                        selectedSentiment={selectedSentiment}
                        onSelect={onSelect}
                        id={id ? `${id}-${Sentiment.Bad}` : undefined}
                    />
                </SentimentComponentContainer>
            </Row>
        );
    }
);
