import { FileCopy } from '@material-ui/icons';
import { CheckCircle } from '@material-ui/icons';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { ClientEventType, JiraProject, JiraUser, TimeInMillis } from '@spinach-shared/types';

import { useExperienceTracking, useLandingAnalytic } from '../../../../hooks';
import { BodyLarge, BodyRegular, HeaderThreeOnboard, Hyperlink, lightTheme } from '../../../../styles';
import { URLUtil, copyTextToClipboard } from '../../../../utils';
import { Stationary } from '../../../series/first-series/common';
import { AnimatedSection, AnimatedText, AnimatingText, ScrollArea, fadeIn } from '../../../stand-up';
import { Notification } from '../../../stand-up';
import { FloatingButton } from '../../FloatingButton';
import { ViewContainer } from '../../OnboardContainer';
import { Column, Hairline, Row, Spacing } from '../../framing';
import { SummarySidebar } from './SummarySidebar';

const SidebarContainer = styled(Column)`
    background-color: ${(props) => props.theme.neutrals.grayLight};
    width: 320px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    padding: 20px;
`;

const FADE_IN_DELTA_MS: TimeInMillis = 250;

const fadeInAnimation = (delay: TimeInMillis) => css`
    animation: ${fadeIn};
    animation-duration: ${FADE_IN_DELTA_MS}ms;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: ${delay}ms;
`;

const FadingBodyLarge = styled(BodyLarge)<{ delay: number }>`
    ${(props) => fadeInAnimation(props.delay)}
`;

const FadingListItem = styled.li<{ delay: number }>`
    ${(props) => fadeInAnimation(props.delay)}
`;

export type SummaryBySection = Record<string, string[]>;
export type SummaryBySectionState = SummaryBySection | null;

export function GenerateWeeklySummary(): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState<SummaryBySectionState>(null);
    const [generationCount, setGenerationCount] = useState(0);
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [selectedProject, setSelectedProject] = useState<JiraProject | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<JiraUser[]>([]);

    const track = useExperienceTracking();

    useLandingAnalytic(ClientEventType.UserLandedOnGenerateSummaryPage);

    return (
        <ViewContainer>
            <Stationary style={{ width: '85vw', padding: '0px' }}>
                <Row style={{ height: '100%', borderRadius: '32px' }}>
                    <SidebarContainer>
                        <SummarySidebar
                            isLoadingSummary={isLoading}
                            setIsLoadingSummary={setIsLoading}
                            setSummary={setSummary}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            setGenerationCount={setGenerationCount}
                            generationCount={generationCount}
                        />
                    </SidebarContainer>
                    <Column style={{ padding: '20px' }}>
                        <HeaderThreeOnboard>Weekly Summary</HeaderThreeOnboard>

                        <Hairline />

                        {isLoading ? (
                            <Column
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AnimatedSection>
                                    <AnimatingText>
                                        <AnimatedText>Gathering weekly progress</AnimatedText>
                                    </AnimatingText>
                                    <div>
                                        <AnimatedText>Consolidating changelog</AnimatedText>
                                    </div>
                                    <div>
                                        <AnimatedText>Saving you hours</AnimatedText>
                                    </div>
                                </AnimatedSection>
                            </Column>
                        ) : (!selectedProject || !selectedUsers.length || !summary) && !summary ? (
                            <Column
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <BodyRegular>Select your project and your teammates to start</BodyRegular>
                            </Column>
                        ) : !!summary ? (
                            <>
                                <ScrollArea style={{ height: '50vh' }} sidePadding={0}>
                                    {Object.entries(summary).map(([title, items]) => {
                                        // TODO; move this elsewhere, memoized
                                        const orderedLines: string[] = [];
                                        Object.entries(summary).forEach(([title, items]) => {
                                            orderedLines.push(title);
                                            items.forEach((item) => orderedLines.push(item));
                                        });

                                        return (
                                            <Column key={title}>
                                                <FadingBodyLarge
                                                    delay={
                                                        FADE_IN_DELTA_MS *
                                                        orderedLines.findIndex((line) => line === title)
                                                    }
                                                >
                                                    {title}
                                                </FadingBodyLarge>
                                                <ul>
                                                    {items.map((item, i) => (
                                                        <FadingListItem
                                                            key={i}
                                                            delay={
                                                                FADE_IN_DELTA_MS *
                                                                orderedLines.findIndex((line) => line === item)
                                                            }
                                                        >
                                                            <BodyRegular style={{ marginBottom: '10px' }}>
                                                                {item}
                                                            </BodyRegular>
                                                        </FadingListItem>
                                                    ))}
                                                </ul>
                                            </Column>
                                        );
                                    })}
                                </ScrollArea>

                                <Spacing factor={2 / 3} />

                                {summary ? (
                                    <Row>
                                        <Column centered>
                                            {/* <FloatingButton
                                                startIcon={<SlackLogo style={{ width: '25px', height: '25px' }} />}
                                                onClick={() => {
                                                    // TODO: use slack state and URl opening
                                                }}
                                            >
                                                Share in Slack channel
                                            </FloatingButton> */}
                                            {/* <FloatingButton
                                                startIcon={<MailOutlineRounded />}
                                                onClick={() => {
                                                    // TODO: use slack state and URl opening
                                                }}
                                            >
                                                Email me a weekly report
                                            </FloatingButton> */}
                                            <FloatingButton
                                                startIcon={<FileCopy />}
                                                onClick={() => {
                                                    setIsToastOpen(true);
                                                    const richText = formatGeneratedSummaryToHTML(summary);
                                                    copyTextToClipboard(richText);
                                                    track(ClientEventType.CopiedGeneratedSummaryClick);
                                                }}
                                            >
                                                Copy summary
                                            </FloatingButton>
                                        </Column>
                                    </Row>
                                ) : null}

                                <div style={{ flexGrow: 1 }} />

                                {summary ? <Spacing factor={2 / 3} /> : null}

                                <Row centered>
                                    <BodyRegular>
                                        Powered by{' '}
                                        <Hyperlink
                                            style={{ fontWeight: 'bold' }}
                                            onClick={() => {
                                                track(ClientEventType.SpinachMarketingSiteClick, {
                                                    Location: 'bottom-of-generated-summary-experiment',
                                                });
                                                URLUtil.openURL('https://spinach.io');
                                            }}
                                        >
                                            Spinach.io
                                        </Hyperlink>
                                    </BodyRegular>
                                </Row>
                            </>
                        ) : null}
                    </Column>
                </Row>
            </Stationary>

            <Notification
                isOpen={isToastOpen}
                containerStyle={{ bottom: '55px', position: 'relative', height: '0px', width: '100px' }}
                onClose={() => setIsToastOpen(false)}
                message="Summary copied!"
                icon={
                    <CheckCircle style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />
                }
            />
        </ViewContainer>
    );
}

export type HTMLString = string;

export function formatGeneratedSummaryToHTML(summary: SummaryBySection): HTMLString {
    const formattedForCopying = Object.entries(summary).reduce((html, [title, items]) => {
        html = `
${html}

<b>${title}</b>
<br/>
<ul>
    ${items.map((item) => `<li>${item}</li>`).join('\n')}
</ul>
<br />
        `;

        return html;
    }, '');

    return formattedForCopying;
}
