import { useEffect, useState } from 'react';

import GeneratingCheckInGifSrc from '../../assets/demo/checkin-loader-v2.gif';
import { ReactComponent as LogoSpinachio } from '../../assets/logo-spinachio.svg';
import { SpinachWindowOverlay } from '../../atoms';
import { useGlobalDemoState, useSeriesReality, useWindowSize } from '../../hooks';
import { BodyBig } from '../../styles';
import { getHeightOffset } from '../../utils';
import { Column, LoadingSquares, Row } from '../common';

export function DemoSpinachStandupOverlay({
    spinachWindowOverlay,
    hasNavBar,
}: {
    spinachWindowOverlay: SpinachWindowOverlay;
    hasNavBar: boolean;
}): JSX.Element {
    const { height } = useWindowSize();
    const { setDemoState, demoState, demoModal } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();

    const FINISH_LOADING_SPINACH_AFTER_MS = 2 * 4000;

    const calculatedHeight = height - getHeightOffset(hasNavBar, isDemoSeries);

    const [hasFiredLoadingSpinach, setHasFiredLoadingSpinach] = useState(false);
    const [hasFiredGeneratingCheckin, setHasFiredGeneratingCheckin] = useState(false);

    useEffect(() => {
        if (!hasFiredLoadingSpinach && spinachWindowOverlay === SpinachWindowOverlay.LoadingSpinachBrowser) {
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    spinachWindowOverlay: SpinachWindowOverlay.GeneratingCheckIn,
                    demoModal: null,
                });
                setHasFiredLoadingSpinach(true);
            }, FINISH_LOADING_SPINACH_AFTER_MS);
        }

        if (!hasFiredGeneratingCheckin && spinachWindowOverlay === SpinachWindowOverlay.GeneratingCheckIn) {
            const FINISH_GENERATING_CHECKIN_AFTER_MS = 8300;
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    spinachWindowOverlay: null,
                });
                setHasFiredGeneratingCheckin(true);
            }, FINISH_GENERATING_CHECKIN_AFTER_MS);
        }
    }, [spinachWindowOverlay, demoState]);

    const COLOR_OF_GIF_BACKGROUND_TO_MATCH = '#F3F1F3';
    switch (spinachWindowOverlay) {
        case SpinachWindowOverlay.LoadingSpinachBrowser:
            return (
                <div
                    style={{
                        width: '100%',
                        height: `${calculatedHeight}px`,
                        backgroundColor: COLOR_OF_GIF_BACKGROUND_TO_MATCH,
                        borderRadius: '8px',
                    }}
                >
                    <Column style={{ height: '100%' }}>
                        <Column style={{ width: '100%', justifyContent: 'center' }}>
                            <Row centered style={{ marginTop: '10px' }}>
                                <Row
                                    style={{
                                        width: '90%',
                                        borderRadius: '20px',
                                        height: '30px',
                                        backgroundColor: 'lightgray',
                                    }}
                                >
                                    <BodyBig
                                        style={{
                                            display: 'flex',
                                            marginLeft: '10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <b>www.spinach.io</b>
                                    </BodyBig>
                                </Row>
                            </Row>
                        </Column>
                        <div style={{ display: 'flex', flexGrow: 1 }} />
                        <Column>
                            <Row centered style={{ height: '250px' }}>
                                <LogoSpinachio style={{ width: '200px', height: '200px' }} />
                            </Row>
                            {!demoModal ? <LoadingSquares style={{ marginTop: '-100px' }} /> : null}
                        </Column>
                        <div style={{ display: 'flex', flexGrow: 1 }} />
                    </Column>
                </div>
            );
        case SpinachWindowOverlay.GeneratingCheckIn:
            return (
                <div
                    style={{
                        width: '100%',
                        height: `${calculatedHeight}px`,
                        backgroundColor: COLOR_OF_GIF_BACKGROUND_TO_MATCH,
                        borderRadius: '8px',
                    }}
                >
                    <Column style={{ height: '100%', justifyContent: 'center', width: '100%' }}>
                        <Row centered>
                            <img src={GeneratingCheckInGifSrc} style={{ maxWidth: '90%' }} />
                        </Row>
                    </Column>
                </div>
            );
    }
}
