export enum WebUrlQuery {
    Secret = 'secret',
    Where = 'where',
    Dashboard = 'dashboard',
    Suid = 'suid',
    Email = 'email',
    Popout = 'popout',
    Meeting = 'meeting',
    Window = 'window',
    PlatformSource = 'platform-source',
    Experiment = 'experiment',
    DemoSandboxRecipient = 'for',
    DemoSandboxSender = 'f',
    SubView = 'subview',
    Source = 'source',
    UTMSource = 'utm_source',
    UTMMedium = 'utm_medium',
    UTMCampaign = 'utm_campaign',
    DemoVersion = 'dv',
    Code = 'code',
    State = 'state',
}

export enum MeetingSettingsSubview {
    TrelloSettings = 'trello-settings',
    SlackSettings = 'slack-settings',
    AsanaSettings = 'asana-settings',
    JiraSettings = 'jira-settings',
    LinearSettings = 'linear-settings',
    ClickUpSettings = 'clickup-settings',
    CalendarSettings = 'calendar-settings',
    GoogleSettings = 'calendar-settings',
}

export enum UTMSource {
    ProductHunt = 'product-hunt',
    Slack = 'slack',
    AiEmail = 'ai-email',
    AiVideoChat = 'ai-video-chat',
    AiOnboardingLandingPage = 'ai-onboarding',
}

export enum UTMMedium {
    AsyncReminder = 'async-reminder',
    SlackIntroduction = 'slack-introduction',
}

export enum UTMCampaign {
    SlackConnect = 'slack-connect',
    GoogleCalendarConnect = 'google-calendar-connect',
}
export enum DemoVersion {
    Persona = '1',
    Legacy = '0',
}

export const DEMO_SANDBOX_SENDER_MAP: Record<string, string> = {
    fm: 'Matan',
    fy: 'Yoav',
    fd: 'Derek',
    fc: 'Coburn',
    fj: 'Josh',
};

export enum ExperimentKey {
    AISummary = 'ai-summary',
    StandupScribe = 'standup-scribe',
}
