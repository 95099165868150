import styled, { css } from 'styled-components';

import { responsiveness } from './responsiveness';

const typographyDefaults = css`
    color: ${(props) => props.theme.primary.midnight};
`;

export type ButtonLabelProps = {
    fontWeight?: number | 'normal' | 'bold';
    disabled?: boolean;
    color?: string;
    size?: ButtonSize;
};

const headerCSS = css`
    ${typographyDefaults};
    font-family: 'Sora';
    font-weight: 700;
    margin: 0;
`;

export const HeaderOne = styled.h1`
    ${headerCSS}
    font-size: 64px;
    line-height: 1.5;
`;

export const HeaderTwo = styled.h2`
    ${headerCSS}
    font-size: 40px;
    line-height: 1.5;
`;

export const HeaderThree = styled.h3`
    ${headerCSS}
    font-size: 20px;
    line-height: 1.5;
`;

export const HeaderThreeOnboard = styled.h3`
    ${headerCSS}
    line-height: 1.5;
    align-self: center;
    text-align: center;

    @media ${responsiveness.sm} {
        font-size: 32px;
    }

    @media ${responsiveness.thinnerThanSM} {
        font-size: 24px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 20px;
    }
`;

const bodyCSS = css`
    ${typographyDefaults};
    font-family: 'Gilroy';
`;

export const BodyHuge = styled.div`
    ${bodyCSS};
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
`;

export const BodyLarger = styled.div`
    ${bodyCSS};
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.24em;
    text-transform: uppercase;
`;

export const BodyLarge = styled.div`
    ${bodyCSS};
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.24em;
    text-transform: uppercase;
`;

export const BodyRegular = styled.div`
    ${bodyCSS};
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.3px;
`;

export const BodyRegularOnboard = styled.div`
    ${bodyCSS};
    font-weight: normal;
    line-height: 125%;
    letter-spacing: 0.3px;

    @media ${responsiveness.sm} {
        font-size: 16px;
    }

    @media ${responsiveness.thinnerThanSM} {
        font-size: 12px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 10px;
    }
`;

export const BodyBig = styled.div`
    ${bodyCSS};
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.3px;
`;

export const BodyBigger = styled(BodyBig)`
    font-size: 18px;
`;

export const AttachmentPill = styled.div`
    position: relative;
    padding: 2px 10px 2px 10px;
    margin-right: 3px;
    border-radius: 11px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
`;

export const BodyBigOnboard = styled.div`
    ${bodyCSS};
    font-weight: normal;
    line-height: 125%;
    letter-spacing: 0.3px;

    @media ${responsiveness.sm} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanSM} {
        font-size: 16px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 14px;
    }
`;

export const BodySmallOnboard = styled.div`
    ${bodyCSS};
    font-weight: normal;
    line-height: 125%;
    letter-spacing: 0.3px;

    @media ${responsiveness.sm} {
        font-size: 12px;
    }

    @media ${responsiveness.thinnerThanSM} {
        font-size: 10px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 8px;
    }
`;

export const ResponsiveModalTitle = styled(BodyHuge)`
    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 18px;
    }
`;

export const BodySubtitle = styled.div<{
    color?: string;
    fontWeight?: number | 'normal' | 'bold' | 'bolder' | 'lighter' | 'number' | 'initial' | 'inherit';
}>`
    ${bodyCSS};
    font-weight: ${(props) => props.fontWeight ?? 400};
    font-size: 12px;
    color: ${(props) => props.color ?? props.theme.primary.green};
    line-height: 150%;
`;

const buttonCSS = css`
    ${typographyDefaults};
    font-family: 'Gilroy';
`;

export const BUTTON_LABEL_SIZE = 16;

function getButtonFontSize(size: ButtonSize): number {
    switch (size) {
        case ButtonSize.Normal:
            return BUTTON_LABEL_SIZE;
        case ButtonSize.Small:
            return 14;
        case ButtonSize.Mini:
            return 12;
        case ButtonSize.Micro:
            return 10;
    }
}

export const ButtonLabel = styled.span<ButtonLabelProps>`
    ${buttonCSS};
    font-size: ${(props) => (props.size ? `${getButtonFontSize(props.size)}px` : `${BUTTON_LABEL_SIZE}px`)};
    line-height: 1.5;
    user-select: none;
`;

export const PrimaryButtonLabel = styled(ButtonLabel)<ButtonLabelProps>`
    font-weight: ${(props) => props.fontWeight ?? 700};
    color: ${(props) => props.color ?? props.theme.neutrals.white};
`;

export const SecondaryButtonLabel = styled(ButtonLabel)<ButtonLabelProps>`
    font-weight: ${(props) => props.fontWeight ?? 600};
    color: ${(props) => (props.disabled ? props.theme.tertiary.midnight : props.theme.primary.green)};
`;

export enum ButtonSize {
    Normal = 'normal',
    Small = 'small',
    Mini = 'mini',
    Micro = 'micro',
}

export const Hyperlink = styled.a`
    cursor: pointer;
    text-decoration: underline;
`;

export const withSelectionPrevention = () => css<{ preventSelection?: boolean }>`
    user-select: ${(props) => (props.preventSelection ? 'none' : 'initial')};
`;
