import { DemoVersion, ExperimentKey, ISOString } from '.';
import { SlackUserSettings } from '..';
import { SpinachIntegration } from './Integration';
import { OutputPlatform, UUID, UserSeriesMetadata } from './Series';
import { ZoomParticipantRole } from './Zoom';
import { validateEnumUniqueness } from './validateEnumUniqueness';

export enum IntegrationCode {
    Zoom = 'zoom',
    GoogleMeet = 'google-meet',
    MicrosoftTeams = 'microsoft-teams',
    Slack = 'slack',
    GoogleChat = 'google-chat',
    Discord = 'discord',
    WhatsApp = 'whats-app',
    Telegram = 'telegram',
    JiraSoftware = 'jira-software',
    Asana = 'asana',
    Trello = 'trello',
    Monday = 'monday-com',
    ClickUp = 'clickup',
    Github = 'github',
    Gitlab = 'gitlab',
    Linear = 'linear',
    Notion = 'notion',
    Other = 'other',
}

export const IntegrationLabelMap = {
    [IntegrationCode.GoogleMeet]: 'Google Meet',
    [IntegrationCode.MicrosoftTeams]: 'Microsoft Teams',
    [IntegrationCode.Zoom]: 'Zoom',
    [IntegrationCode.Slack]: 'Slack',
    [IntegrationCode.GoogleChat]: 'Google Chat',
    [IntegrationCode.Discord]: 'Discord',
    [IntegrationCode.WhatsApp]: 'Whatsapp',
    [IntegrationCode.Telegram]: 'Telegram',
    [IntegrationCode.JiraSoftware]: 'Jira Software',
    [IntegrationCode.Asana]: 'Asana',
    [IntegrationCode.Trello]: 'Trello',
    [IntegrationCode.Monday]: 'Monday.com',
    [IntegrationCode.ClickUp]: 'Clickup',
    [IntegrationCode.Github]: 'Github',
    [IntegrationCode.Gitlab]: 'Gitlab',
    [IntegrationCode.Linear]: 'Linear',
    [IntegrationCode.Notion]: 'Notion',
    [IntegrationCode.Other]: 'Other',
};

export enum HowDidYouHearLabel {
    LinkedIn = 'LinkedIn',
    GoogleSearch = 'Google Search',
    Advertisement = 'Advertisement',
    ProductHunt = 'Product Hunt',
    YCombinator = 'Y Combinator',
    Article = 'Article/Newsletter',
    Zoom = 'Zoom Marketplace',
    FriendCoworker = 'A friend or co-worker',
    Other = 'Other',
}

export enum HowDidYouHearCode {
    LinkedIn = 'linkedin',
    GoogleSearch = 'google-search',
    Advertisement = 'advertisement',
    ProductHunt = 'producthunt',
    YCombinator = 'y-combinator',
    Article = 'article-newsletter',
    Zoom = 'zoom-marketplace',
    FriendCoworker = 'friend-or-coworker',
    Other = 'other',
}

export enum EmailVerifyReason {
    BlockedEmail = 'BlockedEmail',
    CodeFailedToSend = 'CodeFailedToSend',
}

export enum EmailReferralResponseCode {
    Success = 'Success',
    Failed = 'Failed',
}

export const HowDidYouHearMap = {
    LinkedIn: {
        code: HowDidYouHearCode.LinkedIn,
        label: HowDidYouHearLabel.LinkedIn,
    },
    Advertisement: {
        code: HowDidYouHearCode.Advertisement,
        label: HowDidYouHearLabel.Advertisement,
    },
    GoogleSearch: {
        code: HowDidYouHearCode.GoogleSearch,
        label: HowDidYouHearLabel.GoogleSearch,
    },
    ProductHunt: {
        code: HowDidYouHearCode.ProductHunt,
        label: HowDidYouHearLabel.ProductHunt,
    },
    YCombinator: {
        code: HowDidYouHearCode.YCombinator,
        label: HowDidYouHearLabel.YCombinator,
    },
    Article: {
        code: HowDidYouHearCode.Article,
        label: HowDidYouHearLabel.Article,
    },
    Zoom: {
        code: HowDidYouHearCode.Zoom,
        label: HowDidYouHearLabel.Zoom,
    },
    FriendCoworker: {
        code: HowDidYouHearCode.FriendCoworker,
        label: HowDidYouHearLabel.FriendCoworker,
    },
    Other: {
        code: HowDidYouHearCode.Other,
        label: HowDidYouHearLabel.Other,
    },
};

export type VerifyEmailRequest = {
    email: string;
};

export type VerifyEmailCodeRequest = VerifyEmailRequest & {
    code: string;
    experimentCode: string | null;
    demoVersion?: DemoVersion;
    deepLinkedSeriesId: string | null;
};

export type EmailVerificationResponse = {
    success: boolean;
    reason?: EmailVerifyReason;
};

export type UserCreatable = {
    isNewUser?: boolean;
};

export type UserResponse = {
    user: IClientUser | null;
};

export type GoogleVerificationResponse = UserCreatable & UserResponse;

export type EmailVerificationCodeResponse = EmailVerificationResponse & UserCreatable & UserResponse;

export type SeriesDestinationSettings = {
    channelName?: string;
    channelId?: string;
};

export type UserDefaultDestinationSettings = SeriesDestinationSettings;

export type SummaryOutputSettings = {
    shouldReinstall?: boolean;
    platform: OutputPlatform;
    teamId?: string;
    teamType?: string;
    seriesId: string;
    destinationSettings: SeriesDestinationSettings[];
    defaultDestinationSettings?: SeriesDestinationSettings;
};

export type SeriesIntegrationSettings = {
    summaryOutputSettings?: SummaryOutputSettings[];
};

export enum UserIntegrations {
    Jira = 'jira',
    GoogleCalendar = 'google-calendar',
    Slack = 'slack',
}

export type ClientUserSlackSettings = SlackUserSettings & {
    shouldReinstall: boolean;
};

export type UserIntegrationSettings = Partial<
    Record<`${Exclude<SpinachIntegration, SpinachIntegration.Slack>}Settings`, { isAuthed: boolean }>
> & {
    slackSettings?: ClientUserSlackSettings;
    jiraSettings?: {
        isAuthed: boolean;
        jiraAccountUrl: string;
    };
    googleSettings?: {
        isAuthed: boolean;
        hasCalendarScopes: boolean;
    };
};

export enum PlatformSource {
    ChromeExtStore = 'chrome-ext-store',
}

/**
 * These keys are meant to be minimal in length, yet somewhat identifiable,
 * so that as users dismiss feature hints over time, they don't dominate the user metadata
 */
export enum FeatureDiscoveryKey {
    /** deprecated but kept for reference */
    // JiraIntegration = 'ji',

    AsanaIntegration = 'ai',
    SpinachReferral = 'sr',
    PayUp = 'pu',
    Generic = 'g-',
    SecondUserInvite = 'si',
}

export enum CheckInFeatureDiscoveryKey {
    JiraIntegration = 'cji',
}

export type GenericFeatureKey = `${FeatureDiscoveryKey.Generic}${string}`;

export enum TooltipFeatureDiscoveryKey {
    JiraMagicInput = 'jmi',
    RegularUpdateSentiment = 'rus',
    RegularUpdateSentimentReasons = 'rsr',
}

export type DismissableHints =
    | FeatureDiscoveryKey
    | TooltipFeatureDiscoveryKey
    | GenericFeatureKey
    | CheckInFeatureDiscoveryKey;

validateEnumUniqueness([FeatureDiscoveryKey, TooltipFeatureDiscoveryKey, CheckInFeatureDiscoveryKey]);

// html element ID to attach to
export enum TooltipFeatureDiscoveryId {
    JiraMagicInputId = 'JiraMagicInputId',
}

export type UserMetadata = {
    isSelfServe?: boolean;
    isFirstUserInACompanyAssociation?: boolean;
    companyName?: string;
    hasCompletedFirstMeeting?: boolean;
    lastLoggedOn?: ISOString;
    lastEditedOn?: ISOString;
    createdOn?: ISOString;
    preferredName?: string;
    howDidYouHear?: string;
    howDidYouHearOther?: string;
    firstName?: string;
    lastName?: string;
    integrationsVideo?: IntegrationCode;
    integrationsVideoOther?: string;
    integrationsMessaging?: IntegrationCode;
    integrationsMessagingOther?: string;
    integrationsProjectMgmt?: IntegrationCode[];
    integrationsProjectMgmtOther?: string;
    intercomHash?: string;
    numberOfTimesViewingAllOutAsyncTour?: number;
    isJoiningSeriesUponOnboarding?: boolean;
    isOnboarded?: boolean;
    isBot?: boolean;
    practiceRoundsComplete?: number;
    /** @deprecated */
    hasDismissedDemoBanner?: boolean;
    totalSessions?: number;
    timezoneRegion?: string;
    isAnonymousUser?: boolean;
    platformSource?: PlatformSource;
    /**
     * this is meant to contain feature discovery keys that the user has dismissed
     * that were specifically relevant to them. These keys are meant to be short and minimal
     * to reduce size of user metadata.
     */
    dismissedHints?: DismissableHints[];
    experimentCodes?: ExperimentKey[];
    signupBeforeDemo?: boolean;

    demoVersion?: DemoVersion;
    teamKind?: string;
    teamKindOther?: string;
    roleOnTeam?: string;
    roleOnTeamOther?: string;

    hasLoggedIn?: boolean;
    rootDomain?: string;
};

export interface User {
    _id: UUID;
    email?: string;
    googleId?: string;
    preferredName?: string;
    zoomUserId?: string;
    createdOn: ISOString;
    lastLoggedOn?: ISOString;
    lastEditedOn?: ISOString;
    refreshToken?: string;
    schemaVersion?: number;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata?: UserMetadata;
}

export interface IClientUser extends Omit<User, 'refreshToken'> {
    email: string;
    preferredName: string;
    integrationSettings?: UserIntegrationSettings;
    intercomHash?: string;
    featureToggles?: Record<FeatureToggle, FeatureFlagValue>;
}

export type BasicZoomParticipant = BaseUser & {
    meetingParticipantId: string;
    // Role is not defined InMainClient
    role?: ZoomParticipantRole;
};

export type BaseUser = {
    preferredName: string;
};

export enum FeatureToggle {
    Jira1 = 'jira',
    JiraBoard = 'jira-board',
    TeamTopics2 = 'team-topics-2',
    SentimentCopy = 'sentiment-copy',
    MoodCheck = 'vibe-check',
    RecentCheckIns = 'recent-check-ins',
    AnonymousUserSupport = 'anonymous-user-support',
    LiveSummary = 'live-summary',
    NumberOfTimesUserCanViewAoATour = 'number-of-times-user-can-view-aoa-tour',
    AllowedInternalEmails = 'allowed-internal-emails',
    SSOBeta = 'sso-beta',
    SSODomains = 'sso-domains',
    ToggleRoundtable = 'disable-roundtable',
    EditUserName = 'edit-user-name',
    GoogleCalendar = 'google-calendar',
    DiscussionReactions = 'discussion-reactions',
    UserGoals = 'user-goals',
    Asana = 'asana-mvp',
    EmailSummaries = 'email-summaries',
    JiraIntegrationCallout = 'jira-int-callout',
    SentimentOpacity = 'sentiment-opacity',
    JiraMagicInputCallout = 'jira-magic-input-callout',
    Meditation = 'meditation-mvp',
    AsanaIntegrationCallout = 'asana-int-callout',
    ReferralCallout = 'referral-callout',
    SummaryFilters = 'summary-filters',
    PayupCalloutURL = 'pay-up-url',
    GeneratedSummaries = 'generated-summaries',
    GenericCalloutProps = 'generic-callout-props',
    StopStateMachineMonitoring = 'stop-state-machine-monitoring',
    SignupBeforeDemo = 'signup-before-demo',
    RegularItemSentiment = 'regular-item-sentiment',
    LiveItemSentimentDetails = 'live-item-sentiment-details',
    FreeTierLimited = 'free-tier',
    JiraSentimentInFooter = 'jira-sentiment-in-footer',
    IssueBased = 'continuity-1',
    CustomSlackReminders = 'custom-slack-reminders',
    ProgressNavigation = 'progress-navigation',
    JiraSearchAll = 'jira-search-all',
    IssueResolution = 'issue-resolution',
    DemoVersion = 'demo-version',
    InviteViaSlack = 'invite-via-slack',

    AISeriesAsanaIntegration = 'ai-series-asana-integration',
    AISeriesJiraIntegration = 'ai-series-jira-integration',
    AISummaryJiraIntegration = 'ai-summary-jira-integration',
    AISlackActionItems = 'ai-slack-action-items',
    AISlackDMPerPerson = 'ai-slack-dm-per-person',
    AISlackActionItemsWithFollowup = 'ai-slack-action-items-with-followup',
    AISlackIssueActionItems = 'ai-slack-issue-action-items',
    AIShouldSendSlackActionsToAll = 'ai-should-send-slack-actions-to-all',

    ScribeMeetingType = 'scribe-meeting-type',
    ScribePerPersonStandupPromptTemplate = 'open-ai-transcript-summary-prompt',
    ScribeFlowBasedStandupPromptTemplate = 'open-ai-minimal-transcript-flow-prompt',
    ScribeRetroPromptTemplate = 'scribe-retro-prompt-template',
    ScribeWeeklyPromptTemplate = 'scribe-weekly-prompt-template',
    ScribeSprintPlanningPromptTemplate = 'scribe-sprint-planning-prompt-template',
    ScribeBacklogGroomingPromptTemplate = 'scribe-backlog-grooming-prompt-template',
    ScribeAllHandsPromptTemplate = 'scribe-all-hands-prompt-template',
    ScribeResearchSessionPromptTemplate = 'scribe-research-session-prompt-template',
    ScribeOneOnOnePromptTemplate = 'scribe-one-on-one-prompt-template',
    ScribeGenericPromptTemplate = 'scribe-generic-prompt-template',
    ScribeActionsTemplate = 'scribe-actions-template',
    ScribeSprintPlanningWithTickets = 'scribe-sprint-planning-with-tickets',
    ScribeStandupJsonFormat = 'scribe-standup-json-format',
    ScribeGenericWithTickets = 'scribe-generic-with-tickets',

    OpenAiModel = 'open-ai-model',
    ScribeMeetingDeterminationPromptTemplate = 'scribe-meeting-determination-prompt-template',
    ScribeUseGptMeetingDetermination = 'use-gpt-meeting-determination',
    ScribeMergeUsersWithEmailsBestGuessTemplate = 'scribe-merge-users-with-emails-best-guess-template',
    EmailOrganizerOnly = 'email-organizer-only',
    InVideoMeetingSlackConnectMessageEnabled = 'in-video-meeting-slack-connect-message-enabled',
    InVideoMeetingSlackConnectMessageText = 'in-video-meeting-slack-connect-message-text',
    InVideoMeetingSlackConnectMessageNumberRetries = 'in-video-meeting-slack-connect-message-number-retries',
    InVideoMeetingSlackConnectMessageDelay = 'in-video-meeting-slack-connect-message-delay',
    SendSlackSummaryInDmAsDefault = 'slack-summary-in-dm-as-default',
    ScribeEmbeddingOptions = 'scribe-embedding-options',
    ScribeEmbeddingTopK = 'scribe-embedding-topk',
    ScribeFilterPerPersonEmbeddingBySpeaker = 'scribe-filter-per-person-embedding-by-speaker',
    AiGoogleCalendar = 'ai-google-calendar',
    FavorEmbeddingsForActionItemsOutput = 'favor-embeddings-for-action-items',
    LinearIntegration = 'linear-integration',
    ClickUpIntegration = 'clickup-integration',
    ScribeOpenAiTemperature = 'scribe-open-ai-temperature',
    TrelloIntegration = 'trello-integration',
    ScribeSummaryActionItemsEmbedding = 'scribe-action-items-embedding',
    RootDomainManagement = 'root-domain-management',
}

export type FeatureFlagSet<T extends string> = Record<T, FeatureFlagValue>;
export type FeatureFlagValue = number | boolean | string | Array<FeatureFlagValue> | Object;

export type UserIdentity = {
    userId: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    howDidYouHearSource?: string;
    userName: string;
    userEmail: string;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata?: UserMetadata;
};

export enum FeatureIntent {
    PerPersonUpdates = 'per-person-updates',
    ActionItems = 'action-items',
    IssueActions = 'issue-actions',
    MeetingTypeDetermination = 'meeting-type-determination',
    NameEmailMapping = 'name-email-mapping',
    MeetingSummary = 'meeting-summary',
    TranscriptEmbedding = 'transcript-embedding',
    MeetingSummaryActionItems = 'meeting-summary-action-items',
}
