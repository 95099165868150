import { isProductionStage } from '@spinach-shared/utils';

import { isWebPlatform } from './platform';

export class TagManager {
    static isInitialized = false;

    static init() {
        if (isWebPlatform() && isProductionStage()) {
            (function (w, d, s, l, i) {
                // @ts-ignore
                w[l] = w[l] || [];
                // @ts-ignore
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                // @ts-ignore
                j.async = true;
                // @ts-ignore
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                // @ts-ignore
                f.parentNode.insertBefore(j, f);

                TagManager.isInitialized = true;
            })(window, document, 'script', 'dataLayer', 'GTM-NCRJHZV');
        }
    }

    static trackSignUp(email: string) {
        if (this.isInitialized && isWebPlatform() && isProductionStage()) {
            // @ts-ignore
            window.dataLayer.push({
                event: 'sign_up',
                step: 'welcome',
                email,
            });
        }
    }

    static destroy() {
        if (!isWebPlatform()) {
            return;
        }

        try {
            const scripts = document.getElementsByTagName('script');

            Array.from(scripts).forEach((script) => {
                if (
                    script.src.includes('googletag') ||
                    script.src.includes('snap.licdn') ||
                    script.textContent?.includes('facebook') ||
                    script.textContent?.includes('clearbitjs')
                ) {
                    document.body.removeChild(script);
                }
            });

            const noscripts = document.getElementsByTagName('noscript');

            Array.from(noscripts).forEach((noscript) => {
                if (noscript.textContent?.includes('facebook')) {
                    document.body.removeChild(noscript);
                }
            });
        } catch (error) {}
    }
}
