import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { getStage, isLocalStage, validateEnv } from '@spinach-shared/utils';

import { AnonymousUserTracker, initIntercomWithUser } from '../utils';
import { useFetchUser } from './useFetchUser';
import { useGoogleCalendarEvents } from './useGoogleCalendarEvents';
import { useDetailedJiraIssues } from './useJiraIssues';
import { useZoomCollaborate } from './useZoomCollaborate';

export function useStartupProcesses(): { user: ClientUser | null | undefined; isLoading: boolean } {
    useEnvironmentValidation();
    useSentry();
    useDetailedJiraIssues({ maxResults: 20 });
    useFullStory();
    useAnonymousUserTracking();
    useZoomCollaborate();

    const [user] = useFetchUser();
    useIntercom(user);

    const isLoading = user === undefined;

    return { user, isLoading };
}

export function useEnvironmentValidation() {
    useEffect(() => {
        validateEnv([
            'REACT_APP_WEBSOCKET_URL',
            'REACT_APP_PLATFORM',
            'REACT_APP_AUTH_URL',
            'REACT_APP_SLACK_SERVER_URL',
            'REACT_APP_STAGE',
            'REACT_APP_COGNITO_BASE_PATH',
            'REACT_APP_COGNITO_CLIENT_ID',
        ]);
    }, []);
}

export function useSentry() {
    useEffect(() => {
        Sentry.init({
            dsn: 'https://8e63123c073e452eb6f46457750b447f@o527999.ingest.sentry.io/5645082',
            environment: getStage(),
            ignoreErrors: [
                /AbortError: The play/,
                /NotAllowedError: The play method is not allowed/,
                /NotSupportedError: Failed to load because no supported source was found/,
                /can only be initiated by a user gesture/,
                /The request is not allowed by the user agent or the platform in the current context/,
                /The media resource indicated by the src attribute/,
                /Illegal invocation/,
                /Insufficient Permission/,
            ],
        });
    }, []);
}

export function useFullStory() {
    const SCREEN_HEIGHT_OF_LINKEDIN_BOT = 960;
    const shouldNotRecord = window.innerHeight === SCREEN_HEIGHT_OF_LINKEDIN_BOT;

    useEffect(() => {
        if (shouldNotRecord) {
            return;
        }

        FullStory.init({
            orgId: '135H89',
            devMode: isLocalStage(),
            recordCrossDomainIFrames: true,
            recordOnlyThisIFrame: true, // will set _fs_is_outer_script
        });
    }, []);
}

export function useAnonymousUserTracking() {
    useEffect(() => {
        AnonymousUserTracker.init();
    }, []);
}

export function useIntercom(user: ClientUser | null | undefined) {
    const [isIntercomSetup, setIsIntercomSetup] = useState(false);

    useEffect(() => {
        if (!isIntercomSetup && user && user.metadata.intercomHash) {
            initIntercomWithUser(user);
            setIsIntercomSetup(true);
        }
    }, [user, isIntercomSetup]);
}
