import { Avatar } from '@material-ui/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { DemoGuide, PersonaDemoGuide } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import Bulb from '../../assets/bulb.png';
import ManStill from '../../assets/demo/man-still.png';
import UnpreparedAvatar from '../../assets/demo/unprepared-avatar.png';
import WomanStill from '../../assets/demo/woman-still.png';
import {
    useExperienceTracking,
    useGlobalDemoState,
    useGlobalLiveSeries,
    useLandingAnalytic,
    usePersonaDemo,
    useWindowSize,
} from '../../hooks';
import { BodyBig, BodyRegular, ButtonSize, lightTheme, responsiveness } from '../../styles';
import { Column, Row, Spacing } from '../common';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import { PrimaryButton } from '../stand-up/PrimaryButton';

const HideButton = styled.span`
    cursor: pointer;
    display: flex;
    font-weight: bold;
`;

const GuideTitle = styled(BodyRegular)`
    font-weight: bold;
    font-size: 16px;
`;

export function DemoBannerGuideContainer(): JSX.Element {
    const { currentGuide } = useGlobalDemoState();

    switch (currentGuide) {
        case DemoGuide.Rollover:
            return <RolloverGuide />;
        case DemoGuide.JiraTicket:
            return <JiraTicketGuide />;
        case DemoGuide.CheckIn:
            return <WhatAreYouWorkingOnGuide />;
        case DemoGuide.StartStandup:
            return <StartRoundtableGuide />;
        case DemoGuide.EachPersonShares:
            return <EachPersonSharesGuide />;
        case DemoGuide.ParkingLotDiscuss:
            return <ParkingLotGuide />;
        case DemoGuide.ShareSummary:
            return <SummaryGuide />;
    }
}

function HideGuideRow(): JSX.Element {
    const { setIsGuideOpen } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();

    if (isPersonaDemo) {
        return (
            <Row style={{ justifyContent: 'flex-start' }}>
                <OutlinedButton
                    title={'- Hide'}
                    size={ButtonSize.Small}
                    onClick={() => {
                        setIsGuideOpen(false);
                    }}
                />
            </Row>
        );
    }

    return (
        <Row style={{ justifyContent: 'flex-start' }}>
            <HideButton
                onClick={() => {
                    setIsGuideOpen(false);
                }}
            >
                <BodyRegular style={{ color: lightTheme.primary.orangeLight }}>-</BodyRegular>
                <BodyRegular style={{ marginLeft: '5px', fontWeight: 'bold' }}>Hide</BodyRegular>
            </HideButton>
        </Row>
    );
}

export function DemoBannerGuide({ children }: { children: ReactNode }): JSX.Element {
    return (
        <Column>
            <HideGuideRow />
            <Spacing factor={1 / 2} />
            {children}
        </Column>
    );
}

export function SimpleDemoGuide({
    title,
    description,
    icon,
    cta,
}: {
    title: ReactNode;
    description: ReactNode;
    icon: ReactNode;
    cta: string;
}): JSX.Element {
    const { setIsGuideOpen, currentGuide } = useGlobalDemoState();
    const track = useExperienceTracking();

    useLandingAnalytic(ClientEventType.DemoGuideOpened, { Guide: currentGuide });

    return (
        <DemoBannerGuide>
            <GuideTitle>{title}</GuideTitle>
            <Spacing factor={1 / 2} />
            <Row>
                <Column style={{ width: '50px' }}>
                    <img style={{ height: '36px', paddingRight: '8px' }} src={icon as string} />
                </Column>
                <Column>
                    <BodyRegular>{description}</BodyRegular>
                </Column>
            </Row>
            <Spacing />
            <PrimaryButton
                title={cta}
                onClick={() => {
                    setIsGuideOpen(false);
                    track(ClientEventType.UserClosedDemoGuide, { Guide: currentGuide });
                }}
            />
        </DemoBannerGuide>
    );
}

export function WhatAreYouWorkingOnGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="Share your plan for the day"
            icon={Bulb}
            description={`Writing down your plans makes you 1.4x more likely to achieve it.`}
            cta="Add plans for today"
        />
    );
}

export function RolloverGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="See progress since your last standup"
            icon={Bulb}
            description="Still working on it? Just roll it over!"
            cta="Rollover update"
        />
    );
}

export function JiraTicketGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="See tickets being worked and visual status"
            icon={Bulb}
            description="Shortcut: type “/” or click “+” to add a Jira ticket and share how it’s going visually."
            cta="Add a Jira ticket"
        />
    );
}

export function StartRoundtableGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="Get an instant agenda with clear structure"
            icon={Bulb}
            description={`We facilitate "who goes next" so you don't have to.`}
            cta="Let's go!"
        />
    );
}

export function EachPersonSharesGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title='Each person shares, then clicks "Next" to move to the next person'
            icon={Bulb}
            description={
                <Column>
                    <BodyRegular>- Timer keeps it concise</BodyRegular>
                    <BodyRegular>- Blockers clearly visible</BodyRegular>
                    <BodyRegular>- Team stays engaged</BodyRegular>
                </Column>
            }
            cta="Got it! Let's keep going!"
        />
    );
}

export function ParkingLotGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="Save discussion for the end"
            icon={Bulb}
            description={'The Parking Lot keeps standup on track and helps you remember important follow-ups.'}
            cta="Got it!"
        />
    );
}

export function SummaryGuide(): JSX.Element {
    return (
        <SimpleDemoGuide
            title="Get an instant summary of your standup"
            icon={Bulb}
            description={
                "You don't have to take notes! Get an instant summary in Slack, email or copy it to your favorite doc tool."
            }
            cta="View Summary"
        />
    );
}

const Container = styled(Row)<{ currentGuide: PersonaDemoGuide }>`
    position: absolute;
    background: linear-gradient(274.72deg, #ccefd9 -11.54%, #f2fff7 103.31%), linear-gradient(0deg, #f0f7fd, #f0f7fd),
        #ffffff;
    padding: 20px;
    min-width: 300px;
    max-width: 650px;
    width: unset;
    z-index: 2000;
    left: 70px;
    bottom: 70px;
    border-radius: 15px;
    transform: scale(1.2);
    box-shadow: rgb(0, 0, 0, 0.41) 0px 10px 36px 0px, rgb(0, 0, 0, 0.06) 0px 0px 0px 1px;

    @media ${responsiveness.thinnerThan1100} {
        left: 10px;
        transform: scale(1);
        bottom: 10px;
    }

    @media ${responsiveness.thinnerThanLG} {
        transform: scale(0.95);
        bottom: 100px;
    }

    @media ${responsiveness.thinnerThanSM} {
        left: -25px;
        transform: scale(0.8);
        bottom: ${(props) => (props.currentGuide === PersonaDemoGuide.AddUpdateForToday ? '150px' : '200px')};
    }
`;

const BodyText = styled(BodyBig)`
    text-align: left;
`;

export function BottomCornerGuideContainer(): JSX.Element {
    const { spinachWindowOverlay, demoPrefaceView, isGuideOpen } = useGlobalDemoState();
    const [liveSeries] = useGlobalLiveSeries();
    const { currentPersonaDemoGuide } = liveSeries;

    if (spinachWindowOverlay || demoPrefaceView || !isGuideOpen) {
        return <></>;
    }

    switch (currentPersonaDemoGuide) {
        case PersonaDemoGuide.AddUpdateForToday:
            return <AddTodayUpdateGuide />;
        case PersonaDemoGuide.DraftingSuccess:
            return <DraftingSuccessGuide />;
        case PersonaDemoGuide.AgendaOrder:
            return <AgendaOrderGuide />;
        case PersonaDemoGuide.TimeToShare:
            return <TimeToShareGuide />;
        case PersonaDemoGuide.UpdateQuality:
            return <UpdateQualityGuide />;
        case PersonaDemoGuide.DisruptionsBeLike:
            return <DisruptionsBeLikeGuide />;
        case PersonaDemoGuide.ParkingLot:
            return <PersonaParkingLotGuide />;
        case PersonaDemoGuide.InstantSummary:
            return <InstantSummaryGuide />;
    }
}

export function BottomCornerFloatingGuide({ children }: { children: ReactNode }): JSX.Element {
    const isPersonaDemo = usePersonaDemo();
    const { width } = useWindowSize();
    const [liveSeries] = useGlobalLiveSeries();
    const { currentPersonaDemoGuide } = liveSeries;
    let guideMaxWidth = width / 1.2 - 650 - 40;
    if (width < 900 && width > 550) {
        guideMaxWidth = 250;
    }

    if (!isPersonaDemo) {
        return <></>;
    }

    return (
        <Container style={{ maxWidth: `${guideMaxWidth}px` }} currentGuide={currentPersonaDemoGuide}>
            <Column>
                <HideGuideRow />
                <Spacing factor={1 / 2} />
                {children}
            </Column>
        </Container>
    );
}

function AddTodayUpdateGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>Spinach automates most of your updates. Take 2 minutes to add additional context:</b>
            </BodyText>
            <ul style={{ textAlign: 'left' }}>
                <li>
                    <b>Add one update for today to see how it works</b>
                </li>
            </ul>
        </BottomCornerFloatingGuide>
    );
}

function DraftingSuccessGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>You did it! By drafting your update, your standup is already setup for success:</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <BodyText>
                <b>Problem solved:</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={UnpreparedAvatar} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Unprepared: Ready with a great update</i>
                </BodyText>
            </Row>

            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={WomanStill} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Rambler: more concise and focused update</i>
                </BodyText>
            </Row>
        </BottomCornerFloatingGuide>
    );
}

function AgendaOrderGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>Agendas are auto generated and speaking orders are set</b>
            </BodyText>
            <ul style={{ textAlign: 'left' }}>
                <li>
                    <b>No more asking who wants to go first or who's next</b>
                </li>
            </ul>
        </BottomCornerFloatingGuide>
    );
}

function TimeToShareGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>Time to share. Turns out you retain 20% of what you hear and 80% of what you read. You get both!</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <BodyText>
                <b>Problem Solved:</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={UnpreparedAvatar} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Unprepared: Refined update with a view into Blockers that weren't surfaced before.</i>
                </BodyText>
            </Row>
        </BottomCornerFloatingGuide>
    );
}

function UpdateQualityGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>
                    Update quality has increased 5x, and blockers are now raised 11x more often with visual indicators.
                </b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <BodyText>
                <b>Problem Solved:</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={WomanStill} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Rambler: Focused and clear sense of status and blockers</i>
                </BodyText>
            </Row>
        </BottomCornerFloatingGuide>
    );
}

function DisruptionsBeLikeGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>3 out of 4 teams say disruptions during peoples turns double standup time.</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <BodyText>
                <b>Problem Solved:</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={ManStill} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Disruptor: No more derailing by adding questions to Parking Lot</i>
                </BodyText>
            </Row>
        </BottomCornerFloatingGuide>
    );
}

function PersonaParkingLotGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>Parking lot helps solve blockers, capture action items, and keep things moving forward.</b>
            </BodyText>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={WomanStill} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Rambler and Disengaged: Blockers get resolved faster</i>
                </BodyText>
            </Row>
            <Spacing factor={1 / 2} />
            <Row style={{ alignItems: 'center' }}>
                <Avatar src={ManStill} style={{ marginRight: '10px' }} />
                <BodyText>
                    <i>Disruptor: Doesn't derail the meeting anymore</i>
                </BodyText>
            </Row>
        </BottomCornerFloatingGuide>
    );
}

function InstantSummaryGuide(): JSX.Element {
    return (
        <BottomCornerFloatingGuide>
            <BodyText>
                <b>Send your update to the whole team and stakeholders to avoid more status update meetings.</b>
            </BodyText>
            <ul style={{ textAlign: 'left' }}>
                <li>
                    <b>Get an instant summary in slack, email, or copy to your favorite doc tool</b>
                </li>
            </ul>
        </BottomCornerFloatingGuide>
    );
}
