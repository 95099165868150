import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { AsanaIntegrationRow } from '../../common/settings/AsanaIntegrationRow';
import { ClickUpIntegrationRow } from '../../common/settings/ClickUpIntegrationRow';
import { JiraIntegrationRow } from '../../common/settings/JiraIntegrationRow';
import { LinearIntegrationRow } from '../../common/settings/LinearIntegrationRow';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButton, Stationary, StepProps } from './common';

export type TicketSetupProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
};

export function TicketSetup({ onBack, direction, onSubmit, onSkip }: TicketSetupProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedConnectTicketsStep);
    const [user] = useGlobalAuthedUser();
    const hasAnyIntegration =
        user.isAuthedForAsana || user.isAuthedForClickUp || user.isAuthedForJira || user.isAuthedForLinear;
    return (
        <Stationary>
            <BackButton onBack={onBack} style={{ marginLeft: '-25px', marginTop: '-15px' }} />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard>Awesome! Where do you manage your tickets?</HeaderThreeOnboard>
            <Spacing />
            <BodyBigOnboard>We'll link to tickets you mention in your meetings</BodyBigOnboard>
            <Spacing />
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <JiraIntegrationRow
                        message={'Add Jira tickets to your summary'}
                        isAi={true}
                        isConnectViewOnly={true}
                    />
                    <AsanaIntegrationRow isConnectViewOnly={true} />
                    <LinearIntegrationRow isConnectViewOnly={true} />
                    <ClickUpIntegrationRow isConnectViewOnly={true} />
                    <Spacing />
                </SlidingColumn>
            </ScrollView>
            <Row centered>
                <PrimaryButton title={'Next'} onClick={onSubmit} disabled={!hasAnyIntegration} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
