import { Box } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
// import { to_markdown } from 'jira2md';
import moment from 'moment';
import { MouseEventHandler, useState } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

// no typsecript support

/* tslint:enable:no-var-requires */
import { DetailedTicket, SpinachAPIPath, SpinachUpdateTypeYTB, TypedUpdate } from '@spinach-shared/types';

import { jiraColorMap } from '../../../constants';
import { useJiraBoardEnablement } from '../../../hooks';
import { URLUtil, withFullStoryMasking } from '../../../utils';

const JiraPreview = styled.div<{
    onClick?: any;
}>`
    padding: 0px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin-bottom: 5px;
    text-align: left;
    align-items: center;
    cursor: pointer;
`;

const JiraBox = styled(Box)<{ onClick?: any; updateType?: SpinachUpdateTypeYTB; isJiraBoardEnabled?: boolean }>`
    background-color: ${(props) =>
        props.updateType && props.isJiraBoardEnabled
            ? !jiraColorMap[props.updateType]
                ? '#4285F433'
                : `${jiraColorMap[props.updateType]}33`
            : 'none'};
    display: flex;
    flex: 1;
    padding-left: 5px;
    flex-direction: row;
    align-items: center;
    &:hover {
        background-color: ${(props) =>
            props.updateType && props.isJiraBoardEnabled
                ? !jiraColorMap[props.updateType]
                    ? '#4285F470'
                    : `${jiraColorMap[props.updateType]}70`
                : props.theme.neutrals.grayDark};
    }

    ${(props) =>
        !props.onClick
            ? css<{ updateType?: SpinachUpdateTypeYTB; isJiraBoardEnabled?: boolean }>`
                  cursor: pointer;
                  &:hover {
                      background-color: ${(props) =>
                          props.updateType && props.isJiraBoardEnabled
                              ? !jiraColorMap[props.updateType]
                                  ? '#4285F470'
                                  : `${jiraColorMap[props.updateType]}70`
                              : 'white'};
                  }
              `
            : undefined}
`;

const JiraLink = styled.span`
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        color: purple;
    }
`;

interface JiraPreviewContainerProps {
    interactive: boolean;
    update?: TypedUpdate;
    issueData: DetailedTicket;
    onClick?: MouseEventHandler<HTMLDivElement>;
    saveFullTypedUpdate?: (update: TypedUpdate) => void;
}

const RemoveJiraButton = styled(CancelIcon)<{ updateType?: SpinachUpdateTypeYTB; isJiraBoardEnabled?: boolean }>`
    margin-left: auto;
    z-index: 1000;
    color: gray;
    background-color: ${(props) =>
        props.isJiraBoardEnabled && props.updateType
            ? !jiraColorMap[props.updateType]
                ? '#4285F433'
                : `${jiraColorMap[props.updateType]}33`
            : 'white'};
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        background-color: ${(props) =>
            props.isJiraBoardEnabled && props.updateType
                ? !jiraColorMap[props.updateType]
                    ? '#4285F470'
                    : `${jiraColorMap[props.updateType]}70`
                : props.theme.neutrals.gray};
    }
`;

const ExpensionContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ExpensionBar = styled.div`
    background-color: #b8b8c5;
    width: 1px;
    margin: 4px 10px 4px 0px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const LabelRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
`;

const LabelKeyContainer = styled.div`
    color: #b8b8c5;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
`;

const LabelValueContainer = styled.div`
    border-radius: 9px;
    font-size: 12px;
    vertical-align: middle;
    padding: 2px 5px;
    font-weight: bold;
    background: #b3ded3;
    color: black;
    margin-left: 4px;
    margin-right: 4px;
`;

const formatDiff = (date: string) => {
    const a = moment(Date.now());
    const b = moment(date);
    const days = a.diff(b, 'days');
    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}`;
    }
    const hours = a.diff(b, 'hours');
    if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    const minutes = a.diff(b, 'minutes');
    if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return undefined;
};

export const OldJiraPreviewContainer = ({
    update,
    issueData,
    onClick,
    saveFullTypedUpdate,
    interactive,
}: JiraPreviewContainerProps) => {
    const [isExpanded, setExpanded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { title, id, jiraAccountUrl } = issueData;

    const ref = React.useRef(null);
    const isJiraBoardEnabled = useJiraBoardEnablement();

    const timeInStatusRaw = issueData.statusLastUpdated ? formatDiff(issueData.statusLastUpdated) : undefined;
    const timeInStatus = timeInStatusRaw ? ` (${timeInStatusRaw})` : undefined;

    const showCloseButton = update && saveFullTypedUpdate && isHovered;

    return (
        <>
            <JiraPreview
                ref={ref}
                className={withFullStoryMasking()}
                onMouseEnter={() => interactive && setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={onClick}
            >
                <JiraBox
                    isJiraBoardEnabled={isJiraBoardEnabled}
                    updateType={update?.updateType as SpinachUpdateTypeYTB}
                >
                    {interactive ? (
                        <Box onClick={() => setExpanded((b) => !b)} style={{ cursor: 'pointer', marginLeft: '-12px' }}>
                            {isExpanded ? <ArrowDropDown /> : <ArrowRightIcon />}
                        </Box>
                    ) : (
                        <></>
                    )}

                    <JiraLink
                        onClick={() => {
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL!}${
                                    SpinachAPIPath.Redirect
                                }?redirect_url=${jiraAccountUrl}/browse/${id}`
                            );
                        }}
                    >
                        <b>{id}</b>
                    </JiraLink>
                    <span onClick={() => interactive && setExpanded((b) => !b)} style={{ cursor: 'pointer' }}>
                        {title}
                    </span>

                    {showCloseButton ? (
                        <RemoveJiraButton
                            updateType={update.updateType as SpinachUpdateTypeYTB}
                            isJiraBoardEnabled={isJiraBoardEnabled}
                            style={{ height: '19px' }}
                            onClick={() => {
                                saveFullTypedUpdate?.({
                                    ...update,
                                    jiraData: undefined,
                                    ticketData: undefined,
                                    text: !update.text.replace(/ /g, '') ? '' : update.text,
                                });
                            }}
                        />
                    ) : null}
                </JiraBox>

                {isExpanded && (
                    <ExpensionContainer>
                        <ExpensionBar />
                        <LabelContainer>
                            <LabelRowContainer>
                                <LabelKeyContainer>Status</LabelKeyContainer>
                                <LabelValueContainer>{`${issueData.status ?? 'Unknown'}${
                                    timeInStatus ?? ''
                                }`}</LabelValueContainer>
                            </LabelRowContainer>
                            <LabelRowContainer>
                                {issueData.assignee && (
                                    <>
                                        <LabelKeyContainer>Assigned to</LabelKeyContainer>
                                        <LabelValueContainer>{issueData.assignee}</LabelValueContainer>
                                    </>
                                )}
                            </LabelRowContainer>
                        </LabelContainer>
                    </ExpensionContainer>
                )}
            </JiraPreview>
        </>
    );
};
