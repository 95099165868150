import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CalendarEvent } from '@spinach-shared/types';

import { ClientLogger } from '../utils';
import { fetchGoogleCalendarEvents } from '../utils/fetchGoogleCalendarEvents';
import { updateScribeOnGoogleCalendarEvent } from '../utils/updateScribeOnGoogleCalendarEvent';
import { useGlobalUser } from './useGlobalUser';

async function fetchCalendarEvents(timeMinISOString: string, timeMaxISOString: string) {
    const fetchedEvents = await fetchGoogleCalendarEvents({
        singleEvents: true,
        orderBy: 'starttime',
        timeMin: timeMinISOString,
        timeMax: timeMaxISOString,
    });

    return fetchedEvents;
}

async function updateScribeOnEvent(iCalUid: string, addToEvent: boolean): Promise<CalendarEvent> {
    const updated = await updateScribeOnGoogleCalendarEvent(iCalUid, addToEvent);
    if (!updated) {
        throw new Error('Failed to update scribe on event');
    }
    return updated;
}

export const useGoogleCalendarEvents = (dateRange: { startISOString: string; endISOString: string }) => {
    const [user] = useGlobalUser();
    const queryKey = `google-calendar-events-${dateRange.startISOString}-${dateRange.endISOString}`;
    const query = useQuery({
        queryKey,
        queryFn: () => fetchCalendarEvents(dateRange.startISOString, dateRange.endISOString),
        enabled: user?.isAuthedForGoogleCalendar,
        refetchOnWindowFocus: false,
        retry: 2,
    });
    return { ...query, queryKey };
};

export function useBulkUpdateScribeOnEvents(queryKey: string) {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (events: { iCalUid: string; addToEvent: boolean }[]) => {
            return Promise.all(
                events.map((event) => {
                    return updateScribeOnEvent(event.iCalUid, event.addToEvent);
                })
            );
        },

        onMutate: async (events) => {
            //called before mutationFn, used for optimistic updates
            await queryClient.cancelQueries(queryKey); //cancel any outgoing queries with the same queryKey to avoid them overwriting our optimistic update
            const previousEvents = queryClient.getQueryData(queryKey); //get the previous events from the cache
            queryClient.setQueryData(queryKey, (oldData: CalendarEvent[] | undefined) => {
                // set the new data in the cache
                return (
                    oldData?.map((event) => {
                        if (!!events.find((e) => e.iCalUid === event.iCalUID)) {
                            // add or remove the scribe from the event that was clicked on
                            const newAttendees = [
                                ...(event.attendees || []),
                                { email: process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS },
                            ];

                            return { ...event, attendees: newAttendees };
                        }
                        return event;
                    }) || []
                );
            });

            return { previousEvents };
        },
        onError: (err, _data, context) => {
            ClientLogger.error('Failed to bulk add bot to events', err);
            // revert to the old data in case the mutation fails
            queryClient.setQueryData(queryKey, context?.previousEvents); //rollback to the previous events if the mutation fails
        },
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey); //invalidate the query to refetch the data
        },
    });
    return mutation;
}

export function useUpdateScribeOnEvent(queryKey: string) {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: ({ iCalUid, addToEvent }: { iCalUid: string; addToEvent: boolean }) =>
            updateScribeOnEvent(iCalUid, addToEvent),

        onMutate: async ({ iCalUid, addToEvent }) => {
            //called before mutationFn, used for optimistic updates
            await queryClient.cancelQueries(queryKey); //cancel any outgoing queries with the same queryKey to avoid them overwriting our optimistic update
            const previousEvents = queryClient.getQueryData(queryKey); //get the previous events from the cache
            queryClient.setQueryData(queryKey, (oldData: CalendarEvent[] | undefined) => {
                // set the new data in the cache
                return (
                    oldData?.map((event) => {
                        if (event.iCalUID === iCalUid) {
                            // add or remove the scribe from the event that was clicked on
                            const newAttendees = addToEvent
                                ? [...(event.attendees || []), { email: process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS }]
                                : event.attendees?.filter(
                                      (attendee) => attendee.email !== process.env.REACT_APP_SCRIBE_EMAIL_ADDRESS
                                  );
                            return { ...event, attendees: newAttendees };
                        }
                        return event;
                    }) || []
                );
            });

            return { previousEvents };
        },
        onError: (err, _data, context) => {
            // revert to the old data in case the mutation fails
            queryClient.setQueryData(queryKey, context?.previousEvents); //rollback to the previous events if the mutation fails
        },
    });
    return mutation;
}
