export enum SpinachIntegration {
    Slack = 'slack',
    Jira = 'jira',
    Clickup = 'clickUp',
    Trello = 'trello',
    Linear = 'linear',
    Asana = 'asana',
    Google = 'google',
}
export type SpinachIntegrationConfiguration = {
    code: SpinachIntegration;
    displayName: string;
};

export const SpinachIntegrations: Record<SpinachIntegration, SpinachIntegrationConfiguration> = {
    [SpinachIntegration.Slack]: {
        code: SpinachIntegration.Slack,
        displayName: 'Slack',
    },
    [SpinachIntegration.Jira]: {
        code: SpinachIntegration.Jira,
        displayName: 'Jira',
    },
    [SpinachIntegration.Clickup]: {
        code: SpinachIntegration.Clickup,
        displayName: 'Clickup',
    },
    [SpinachIntegration.Trello]: {
        code: SpinachIntegration.Trello,
        displayName: 'Trello',
    },
    [SpinachIntegration.Linear]: {
        code: SpinachIntegration.Linear,
        displayName: 'Linear',
    },
    [SpinachIntegration.Asana]: {
        code: SpinachIntegration.Asana,
        displayName: 'Asana',
    },
    [SpinachIntegration.Google]: {
        code: SpinachIntegration.Google,
        displayName: 'Google',
    },
};
