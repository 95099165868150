import { v4 } from 'uuid';

import {
    CURRENT_ITEMS_CUSTOM_LIST,
    FINISHED_ITEMS_CUSTOM_LIST,
    NON_TYB_RESERVED_TYPES,
    PRESENTING_WORKING_ITEMS_CUSTOM_LIST,
    RESERVED_TYPES,
    challengesUpdateSectionTypeProps,
    todaysUpdateSectionTypeProps,
    yesterdaysHighlightsUpdateSectionTypeProps,
} from '@spinach-shared/constants';
import { ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS } from '@spinach-shared/constants';
import {
    CompositionalComponentKind,
    FeatureFlagSet,
    FeatureToggle,
    InactiveSeries,
    RoundtableComponent,
    SeriesIntegrationSettings,
    SpinachComponentKind,
    SpinachComponents,
    SpinachList,
    SpinachUpdateType,
    StoredSpinachSeriesMetadata,
} from '@spinach-shared/types';
import { SeriesSchedule, isDemoSeries } from '@spinach-shared/utils';

export const RESERVED_LIST_COUNT = 3;
export const MAX_CUSTOM_LIST_COUNT = 5;
export const MAX_LIST_COUNT = RESERVED_LIST_COUNT + MAX_CUSTOM_LIST_COUNT;

export function createCustomSpinachList(id = v4()): SpinachList {
    return {
        id,
        kind: SpinachComponentKind.List,
        title: '',
        isHidden: true,
    };
}

export function getDefaultStandupComponents(): SpinachComponents[] {
    return [
        {
            id: v4(),
            kind: CompositionalComponentKind.Roundtable,
            name: 'Roundtable',
            isHidden: false,
            components: [
                {
                    id: v4(),
                    kind: SpinachComponentKind.List,
                    title: yesterdaysHighlightsUpdateSectionTypeProps.title,
                    reservedType: SpinachUpdateType.Yesterday,
                    isHidden: false,
                },
                {
                    id: v4(),
                    kind: SpinachComponentKind.List,
                    title: todaysUpdateSectionTypeProps.title,
                    reservedType: SpinachUpdateType.Today,
                    isHidden: false,
                },
                {
                    id: v4(),
                    kind: SpinachComponentKind.List,
                    title: challengesUpdateSectionTypeProps.title,
                    reservedType: SpinachUpdateType.Challenge,
                    isHidden: false,
                },
                createCustomSpinachList(),
                createCustomSpinachList(),
                createCustomSpinachList(),
                createCustomSpinachList(),
                createCustomSpinachList(),
            ],
        },
    ];
}

// TODO: add metadata
export class BaseSeriesProps {
    id: string;
    slug: string;
    name: string;
    metadata?: StoredSpinachSeriesMetadata;
    integrationSettings?: SeriesIntegrationSettings;
    components: SpinachComponents[];
    featureFlags?: FeatureFlagSet<FeatureToggle>;

    constructor(series: InactiveSeries) {
        this.id = series.id;
        this.slug = series.slug;
        this.name = series.name;
        this.metadata = series.metadata
            ? {
                  ...(series.metadata?.dateTimes ? { dateTimes: new SeriesSchedule(series.metadata?.dateTimes) } : {}),
                  reminderSettings: series.metadata.reminderSettings,
              }
            : undefined;

        this.integrationSettings = series.integrationSettings;
        this.featureFlags = series.featureFlags;

        this.components = series.components ?? getDefaultStandupComponents();
        const customLists = this.roundtableComponent.components.filter((l) => !l.reservedType);

        // corrects any missing or extra lists from previous testing
        if (customLists.length < MAX_CUSTOM_LIST_COUNT) {
            const diff = MAX_CUSTOM_LIST_COUNT - customLists.length;
            for (let i = 0; i < diff; i++) {
                this.roundtableComponent.components.push(createCustomSpinachList());
            }
        } else if (customLists.length > MAX_CUSTOM_LIST_COUNT) {
            this.roundtableComponent.components = this.roundtableComponent.components.slice(0, MAX_LIST_COUNT);
        }

        if (
            this.featureToggles?.[FeatureToggle.IssueBased] &&
            !this.roundtableComponent.components.find((c) => ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS.includes(c.id))
        ) {
            this.roundtableComponent.components.push(FINISHED_ITEMS_CUSTOM_LIST);
            this.roundtableComponent.components.push(PRESENTING_WORKING_ITEMS_CUSTOM_LIST);
            this.roundtableComponent.components.push(CURRENT_ITEMS_CUSTOM_LIST);
        }
    }

    get featureToggles(): FeatureFlagSet<FeatureToggle> | undefined {
        return this.featureFlags;
    }

    get isDemo(): boolean {
        return isDemoSeries(this.slug);
    }

    get schedule(): SeriesSchedule | undefined {
        return this.metadata?.dateTimes;
    }

    get enabledReservedRoundtableLists(): SpinachList[] {
        // enable all lists, YTB, if feature is off
        if (!this.featureFlags?.[FeatureToggle.ToggleRoundtable]) {
            return ((getDefaultStandupComponents()[0] as RoundtableComponent).components as SpinachList[]).filter(
                (l) => !!l.reservedType
            );
        }

        return this.roundtableComponent.components.filter(
            (c): c is SpinachList & { reservedType: SpinachUpdateType } => !!c.reservedType && !c.isHidden
        );
    }

    get enabledCustomRoundtableLists(): SpinachList[] {
        if (!this.featureFlags?.[FeatureToggle.ToggleRoundtable]) {
            return [];
        }

        let customLists = this.roundtableComponent.components.filter(
            (list) => !ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS.includes(list.id)
        );

        /** Ensure that the experiment's custom lists are before user-created custom lists */
        if (this.featureFlags?.[FeatureToggle.IssueBased]) {
            customLists = [FINISHED_ITEMS_CUSTOM_LIST, PRESENTING_WORKING_ITEMS_CUSTOM_LIST, ...customLists];
        }

        return customLists.filter((c) => !c.reservedType && !c.isHidden);
    }

    /**
     * Right now, we only support list components inside roundtable,
     * but as soon as we support other components, we will need a
     * better way to type them rather than asserting they're SpinachLists
     */
    get enabledRoundtableLists(): SpinachList[] {
        return this.roundtableComponent.components.filter((c): c is SpinachList => !!c).filter((c) => !c.isHidden);
    }

    /**
     * Right now, there will always be a roundtable component until we
     * enable fuller customization of meetings / standups, so we can
     * be sure we have access for now
     */
    get roundtableComponent(): RoundtableComponent {
        return this.components.find((c) => c.kind === CompositionalComponentKind.Roundtable) as RoundtableComponent;
    }

    get isRoundtableEnabled(): boolean {
        if (!this.featureFlags?.[FeatureToggle.ToggleRoundtable]) {
            return true;
        }

        return !this.roundtableComponent.isHidden;
    }

    isComponentEnabled(reservedType?: SpinachUpdateType, customListId?: string): boolean {
        if (!reservedType && !customListId) {
            throw new Error('no inputs when checking enablement of specific component');
        }

        if (!this.featureFlags?.[FeatureToggle.ToggleRoundtable]) {
            if (!reservedType) {
                return true;
            } else {
                return RESERVED_TYPES.includes(reservedType);
            }
        }

        if (reservedType) {
            const isTypeAnEnabledYTBList = this.enabledRoundtableLists.some((l) => l.reservedType === reservedType);
            const isTypeAnEnabledNonYTBList = NON_TYB_RESERVED_TYPES.includes(reservedType);
            return isTypeAnEnabledYTBList || isTypeAnEnabledNonYTBList;
        } else {
            return this.enabledRoundtableLists.some((l) => l.id === customListId);
        }
    }
}
