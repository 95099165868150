import { useRecoilState } from 'recoil';
import { Socket } from 'socket.io-client';

import { atomDashboardSocket, atomMeetingSocket } from '../atoms';
import { SetValue } from '../types';

export function useGlobalNullableDashboardSocket(): [Socket | null, SetValue<Socket | null>] {
    return useRecoilState(atomDashboardSocket);
}

export function useGlobalDashboardSocket(): [Socket, SetValue<Socket>] {
    return useRecoilState(atomDashboardSocket) as [Socket, SetValue<Socket>];
}

export function useGlobalNullableMeetingSocket(): [Socket | null, SetValue<Socket | null>] {
    return useRecoilState(atomMeetingSocket);
}

export function useGlobalMeetingSocket(): [Socket, SetValue<Socket>] {
    return useRecoilState(atomMeetingSocket) as [Socket, SetValue<Socket>];
}
