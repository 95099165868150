import Cookie from 'js-cookie';
import React from 'react';

import { getWebAppHost, isLocalStage } from '@spinach-shared/utils';

import { postLogOut } from '../../apis/postLogOut';
import { ClientLogger, isZoomPlatform } from '../../utils';
import { AbstractBackground } from '../onboarding';

export class AutoReloadingErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: {}) {
        super(props);
        const reloadCountCookie = Cookie.get('reloadCount');

        if (reloadCountCookie === undefined) {
            Cookie.set('reloadCount', '0', { expires: 2 });
        }

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch() {
        if (isLocalStage()) {
            return;
        }

        async function logOutFlow() {
            await postLogOut();
            window.location.href = `${getWebAppHost()}/verify`;
        }

        async function handleSevereScenario() {
            ClientLogger.error('handling severe auto-reloading scenario on error', {
                location: window.location.href,
            });

            setTimeout(() => {
                Cookie.set('reloadCount', '0', { expires: 2 });

                if (!isZoomPlatform()) {
                    logOutFlow();
                } else {
                    // send zoom client back to dashboard at least since can't log out
                    window.location.href = process.env.REACT_APP_AUTH_URL!;
                }
            }, 100);
        }

        async function handleReload() {
            ClientLogger.error('handling auto-reloading scenario on error', { location: window.location.href });

            setTimeout(() => {
                Cookie.set('reloadCount', `${reloadCount + 1}`, { expires: 2 });
                window.location.reload();
            }, 100);
        }

        const reloadCountCookie = Cookie.get('reloadCount') ?? 0;

        const reloadCount = Number(reloadCountCookie);

        if (reloadCount < 6) {
            handleReload();
        } else {
            handleSevereScenario();
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <AbstractBackground />
                </>
            );
        }

        return this.props.children;
    }
}
