import { TextField, TextFieldProps, makeStyles } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';
import { CSSProperties } from 'react';

import { TypedUpdate } from '@spinach-shared/types';

import { useSeriesReality } from '../../hooks';
import { lightTheme } from '../../styles';
import { withFullStoryMasking } from '../../utils';

export const useSpinachInputStyles = makeStyles<
    DefaultTheme,
    {
        value: string;
        disabled?: boolean;
        isConfirmed?: boolean;
        isMouseIn?: boolean;
        ticketItem?: boolean;
        muiInputMultilineOverrides?: CSSProperties;
        muiBaseInputOverrides?: CSSProperties;
        cursorOverride?: string;
    }
>({
    base: {
        fontFamily: 'Gilroy',
    },
    root: (props) => ({
        '& .MuiInputBase-input': {
            color: lightTheme.primary.midnight,
            fontStyle: 'initial',
            textDecoration: (props) => (props.ticketItem ? 'underline' : 'unset'),
            textDecorationColor: (props) => (props.ticketItem ? lightTheme.primary.midnight : 'unset'),
            overflow: (props) => (props.ticketItem ? 'hidden' : 'unset'),
            textOverflow: (props) => (props.ticketItem ? 'ellipsis' : 'unset'),
            whiteSpace: (props) => (props.ticketItem ? 'nowrap' : 'unset'),
            cursor: (props) => props.cursorOverride ?? 'default',
            ...props.muiBaseInputOverrides,
            // padding: '3px 0 3px',
        },
        borderBottom: 'none',

        '& .MuiInput-underline': {
            borderBottom: (props) => (props.value ? '1px solid rgb(0,0,0,0)' : `1px solid ${lightTheme.neutrals.gray}`),
            '&:hover': {
                borderBottom: (props) =>
                    props.disabled ? '1px solid rgb(0,0,0,0)' : `1px solid ${lightTheme.primary.orangeLight}`,
                backgroundColor: (props) => (props.disabled ? 'none' : 'none'),
                '&:before': {
                    borderBottom: 'none',
                    backgroundColor: 'none',
                },
            },
            '&.Mui-focused': {
                borderBottom: `1px solid ${lightTheme.primary.orangeLight}`,
                backgroundColor: (props) => (props.disabled ? lightTheme.neutrals.grayLight : 'none'),
            },
            '&:after': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
            '&:before': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
            ...props.muiInputMultilineOverrides,
        },
        '& .MuiInputBase-multiline': {
            padding: '3px 0 3px',
        },
    }),
});

export const useSpinachNewInputStyles = makeStyles<
    DefaultTheme,
    {
        value: string;
        disabled?: boolean;
        isConfirmed?: boolean;
        isMouseIn?: boolean;
        ticketItem?: boolean;
        muiInputMultilineOverrides?: CSSProperties;
        muiBaseInputOverrides?: CSSProperties;
        cursorOverride?: string;
        isReadOnlyItem?: boolean;
    }
>({
    base: {
        fontFamily: 'Gilroy',
        fontSize: '15px',
        padding: '3px 4px 3px 4px',
    },
    root: (props) => ({
        '& .MuiInputBase-input': {
            color: (props) => (props.isReadOnlyItem ? lightTheme.tertiary.midnight : lightTheme.primary.midnight),
            fontStyle: 'initial',
            textDecoration: (props) => (props.ticketItem ? 'underline' : 'unset'),
            textDecorationColor: (props) => (props.ticketItem ? lightTheme.primary.midnight : 'unset'),
            overflow: (props) => (props.ticketItem ? 'hidden' : 'unset'),
            textOverflow: (props) => (props.ticketItem ? 'ellipsis' : 'unset'),
            whiteSpace: (props) => (props.ticketItem ? 'nowrap' : 'unset'),
            cursor: (props) => props.cursorOverride ?? (props.disabled ? 'default' : 'text'),
            ...props.muiBaseInputOverrides,
        },
        borderBottom: 'none',
        '& .MuiInput-underline': {
            borderBottom: 'none',
            '&:hover': {
                borderBottom: `none`,
                backgroundColor: (props) => (props.disabled ? 'none' : 'none'),
                '&:before': {
                    borderBottom: 'none',
                    backgroundColor: 'none',
                },
            },
            '&.Mui-focused': {
                borderBottom: 'none',
                backgroundColor: (props) => (props.disabled ? lightTheme.neutrals.grayLight : 'none'),
            },
            '&:after': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
            '&:before': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
            ...props.muiInputMultilineOverrides,
        },
        '& .MuiInputBase-multiline': {},
    }),
});

export type BaseInputProps = TextFieldProps & {
    typedUpdate: TypedUpdate;
    isMouseIn?: boolean;
    cursorOverride?: string;
    isReadOnlyItem?: boolean;
};

// TODO: Remove forwardRef if it's not needed
export const BaseInput = ({
    autoFocus,
    typedUpdate,
    disabled,
    isMouseIn,
    cursorOverride,
    isReadOnlyItem,
    ...rest
}: BaseInputProps): JSX.Element => {
    const { isDemoSeries } = useSeriesReality();
    const classes = useSpinachNewInputStyles({
        value: typedUpdate.text,
        disabled,
        isMouseIn,
        cursorOverride,
        isReadOnlyItem,
    });

    return (
        <TextField
            aria-label={`check-in section for ${typedUpdate.updateType}`}
            disabled={disabled}
            InputProps={{ classes: { root: classes.base } }}
            className={isDemoSeries ? classes.root : withFullStoryMasking(classes.root)}
            multiline
            fullWidth
            value={typedUpdate.text}
            autoFocus={autoFocus}
            {...rest}
        />
    );
};
