import { Tooltip, TooltipProps, styled } from '@material-ui/core';

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .MuiTooltip-tooltip`]: {
        // TODO: Codify this or use existing pallete
        backgroundColor: '#424242',
    },
    [`& .MuiTooltip-arrow::before`]: {
        color: '#424242',
        backgroundColor: '#424242',
    },
}));
