import { Menu, MenuProps } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { CSSProperties } from 'react';
import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { lightTheme } from '../../styles';
import { ListItemProps, ListItemValue } from '../../types';
import { OutlinedButton, OutlinedButtonProps } from '../stand-up/OutlinedButton';
import { ItemList } from './ItemList';

type EndIconComponent = ({
    isOpen,
    isLoading,
    selectedValue,
}: {
    isOpen: boolean;
    isLoading?: boolean;
    selectedValue: string | undefined;
}) => JSX.Element;

export type DropDownProps<T extends string | number | null> = ListItemProps<T> & {
    title: string | JSX.Element;
    defaultValue?: string;
    style?: CSSProperties;
    selected?: string | null;
    menuProps?: Partial<MenuProps>;
    buttonProps?: Partial<OutlinedButtonProps>;
    textStyle?: CSSProperties;
    endIcon?: EndIconComponent;
};

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        sx={{
            backgroundColor: lightTheme.neutrals.white,
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))((props) => ({
    '& .MuiPaper-root': {
        border: `1px solid ${lightTheme.primary.green}`,
        boxSizing: 'border-box' as 'border-box',
        borderRadius: '20px',
        position: 'relative',
        maxHeight: '50%',
        borderColor: lightTheme.primary.green,
        margin: '4px 0',
        minWidth: 217,
        width: props.size,
        color: 'rgb(0, 0, 0)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 10,
            },
        },
        '@media (max-width: 500px)': {
            transform: 'scale(0.8) !important',
        },
        ...(props.styleOverrides ?? {}),
    },
}));

function EndIcon({
    isOpen,
    isLoading,
    selectedValue,
}: {
    isOpen: boolean;
    isLoading?: boolean;
    selectedValue: string | undefined;
}): JSX.Element {
    if (isLoading) {
        return <></>;
    } else if (isOpen) {
        return (
            <KeyboardArrowUp htmlColor={selectedValue ? lightTheme.primary.midnight : lightTheme.neutrals.midnight} />
        );
    } else {
        return (
            <KeyboardArrowDown htmlColor={selectedValue ? lightTheme.primary.midnight : lightTheme.neutrals.midnight} />
        );
    }
}

// Can this be merged with the ItemList component?
// Seems as though
export function DropDown<T extends string | number | null>({
    title,
    values,
    handleSelection,
    defaultValue = undefined,
    selected,
    style,
    buttonProps,
    endIcon,
    menuProps,
    textStyle,
}: DropDownProps<T>) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string | undefined>(selected ?? defaultValue);

    const handleClick = (event: MouseEvent<HTMLButtonElement, any>) => {
        setIsOpen(!isOpen);
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = (code: T, value: ListItemValue<T>) => {
        handleSelection?.(code, value);
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setSelectedValue(selected ?? defaultValue);
    }, [selected]);

    return (
        <>
            <OutlinedButton
                title={selectedValue ?? title}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup="true"
                fontColor={selectedValue ? lightTheme.primary.midnight : lightTheme.neutrals.midnight}
                fontWeight={400}
                onClick={handleClick}
                disabled={!!buttonProps?.isLoading}
                endIcon={
                    endIcon ? (
                        endIcon({ isOpen, isLoading: buttonProps?.isLoading, selectedValue })
                    ) : (
                        <EndIcon isOpen={isOpen} selectedValue={selectedValue} isLoading={buttonProps?.isLoading} />
                    )
                }
                {...buttonProps}
            />
            <StyledMenu
                open={isOpen}
                anchorEl={anchorEl}
                styleOverrides={style}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                size={anchorEl?.scrollWidth}
                onClose={() => setIsOpen(!isOpen)}
                {...menuProps}
            >
                <ItemList
                    shouldListItemHover={true}
                    values={values}
                    handleSelection={handleClose}
                    textStyle={{
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        textAlign: 'left',
                        alignItems: 'center',
                        textDecoration: 'none',
                        minHeight: 'auto',
                        width: '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        fontSize: '1rem',
                        boxSizing: 'border-box',
                        fontWeight: 400,
                        lineHeight: '1.5',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        whiteSpace: 'nowrap',
                        ...textStyle,
                    }}
                />
            </StyledMenu>
        </>
    );
}
