import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import {
    ClientEventType,
    DemoVersion,
    EmailVerifyReason,
    IClientUser,
    SpinachAPIPath,
    TimeInMillis,
    WebUrlQuery,
} from '@spinach-shared/types';
import { getLowercaseDomainFromEmail, getWebAppHost, isLocalStage } from '@spinach-shared/utils';

import { getSession, getUser, postEmailVerify, postExperienceEvent, postSpinachAPI, postVerifyCode } from '../../apis';
import { DemoModal } from '../../atoms';
import {
    useEmailSearchRemoval,
    useGlobalDemoState,
    useGlobalModal,
    useGlobalUser,
    useLocationalSeriesId,
} from '../../hooks';
import { AnonymousUserTracker, ClientLogger, URLUtil } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { ClientPath, FYI, FYIState } from '../common';
import { GetStartedRoute } from './GetStartedRoute';
import { VerifyCodeRoute, VerifyCodeRouteProps } from './VerifyCodeRoute';
import { VerifyEmailRoute, VerifyEmailRouteProps } from './VerifyEmailRoute';

enum WebRoute {
    EmailInput,
    CodeInput,
    Loading,
}

function minimumLoadingDuration(milliseconds: TimeInMillis = 1800): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

type WebOnboardingRoutingProps = {
    verifyEmailRouteProps: VerifyEmailRouteProps;
    verifyCodeRouteProps: VerifyCodeRouteProps;
    loadingMessage: string;
    route: WebRoute;
};

function useWebAuthRouting(): WebOnboardingRoutingProps {
    const [user, setUser] = useGlobalUser();
    const [, setGlobalModal] = useGlobalModal();
    const { setDemoModal } = useGlobalDemoState();
    const [route, setRoute] = useState(WebRoute.EmailInput);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [notification, setNotification] = useState('');
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [params] = useSearchParams();
    const experimentCode = params.get(WebUrlQuery.Experiment);
    const demoVersion = (params.get(WebUrlQuery.DemoVersion) as DemoVersion) ?? undefined;
    const locationalSeriesId = useLocationalSeriesId();

    const payload = {
        UserName: user?.preferredName,
        Email: user?.email || email,
        UserId: user?.spinachUserId || '',
        FeatureToggles: Object.values(user?.featureToggles ?? {}),
    };

    async function handleAuthedUserResponse(user: IClientUser, isNewUser: boolean) {
        const validatedUser = new ClientUser(user);

        AnonymousUserTracker.trackUser(validatedUser.spinachUserId);

        if (isNewUser) {
            TagManager.trackSignUp(validatedUser.email);
        }

        await postExperienceEvent({
            eventType: ClientEventType.UserSucceededToProceedFromCodeEntryView,
            payload: validatedUser.toUserIdentityPayload(),
        });

        // ensure that the web auth router is hidden for existing users logging in via play mode
        setGlobalModal(null);
        setUser(user);

        /**
         * for Anonymous users, when they're using the web auth modal
         * we want to completely reload the app such that all state is set
         * to their authed, known account. This is for quickness of development
         */
        // TODO: find a better way move fwd with onboarding after anonymous upgrade
        if (validatedUser.shouldAuthBeforeDemo) {
            // hide auth modal and show context modal
            setDemoModal(DemoModal.PrepareCheckIn);
        } else if (user?.metadata?.isAnonymousUser === false) {
            window.location.href = getWebAppHost();
        } else {
            setNotification('');
        }
    }

    const onEmailSubmit = async (referralEmail?: string) => {
        const workingEmail = referralEmail || email;
        const response = await postSpinachAPI<{ ssoDomain: string | undefined }>(SpinachAPIPath.CognitoCheck, {
            email: workingEmail,
        });

        if (response?.ssoDomain) {
            ClientLogger.info('redirecting user to SSO login', { domain: getLowercaseDomainFromEmail(workingEmail) });
            const session = await getSession();
            const redirectUrl = `${process.env.REACT_APP_AUTH_URL}/cognito`;
            if (session && session.sessionId) {
                URLUtil.openURL(
                    `${process.env.REACT_APP_COGNITO_BASE_PATH}/oauth2/authorize?${new URLSearchParams({
                        client_id: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
                        response_type: 'code',
                        scope: 'openid',
                        state: JSON.stringify({
                            sessionId: session.sessionId,
                            redirectUrl,
                        }),
                        redirect_uri: redirectUrl,
                        identity_provider: response.ssoDomain,
                    }).toString()}`
                );

                setRoute(WebRoute.Loading);
                setLoadingMessage('Looking your email up...');

                const intervalId = setInterval(async () => {
                    if ((await getUser()).user) {
                        clearInterval(intervalId);
                        window.location.reload(); // reload app when you are already logged in
                    }
                }, 1500);
                setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000); // stop pulling log-in results after 2 minutes
            }
            return;
        }

        setRoute(WebRoute.Loading);
        setLoadingMessage('Looking your email up...');

        AnonymousUserTracker.trackEvent(ClientEventType.UserSubmittedEmail, {
            ...payload,
            Email: workingEmail || payload.Email,
        });

        const [{ success, reason }] = await Promise.all([
            postEmailVerify({ email: workingEmail }),
            minimumLoadingDuration(),
        ]);

        if (success) {
            if (isLocalStage()) {
                const response = await postVerifyCode({
                    email,
                    code: '123456',
                    experimentCode,
                    demoVersion,
                    deepLinkedSeriesId: locationalSeriesId,
                });
                if (response.user) {
                    await handleAuthedUserResponse(response.user, !!response.isNewUser);
                }
            } else {
                setRoute(WebRoute.CodeInput);
                setNotification('');
            }
        } else {
            setRoute(WebRoute.EmailInput);

            if (reason === EmailVerifyReason.BlockedEmail) {
                setNotification('Work emails only, pretty please.');
            } else {
                setNotification("I'm having trouble with this email. Reach out to us at hello@spinach.io for help.");
            }
        }
    };

    const onCodeSubmit = async () => {
        setRoute(WebRoute.Loading);
        setLoadingMessage("Making extra sure it's you");

        AnonymousUserTracker.trackEvent(ClientEventType.UserSubmittedCode, payload);

        const [response] = await Promise.all([
            postVerifyCode({ email, code, experimentCode, demoVersion, deepLinkedSeriesId: locationalSeriesId }),
            minimumLoadingDuration(),
        ]);

        if (response.success === false) {
            AnonymousUserTracker.trackEvent(ClientEventType.UserFailedToProceedFromCodeEntryView, payload);

            setRoute(WebRoute.CodeInput);
            setNotification('Hm, no dice with that code. Try resending the code.');
        } else if (response.user) {
            await handleAuthedUserResponse(response.user, !!response.isNewUser);
        }
    };

    const onResendCode = async () => {
        setRoute(WebRoute.Loading);
        setLoadingMessage('Sending you a new code');

        AnonymousUserTracker.trackEvent(ClientEventType.UserAttemptedToResendEmailCode, payload);

        const [{ success }] = await Promise.all([postEmailVerify({ email }), minimumLoadingDuration()]);

        setRoute(WebRoute.CodeInput);

        if (success) {
            setNotification('New code sent!');
            AnonymousUserTracker.trackEvent(ClientEventType.ResentEmailCodeSuccess, payload);
        } else {
            setNotification(
                "We're having some trouble resending the code. Try again and let us know at hello@spinach.io"
            );
            AnonymousUserTracker.trackEvent(ClientEventType.ResentEmailCodeFailed, payload);
        }
    };

    const onRouteToEmailEntry = async () => {
        setEmail('');
        setRoute(WebRoute.Loading);
        setLoadingMessage("Let's start fresh");

        AnonymousUserTracker.trackEvent(ClientEventType.UserClickedRetypeEmailButton, payload);

        await minimumLoadingDuration();

        setRoute(WebRoute.EmailInput);
        setNotification('');
    };

    const verifyEmailRouteProps = {
        email,
        setEmail,
        onEmailSubmit,
        notification,
    };

    const verifyCodeRouteProps = {
        email,
        onCodeSubmit,
        code,
        setCode,
        notification,
        onResendCode,
        onRouteToEmailEntry,
    };

    return {
        verifyEmailRouteProps,
        verifyCodeRouteProps,
        loadingMessage,
        route,
    };
}

export function WebAuthRouter(): JSX.Element {
    const { route, loadingMessage, verifyCodeRouteProps, verifyEmailRouteProps } = useWebAuthRouting();
    const location = useLocation();
    useEmailSearchRemoval();

    if (location.pathname === ClientPath.SignUp && route === WebRoute.EmailInput) {
        return <GetStartedRoute {...verifyEmailRouteProps} />;
    }

    switch (route) {
        case WebRoute.EmailInput:
            return <VerifyEmailRoute {...verifyEmailRouteProps} />;
        case WebRoute.CodeInput:
            return <VerifyCodeRoute {...verifyCodeRouteProps} />;
        case WebRoute.Loading:
            return <FYI state={FYIState.Loading} header={loadingMessage} />;
    }
}
