import { Modal } from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import { Titles } from '@spinach-clients/constants';
import { useState } from 'react';

import { SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyHuge, BodyRegular } from '../../../styles';
import { URLUtil, withAccessibleSubmitProps } from '../../../utils';
import { DisabledOptionRow } from '../../series/common';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { GoogleLoginComponent } from '../GoogleLoginComponent';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export function GoogleCalendarLoginModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [openModal, setOpenModal] = useGlobalModal();
    const [isGooglePending, setIsGooglePending] = useState(false);

    if (!user) {
        return <></>;
    }

    return (
        <Modal
            open={openModal === GlobalModal.GoogleCalendarLogin}
            onClose={() => {
                setOpenModal(null);
            }}
        >
            <SpinachModalContent style={{ maxHeight: '450px', minHeight: 'unset' }} onClose={() => setOpenModal(null)}>
                <Spacing factor={1 / 2} />
                <Row>
                    <BodyHuge style={{ textAlign: 'center' }}>Spinach and Google, a perfect match 🥬</BodyHuge>
                </Row>
                <Spacing factor={1 / 2} />
                <BodyRegular>
                    We recommend connecting Spinach.io to your calendar to get the full benefits of Spinach
                </BodyRegular>
                <Spacing />
                {isGooglePending ? (
                    <Column style={{ padding: '5px', justifyContent: 'space-between' }}>
                        <DisabledOptionRow
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <WatchLater style={{ paddingRight: '5px' }} />
                            <Row>
                                <BodyRegular style={{ display: 'flex', alignItems: 'center' }}>
                                    Google Connection Pending...
                                </BodyRegular>
                            </Row>
                        </DisabledOptionRow>
                        <OutlinedButton
                            title={Titles.Refresh}
                            {...withAccessibleSubmitProps((e) => {
                                e.stopPropagation();
                                // TODO: refetch user instead;
                                window.location.reload();
                            })}
                            style={{ margin: '5px', float: 'right', transform: 'scale(0.8)' }}
                        />
                    </Column>
                ) : (
                    <Row style={{ justifyContent: 'center' }}>
                        <GoogleLoginComponent
                            onClick={() => {
                                setIsGooglePending(true);
                                URLUtil.openURL(
                                    `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                );
                            }}
                        />
                    </Row>
                )}
                <Spacing />
                <SecondaryButton title="Dismiss" onClick={() => setOpenModal(null)} />
            </SpinachModalContent>
        </Modal>
    );
}
