import { Tooltip, makeStyles } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { uniqBy } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { CURRENT_ITEMS_CUSTOM_LIST_ID } from '@spinach-shared/constants';
import { BaseAgendaProps, YTBAgendaItemProps, YTBHistoryProps, YTBUpdateProps } from '@spinach-shared/models';
import {
    ClientEventType,
    GoalStatus,
    SpinachUpdateType,
    TypedUpdate,
    TypedUpdateWrapper,
    UpdateEditedPayload,
    UpdateSectionTypeProps,
    UserIdentity,
} from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import { GlobalModal } from '../../atoms';
import {
    useElasticLiveInputs,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalModal,
    useRecentCheckInEnablement,
} from '../../hooks';
import { lightTheme } from '../../styles';
import { BodyLarge, BodyRegular, ButtonSize } from '../../styles/typography';
import { FullUpdateEmitter, SetTypedUpdates } from '../../types/StandUp';
import { Column } from '../common';
import { PresentersRecentCheckIns } from '../common/PresentersRecentCheckIns';
import { LiveUpdateDemoHints } from '../input';
import { PreviousUpdateSectionInputWithRollover } from '../input/jira/PreviousUpdateSectionInputWithRollover';
import Draggable from './Draggable';
import { LiveTypedUpdatedProps, LiveUpdate } from './LiveUpdate';
import { TeamTopicsForParticipantProps } from './ParkingLotItemsByParticipant';
import SecondaryButton from './SecondaryButton';

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export function TypedUpdates({
    typedUpdates,
    setTypedUpdates,
    updateViewProps,
    typeProps,
    focusedInputIndex,
    setFocusedInputIndex,
    isInNavDrawer,
    autoFocusNewInputOnMountAfter,
    userId,
    hideAddLine,
    customListId,
}: LiveTypedUpdatedProps): JSX.Element {
    const remoteUpdates = customListId
        ? updateViewProps.standUpUpdate.getCustomUpdatesForList(customListId)
        : updateViewProps.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType);

    const emptyInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const subItemEmptyInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();

    useElasticLiveInputs(
        typedUpdates,
        setTypedUpdates,
        focusedInputIndex,
        typeProps.spinachUpdateType,
        remoteUpdates,
        customListId,
        isInNavDrawer
    );

    const [typedUpdateCountOnMount] = useState(typedUpdates.length || 1);
    const { createUpdateEmitter, createFullUpdateEmitter, createReorderEmitter } = updateViewProps;

    const moveDraggable = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const reorderedUpdates = typedUpdates.map((u) => u);
            reorderedUpdates.splice(dragIndex, 1);
            reorderedUpdates.splice(hoverIndex, 0, typedUpdates[dragIndex]);
            setTypedUpdates(reorderedUpdates);
        },
        [typedUpdates]
    );
    return (
        <>
            {typedUpdates.map((typedUpdate, index) => {
                const wrapped = new TypedUpdateWrapper(typedUpdate);

                const remoteTypedUpdate = updateViewProps.standUpUpdate.getUpdateByID(typedUpdate.id);

                const setTypedUpdateByIndex = (index: number) => (typedUpdate: TypedUpdate) => {
                    const oldUpdate = typedUpdates[index];

                    const newUpdate: TypedUpdate = {
                        ...oldUpdate,
                        ...typedUpdate,
                    };
                    const newTypedUpdates = [...typedUpdates];

                    newTypedUpdates[index] = newUpdate;
                    setTypedUpdates(newTypedUpdates);
                };

                const setFocusedInputById = (index: number) => (nullable?: null) => {
                    setFocusedInputIndex(nullable === null ? null : index);
                };

                const removeTabbedItemByIndex = (index: number) => () => {
                    const subItemArray = [...(typedUpdate.subItems || [])];
                    subItemArray.splice(index, 1);

                    setTypedUpdate({ ...typedUpdate, subItems: subItemArray });
                    emitFullTypedUpdateSave({ ...typedUpdate, subItems: subItemArray });

                    track(ClientEventType.UserRemovedMultilineItem, {
                        ParentItemId: typedUpdate.id,
                        NumberOfSubItemsOnParentItem: typedUpdate.subItems?.length ?? 0,
                    });
                };

                const createTabbedItem = () => {
                    const oldUpdate = { ...typedUpdate };
                    const newUpdate: TypedUpdate = {
                        ...oldUpdate,
                        subItems: [
                            ...(oldUpdate?.subItems || []),
                            getNewTypedUpdate({
                                updateType: typeProps.spinachUpdateType,
                                text: '',
                                creatorId: user.spinachUserId,
                            }),
                        ],
                    };
                    const newTypedUpdates = [...typedUpdates];
                    newTypedUpdates[index] = newUpdate;

                    setTypedUpdates(newTypedUpdates);
                    setFocusedInputById(index + 1)();

                    track(ClientEventType.UserCreatedMultilineItem, {
                        ParentItemId: oldUpdate.id,
                        NumberOfSubItemsOnParentItem: oldUpdate.subItems?.length ?? 0,
                    });
                };

                const setTypedUpdate = setTypedUpdateByIndex(index);
                const setFocusedInput = setFocusedInputById(index);

                const shouldCreate = !remoteTypedUpdate && typedUpdate.text !== '';

                const emitTypedUpdateSave = createUpdateEmitter({ ...typedUpdate, creatorId: user.spinachUserId });

                const saveTypedUpdate = (text: string) => {
                    const isTextChanged = remoteTypedUpdate?.text !== text;
                    const shouldUpdate = !!remoteTypedUpdate && isTextChanged;

                    if (shouldCreate || shouldUpdate) {
                        emitTypedUpdateSave(text);
                        const payload: UpdateEditedPayload = {
                            UpdateAuthorId: updateViewProps.standUpUpdate.spinachUserId,
                            UpdateEditorId: user.spinachUserId,
                            UpdateEditorIsAuthor: updateViewProps.standUpUpdate.spinachUserId == user.spinachUserId,
                            UpdateId: remoteTypedUpdate?.id,
                            InNavDrawer: isInNavDrawer,
                            IsSubItem: false,
                            UpdateCreated: !!shouldCreate,
                            UpdateDeleted: !shouldCreate && !text, //wasn't just created, but text updated to empty string
                            UpdateType: remoteTypedUpdate?.updateType,
                        };
                        track(ClientEventType.UpdateEdited, payload);
                    }
                };

                const setTabbedItemByIndex = (subItemIndex: number) => (update: TypedUpdate) => {
                    const typedUpdateCopy = { ...typedUpdate, subItems: [...(typedUpdate.subItems || [])] };
                    if (typedUpdateCopy.subItems?.[subItemIndex]) {
                        typedUpdateCopy.subItems[subItemIndex] = update;
                        const newTypedUpdates = [...typedUpdates];
                        newTypedUpdates[index] = typedUpdateCopy;

                        setTypedUpdates(newTypedUpdates);
                    }
                };

                const savedTabbedItemByIndex = (subItemIndex: number) => (text: string) => {
                    const subItems = uniqBy(
                        [...(remoteTypedUpdate?.subItems || []), ...(typedUpdate.subItems || [])],
                        'id'
                    );
                    const isTextChanged = remoteTypedUpdate?.subItems?.[subItemIndex]?.text !== text;
                    const shouldCreate = !remoteTypedUpdate?.subItems?.[subItemIndex];
                    const shouldUpdate = !!subItems?.[subItemIndex] && isTextChanged;

                    if (shouldCreate || shouldUpdate) {
                        subItems[subItemIndex] = { ...subItems[subItemIndex], text };
                        saveFullTypedUpdate({ ...remoteTypedUpdate!, subItems });
                        const payload: UpdateEditedPayload = {
                            UpdateAuthorId: updateViewProps.standUpUpdate.spinachUserId,
                            UpdateEditorId: user.spinachUserId,
                            UpdateEditorIsAuthor: updateViewProps.standUpUpdate.spinachUserId == user.spinachUserId,
                            UpdateId: remoteTypedUpdate?.id,
                            InNavDrawer: isInNavDrawer,
                            IsSubItem: true,
                            UpdateCreated: !!shouldCreate,
                            UpdateDeleted: !shouldCreate && !text, //wasn't just created, but text updated to empty string
                            UpdateType: remoteTypedUpdate?.updateType,
                        };
                        track(ClientEventType.UpdateEdited, payload);
                    }
                };

                const saveFullTabbedItemByIndex = (subItemIndex: number) => (update: TypedUpdate) => {
                    const typedUpdateCopy = { ...typedUpdate };
                    if (typedUpdateCopy.subItems?.[subItemIndex]) {
                        typedUpdateCopy.subItems[subItemIndex] = update;
                        emitFullTypedUpdateSave(typedUpdateCopy);
                    }
                };

                const emitFullTypedUpdateSave = createFullUpdateEmitter(typedUpdate);

                const saveFullTypedUpdate = (update: TypedUpdate, updates?: TypedUpdate[]) => {
                    emitFullTypedUpdateSave(update, updates);
                };

                const isSaved = index < typedUpdates.length - 1;
                const isDraggable = typedUpdates.length > 2 && isSaved;
                const emitOrderedUpdatesSave = createReorderEmitter && createReorderEmitter(typedUpdate);

                /**
                 * Warning: adjusting the structure of this JSX may impact drag-and-drop
                 * it's sensitive. Be sure to test DND if you adjust JSX here.
                 */
                return hideAddLine && index === typedUpdates.length - 1 ? null : (
                    <Draggable
                        key={typedUpdate.id}
                        index={index}
                        style={{
                            paddingLeft: 'unset',
                            marginLeft: 'unset',
                        }}
                        id={typedUpdate.id}
                        moveDraggable={moveDraggable}
                        isDraggable={isDraggable}
                        emitOrderedUpdatesSave={emitOrderedUpdatesSave}
                        type={`${typedUpdate.updateType}-${userId}`}
                        idList={typedUpdates.map((u) => u.id)}
                    >
                        {wrapped.asGoal && wrapped.asGoal.status === GoalStatus.Unset ? (
                            <PreviousUpdateSectionInputWithRollover
                                createUpdateEmitter={createUpdateEmitter}
                                typedUpdate={typedUpdate}
                            />
                        ) : (
                            <LiveUpdate
                                index={index}
                                key={typedUpdate.id}
                                typedUpdate={typedUpdate}
                                createTabbedItem={createTabbedItem}
                                removeTabbedItem={removeTabbedItemByIndex(index)}
                                setTypedUpdate={setTypedUpdate}
                                setFocusedInput={setFocusedInput}
                                isInNavDrawer={isInNavDrawer}
                                emptyInputRef={emptyInputRef}
                                subItemEmptyInputRef={subItemEmptyInputRef}
                                isFocused={focusedInputIndex === index}
                                removeTabbedItemByIndex={removeTabbedItemByIndex}
                                setTabbedItemByIndex={setTabbedItemByIndex}
                                savedTabbedItemByIndex={savedTabbedItemByIndex}
                                saveFullTabbedItemByIndex={saveFullTabbedItemByIndex}
                                saveTypedUpdate={saveTypedUpdate}
                                saveFullTypedUpdate={saveFullTypedUpdate}
                                placeholder={typeProps.placeholder}
                                updateOwnerId={updateViewProps.standUpUpdate.spinachUserId}
                                autoFocusOnMountAfter={
                                    typedUpdateCountOnMount - 1 === index ? autoFocusNewInputOnMountAfter : undefined
                                }
                                isOmniboxSearchEnabled={false}
                                hint={
                                    <LiveUpdateDemoHints
                                        typedUpdate={typedUpdate}
                                        typedUpdates={typedUpdates}
                                        isInNavDrawer={!!isInNavDrawer}
                                    />
                                }
                            />
                        )}
                    </Draggable>
                );
            })}
        </>
    );
}

export type LiveRolloverProps = {
    history?: YTBHistoryProps;
    userIdentity: UserIdentity;
    // not sure if this is used anymore
    containerRef?: React.MutableRefObject<HTMLDivElement | null>;
    onToggleConfirmation?: (typedUpdate: TypedUpdate) => void;
};

export type LiveUpdateViewProps = {
    standUpUpdate: YTBUpdateProps;
    createUpdateEmitter: (typedUpdate: TypedUpdate) => (text: string) => void;
    createFullUpdateEmitter: FullUpdateEmitter;
    createReorderEmitter?: (typedUpdate: TypedUpdate) => (moveToIndex: number) => void;
    rolloverProps?: LiveRolloverProps;
};

type LiveUpdateTeamTopicsSectionProps = {
    typedUpdates: TypedUpdate[];
    setTypedUpdates: SetTypedUpdates;
    typeProps: UpdateSectionTypeProps;
    updateViewProps: LiveUpdateViewProps;
    agenda: BaseAgendaProps;
    userItem: YTBAgendaItemProps;
    teamTopicsProps: TeamTopicsForParticipantProps;
};

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: 'white',
        color: lightTheme.primary.midnight,
        boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, 0.25)',
        padding: '15px',
        marginTop: '10px',
        marginLeft: '25px',
    },
    tooltipPlacementTop: {},
}));

export function UpdateSectionTitle({
    typeProps,
    isRecentCheckinsEnabled,
    isPresentingAndEmpty = false,
}: {
    typeProps: UpdateSectionTypeProps;
    isRecentCheckinsEnabled?: boolean;
    isPresentingAndEmpty?: boolean;
}): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const isFeatureEnabled = useRecentCheckInEnablement();
    const tooltipClasses = useTooltipStyles();
    const title =
        typeProps.spinachUpdateType === SpinachUpdateType.Icebreaker
            ? liveSeries.currentMeeting.icebreakerQuestionPrompt
            : typeProps.title;
    const shouldDisplayNone =
        typeProps.spinachUpdateType === SpinachUpdateType.Icebreaker ? false : isPresentingAndEmpty;

    return (
        <Row
            style={{
                marginLeft: '7px',
                paddingLeft: '2px',
                paddingBottom: '5px',
                justifyContent: 'flex-start',
                alignItems: 'start',
            }}
        >
            <Column>
                <BodyLarge
                    style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        letterSpacing: '0.1px',
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'row',
                        alignItems: 'center',
                    }}
                >
                    {title} {shouldDisplayNone ? ' (none)' : ''}
                    {typeProps.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID ? (
                        <SecondaryButton
                            title="(View more)"
                            size={ButtonSize.Small}
                            style={{ marginTop: '-3px', transform: 'scale(0.9)' }}
                            onClick={() => {
                                setGlobalModal(GlobalModal.ArchivedUserGoals);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </BodyLarge>

                {isRecentCheckinsEnabled && isFeatureEnabled ? (
                    <Tooltip
                        interactive
                        onMouseEnter={() => {
                            track(ClientEventType.RecentCheckInHovered, { Category: typeProps.title });
                        }}
                        classes={tooltipClasses}
                        title={<PresentersRecentCheckIns updateType={typeProps.spinachUpdateType} />}
                        placement={'right-end'}
                    >
                        <HistoryIcon htmlColor={lightTheme.primary.greenLight} style={{ marginTop: '-5px' }} />
                    </Tooltip>
                ) : null}
                {typeProps.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID ? (
                    <BodyRegular style={{ fontSize: '12px' }}>
                        Change the status to add items to your Check-In
                    </BodyRegular>
                ) : (
                    <></>
                )}
            </Column>
        </Row>
    );
}
