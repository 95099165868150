import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { GlobalSlackState, atomSlack } from '../atoms';
import { SetValue } from '../types';
import { ClientLogger } from '../utils';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalNullableStoredSeries } from './useSeriesData';

export type GlobalSlackStateHook = {
    setInstallURL: (url: string) => void;
    slackState: GlobalSlackState;
    setSlackState: SetValue<GlobalSlackState>;
    setShouldSendSummaryOnChannelSelect: (val: boolean) => void;
};

export function useGlobalSlack(): GlobalSlackStateHook {
    const [slackState, setSlackState] = useRecoilState(atomSlack);

    function setInstallURL(installUrl: string) {
        setSlackState({
            ...slackState,
            installUrl,
        });
    }

    function setShouldSendSummaryOnChannelSelect(val: boolean) {
        setSlackState({
            ...slackState,
            shouldSendSummaryOnChannelConnect: val,
        });
    }

    return {
        setInstallURL,
        slackState,
        setSlackState,
        setShouldSendSummaryOnChannelSelect,
    };
}

export function useSlackInstallLink(): string {
    const [user] = useGlobalAuthedUser();
    const [storedSeries] = useGlobalNullableStoredSeries();
    const { setInstallURL, slackState } = useGlobalSlack();

    const seriesId = storedSeries?.slug;

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_SLACK_SERVER_URL}/install?href=true&sid=${user.spinachUserId}&seriesId=${seriesId}&version=${process.env.REACT_APP_APP_VERSION}`
        )
            .then((response) =>
                response.text().then((url) => {
                    setInstallURL(url);
                })
            )
            .catch((error) => {
                ClientLogger.error('Failed to load slack install link', error);
            });
    }, [user.spinachUserId, seriesId]);

    return slackState.installUrl;
}
