import { Participation, StartStop } from '@spinach-shared/types';

export class StartStopProps {
    segments: StartStop[];

    constructor(segments: StartStop[]) {
        this.segments = segments;
    }

    get inProgress(): StartStop | undefined {
        return this.segments.find((s) => s.endedAt === undefined);
    }

    get areAllFinished(): boolean {
        return this.segments.every((part) => part.endedAt !== undefined);
    }

    get latest(): Participation {
        return this.segments[this.segments.length - 1];
    }

    toJSON(): Participation[] {
        return this.segments.map((segment) => ({ startedAt: segment.startedAt, endedAt: segment.endedAt }));
    }
}
