import { IClientUser, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getUser(): Promise<{ user: IClientUser | null }> {
    const response = await getSpinachAPI<{ user: IClientUser | null }>(SpinachAPIPath.User, {
        params: { ts: new Date().getTime() },
    });

    return response ?? { user: null };
}
