import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useEffect } from 'react';

import { ClientEventType, MeetingSettingsSubview, SpinachIntegration } from '@spinach-shared/types';

import {
    useAiGoogleCalendarEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalRouting,
} from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { BodyBigOnboard, BodyRegular, HeaderThreeOnboard } from '../../styles';
import { Spacer, UserAccountButton } from '../common';
import { AsanaIntegrationRow } from '../common/settings/AsanaIntegrationRow';
import { ClickUpIntegrationRow } from '../common/settings/ClickUpIntegrationRow';
import { ClickUpIntegrationSettings } from '../common/settings/ClickUpIntegrationSettings';
import { GoogleCalendarIntegrationRow } from '../common/settings/GoogleCalendarIntegrationRow';
import { JiraIntegrationRow } from '../common/settings/JiraIntegrationRow';
import { JiraIntegrationSettings } from '../common/settings/JiraIntegrationSettings';
import { LinearIntegrationRow } from '../common/settings/LinearIntegrationRow';
import { LinearIntegrationSettings } from '../common/settings/LinearIntegrationSettings';
import { SlackIntegrationRow } from '../common/settings/SlackIntegrationRow';
import { SlackIntegrationSettings } from '../common/settings/SlackIntegrationSettings';
import { TrelloIntegrationRow } from '../common/settings/TrelloIntegrationRow';
import { ScrollArea } from '../stand-up';
import { ScribeCalendarPage } from './ScribeCalendarPage';
import { AsanaSettings, TicketSettingsSubview } from './ScribeSeriesSettingsPage';
import { ButtonContainer, Container, ContentContainer } from './styles';

function Settings(): JSX.Element {
    const { routeToDashboard } = useGlobalRouting();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const aiGoogleCalendarEnabled = useAiGoogleCalendarEnablement();

    const title = 'Spinach Settings';

    return (
        <Container>
            <ContentContainer>
                {user.seriesMetadataList.length > 0 ? ( // only show back button if user has some series
                    <ButtonContainer>
                        <IconButton
                            aria-label="back to meeting dashboard"
                            size="small"
                            style={{ marginTop: '-5px', marginLeft: '-5px' }}
                            onClick={() => {
                                track(ClientEventType.BackToDashboardClick);
                                routeToDashboard();
                            }}
                        >
                            <ChevronLeft fontSize="default" />
                        </IconButton>
                    </ButtonContainer>
                ) : (
                    <></>
                )}

                <HeaderThreeOnboard>{title}</HeaderThreeOnboard>
                <ScrollArea sidePadding={0}>
                    <BodyBigOnboard style={{ textTransform: 'uppercase', letterSpacing: '4px' }}>
                        Summary Output
                    </BodyBigOnboard>
                    <Spacer />
                    <>
                        <SlackIntegrationRow
                            connectedMessage="Summaries connected to"
                            notConnectedMessage={
                                <span>
                                    Summaries are <b>not connected</b> to a channel yet
                                </span>
                            }
                            isUserDefaultChannel={true}
                        />

                        <JiraIntegrationRow message={'Add Jira tickets to your summary'} isAi={true} />
                        <AsanaIntegrationRow />
                        <LinearIntegrationRow />
                        <ClickUpIntegrationRow />
                        <TrelloIntegrationRow />
                    </>
                    <Spacer />
                    {aiGoogleCalendarEnabled ? (
                        <>
                            <BodyBigOnboard style={{ textTransform: 'uppercase', letterSpacing: '4px' }}>
                                Manage Meetings
                            </BodyBigOnboard>
                            <Spacer />
                            <GoogleCalendarIntegrationRow />
                        </>
                    ) : (
                        <></>
                    )}
                </ScrollArea>
            </ContentContainer>
        </Container>
    );
}

function SlackSettings() {
    const [user] = useGlobalAuthedUser();
    const { isAuthedForSlack } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForSlack) {
            setSubview(null);
        }
    }, [isAuthedForSlack]);
    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <SlackIntegrationSettings
                    showSlackRemindersSection={false}
                    isUserDefaultChannel={true}
                    title="Choose a default slack channel"
                    channelSelectionHeader={
                        <BodyRegular>
                            This is where we will send any summary that doesn’t have a channel set up.
                        </BodyRegular>
                    }
                />
            </ContentContainer>
        </Container>
    );
}

function JiraSettings(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { isAuthedForJira } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForJira) {
            setSubview(null);
        }
    }, [isAuthedForJira]);
    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <JiraIntegrationSettings />
            </ContentContainer>
        </Container>
    );
}

function LinearSettings(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { isAuthedForLinear } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForLinear) {
            setSubview(null);
        }
    }, [isAuthedForLinear]);

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <LinearIntegrationSettings />
            </ContentContainer>
        </Container>
    );
}

function ClickUpSettings(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { isAuthedForClickUp } = user;
    const { setSubview } = useGlobalMeetingSettings();

    useEffect(() => {
        if (!isAuthedForClickUp) {
            setSubview(null);
        }
    }, [isAuthedForClickUp]);

    return (
        <Container>
            <ContentContainer>
                <ButtonContainer>
                    <IconButton
                        aria-label="back to settings view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={() => setSubview(null)}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                </ButtonContainer>
                <ClickUpIntegrationSettings />
            </ContentContainer>
        </Container>
    );
}

export function ScribeUserSettingsPage() {
    const { subview } = useGlobalMeetingSettings();
    const [user] = useGlobalAuthedUser();

    let subViewComponent;
    switch (subview) {
        case MeetingSettingsSubview.SlackSettings:
            subViewComponent = <SlackSettings />;
            break;
        case MeetingSettingsSubview.JiraSettings:
            subViewComponent = <JiraSettings />;
            break;
        case MeetingSettingsSubview.AsanaSettings:
            subViewComponent = <AsanaSettings />;
            break;
        case MeetingSettingsSubview.LinearSettings:
            subViewComponent = <LinearSettings />;
            break;
        case MeetingSettingsSubview.TrelloSettings:
            subViewComponent = (
                <TicketSettingsSubview
                    isAuthedForIntegration={user.isAuthedForTrello}
                    integration={SpinachIntegration.Trello}
                    integrationName={'Trello'}
                />
            );
            break;
        case MeetingSettingsSubview.ClickUpSettings:
            subViewComponent = <ClickUpSettings />;
            break;
        case MeetingSettingsSubview.CalendarSettings:
            subViewComponent = <ScribeCalendarPage />;
            break;
        case null:
            subViewComponent = <Settings />;
    }
    return (
        <>
            <UserAccountButton />
            {subViewComponent}
        </>
    );
}
