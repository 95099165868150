import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { GlobalModal } from '../../../atoms';
import { useGlobalModal, usePrevious } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { Direction } from '../SlidingColumn';
import { IntegrationSettingsSubview } from '../settings/IntegrationSettingsSubview';
import { TopLevelIntegrationSettings } from '../settings/TopLevelntegrationSettings';

export function IntegrationsSettingsModal(): JSX.Element {
    const { subview, setSubview } = useGlobalMeetingSettings();

    const [globalModal, setGlobalModal] = useGlobalModal();

    const [direction, setDirection] = useState<Direction | undefined>(undefined);
    const lastSubview = usePrevious(subview);

    useEffect(() => {
        if (subview !== lastSubview && !subview) {
            setDirection(Direction.Backward);
        }
    }, [subview, lastSubview]);

    useEffect(() => {
        if (globalModal !== GlobalModal.IntegrationsSettings) {
            setDirection(undefined);
            setSubview(null);
        }
    }, [globalModal]);

    function onClose() {
        setGlobalModal(null);
        setSubview(null);
        // @TODO
        // track(ClientEventType.SeriesSettingsClosedWithoutSaving);
    }

    if (globalModal !== GlobalModal.IntegrationsSettings) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.IntegrationsSettings} onClose={onClose}>
            {!subview ? <TopLevelIntegrationSettings direction={direction} /> : <IntegrationSettingsSubview />}
        </Modal>
    );
}
