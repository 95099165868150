export enum SpinachAPIPath {
    User = '/user',
    VerifyEmail = '/verify-email',
    VerifyGoogle = '/verify-google',
    VerifyGoogleWithCode = '/verify-google-code',
    VerifyCode = '/verify-code',
    Cognito = '/cognito',
    CognitoCheck = '/cognito-check',
    Session = '/session',
    LogOut = '/log-out',

    UserGoal = '/user-goal',

    ExperienceEvent = '/experience-event',

    Logging = '/logging',

    YTBHistory = '/ytb-history',
    YTBHistoryForSeries = '/ytb-history/:seriesId',

    AddSeriesToUser = '/user/series',
    AddUsersToSeries = '/series/:seriesId/users',
    PatchSeries = '/series/:seriesId',

    RecentSignups = '/recent-signups',
    MeetingUUID = '/meeting-uuid',

    History = '/history',
    Series = '/series',
    GetSeries = '/series/:seriesId',
    RemoveUserFromSeries = '/series/:seriesId/users/:userId',

    ZoomCollaborate = '/zoom-collaborate',
    ZoomCollaborateMeetingUUID = '/zoom-collaborate/:meetingUUID',

    // Google Auth
    GoogleAuth = '/google/auth-url',
    GoogleRedirect = '/google/redirect',
    GoogleFinished = '/google/finished',
    GoogleFailed = '/google/failed',

    // Google APIs
    GoogleCalendarEvents = '/google/calendar/events',
    GoogleCalendarEvent = '/google/calendar/events/:eventId',
    GoogleCalendarUser = '/google/calendar/user',
    UpdateScribeOnGoogleCalendarEvent = '/google/calendar/events/:iCalUid/scribe',

    // Jira Auth
    JiraAuth = '/jira/auth-url',
    JiraRedirect = '/jira/redirect',
    JiraFinished = '/jira/finished',
    JiraFailed = '/jira/failed',
    JiraDisconnect = '/jira/disconnect',

    // Jira APIs
    JiraIssue = '/jira/issue/:issueId',
    JiraIssues = '/jira/issues',
    JiraSuggestions = '/jira/suggestions',
    JiraProjects = '/jira/projects',
    JiraUsersForProject = '/jira/projects/:projectId/users',
    JiraIssueSearch = '/jira/issue/search',

    // Asana Auth
    AsanaAuth = '/asana/auth-url',
    AsanaRedirect = '/asana/redirect',
    AsanaFinished = '/asana/finished',
    AsanaFailed = '/asana/failed',
    AsanaDisconnect = '/asana/disconnect',

    // Asana APIs
    AsanaIssue = '/asana/issue/:issueId',
    AsanaIssues = '/asana/issues',
    AsanaSuggestions = '/asana/suggestions',

    // Integration APIs
    IntegrationAuth = '/integration/:oauthProvider/auth-url',
    IntegrationRedirect = '/integration/:oauthProvider/redirect',
    IntegrationFinished = '/integration/:oauthProvider/finished',
    IntegrationFailed = '/integration/:oauthProvider/failed',
    IntegrationDisconnect = '/integration/:oauthProvider/disconnect',

    // Linear Auth
    LinearAuth = '/linear/auth-url',
    LinearRedirect = '/linear/redirect',
    LinearFinished = '/linear/finished',
    LinearDisconnect = '/linear/disconnect',

    // ClickUp Auth
    ClickUpAuth = '/clickup/auth-url',
    ClickUpRedirect = '/clickup/redirect',
    ClickUpFinished = '/clickup/finished',
    ClickUpDisconnect = '/clickup/disconnect',

    // output
    UnsubscribeUserFromSeries = '/unsubscribe/:seriesId/:userId',

    EmailReferral = '/email-referral',

    SlackChannels = '/slack/get-channels',
    SlackMembers = '/slack/get-members',
    SlackChannelSelection = '/slack/channel',
    SlackChannelJoin = '/slack/join-channel',
    SlackUser = '/slack/user',
    SlackUserByEmail = '/slack/user',
    SlackDefaultUserChannel = '/slack/user/channel',
    SlackUsers = '/slack/users',
    SlackSeries = '/slack/series/:seriesId',

    Redirect = '/redirect',
}
